import { useToastService } from "@/services/toast/utils";

export const useTrackingPageToastService = () => {
  const { displaySuccessToast, displayErrorToast } = useToastService();

  const displayEditSuccessToast = (name) => {
    return displaySuccessToast({
      message: `The tracking page ${name} has been updated.`,
      summary: "Tracking Page Updated",
    });
  };

  const displayEditFailToast = (name) => {
    return displayErrorToast({
      message: `The tracking page '${name}' could not be updated.`,
      summary: "Tracking Page Not Updated",
    });
  };

  return {
    displayEditSuccessToast,
    displayEditFailToast,
  };
};
