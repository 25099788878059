import { showToastMessage } from "@/store/toastService";

export const successToastConfig = ({ message, summary, life }) => ({
  severity: "success",
  summary: summary,
  life: life,
  ...(message && { detail: message }),
});

export const warnToastConfig = ({ message, summary, life }) => ({
  severity: "warn",
  summary: summary,
  life: life,
  ...(message && { detail: message }),
});

export const errorToastConfig = ({ message, summary, life }) => ({
  severity: "error",
  summary: summary,
  errorIcon: "pi pi-times-circle",
  life: life,
  ...(message && { detail: message }),
});

export const useToastService = () => {
  const displayErrorToast = ({ message, summary = "Error", life = 5000 }) => {
    return showToastMessage(
      errorToastConfig({
        message: message,
        summary: summary,
        life: life,
      }),
    );
  };

  const displayErrorGenericToast = () => {
    return showToastMessage(
      errorToastConfig({
        message:
          "An error has occurred. Please try again later or contact support if the issue persists.",
        summary: "Oops, something went wrong",
        life: 5000,
      }),
    );
  };

  const displayWarnToast = ({ message, summary = "Warn", life = 5000 }) => {
    return showToastMessage(
      warnToastConfig({
        message: message,
        summary: summary,
        life: life,
      }),
    );
  };

  const displaySuccessToast = ({
    message,
    summary = "Success",
    life = 5000,
  }) => {
    return showToastMessage(
      successToastConfig({
        message: message,
        summary: summary,
        life: life,
      }),
    );
  };

  return {
    displayErrorToast,
    displayErrorGenericToast,
    displaySuccessToast,
    displayWarnToast,
  };
};
