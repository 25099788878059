<template>
  <div
    v-if="shipmentStatus"
    class="header-cards"
    data-cy="shipment-details-header"
  >
    <ShipmentDetailsHeaderShipmentInfo
      :icon="getStatusIcon()"
      extra-id="overflow-visible"
      data-cy="current-status"
    >
      <template #title>Status:</template>
      <template #value>
        <span class="header__value">{{ shipmentStatusDisplayText }}</span>
      </template>
    </ShipmentDetailsHeaderShipmentInfo>
    <ShipmentDetailsHeaderShipmentInfo
      v-if="routeName"
      :icon="ICON_SHIPMENT_DETAILS_ROUTE"
      data-cy="route-assigned"
      :tooltip-text="routeName"
      :show-tooltip="!showTooltip(routeName)"
    >
      <template #title>Campaign:</template>
      <template #value>
        <router-link :to="routeUrlConfig">
          {{ routeName }}
        </router-link>
      </template>
    </ShipmentDetailsHeaderShipmentInfo>
    <ShipmentDetailsHeaderEventInfo
      :icon="ICON_SHIPMENT_DETAILS_CREATED_DATE"
      title="Created:"
      data-cy="created-info"
    >
      <template #content>
        <span class="header__value">
          {{ createdDate }}
        </span>
        <span v-if="!isShipmentErrored" class="header__time">
          {{ createdTime }}
        </span>
      </template>
    </ShipmentDetailsHeaderEventInfo>
    <ShipmentDetailsHeaderEventInfo
      :icon="ICON_SHIPMENT_DETAILS_LAST_EVENT"
      title="Latest Event:"
      data-cy="latest-event"
    >
      <template #content>
        <StatusBadge :status="latestStatus" size="normal">
          {{ latestStatus }}
        </StatusBadge>
        <div v-if="!isShipmentErrored" class="card__last-event-date">
          <span class="header__value header__value--small">
            {{ latestStatusDate }}
          </span>
          <divider layout="vertical" class="card__divider" />
          <span class="header__value header__value--small">
            {{ latestStatusTime }}
          </span>
        </div>
      </template>
    </ShipmentDetailsHeaderEventInfo>
  </div>
  <div v-else>
    <ProgressSpinner />
  </div>
</template>
<script setup>
import {
  ERROR_STATUS,
  EVENT_STATUS_DISPLAY_MAP,
  STATUS_DISPLAY_MAP,
} from "@/constants";
import { computed, toRefs } from "vue";
import {
  getFormatedAwareDate,
  getFormatedAwareTime,
  getFormatedNaiveDate,
  getFormatedNaiveTime,
} from "@/services/utils/datesFormatter";
import StatusBadge from "@/components/StatusBadge.vue";
import Divider from "primevue/divider";
import {
  getShipmentIconForStatus,
  ICON_SHIPMENT_DETAILS_CREATED_DATE,
  ICON_SHIPMENT_DETAILS_LAST_EVENT,
  ICON_SHIPMENT_DETAILS_ROUTE,
} from "@/services/utils/iconsMapper";
import ShipmentDetailsHeaderShipmentInfo from "@/components/shipments/ShipmentDetailsHeaderShipmentInfo.vue";
import ShipmentDetailsHeaderEventInfo from "@/components/shipments/ShipmentDetailsHeaderEventInfo.vue";
import ProgressSpinner from "primevue/progressspinner";
import { ROUTE_NAME_MAX_WIDTH } from "@/components/shipments/helpers/constants";
import { getTextWidth } from "@/services/utils/utils";
import { useWorkflowStore } from "@/components/route-configurations/stores/workflow";

const store = useWorkflowStore();
const { resetWorkflow } = store;

const props = defineProps({
  shipmentCreatedTimestamp: {
    type: String,
    default: "",
  },
  latestEvent: {
    type: Object,
    default: () => {},
  },
  shipmentStatus: {
    type: String,
    default: "",
  },
  routeUrlConfig: {
    type: Object,
    default: () => {},
  },
  routeName: {
    type: String,
    default: "",
  },
});
const {
  shipmentCreatedTimestamp,
  latestEvent,
  shipmentStatus,
  routeUrlConfig,
} = toRefs(props);

const shipmentStatusDisplayText = STATUS_DISPLAY_MAP[shipmentStatus.value];

const createdDate = getFormatedAwareDate(shipmentCreatedTimestamp.value);
const createdTime = getFormatedAwareTime(shipmentCreatedTimestamp.value);

const latestStatus = computed(
  () => EVENT_STATUS_DISPLAY_MAP[latestEvent.value.status],
);
const latestStatusDate = computed(() =>
  getFormatedNaiveDate(latestEvent.value.timestamp),
);
const latestStatusTime = computed(() =>
  getFormatedNaiveTime(latestEvent.value.timestamp),
);

const getStatusIcon = () => getShipmentIconForStatus(shipmentStatus.value);

const isShipmentErrored = shipmentStatus.value === ERROR_STATUS;

const showTooltip = (text) => {
  return getTextWidth(text) > ROUTE_NAME_MAX_WIDTH;
};

await resetWorkflow();
</script>

<style lang="scss" scoped>
.header-cards {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.header--smaller-gap {
  gap: 12px;
}

.header__title {
  color: #616161;
  font-weight: 500;
  font-size: 13px;
  height: 13px;
}

.header__time {
  font-weight: 400;
  font-size: 14px;
  color: #1f2a4e;
  line-height: 14px;
}

.header__date {
  color: #1f2a4e;
  font-weight: 500;
  font-size: 13px;
  height: 13px;
}

.header__value {
  font-weight: 700;
  font-size: 17.5px;
  line-height: 28px;
  color: #1f2a4e;
  height: 28px;
}

.header__value--small {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #1f2a4e;
}

.card__last-event-date {
  display: flex;
  flex-direction: row;
  height: 14px;
  font-weight: 400;
  font-size: 14px;
}

.card__divider {
  padding: 0;
  margin: 0 8px;
}

.card__icon {
  width: 64px;
  height: 64px;
  font-size: 28px;
  display: flex;
  background: #ffffff;
  box-shadow: -2px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 6px;

  justify-content: center;
  align-items: center;
}

.card__icon--no-box {
  color: #737373 !important;
  box-shadow: none;
  font-size: 24px;
  height: 24px;
}
</style>
