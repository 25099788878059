<template>
  <div class="error-modal" data-cy="error-summary-modal">
    <div class="error-modal-header">
      <span class="error-modal-header__filename" data-cy="filename">
        {{ filename }}
      </span>
      <StatusBadge :status="status" is-rounded data-cy="status" />
      <ActionBadge
        data-cy="total-added-badge"
        @mouseover="successBadgeHover = true"
        @mouseleave="successBadgeHover = false"
      >
        <template #label>
          <i class="pi pi-check-circle" /> {{ totalAdded }}
          <span v-if="successBadgeHover">Imported</span>
        </template>
      </ActionBadge>
      <ActionBadge
        data-cy="total-failed-badge"
        @mouseover="failedBadgeHover = true"
        @mouseleave="failedBadgeHover = false"
      >
        <template #label>
          <i class="pi pi-times-circle" /> {{ totalFailed }}
          <span v-if="failedBadgeHover">Failed</span>
        </template>
      </ActionBadge>
    </div>
    <div class="error-modal-bar" data-cy="error-modal-bar">
      See import errors for
      <div class="error-modal-bar__errorNum">{{ totalFailed }}</div>
      failed shipments below:
    </div>
    <DataTable
      :value="rowsFeedback"
      group-rows-by="indexRow"
      row-group-mode="rowspan"
      data-cy="feedback-table"
    >
      <Column header="CSV Row" field="indexRow" />
      <Column header="Shipment Attribute" field="attributeName" />
      <Column header="Error Message" field="error" />
    </DataTable>
    <Button
      class="p-button p-button-outlined error-modal-bar__button"
      data-cy="close-button"
      @click="closeDialog"
    >
      Close
    </Button>
  </div>
</template>
<script setup>
import { inject, ref } from "vue";
import StatusBadge from "@/components/StatusBadge.vue";
import ActionBadge from "@/components/ActionBadge.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import { useGlobalDialogService } from "@/services/modals/base";

const dialogRef = inject("dialogRef");
const { rowsFeedback, filename, status, totalFailed, totalAdded } =
  dialogRef.value.data;
const closeDialog = () => {
  dialogRef.value.close();
};

const successBadgeHover = ref(false);
const failedBadgeHover = ref(false);

const { closeOnRouteChange } = useGlobalDialogService();
closeOnRouteChange();
</script>
<style scoped lang="scss">
.error-modal {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.error-modal-header {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
}

.error-modal-header__filename {
  margin-right: auto;
  color: #495057;
  font-weight: 600;
  font-size: 17.5px;
}

.error-modal-bar {
  background: #fff2f5;
  border: 1px solid #fccad5;
  min-height: 48px;
  color: #a60d2d;
  font-weight: 600;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  border-radius: 6px;

  &__errorNum {
    background: white;
    text-align: center;
    border: 1px solid #fccad5;
    padding: 5.5px 8px 5.5px 8px;
    border-radius: 4px;
  }

  &__button {
    max-width: fit-content;
    margin-left: auto;
  }
}

.status-badge {
  height: 24px;
  font-size: 11px;
  padding: 6px 12px;
}

:deep(.action-badge) {
  transition: all 0.5s linear;
  max-width: 50px;

  .action-badge__expanded {
    opacity: 0;
  }
}

:deep(.action-badge:hover) {
  overflow: hidden;
  max-width: 200px;

  .action-badge__expanded {
    opacity: 1;
  }
}
</style>
