import { useDialog } from "primevue/usedialog";

export const useOpenDialogMethod = () => {
  const dialog = useDialog();

  const openDialog = ({ component, dialogProps, componentData, onClose }) => {
    return dialog.open(component, {
      props: dialogProps,
      data: componentData,
      onClose: onClose,
    });
  };

  return { openDialog };
};
