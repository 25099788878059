<template>
  <div class="p-dialog-content">
    <span class="p-confirm-dialog-message">{{ dialogMsg }}</span>
  </div>
  <div class="confirm-input">
    <InputText
      v-model="userInput"
      autofocus
      placeholder="Name of automation..."
      data-cy="confirm-phrase-input"
      @keyup.enter="takeAction()"
    />
  </div>
  <div class="p-dialog-footer">
    <Button
      label="Cancel"
      class="p-button p-button-text"
      data-cy="confirm-phrase-reject-btn"
      @click="dialogRef.close()"
    />
    <Button
      :label="confirmBtn.label"
      :class="'p-button'"
      :icon="confirmBtn.icon"
      :disabled="disableSubmit"
      data-cy="submit-input-dialog-btn"
      @click="takeAction()"
    />
  </div>
</template>

<script setup>
import { ref, inject, computed, onMounted } from "vue";
import Button from "primevue/button";
import InputText from "primevue/inputtext";

const dialogRef = inject("dialogRef");
const dialogProps = dialogRef.value.options.props;
const dialogMsg = dialogProps.message;
const confirmBtn = dialogProps.confirmBtn;
const userInput = ref("");
const disableSubmit = computed(() => {
  return !userInput.value;
});

const takeAction = () => {
  if (!disableSubmit.value) {
    confirmBtn.callback(userInput.value);
    dialogRef.value.close();
  }
};

onMounted(() => {
  const containerDiv = document.getElementsByClassName("p-dialog-content")[0];
  containerDiv.classList.remove("p-dialog-content");
  containerDiv.parentElement.classList.add("p-confirm-dialog");
});
</script>
<style scoped lang="scss">
.p-dialog-content {
  margin-top: -20px;
  .p-confirm-dialog-message {
    margin-top: 4px;
    margin-left: 0;
  }
}
.p-dialog-title {
  color: #1f2a4e;
  font-weight: 600;
  font-size: 21px;
  line-height: 25px;
}

.p-dialog-footer {
  padding-top: 0;
}
.confirm-input {
  padding: 24px 21px 28px 24px;
  background: #fff;
  span {
    font-weight: 600;
  }
  p {
    margin-bottom: 0;
  }
  input {
    width: 100%;
    height: 42px;
  }
}
button {
  transition: all 0.3s;
}
</style>
