<template>
  <div class="shipment-status" :class="statusClass">
    <i
      v-tooltip.top="{
        value: toolTip,
        id: 'ListShipmentsTableStatusTooltip',
      }"
      :class="iconClass"
    ></i>
  </div>
</template>

<script setup>
import { toRefs } from "vue";

const props = defineProps({
  status: {
    default: "",
    type: String,
  },
});

const { status } = toRefs(props);
const statusMap = {
  "Campaign Assigned": {
    statusClass: "status-import-routed",
    toolTip: "Campaign Assigned!",
    iconClass: "pi pi-check-circle text-success",
  },
  "No Campaign Assigned": {
    statusClass: "status-no-route",
    toolTip: "No campaign assigned. Please review your campaign segmentation.",
    iconClass: "pi pi-exclamation-triangle text-warning",
  },
  "Import Failed": {
    statusClass: "status-import-error",
    toolTip: "Import Failed. Click for more details.",
    iconClass: "pi pi-ban text-danger",
  },
};

const { statusClass, toolTip, iconClass } = statusMap[status.value];
</script>

<style lang="scss" scoped>
i.text-success {
  color: #1fb256 !important;
  font-size: 14px;
}
i.text-warning {
  color: #d99816 !important;
  font-size: 14px;
}
i.text-danger {
  color: #cc143b !important;
  font-size: 14px;
}
</style>
