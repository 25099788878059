<template>
  <FormFieldContainer>
    <template #field>
      <div class="column">
        <label v-if="label" for="type" class="label">{{ label }}</label>
        <MultiSelect
          :model-value="innerValue"
          :name="name"
          :options="options"
          option-label="name"
          option-value="id"
          :option-disabled="optionDisabled"
          :panel-class="`${name}-multi-dropdown-options`"
          :placeholder="placeholder"
          :class="[$attrs.class, { 'p-invalid': isError() }]"
          :data-cy="`multi-dropdown-${name}`"
          :disabled="disabled"
          display="chip"
          @update:model-value="onChange"
        />
        <small
          v-if="isError()"
          id="email-help"
          class="p-error error-label"
          data-cy="form-error-msg"
        >
          {{ errorMessage }}
        </small>
      </div>
    </template>
  </FormFieldContainer>
</template>

<script setup>
import { toRef, watch } from "vue";
import { useField } from "vee-validate";
import MultiSelect from "primevue/multiselect";
import FormFieldContainer from "@/components/FormFieldContainer";

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: "",
  },
  options: {
    type: Array,
    required: true,
  },
  placeholder: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  optionDisabled: {
    type: [String, Function],
    default: "option-disabled",
  },
});

const isError = () => !!errorMessage.value;
const emit = defineEmits(["update:modelValue"]);
const fieldValue = toRef(props, "modelValue");
const fieldName = toRef(props, "name");

const onChange = (e) => {
  emit("update:modelValue", e);
  setValue(e);
};

watch(
  () => fieldValue.value,
  (newVal) => setValue(newVal),
);

const {
  errorMessage,
  value: innerValue,
  setValue,
} = useField(fieldName, undefined, {
  initialValue: fieldValue,
  syncVModel: false,
});
</script>
<style scoped lang="scss">
:deep(.pi-times-circle) {
  color: #495057;
}
</style>
