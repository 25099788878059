<template>
  <div class="tracking-page-images-container">
    <div class="tracking-page-images">
      <!-- Header -->
      <div class="tracking-page-header">
        <div class="tracking-page-logo-placeholder">
          <h2>LOGO</h2>
        </div>
      </div>
      <!-- Content -->
      <div class="tracking-page-content">
        <div class="upload-images-container">
          <div class="top-row-images">
            <div
              class="img-upload-container left-img-container"
              data-cy="left-image-config"
            >
              <TrackingPageImageConfigField
                :image-type="LEFT_IMAGE"
                :image="leftImage"
              />
            </div>
            <TrackingPageContentPlaceholder
              heading="Tracking Timeline"
              :icon-class="'icon-timeline-placeholder'"
            />
            <div
              v-if="showRightImageUpload"
              class="img-upload-container right-img-container"
              data-cy="right-image-config"
            >
              <TrackingPageImageConfigField
                :image-type="RIGHT_IMAGE"
                :image="rightImage"
              />
            </div>
            <TrackingPageContentPlaceholder
              v-else
              heading="Order Details"
              :icon-class="'icon-order-details-placeholder'"
            />
          </div>
          <div class="bottom-row-images" data-cy="bottom-image-config">
            <TrackingPageImageConfigField
              :image-type="BOTTOM_IMAGE"
              :image="bottomImage"
            />
          </div>
        </div>
      </div>
      <!-- Footer -->
      <div class="preview-footer">
        <img
          src="@/assets/img/powered-by-scurri-logo.png"
          alt="Powered By Scurri"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, toRefs } from "vue";
import {
  GENERIC_TEMPLATE,
  RIGHT_IMAGE,
  LEFT_IMAGE,
  BOTTOM_IMAGE,
} from "@/constants";
import TrackingPageImageConfigField from "@/components/tracking-pages/TrackingPageImageConfigField";
import TrackingPageContentPlaceholder from "@/components/tracking-pages/TrackingPageContentPlaceholder";
import { useTrackingPageEditStore } from "@/components/tracking-pages/stores/tracking-page";
import { storeToRefs } from "pinia";

const props = defineProps({
  templateName: {
    type: String,
    default: "",
  },
});

const { templateName } = toRefs(props);
const editTrackingStore = useTrackingPageEditStore();

const { leftImage, rightImage, bottomImage } = storeToRefs(editTrackingStore);
const showRightImageUpload = computed(() => {
  return templateName.value === GENERIC_TEMPLATE;
});
</script>
<style scoped lang="scss">
.tracking-page-images-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  outline: 1px solid #dadee3;
  background: #fcfcfc;

  .tracking-page-images {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    background: #fcfcfc;

    .tracking-page-header {
      display: flex;
      height: 56px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-bottom: 1px solid #ebebeb;
      background: #fff;

      @media screen and (max-width: 1280px) {
        height: 40px;
      }

      .tracking-page-logo-placeholder {
        color: #64748b;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        margin-bottom: 0px;

        h2 {
          color: #64748b;
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px;
          margin-bottom: 0;

          @media screen and (max-width: 1280px) {
            font-size: 20px;
            line-height: 20px;
          }
        }
      }
    }

    .tracking-page-content {
      display: flex;
      padding: 24px;
      justify-content: center;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;

      @media screen and (max-width: 1280px) {
        padding: 16px;
      }

      .upload-images-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        width: 100%;

        @media screen and (max-width: 1280px) {
          gap: 16px;
        }

        @media screen and (min-width: 1441px) {
          max-width: 1320px;
        }

        .top-row-images {
          display: flex;
          height: 420px;
          align-items: flex-start;
          gap: 24px;
          align-self: stretch;

          @media screen and (min-width: 1441px) {
            height: 55vh;
          }

          @media screen and (max-width: 1280px) {
            height: 360px;
            gap: 16px;
          }

          .img-upload-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 24px;
            align-self: stretch;
            border-radius: 4px;
            border: 1px solid #dadee3;
            background: #fff;
            flex: 1 0 0;
          }
        }

        .bottom-row-images {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 24px;
          align-self: stretch;
          border-radius: 4px;
          border: 1px solid #dadee3;
          background: #fff;
          height: 260px;

          @media screen and (min-width: 1441px) {
            height: 32vh;
          }

          @media screen and (max-width: 1280px) {
            height: 216px !important;
          }
        }
      }
    }
  }

  .preview-footer {
    display: flex;
    height: 48px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-top: 1px solid #ebebeb;
    background: #fff;

    @media screen and (max-width: 1280px) {
      height: 40px;
    }
    img {
      display: flex;
      width: auto;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 1280px) {
        height: 16px;
      }
    }
  }
}
</style>
