<template>
  <div class="image-uploader">
    <span class="uploader-title"
      >{{ label }} image: {{ reqWidth }}(w) x {{ reqHeight }}(h) px</span
    >
    <FileUploader
      ref="fileUploader"
      v-model:files="localSelectedFiles"
      accept=".jpg,.jpeg,.png,.webp,.gif"
      :size-limit="maxImgSize"
      content-icon="pi pi-image"
      :is-loading="isLoading"
      @on-select="onImageSelect"
      @on-error="onFileUploaderError"
    >
      <template #empty="{ onChoose }">
        <div :class="['file-upload__content-container', emptyClass]">
          <Button
            class="p-button p-button-tertiary-outlined"
            data-cy="file-input-button"
            @mouseup="onChoose"
            @keydown.enter="onChoose"
          >
            <i class="pi pi-plus" />
            <span class="p-button-label">Select Image</span>
          </Button>
        </div>
        <div class="file-upload__description-container">
          <span class="file-upload--instruction">or drag and drop</span>
        </div>
        <div v-if="errors">
          <p
            v-for="(error, i) in errors"
            :key="i"
            class="error-label"
            data-cy="error-message"
          >
            {{ error }}
          </p>
        </div>
      </template>
      <template #content="{ removeFile }">
        <div class="card card-body no-margin file-uploaded">
          <div class="file-uploaded-status">
            <i class="pi pi-check-circle uploaded-icon" />
            <h6 class="file-details__filename">{{ label }} Image</h6>
          </div>

          <div class="uploader-buttons">
            <Button
              class="p-button p-button-tertiary-outlined p-button-icon csv-action-button"
              data-cy="clear-button"
              @click="removeFile"
            >
              <i class="pi pi-times" />
              Remove
            </Button>
          </div>
        </div>
      </template>
    </FileUploader>
    <span class="max-file-size">
      Max. file size: {{ formatSize(maxImgSize) }}. Formats: JPEG, PNG, WebP and
      GIF
    </span>
  </div>
</template>
<script setup>
import FileUploader from "@/components/FileUploader.vue";
import { computed, toRefs, ref } from "vue";
import { formatSize, readImage } from "@/services/utils/filesUtils";
import Button from "primevue/button";

const props = defineProps({
  selectedFiles: {
    type: Array,
    default: () => [],
  },
  reqWidth: {
    type: Number,
    required: true,
  },
  reqHeight: {
    type: Number,
    required: true,
  },
  label: {
    type: String,
    default: "",
  },
  maxImgSize: {
    type: String,
    default: "2000000",
  },
  fieldError: {
    type: String,
    default: "",
  },
});

const { selectedFiles, reqWidth, reqHeight, label, maxImgSize, fieldError } =
  toRefs(props);

const isLoading = ref(false);
const fileUploader = ref(null);
const fileUploaderErrors = ref([]);

const emit = defineEmits(["update:selectedFiles"]);

const localSelectedFiles = computed({
  get() {
    return selectedFiles.value;
  },
  set(newFile) {
    emit("update:selectedFiles", newFile);
  },
});

const onImageSuccess = (newContent) => {
  selectedFiles.value[0].data = newContent;
  emit("update:selectedFiles", selectedFiles.value);
};

const onImageError = (errorMessage) => {
  fileUploader.value.clearAndSetErrors(errorMessage);
};

const errors = computed(() => {
  // uploader errors have priority over field errors
  return fileUploaderErrors.value.length > 0
    ? fileUploaderErrors.value
    : [fieldError.value];
});

const emptyClass = computed(() => {
  return errors.value.length > 0 ? "is-error" : "empty";
});

const onFileUploaderError = (errorMessages) => {
  fileUploaderErrors.value = errorMessages;
};

const onImageSelect = async (fileToUpload) => {
  isLoading.value = true;
  fileUploaderErrors.value = [];
  const { errorMessage, readFilesContent } = await readImage(
    fileToUpload,
    reqWidth.value,
    reqHeight.value,
  );
  if (errorMessage.value) {
    onImageError(errorMessage.value);
  } else {
    onImageSuccess(readFilesContent.value[0]);
  }
  isLoading.value = false;
};
</script>
<style scoped lang="scss">
:deep(.file-upload) {
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  border: 1px dashed #ccc;
  border-radius: 6px;
  padding: 20px;
  min-height: 136px;

  &:has(.file-uploaded) {
    border: 1px solid #ccc;
    background: #f7f8f9;
    max-height: 136px;
  }

  &:has(.is-error) {
    border: 1px dashed #ed244e;
  }
}

.image-uploader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;

  .uploader-title,
  .max-file-size {
    font-style: normal;
    font-weight: 400;
  }

  .uploader-title {
    color: #212121;
    font-size: 14px;
    line-height: 21px;
  }
  .max-file-size {
    color: #6c757d;
    font-size: 12px;
    line-height: 20px;
  }

  .file-upload--instruction {
    color: #6c757d;
  }

  .file-uploaded {
    gap: 8px;
    background: none;
    margin-bottom: 0;

    .file-details__filename {
      color: #6c757d;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
      margin: 0;
    }

    .file-uploaded-status {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: stretch;
      gap: 4px;
    }

    .uploaded-icon {
      font-size: 28px;
      color: #6c757d;
    }
  }

  .error-label {
    font-size: 12px;
    color: #ed244e;
    font-weight: 400;
    text-align: center;
    margin-bottom: 0;
  }
}
</style>
