<template>
  <ImportProfileStepMapping
    :selected-attributes="selectedAttributes"
    :parsed-csv-file="parsedCsvFile"
    :profile-name="profileName"
    :number-of-columns="numberOfColumns"
    page-title="Step 3: Field Mapping"
    button-submit-label="Create Profile"
    @on-submit-csv-profile="createCsvProfile"
    @update:selected-attributes="setSelectedAttributes"
  />
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useCsvProfileCreateStore } from "@/components/csv-import/stores/csvProfile";
import ImportProfileStepMapping from "@/components/csv-import/ImportProfileStepMapping.vue";
import { useCsvProfileToastService } from "@/components/csv-import/helpers/toastConfigs.js";
import { useCsvProfileCreationRequest } from "@/components/csv-import/helpers/csvProfileCreateEditRequests";
import { csvErrorMapper } from "@/components/csv-import/helpers/errors";
import { useCompanyStateManagement } from "@/queries";
import CsvApiRequests from "@/components/csv-import/helpers/apiRequests";

const csvImportProfileStore = useCsvProfileCreateStore();
const {
  parsedCsvFile,
  profileName,
  numberOfColumns,
  selectedAttributes,
  isSubmitting,
} = storeToRefs(csvImportProfileStore);
const { setSelectedAttributes } = csvImportProfileStore;

const { displayCsvProfileCreationSuccessToast } = useCsvProfileToastService();

const { invalidateCompanyState } = useCompanyStateManagement();
const { makeCsvProfileRequest } = useCsvProfileCreationRequest();
const createCsvProfile = async (profileDetails) => {
  isSubmitting.value = true;
  const { error } = await makeCsvProfileRequest({
    endpoint: CsvApiRequests.createCsvImportProfile({
      profileName: profileDetails.profileName,
      mappings: profileDetails.selectedAttributes,
      sample: profileDetails.sample,
    }),
    onSuccess: () => displayCsvProfileCreationSuccessToast(profileName.value),
    errorMapper: (errors) => csvErrorMapper(errors, profileName.value),
  });
  if (!error.value) {
    csvImportProfileStore.resetStore();
    await invalidateCompanyState();
  }
};
</script>
