import { httpRequest } from "@/services/apiRequests";

class RoutesApiRequests {
  static async getRouteConfiguration(configId, version) {
    const { data } = await httpRequest.get(
      `route-configurations/${configId}/versions/${version}`,
    );
    return {
      name: data.name,
      sinceBeenDeleted: data.since_been_deleted,
      isLatestVersion: data.is_latest_version,
      routes: data.routes.map((route) => ({
        name: route.name,
        isActive: route.is_active,
        shipmentFilters: route.shipment_filters,
        routeOptions: route.route_options.map((option) => ({
          triggeredStatuses: option.triggered_statuses,
          scheduledActions: option.scheduled_actions.map((action) => ({
            actionId: action.action_id,
            scheduleId: action.schedule_id,
          })),
        })),
      })),
    };
  }

  static async getRouteConfigurationSetups(configId, version) {
    const url =
      version === "latest"
        ? "route-configuration-setups/"
        : `route-configuration-setups/${configId}/${version}/`;
    const { data } = await httpRequest.get(url);
    return {
      shipmentFilters: data.shipment_filters.map((filter) => ({
        id: filter.id,
        name: filter.name,
      })),
      statuses: data.statuses.map((status) => ({
        id: status.id,
        name: status.name,
        deleted: status.deleted,
        isAny: status.is_any,
      })),
      actions: data.actions.map((action) => ({
        id: action.id,
        name: action.name,
      })),
      schedules: data.schedules.map((schedule) => ({
        id: schedule.id,
        name: schedule.name,
      })),
    };
  }

  static mapAndSortRouteConfigurations(configurations) {
    let mappedData = configurations.map((configuration) => ({
      allowedActions: configuration.allowed_actions,
      id: configuration.id,
      name: configuration.name,
      status: configuration.status,
      isActive: configuration.status.toLowerCase() === "active",
    }));
    return mappedData.sort((c) => (c.isActive ? -1 : 1));
  }

  static async createConfiguration({ name, routes, uuid }) {
    const formData = {
      name,
      uuid,
      routes: routes.map((route) => ({
        name: route.name,
        is_active: route.isActive,
        uuid: route.uuid,
        shipment_filters: route.shipmentFilters,
        route_options: route.routeOptions.map((option) => ({
          uuid: option.uuid,
          triggered_statuses: option.triggeredStatuses,
          scheduled_actions: option.scheduledActions.map((action) => ({
            action_id: action.actionId,
            schedule_id: action.scheduleId,
          })),
        })),
      })),
    };
    const { data } = await httpRequest.post("route-configurations/", formData);
    return this.mapAndSortRouteConfigurations(data);
  }

  static async updateRouteConfiguration({ id, name, routes }) {
    const formData = {
      name,
      routes: routes.map((route) => ({
        name: route.name,
        is_active: route.isActive,
        shipment_filters: route.shipmentFilters,
        route_options: route.routeOptions.map((option) => ({
          triggered_statuses: option.triggeredStatuses,
          scheduled_actions: option.scheduledActions.map((action) => ({
            action_id: action.actionId,
            schedule_id: action.scheduleId,
          })),
        })),
      })),
    };

    const { data } = await httpRequest.put(
      `route-configurations/${id}`,
      formData,
    );
    return this.mapAndSortRouteConfigurations(data);
  }

  static async duplicateRouteConfiguration(id) {
    const { data } = await httpRequest.post(
      `route-configurations/${id}/duplicate/`,
    );
    return this.mapAndSortRouteConfigurations(data);
  }

  static async activateRouteConfiguration(id) {
    const { data } = await httpRequest.post(
      `route-configurations/${id}/activate/`,
    );
    return this.mapAndSortRouteConfigurations(data);
  }

  static async deactivateRouteConfiguration() {
    const { data } = await httpRequest.post(`route-configurations/deactivate/`);
    return this.mapAndSortRouteConfigurations(data);
  }

  static async getRouteConfigurations() {
    const { data } = await httpRequest.get("route-configurations/");
    return this.mapAndSortRouteConfigurations(data);
  }

  static async deleteConfiguration(id) {
    const { data } = await httpRequest.delete(`route-configurations/${id}`);
    return this.mapAndSortRouteConfigurations(data);
  }
}

export default RoutesApiRequests;
