<template>
  <div class="card shadow">
    <div class="card-body">
      <NestedEmpty
        heading="Oops! Something went wrong!"
        :message="emptyMessage"
        icon-class="icon-diamond-workflow-small"
        btn-icon-class="pi pi-arrow-left"
        btn-label="Back to Automations"
        style-id="config-warning"
        @button-action="navigateToView('ListNotificationWorkflows')"
      />
    </div>
  </div>
</template>
<script setup>
import NestedEmpty from "@/components/NestedEmpty";
import { Navigator } from "@/services/utils/navigator";

const { navigateToView } = Navigator();
const emptyMessage = `We encountered an error while creating the automation.
Please contact our support team for further assistance.`;
</script>
