<template>
  <div
    v-if="isConfig"
    :id="emptyConfig.extraId"
    class="empty-page"
    data-cy="empty-page"
  >
    <div
      class="flex flex-wrap align-items-center justify-content-center empty-message-container"
    >
      <div
        class="align-self-center flex align-items-center justify-content-center empty-content"
      >
        <div :class="'empty-icon-image ' + emptyConfig.iconClass"></div>
        <div class="empty-text">
          <div class="empty-headline">
            <h2>{{ emptyConfig.heading }}</h2>
            <p>{{ emptyConfig.tagline }}</p>
          </div>
          <div class="empty-message-buttons">
            <Button
              v-if="emptyConfig.buttonPrimary"
              class="p-button"
              data-cy="primary-empty-state-button"
              @click="emit('buttonPrimaryAction')"
            >
              <i :class="emptyConfig.buttonPrimary.iconClass" />{{
                emptyConfig.buttonPrimary.label
              }}
            </Button>
            <Button
              v-if="emptyConfig.buttonSecondary"
              class="p-button p-button-outlined"
              data-cy="secondary-empty-state-button"
              @click="emit('buttonSecondaryAction')"
            >
              <i :class="emptyConfig.buttonSecondary.iconClass" />{{
                emptyConfig.buttonSecondary.label
              }}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Button from "primevue/button";
import { computed, toRefs } from "vue";

const props = defineProps({
  emptyConfig: {
    default: null,
    type: Object,
  },
});

const { emptyConfig } = toRefs(props);
const isConfig = computed(() => Object.keys(emptyConfig.value).length);

const emit = defineEmits(["buttonPrimaryAction", "buttonSecondaryAction"]);
</script>
<style scoped lang="scss">
.empty-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .empty-message-container {
    text-align: center;
    vertical-align: center;

    .empty-content {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .empty-text {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .empty-headline {
          display: flex;
          flex-direction: column;
          gap: 4px;
        }
      }

      .empty-icon-image {
        background-repeat: no-repeat;
        width: 160px;
        height: 185px;
        margin: 0 auto;
      }

      .small-diamond {
        height: 145px;
        width: 125px;
        margin: 0 auto;
      }

      h2 {
        font-weight: 600;
        font-size: 28px;
        color: #556376;
        line-height: 34px;
        margin-bottom: 0;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #64748b;
        margin-bottom: 0;
      }

      .empty-message-buttons {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        margin: 0 auto;
      }
    }
  }
}

#nested-empty-page {
  height: calc(100vh - 186px);
}
</style>
