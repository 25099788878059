import {
  ERROR_STATUS,
  FAILED_NOTIFICATION_STATUS,
  PENDING_NOTIFICATION_STATUS,
  ROUTED_STATUS,
  ROUTING_FAILED_STATUS,
  TRIGGERED_NOTIFICATION_STATUS,
} from "@/constants";

export const ICON_PENDING_NOTIFICATION = "pi pi-clock";
export const ICON_TRIGGERED_NOTIFICATION = "pi pi-send";
export const ICON_FAILED_NOTIFICATION = "pi pi-times-circle";

export const ICON_ERROR_SHIPMENT = "pi pi-ban";
export const ICON_ROUTING_FAILED_SHIPMENT = "pi pi-exclamation-triangle";
export const ICON_ROUTED_SHIPMENT = "pi pi-check-circle";

export const ICON_SHIPMENT_DETAILS_ROUTE = "pi pi-megaphone";
export const ICON_SHIPMENT_DETAILS_CREATED_DATE = "pi pi-download";
export const ICON_SHIPMENT_DETAILS_LAST_EVENT = "pi pi-sync";

export const ICON_NOTIFICATION_STATUS_MAP = {
  [PENDING_NOTIFICATION_STATUS]: ICON_PENDING_NOTIFICATION,
  [TRIGGERED_NOTIFICATION_STATUS]: ICON_TRIGGERED_NOTIFICATION,
  [FAILED_NOTIFICATION_STATUS]: ICON_FAILED_NOTIFICATION,
};

export const ICON_SHIPMENT_STATUS_MAP = {
  [ERROR_STATUS]: ICON_ERROR_SHIPMENT,
  [ROUTING_FAILED_STATUS]: ICON_ROUTING_FAILED_SHIPMENT,
  [ROUTED_STATUS]: ICON_ROUTED_SHIPMENT,
};

export const getShipmentIconForStatus = (shipmentStatus) => {
  return ICON_SHIPMENT_STATUS_MAP[shipmentStatus];
};

export const getNotificationIconForStatus = (notificationStatus) => {
  return ICON_NOTIFICATION_STATUS_MAP[notificationStatus];
};
