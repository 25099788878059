import { useConfirmDialog } from "@/services/confirm-modals/base";

const confirmReEvaluateShipmentsConfig = () => {
  return {
    header: "Recheck Shipments?",
    message: `This will attempt to assign a campaign to your shipments.
    Are you sure you want to proceed?`,
    acceptLabel: "Yes, continue",
    rejectLabel: "Cancel",
    rejectClass: "p-button p-button-text",
    defaultFocus: "reject",
  };
};

const confirmReEvaluateSingleShipmentConfig = () => {
  return {
    header: "Recheck Shipment?",
    message: `This will attempt to assign a campaign to your shipment.
    Are you sure you want to proceed?`,
    acceptLabel: "Yes, continue",
    rejectLabel: "Cancel",
    rejectClass: "p-button p-button-text",
    defaultFocus: "reject",
  };
};

const confirmBulkDeleteImportErrorConfig = () => {
  return {
    header: "Delete Shipments?",
    message: `Are you sure you want to delete all import failed shipments?
    Note: this action cannot be undone.`,
    acceptLabel: "Delete",
    acceptClass: "p-button p-button-danger",
    rejectLabel: "Cancel",
    rejectClass: "p-button p-button-text",
    defaultFocus: "reject",
  };
};

export const useShipmentsConfirmDialog = () => {
  const { confirmAction } = useConfirmDialog();

  const confirmReEvaluateShipments = ({ callback }) => {
    return confirmAction({
      callback,
      ...confirmReEvaluateShipmentsConfig(),
    });
  };

  const confirmReEvaluateSingleShipment = ({ callback }) => {
    return confirmAction({
      callback,
      ...confirmReEvaluateSingleShipmentConfig(),
    });
  };

  const confirmBulkDeleteImportError = ({ callback }) => {
    return confirmAction({
      callback,
      ...confirmBulkDeleteImportErrorConfig(),
    });
  };

  return {
    confirmReEvaluateShipments,
    confirmBulkDeleteImportError,
    confirmReEvaluateSingleShipment,
  };
};
