import { useToastService } from "@/services/toast/utils";

export const useWorkflowToastService = () => {
  const { displaySuccessToast, displayErrorToast, displayErrorGenericToast } =
    useToastService();

  const displayDeleteSuccessToast = (name) => {
    return displaySuccessToast({
      message: `The automation ${name} has been successfully deleted.`,
      summary: "Automation Deleted",
    });
  };

  const displayDeleteFailToast = (name) => {
    return displayErrorToast({
      message: `The automation ${name} has failed to delete.`,
      summary: "Automation Deletion Failed",
    });
  };

  const displayDuplicateSuccessToast = (name) => {
    return displaySuccessToast({
      message: `The automation ${name} has been successfully duplicated.`,
      summary: "Automation Duplicated",
    });
  };

  const displayWorkflowDuplicateFailToast = (name) => {
    return displayErrorToast({
      message: `The automation ${name} has failed to duplicate.`,
      summary: `Automation Duplication Failed`,
    });
  };

  const displayCreateSuccessToast = (name) => {
    return displaySuccessToast({
      message: `The automation ${name} has been created.`,
      summary: "Automation Created",
    });
  };

  const displayEditSuccessToast = (name) => {
    return displaySuccessToast({
      message: `The automation ${name} has been updated.`,
      summary: "Automation Updated",
    });
  };

  const displayWorkflowCreateNameAlreadyExists = (name) => {
    return displayErrorToast({
      message: `Creation was unsuccessful as an automation with the name ${name} already exists.`,
      summary: "Automation Creation Failed",
    });
  };

  const displayWorkflowEditNameAlreadyExists = (name) => {
    return displayErrorToast({
      message: `Editing was unsuccessful as an automation with the name ${name} already exists.`,
      summary: "Automation Edit Failed",
    });
  };

  const displayWorkflowDeletionSuccessToast = (name) => {
    return displaySuccessToast({
      message: `The automation ${name} has been successfully deleted.`,
      summary: `Automation Deleted`,
    });
  };

  const displayWorkflowDeletionFailToast = (name) => {
    return displayErrorToast({
      message: `The automation ${name} has failed to delete.`,
      summary: `Automation Deletion Failed`,
    });
  };

  const displayWorkflowActivateSuccessToast = (name) => {
    return displaySuccessToast({
      message: `The automation ${name} has been successfully activated.`,
      summary: `Automation Activated`,
    });
  };

  const displayWorkflowActivateFailToast = (name) => {
    return displayErrorToast({
      message: `Unable to activate the automation ${name} at this time. Please try again later.`,
      summary: `Automation Activation Failed`,
    });
  };

  const displayWorkflowDeactivateSuccessToast = (name) => {
    return displaySuccessToast({
      message: `The automation ${name} has been successfully deactivated.`,
      summary: `Automation Deactivated`,
    });
  };

  const displayWorkflowDeactivateFailToast = (name) => {
    return displayErrorToast({
      message: `Unable to deactivate the automation ${name} at this time. Please try again later.`,
      summary: `Automation Deactivation Failed`,
    });
  };

  const displayRouteSuccessToast = (state, name) => {
    return displaySuccessToast({
      message: `The campaign ${name} has been successfully ${state.toLowerCase()}.`,
      summary: `Campaign ${state}`,
    });
  };

  const displayWorkflowRoutesReorderedToast = () => {
    return displaySuccessToast({
      message: `Campaigns have been successfully reordered`,
      summary: `Campaigns Reordered`,
    });
  };

  return {
    displayDeleteSuccessToast,
    displayDeleteFailToast,
    displayDuplicateSuccessToast,
    displayWorkflowDuplicateFailToast,
    displayCreateSuccessToast,
    displayEditSuccessToast,
    displayWorkflowCreateNameAlreadyExists,
    displayWorkflowEditNameAlreadyExists,
    displayErrorGenericToast,
    displayWorkflowActivateSuccessToast,
    displayWorkflowActivateFailToast,
    displayWorkflowDeactivateSuccessToast,
    displayWorkflowDeactivateFailToast,
    displayWorkflowDeletionSuccessToast,
    displayWorkflowDeletionFailToast,
    displayRouteSuccessToast,
    displayWorkflowRoutesReorderedToast,
  };
};
