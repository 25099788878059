<template>
  <tr :class="configuration.isActive ? 'active-row' : 'inactive-row'">
    <td
      class="col-6 d-inline-flex flex-direction-row align-items-center route-config-info"
      data-cy="config-info"
    >
      <InputSwitch
        :model-value="configuration.isActive"
        @change="activateToggleClicked"
      />
      <h6>{{ configuration.name }}</h6>
      <span v-if="configuration.isActive" class="route-active-label">
        Active
      </span>
    </td>
    <td class="col-6 d-inline-flex justify-content-end align-items-center">
      <Button
        v-if="allowEdit"
        class="p-button p-button-tertiary-outlined"
        data-cy="btn-edit"
        @click="onEditWorkflow"
      >
        Edit
      </Button>
      <Button
        type="button"
        class="p-button p-button-text p-button-icon-only mx-2"
        aria-haspopup="true"
        aria-controls="overlay_menu"
        data-cy="more-options-btn"
        @click="toggle"
      >
        <i class="pi pi-ellipsis-v" />
      </Button>
      <Menu
        ref="menu"
        class="overlay-menu"
        :model="subMenuItems"
        :popup="true"
        data-cy="more-options-menu"
      />
    </td>
  </tr>
</template>
<script setup>
import { ref, computed, toRefs } from "vue";
import InputSwitch from "primevue/inputswitch";
import Button from "primevue/button";
import Menu from "primevue/menu";
import { useWorkflowsConfirmDialog } from "@/components/route-configurations/helpers/confirmModals";
import { useConfirmPhraseDialog } from "@/services/modals/confirmPhraseDialog";

const { confirmWorkflowDuplication, confirmWorkflowActivation } =
  useWorkflowsConfirmDialog();
const { confirmWorkflowDeletion, confirmWorkflowDeactivation } =
  useConfirmPhraseDialog();
const menu = ref();

const toggle = (event) => {
  menu.value.toggle(event);
};

const props = defineProps({
  configuration: {
    default: null,
    type: Object,
  },
  hasActiveWorkflow: {
    default: false,
    type: Boolean,
  },
});
const { configuration, hasActiveWorkflow } = toRefs(props);

const emit = defineEmits([
  "activateRouteConfiguration",
  "deactivateRouteConfiguration",
  "duplicateRouteConfiguration",
  "deleteRouteConfiguration",
  "navigateToEdit",
]);

const subMenuItems = computed(() => {
  let menuItems = [];
  if (configuration.value.allowedActions.includes("delete")) {
    menuItems.push({
      label: "Delete",
      icon: "pi pi-trash",
      command: () => confirmDeletion(),
    });
  }
  if (configuration.value.allowedActions.includes("duplicate")) {
    menuItems.push({
      label: "Duplicate",
      icon: "pi pi-clone",
      command: () => confirmDuplication(),
    });
  }
  return menuItems;
});

const allowEdit = computed(() => {
  return configuration.value.allowedActions.includes("edit");
});

const confirmDuplication = () => {
  confirmWorkflowDuplication({
    callback: async () =>
      await emit(
        "duplicateRouteConfiguration",
        configuration.value.id,
        configuration.value.name,
      ),
  });
};

const activateToggleClicked = () => {
  if (configuration.value.isActive) {
    confirmDeactivation();
  } else {
    confirmActivation();
  }
};

const confirmActivation = () => {
  confirmWorkflowActivation({
    callback: async () =>
      await emit(
        "activateRouteConfiguration",
        configuration.value.id,
        configuration.value.name,
      ),
    hasActiveWorkflow: hasActiveWorkflow.value,
  });
};

const confirmDeletion = () => {
  confirmWorkflowDeletion({
    callback: async () =>
      await emit(
        "deleteRouteConfiguration",
        configuration.value.id,
        configuration.value.name,
      ),
    isActive: configuration.value.isActive,
  });
};

const confirmDeactivation = () => {
  confirmWorkflowDeactivation({
    callback: async () =>
      await emit("deactivateRouteConfiguration", configuration.value.name),
  });
};

const onEditWorkflow = async () => {
  emit("navigateToEdit");
};
</script>
<style scoped lang="scss">
h6 {
  font-size: 14px;
  font-weight: 600;
  color: #495057;
  margin: 0;
}

.route-config-info {
  gap: 12px;

  .route-active-label {
    font-weight: 700;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.03em;
    color: #282e38;
    text-transform: uppercase;
    width: auto;
    height: 20px;
    border-radius: 24px;
    background: #dadee3;
    padding: 6px 12px;
    gap: 4px;
  }
}

.active-row,
.inactive-row {
  border-radius: 6px;
}
.active-row {
  background: #f7f8f9;
}
</style>
