<template>
  <StepContainer
    title="Step 1: Campaign Name"
    subtitle="Add the campaign name below:"
    next-button-label="Next Step"
    next-button-icon="pi pi-angle-right"
    previous-button-label="Back to Automation"
    :is-next-button-disabled="disableNextButton"
    display-previous-button
    @next-button-click="onNextClick"
    @prev-button-click="onPrevClick"
  >
    <template #content>
      <FormInputText
        ref="routeNameInputRef"
        v-model="name"
        name="routeName"
        placeholder="Campaign name..."
        data-cy="route-name"
        :custom-rules="inputValidation"
        :auto-focus="true"
      />
    </template>
  </StepContainer>
</template>

<script setup>
import FormInputText from "@/components/FormInputText";
import { useRouteStore } from "@/components/route-configurations/stores/route";
import { useWorkflowStore } from "@/components/route-configurations/stores/workflow";
import { storeToRefs } from "pinia";
import { ref, computed } from "vue";
import StepContainer from "@/components/stepper/StepContainer";
import { isValidString } from "@/services/validation-form-schemas/utils";
import { Navigator } from "@/services/utils/navigator";

const routeStore = useRouteStore();
const workflowStore = useWorkflowStore();
const { name } = storeToRefs(routeStore);
const { id } = storeToRefs(workflowStore);
const disableNextButton = computed(() => !name.value);
const inputValidation = isValidString({
  errorMessage: "Route name is required",
});

const { navigateToView } = Navigator();

const onPrevClick = async () => {
  if (id.value) {
    await navigateToView("EditNotificationWorkflow", { id: id.value });
  } else {
    await navigateToView("CreateNotificationWorkflow");
  }
};

const emit = defineEmits(["prev-page", "next-page"]);

const routeNameInputRef = ref();
const onNextClick = async () => {
  const errors = await routeNameInputRef.value.validateField();
  if (!errors.value) emit("next-page");
};
</script>
