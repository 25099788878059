import { isValidString } from "@/services/validation-form-schemas/utils";
import * as zod from "zod";

const commonSchema = {
  configurationName: isValidString({
    errorMessage: "Please enter an email notification name.",
  }),
  selectedTemplateMapping: isValidString({
    errorMessage: "Please select a data mapping.",
  }),
};

const sendgridSchema = {
  ...commonSchema,
  integrationDetails: zod.object({
    selectedConfiguration: isValidString({
      errorMessage: "Please select an email configuration name.",
    }),
    selectedTemplates: isValidString({
      errorMessage: "Please select an email template name.",
    }),
  }),
};

const SCHEMA_INTEGRATION_MAP = {
  sendgrid: sendgridSchema,
};

export const getNotificationSchema = (integrationName) => {
  if (!integrationName) return {};
  const schema = SCHEMA_INTEGRATION_MAP[integrationName];
  return {
    schema,
  };
};
