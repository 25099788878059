<template>
  <MainPage data-cy="settings-page" :error-message="errorMessage">
    <template #headingBar>
      <div class="shipment-details-header">
        <h3>
          <span>Settings</span>
        </h3>
      </div>
    </template>
    <template #body>
      <div class="card">
        <TabView>
          <template #TabPanels>
            <TabPanel header="Cookie Settings">
              <Suspense>
                <SettingsCookiePrivacy
                  data-cy="privacy-policy-bar"
                  @on-error="errorMessage = $event"
                />
              </Suspense>
            </TabPanel>
          </template>
        </TabView>
      </div>
    </template>
  </MainPage>
</template>

<script setup>
import { ref } from "vue";

import TabView from "@/components/TabView";
import TabPanel from "primevue/tabpanel";

import MainPage from "@/components/MainPage.vue";
import SettingsCookiePrivacy from "@/components/settings/SettingsCookiePrivacy";

const errorMessage = ref("");
</script>

<style lang="scss" scoped>
.card {
  padding: 32px;
}
</style>
