import { httpRequest } from "@/services/apiRequests";
import _ from "lodash";

const _generatePayload = (
  trackingPageId,
  templateName,
  leftImage,
  rightImage,
  bottomImage,
) => {
  return {
    portal_id: trackingPageId,
    template_name: templateName,
    left: {
      desktop_relative_path: leftImage.desktop,
      mobile_relative_path: leftImage.mobile,
      url: leftImage.url,
    },
    right: {
      desktop_relative_path: rightImage.desktop,
      mobile_relative_path: rightImage.mobile,
      url: rightImage.url,
    },
    bottom: {
      desktop_relative_path: bottomImage.desktop,
      mobile_relative_path: bottomImage.mobile,
      url: bottomImage.url,
    },
  };
};

class TrackingPageApiRequests {
  static async getTrackingPages() {
    const { data } = await httpRequest.get("tracking-page/portals/");

    let mappedData = data.map((trackingPage) => ({
      id: trackingPage.id,
      name: trackingPage.name,
      url: trackingPage.url,
      urlWithHandlebar: trackingPage.url_with_handlebar,
      templateName: trackingPage.template_name,
    }));

    return _.sortBy(mappedData, ["name"]);
  }

  static async getTrackingPageById(trackingPageId) {
    const { data } = await httpRequest.get(
      `tracking-page/portals/${trackingPageId}`,
    );
    return {
      id: data.id,
      name: data.name,
      templateName: data.template_name,
      leftImage: {
        desktop: data["left"]["desktop_relative_path"],
        mobile: data["left"]["mobile_relative_path"],
        url: data.left.url,
      },
      rightImage: {
        desktop: data["right"]["desktop_relative_path"],
        mobile: data["right"]["mobile_relative_path"],
        url: data.right.url,
      },
      bottomImage: {
        desktop: data["bottom"]["desktop_relative_path"],
        mobile: data["bottom"]["mobile_relative_path"],
        url: data.bottom.url,
      },
    };
  }

  static async updateTrackingPage(
    trackingPageId,
    templateName,
    leftImage,
    rightImage,
    bottomImage,
  ) {
    const details = _generatePayload(
      trackingPageId,
      templateName,
      leftImage,
      rightImage,
      bottomImage,
    );
    return await httpRequest.put(
      `tracking-page/portals/${trackingPageId}/`,
      details,
    );
  }

  static async generateTrackingPagePreview(
    trackingPageId,
    templateName,
    leftImage,
    rightImage,
    bottomImage,
  ) {
    const details = _generatePayload(
      trackingPageId,
      templateName,
      leftImage,
      rightImage,
      bottomImage,
    );
    const { data } = await httpRequest.post(
      `tracking-page/portals/${trackingPageId}/preview/`,
      details,
    );

    return {
      preview: data.code,
    };
  }
}

export default TrackingPageApiRequests;
