<template>
  <div class="p-dialog-content">
    <i class="p-confirm-dialog-icon pi pi-exclamation-triangle"></i>
    <span class="p-confirm-dialog-message">{{ dialogMsg }}</span>
  </div>
  <div class="confirm-input">
    <p>
      Type <span>'{{ confirmPhrase }}'</span> to confirm.
    </p>
    <InputText
      v-model="userInput"
      data-cy="confirm-phrase-input"
      @keyup.enter="takeAction()"
    />
  </div>
  <div class="p-dialog-footer">
    <Button
      label="Cancel"
      class="p-button p-button-text"
      data-cy="confirm-phrase-reject-btn"
      @click="dialogRef.close()"
    />
    <Button
      :label="confirmBtn.label"
      :class="'p-button ' + confirmBtn.class"
      :icon="confirmBtn.icon"
      :disabled="disableSubmit"
      data-cy="confirm-phrase-accept-btn"
      @click="takeAction()"
    />
  </div>
</template>

<script setup>
import { ref, inject, computed, onMounted } from "vue";
import Button from "primevue/button";
import InputText from "primevue/inputtext";

const dialogRef = inject("dialogRef");
const dialogProps = dialogRef.value.options.props;
const dialogMsg = dialogProps.message;
const confirmBtn = dialogProps.confirmBtn;
const confirmPhrase = dialogProps.confirmPhrase.toUpperCase();
const userInput = ref("");
const disableSubmit = computed(() => {
  return userInput.value.toUpperCase() !== confirmPhrase;
});

const takeAction = () => {
  if (!disableSubmit.value) {
    confirmBtn.callback();
    dialogRef.value.close();
  }
};

onMounted(() => {
  const containerDiv = document.getElementsByClassName("p-dialog-content")[0];
  containerDiv.classList.remove("p-dialog-content");
  containerDiv.parentElement.classList.add("p-confirm-dialog");
});
</script>
<style scoped lang="scss">
.p-dialog-content {
  padding: 0 21px 12px 21px;
}

.p-dialog-footer {
  padding-top: 0;
}
.confirm-input {
  padding: 0 21px 28px 63px;
  background: #fff;
  span {
    font-weight: 600;
  }
  p {
    margin-bottom: 0;
  }
  input {
    text-transform: uppercase;
    width: 402px;
  }
}
button {
  transition: all 0.3s;
}
</style>
