import { ref } from "vue";
import { defineStore } from "pinia";
import { useConfigurationRequests } from "@/services/utils/configurationRequests";
import TrackingPageApiRequests from "@/components/tracking-pages/helpers/apiRequests";
import {
  BOTTOM_IMAGE,
  FAIL_STATUS,
  LEFT_IMAGE,
  RIGHT_IMAGE,
  SUCCESS_STATUS,
} from "@/constants";
import { useTrackingPageToastService } from "@/components/tracking-pages/helpers/toastConfigs";

const { displayEditSuccessToast, displayEditFailToast } =
  useTrackingPageToastService();
export const useTrackingPageStore = defineStore("trackingPages", () => {
  // State
  const trackingPages = ref([]);

  // Actions
  async function getTrackingPages() {
    const { response } = await makeRequest({
      endpoint: async () => await TrackingPageApiRequests.getTrackingPages(),
    });
    replaceTrackingPages(response.value);
  }

  function replaceTrackingPages(updatedTrackingPages) {
    trackingPages.value = updatedTrackingPages;
  }

  const { makeRequest } = useConfigurationRequests();

  return {
    trackingPages,
    getTrackingPages,
  };
});

export const useTrackingPageEditStore = defineStore(
  "trackingPages/edit",
  () => {
    // State
    const id = ref("");
    const name = ref("");
    const templateName = ref("");
    const leftImage = ref({});
    const rightImage = ref({});
    const bottomImage = ref({});
    const hasLocalChanges = ref(false);

    function replaceTrackingPage(updatedTrackingPage) {
      id.value = updatedTrackingPage.id;
      name.value = updatedTrackingPage.name;
      templateName.value = updatedTrackingPage.templateName;
      leftImage.value = updatedTrackingPage.leftImage;
      rightImage.value = updatedTrackingPage.rightImage;
      bottomImage.value = updatedTrackingPage.bottomImage;
    }

    // Actions
    async function getTrackingPageDetails(trackingPageId) {
      const response =
        await TrackingPageApiRequests.getTrackingPageById(trackingPageId);
      replaceTrackingPage(response);
    }

    async function updateTrackingPage() {
      const { error } = await makeRequest({
        endpoint: async () =>
          await TrackingPageApiRequests.updateTrackingPage(
            id.value,
            templateName.value,
            leftImage.value,
            rightImage.value,
            bottomImage.value,
          ),
        onSuccess: async () => {
          displayEditSuccessToast(name.value);
        },
        onFail: () => {
          displayEditFailToast(name.value);
        },
      });
      if (!error.value) {
        await resetStore();
        return SUCCESS_STATUS;
      }
      return FAIL_STATUS;
    }

    function resetStore() {
      id.value = "";
      templateName.value = "";
      leftImage.value = {};
      rightImage.value = {};
      bottomImage.value = {};
      hasLocalChanges.value = false;
    }

    function setHasLocalChanges() {
      hasLocalChanges.value = true;
    }

    function setImage(imageType, data) {
      switch (imageType) {
        case LEFT_IMAGE:
          leftImage.value = data;
          setHasLocalChanges();
          break;
        case RIGHT_IMAGE:
          rightImage.value = data;
          setHasLocalChanges();
          break;
        case BOTTOM_IMAGE:
          bottomImage.value = data;
          setHasLocalChanges();
          break;
        default:
          break;
      }
    }

    const { makeRequest } = useConfigurationRequests();

    return {
      id,
      templateName,
      leftImage,
      rightImage,
      bottomImage,
      hasLocalChanges,
      resetStore,
      getTrackingPageDetails,
      setImage,
      updateTrackingPage,
      setHasLocalChanges,
    };
  },
);
