<template>
  <div>
    <p>
      Test your automation by importing a dummy tracking event for this
      shipment.
    </p>
    <ErrorBar
      v-if="importEventErrorMessage"
      :error-message="importEventErrorMessage"
    />
    <FormField>
      <div class="d-flex flex-direction-row" style="gap: 16px">
        <Dropdown
          v-model="eventStatus"
          :options="DUMMY_EVENT_CHOICES"
          option-label="label"
          option-value="value"
          :placeholder="DUMMY_EVENT_CHOICES[0].label"
          class="w-100"
          data-cy="dummy-event-dropdown"
        />
        <Button
          :loading="creatingEvent"
          :disabled="creatingEvent"
          :label="creatingEvent ? 'Importing...' : 'Import'"
          data-cy="import-event-btn"
          @click="createDummyEvent"
        />
      </div>
    </FormField>
  </div>
</template>
<script setup>
import { DUMMY_EVENT_CHOICES, EVENT_STATUS_DISPLAY_MAP } from "@/constants";
import { useToastService } from "@/services/toast/utils";
import { ref, toRefs } from "vue";

import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import FormField from "@/components/FormField";
import ErrorBar from "@/components/ErrorBar";

import ShipmentsApiRequests from "@/components/shipments/helpers/apiRequests";

const props = defineProps({
  shipmentId: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["updateData"]);

const eventStatus = ref(DUMMY_EVENT_CHOICES[0]);
const importEventErrorMessage = ref("");
const creatingEvent = ref(null);

const { displaySuccessToast } = useToastService();

const createDummyEvent = async () => {
  if (eventStatus.value) {
    creatingEvent.value = true;
    const eventStatusLabel = EVENT_STATUS_DISPLAY_MAP[eventStatus.value];

    try {
      await ShipmentsApiRequests.createDummyEvent(
        props.shipmentId,
        eventStatus.value,
      );
      displaySuccessToast({
        message: `${eventStatusLabel} Fake Event Imported`,
      });
      const { preparedData } = toRefs(
        await ShipmentsApiRequests.getShipmentDetails(props.shipmentId),
      );
      emit("updateData", preparedData);
    } catch {
      importEventErrorMessage.value = "Could not import Fake Event.";
    }
    creatingEvent.value = false;
  }
};
</script>

<style scoped></style>
