<template>
  <PoliciesBase>
    <template #policy-text>
      <h1>Scurri Privacy Policy</h1>
      <h4>This Privacy Policy was last modified on Jan 10th, 2023.</h4>

      <h2>Introduction</h2>

      <p>
        Welcome to Scurri Web Services LTD. This policy explains how we handle
        and use your personal information and your rights in relation to that
        information. Under the GDPR of May 2018, Scurri is your data processor
        while using the Scurri SaaS application.
      </p>

      <p>
        Scurri Web Services LTD (“Scurri”, “The organisation”, “we”, “our”, or
        “us”) is committed to protecting and respecting your privacy.
      </p>

      <p>
        This Privacy Policy explains why and how we will use the personal
        information that we have obtained from you or others, with whom we share
        it and the rights you have in connection with the information we use.
        Please read the following carefully.
      </p>

      <p>
        This policy describes how we handle and use the personal information we
        obtain from all the different interactions you may have with us as a
        client or when you contact us.
      </p>

      <p>
        Your privacy on the Internet is important to us. As the Internet offers
        the ability to collect certain types of information about users, we want
        you to understand the terms and conditions surrounding the capture, use
        and processing of any information relating to you that we gather.
      </p>

      <p>
        Scurri complies with the requirements of the relevant European Data
        Protection legislation based on the jurisdiction of our main
        establishment in Ireland, namely:
      </p>

      <ul>
        <li>The General Data Protection Regulation (2016/679)</li>
        <li>The Irish Data Protection Act (2018)</li>
        <li>The EC (Electronic Communications Regulation) Act 2011</li>
      </ul>
      <p>
        Scurri has registered offices at Common Quay St, Ferrybank South,
        Wexford, Y35 EK88, Ireland, and we are a company registered in Ireland
        under company number 483417. We act as the data processor when
        processing your data on our application.
      </p>

      <h2>Personal Information We Collect About You</h2>

      <p>
        Where a Scurri application visitor provides personal data, the system
        collects the following pieces of personal information through the
        website such as:
      </p>

      <ul>
        <li>First name</li>
        <li>Surname</li>
        <li>Email</li>
        <li>Company</li>
        <li>Telephone number</li>
        <li>Message or query entered by the visitor</li>
        <li>Location data such as address</li>
      </ul>

      <p>
        We gather various types of information, including information that
        identifies or may identify you as an individual (“Personal
        Information”), as explained in more detail below.
      </p>

      <h2>When you use the Website</h2>

      <p>
        When you visit the application, we collect certain information related
        to your device, such as and not limited to the following: your device’s
        IP address, referring website, what pages your device visited, and the
        time and date that you visited our application using this device.
      </p>

      <h2>How do we use the information?</h2>

      <p>We will use the information we collect via our application:</p>
      <ul>
        <li>
          To administer our application and for internal operations, including
          troubleshooting, data analysis, testing, and statistical and survey
          purposes
        </li>
        <li>
          To improve our application to ensure that content is presented most
          effectively for you and for your computer
        </li>
        <li>For trend monitoring, marketing and advertising</li>
        <li>
          For purposes made clear to you at the time you submit your information
          – for example, to provide you with information you have requested
          about our Services, and
        </li>
        <li>As part of our efforts to keep our website secure</li>
      </ul>

      <p>
        Our use of your Personal Information may be based on our legitimate
        interest to ensure network and information security and for our direct
        marketing purposes on the basis that you have consented to such
        communication (e.g., when you request an onboarding).
      </p>

      <h2>The Periods For Which We Retain Your Information</h2>

      <p>
        We are obliged to retain certain information to ensure accuracy, to help
        maintain quality of service and for legal, regulatory, fraud prevention
        and legitimate business purposes.
      </p>

      <p>
        Other information will be retained for no longer than is necessary for
        the purpose we obtained it or as required or permitted for legal,
        regulatory, fraud prevention and legitimate business purposes. In
        general, we will hold this information for seven years unless we are
        obliged to hold it for a longer period under law or applicable
        regulations.
      </p>

      <p>
        We will not hold your personal information in an identifiable format for
        any longer than is necessary for the purposes for which we collected it.
        For specific purposes, we retain your personal information indefinitely
        (e.g., to suppress marketing messages).
      </p>

      <h2>How do we share and disclose information to third parties?</h2>

      <p>
        Scurri will use third-party tools to improve and enhance the application
        for users. For example, we use Sentry and Rapid7 InsightOps, which are
        third-party tools to provide issue tracking and log analysis to improve
        issue remediation time.
      </p>

      <p>
        Scurri ensures third parties store data within the E.U. If we need to
        use a third party which may process/transfer your data to countries
        outside of the E.U, Scurri will ensure all activity is in accordance
        with the relevant legislation and use the appropriate legal mechanisms
        to safeguard the transfer.
      </p>

      <p>
        We protect your personal data throughout and assure you that it will
        only be used for the above purpose. All processors acting on our behalf
        only process your data in accordance with instructions from us and
        comply fully with this privacy notice, the data protection laws and any
        other appropriate confidentiality and security measures. Please refer to
        your SaaS agreement with us or contact our support team for more
        information.
      </p>

      <p>
        We do not rent or sell your Personal Information to anyone. We may share
        and disclose information (including Personal Information) about our
        customers in the following limited circumstances:
      </p>

      <h3>Carriers/Suppliers</h3>

      <p>
        To provide the functionality of carrier management system, package
        tracking or customer communications, Scurri will share data with carrier
        partners, suppliers and their systems which are the data recipient or
        sub-processor of your data.
      </p>

      <h3>Scurri</h3>

      <p>
        We reserve the right to access, read, preserve, and disclose any
        information as necessary to comply with law or court order; enforce or
        apply our agreements with you and other agreements; or protect the
        rights, property, or safety of Scurri, our employees, our users, or
        others.
      </p>

      <h3>Disclosures for National Security or Law Enforcement</h3>

      <p>
        Under certain circumstances, we may be required to disclose your
        Personal Information in response to valid requests by public
        authorities, including to meet national security or law enforcement
        requirements. In all cases, Scurri will communicate this requirement to
        you before any disclosure.
      </p>

      <h2>Security</h2>

      <p>
        We use appropriate technical, organisational and administrative security
        measures to protect any information we hold in our records from loss,
        misuse, and unauthorised access, disclosure, alteration and destruction.
      </p>

      <p>
        Unfortunately, no company or service can guarantee complete security.
        Unauthorised entry or use, hardware or software failure, and other
        factors may compromise the security of user information at any time.
        Among other practices, your account is protected by a password for your
        privacy and security. You must prevent unauthorised access to your
        account and Personal Information by selecting and protecting your
        password appropriately and limiting access to your computer or device
        and browser by signing off after you have finished accessing your
        account.
      </p>

      <h2>Cookies</h2>
      <p>
        We do use cookies on our website. For further details, please see our
        <a :href="getCookiePolicyUrl()" target="_blank">cookie policy</a>
      </p>

      <h2>Marketing Communications</h2>
      <p>
        You can opt-out of receiving certain promotional or marketing
        communications from us at any time by using the unsubscribe link in the
        email communications we send.
      </p>

      <p>
        If you have an account for our Services, including Scurri, we will still
        send you non-promotional communications, like service-related emails.
      </p>

      <h2>Your Data Subject Rights</h2>

      <p>
        Under the General Data Protection Regulation (GDPR) and The Data
        Protection Act 2018 (DPA2018), you have a number of rights with regard
        to your personal data. You have the right to request from us:
      </p>

      <ul>
        <li>access to your personal data</li>
        <li>rectification of your personal data where it is incorrect</li>
        <li>erasure of your personal data in certain circumstances</li>
        <li>
          the right to restrict processing, object to processing as well as the
          right to data portability in certain circumstances
        </li>
      </ul>

      <p>
        If you have provided consent for the processing of your personal data,
        you have the right (in certain circumstances) to withdraw that consent
        at any time, which will not affect the lawfulness of the processing
        before your consent was withdrawn.
      </p>

      <p>
        You have the right to lodge a complaint to the Data Protection
        Commission if you believe that we have not complied with the
        requirements of the GDPR or DPA 18 with regard to your personal data.
      </p>

      <h2>How Can I Exercise My Data Subject Rights?</h2>

      <p>
        A request can be submitted to our Support team, indicating the scope of
        personal data required.
      </p>

      <p>
        We will respond to all as quickly as possible, but in any event, within
        one month of receipt of the validated request.
      </p>

      <h2>International Data Transfers</h2>

      <p>
        Scurri does not transfer your personal information outside of Europe
        however, as mentioned above, some of our third-party processors may do
        so, or the configuration of a carrier account may do so or service
        providers used in the application may require transfer to data centres
        located outside of Europe depending on the customer account agreement.
      </p>

      <p>
        In some limited circumstances Scurri may use a sub-processor which is
        located outside of Europe, in which case data will be transferred
        outside of the European Economic Area (EEA). In this case Scurri will
        have Data Protection Agreements (DPA’s), Standard Contractual Clauses
        (SCC’s), have performed full security due diligence and included
        Clause’s required by our customers. This requirement will be clearly
        agreed with the customer and stated within the SaaS agreement with the
        customer.
      </p>

      <p>
        All information you provide to us is stored on our secure servers, which
        are located within the European Economic Area (EEA).
      </p>

      <p>
        If at any time we transfer your personal information to, or store it in,
        countries located outside of the EEA (for example, if our hosting
        services provider changes) we will amend this policy and notify you of
        the changes. We will also ensure that appropriate safeguards are in
        place for that transfer and storage as required by applicable law. This
        is because some countries outside of the EEA do not have adequate data
        protection laws equivalent to those in the EEA. If we transfer your
        personal information to the United States of America, we will only send
        the personal information to companies with safeguards in place in
        accordance with applicable law, such as Standard Contractual Clauses.
        Where they apply to our data transfer activities, we will rely on
        adequacy decisions by the European Commission about certain countries
        for data transfers to countries outside the EEA.
      </p>

      <h2>Children</h2>
      <p>
        We do not knowingly collect or solicit personal information from anyone
        under the age of If you are under 18, please do not attempt to register
        for the Services or send any Personal Information about yourself to us.
        If we learn that we have collected Personal Information from a child
        under the age of 18, we will delete that information as quickly as
        possible. If you believe that a child under 18 may have provided us with
        Personal Information, please contact our Support team.
      </p>

      <h2>Security and Links to Other Websites</h2>
      <p>
        We take the security of your personal information seriously and use a
        variety of measures based on good industry practices to keep it secure.
        Nonetheless, transmissions over the internet and to our Site may not be
        completely secure, so please exercise caution. When accessing links to
        other websites, their privacy policies, not ours, will apply to your
        personal information.
      </p>

      <p>
        We employ security measures to protect the personal information you
        provide to us, to prevent access by unauthorised persons and unlawful
        processing, accidental loss, destruction and damage.
      </p>

      <p>
        The transmission of information via the internet is not completely
        secure. Although we will do everything possible to protect your personal
        information, we cannot guarantee the security of any personal
        information during its transmission to us online. You accept the
        inherent security implications of using the internet and will not hold
        us responsible for any security breach unless we are at fault.
      </p>

      <p>
        If you are using a computer or terminal in a public location, we
        recommend you always log out and close the website browser when you
        complete an online session for security. In addition, we recommend that
        you take the following security measures to enhance your online safety:
      </p>
      <ul>
        <li>
          When creating a password, we recommend you to follow NIST guidelines
          for password strength
        </li>
        <li>
          We recommend using a password management solution to generate and
          manage secure passwords
        </li>
        <li>
          Keep your passwords private. Remember, anyone who knows your password
          may access your account
        </li>
        <li>
          Add multi factor authentication to your account to add an extra layer
          of security to your account
        </li>
        <li>Avoid using the same password for multiple online accounts</li>
        <li>
          Review the active sessions on your account to ensure you have
          visibility where your account is logged in from
        </li>
        <li>
          We will never ask you for credentials or personal data via email. If
          you receive such an email claiming to be from Scurri asking you to do
          so, please ignore it and do not respond and report it to us
        </li>
      </ul>

      <p>
        Our Site may contain links to other websites run by other organisations
        which we do not control. This policy does not apply to those other
        websites, so we encourage you to read their privacy policies. We
        specifically disclaim responsibility for their content, privacy
        practices and terms of use, and we make no endorsements, representations
        or promises about their accuracy, content or thoroughness. Your
        disclosure of personal information to third party websites is at your
        own risk.
      </p>

      <h2>Changes to this Statement</h2>
      <p>
        We reserve the right to change this policy at any time by notifying
        users of the existence of a revised statement. By continuing to use the
        Scurri application, you agree to be bound by the amended policy. We will
        inform you via a notice or email when a policy is updated.
      </p>

      <p>
        Please refer any questions in relation to this Policy, or to Scurri’s
        processing of your personal data, to our Support team.
      </p>
    </template>
  </PoliciesBase>
</template>
<script setup>
import PoliciesBase from "@/components/policies/PoliciesBase";
import { getPoliciesUrl } from "@/services/utils/policiesService";

const { getCookiePolicyUrl } = getPoliciesUrl();
</script>
