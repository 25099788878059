<template>
  <FormFieldContainer>
    <template #field>
      <div class="column">
        <label v-if="label" for="name" class="label">{{ label }}</label>
        <InputText
          ref="inputText"
          v-model="innerValue"
          :class="[$attrs.class, { 'p-invalid': errorMessage }]"
          :name="name"
          v-bind="$attrs"
          :data-cy="`form-input-text-${name}`"
          @input="onInput"
        />
        <small
          v-if="errorMessage"
          id="email-help"
          class="p-error error-label"
          data-cy="form-error-msg"
        >
          {{ errorMessage }}
        </small>
      </div>
    </template>
  </FormFieldContainer>
</template>
<script setup>
import InputText from "primevue/inputtext";
import { useField } from "vee-validate";
import FormFieldContainer from "@/components/FormFieldContainer.vue";
import { computed, onMounted, ref, toRef, watch } from "vue";
import { toTypedSchema } from "@vee-validate/zod";

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  customRules: {
    type: [String, Object, Function],
    default: "",
  },
  name: {
    type: String,
    required: true,
  },
});

const fieldValue = toRef(props, "modelValue");

const emit = defineEmits(["update:modelValue"]);
const inputText = ref();
onMounted(() => {
  if (props.autoFocus) {
    inputText.value.$el.focus();
  }
});

// TODO: That needs to be removed
const fieldSchema = computed(() => {
  if (props.customRules) return toTypedSchema(props.customRules);
  else return undefined;
});
const validateField = async () => {
  await validate();
  return errorMessage;
};
defineExpose({ validateField });

const onInput = (e) => {
  setValue(e.target.value);
  emit("update:modelValue", innerValue.value);
};

watch(
  () => fieldValue.value,
  (newVal) => setValue(newVal),
);

const {
  value: innerValue,
  errorMessage,
  validate,
  setValue,
} = useField(() => props.name, fieldSchema, { initialValue: fieldValue });
</script>
