<template>
  <MainPage
    :error-message="errorMessage"
    :loading="isLoading"
    data-cy="list-filters-page"
    :heading="mainPageConfig.heading"
    :tagline="mainPageConfig.tagline"
    :show-footer="false"
    @wipe-error="wipeError"
  >
    <template v-if="!isEmpty" #actionsBar>
      <Button
        label="New Segment"
        class="p-button"
        icon="pi pi-plus"
        data-cy="create-filter-link"
        @click="openDialog"
      ></Button>
    </template>
    <template #body>
      <div class="card shadow">
        <div class="card-body" data-cy="list-filters">
          <EmptyPage
            v-if="isEmpty"
            :empty-config="emptyMessageConfig"
            @button-primary-action="openDialog"
          />
          <FilterTable v-else v-model:filters="filters" />
        </div>
      </div>
    </template>
  </MainPage>
</template>

<script setup>
import { ref, computed } from "vue";
import Button from "primevue/button";
import FilterTable from "@/components/filters/FilterTable";
import EmptyPage from "@/components/EmptyPage";
import { useFilterStore } from "@/components/filters/stores/filter";
import { storeToRefs } from "pinia";
import { useFilterFormDialog } from "@/components/filters/helpers/modalConfigs";
import { useQueryCompanyState } from "@/queries";
import { useFiltersEmptyState } from "@/components/filters/helpers/emptyStateConfig";

const { openFilterFormDialog } = useFilterFormDialog();
const isLoading = ref(true);
const errorMessage = ref(null);

const store = useFilterStore();
const { getFilters } = store;
const { filters } = storeToRefs(store);

const mainPageConfig = computed(() => {
  return {
    heading: isEmpty.value ? "" : "Segments",
    tagline: isEmpty.value ? "" : filtersTagline,
  };
});

const openDialog = () => {
  openFilterFormDialog({});
};

const getAllFilters = async () => {
  isLoading.value = true;
  try {
    await getFilters();
  } catch {
    errorMessage.value = "Failed to load.";
  }
  isLoading.value = false;
};

const wipeError = () => {
  errorMessage.value = null;
};

const { emptyConfig, initialConfig, filtersTagline } = useFiltersEmptyState();

const isEmpty = computed(() => !filters.value.length);
const { data, isFetched } = useQueryCompanyState();
const emptyMessageConfig = computed(() => {
  if (isEmpty.value && isFetched.value) {
    if (data.value.hasCreatedFilter) {
      return emptyConfig;
    } else {
      return initialConfig;
    }
  }
  return {};
});

await getAllFilters();
</script>
<style scoped lang="scss">
:deep(.card-body) {
  padding: 32px;
}
.pi-times {
  position: absolute;
  width: 40px;
  height: 40px;
  left: -56px;
  top: 16px;
  background: #ffffff;
  box-shadow: -4px 8px 16px rgba(0, 0, 0, 0.08);
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #cccccc;
    transition: background-color 0.3s ease;
  }
}
</style>
