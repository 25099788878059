import * as zod from "zod";
import {
  isString,
  isValidString,
} from "@/services/validation-form-schemas/utils";

export const useFilterSchema = (filterId, filterNameValidator) => {
  return zod.object({
    filterName: isValidString({
      errorMessage: "Please enter a segment name.",
    }).superRefine((data, ctx) => {
      if (!filterNameValidator(filterId, data)) {
        ctx.addIssue({
          code: zod.ZodIssueCode.custom,
          message: `The segment name '${data}' already exists. Please choose a unique name.`,
        });
      }
    }),
    statements: zod.array(
      zod
        .object({
          propertyId: isValidString({
            errorMessage: "Please select a filter condition",
          }),
          operatorId: isValidString({
            errorMessage: "Please select a filter function",
          }),
          inputValue: isString({
            errorMessage: "Please select a filter value",
          }),
          selectedValue: isString({
            errorMessage: "Please select a filter value",
          }),
          multiSelectedValues: zod.array(
            isString({
              errorMessage: "Please select a filter value",
            }),
          ),
          valueSelector: isString({
            errorMessage: "Please select a filter value",
          }),
        })
        .partial({
          inputValue: true,
          selectedValue: true,
          multiSelectedValues: true,
        })
        .superRefine((data, ctx) => {
          if (
            !data.inputValue &&
            !data.multiSelectedValues?.length &&
            !data.selectedValue
          ) {
            ctx.addIssue({
              code: zod.ZodIssueCode.custom,
              path: ["inputValue"],
              message: "Please select a filter value",
            });
            ctx.addIssue({
              code: zod.ZodIssueCode.custom,
              path: ["selectedValue"],
              message: "Please select a filter value",
            });
            ctx.addIssue({
              code: zod.ZodIssueCode.custom,
              path: ["multiSelectedValues"],
              message: "Please select a filter value",
            });
          }
          if (data.valueSelector === "input") {
            if (!data.inputValue) {
              ctx.addIssue({
                code: zod.ZodIssueCode.custom,
                path: ["inputValue"],
                message: "Please select a filter value",
              });
            }
          }
          if (data.valueSelector === "multiSelect") {
            if (!data.multiSelectedValues) {
              ctx.addIssue({
                code: zod.ZodIssueCode.custom,
                path: ["multiSelectedValues"],
                message: "Please select a filter value",
              });
            }
          }
          if (data.valueSelector === "select") {
            if (!data.selectedValue) {
              ctx.addIssue({
                code: zod.ZodIssueCode.custom,
                path: ["selectedValue"],
                message: "Please select a filter value",
              });
            }
          }
        }),
    ),
  });
};
