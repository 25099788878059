<template>
  <FormFieldContainer>
    <template #field>
      <div class="column">
        <label v-if="label" for="type" class="label">{{ label }}</label>
        <Dropdown
          :model-value="innerValue"
          :name="name"
          :options="options"
          option-label="name"
          option-value="id"
          :option-disabled="optionDisabled"
          :option-group-label="optionGroupLabel"
          :option-group-children="optionGroupChildren"
          :panel-class="panelClass || 'dropdown-options-panel'"
          :placeholder="placeholder"
          :class="[$attrs.class, { 'p-invalid': isError() }]"
          :data-cy="`dropdown-${name}`"
          :disabled="disabled"
          :auto-option-focus="false"
          :show-clear="showClear"
          @update:model-value="onChange"
        >
          <template #value="{ value, placeholder: _placeholder }">
            <slot
              name="value"
              :raw-value="value"
              :parsed-value="getLabel(value)"
              :placeholder="_placeholder"
            >
              {{ getLabel(value) || placeholder }}
            </slot>
          </template>
          <template #option="{ option, index }">
            <slot name="option" :option="option" :index="index"></slot>
          </template>
          <template #optiongroup="{ option, index }">
            <slot name="optiongroup" :option="option" :index="index"></slot>
          </template>
        </Dropdown>
        <small
          v-if="isError()"
          id="email-help"
          class="p-error error-label"
          data-cy="form-error-msg"
        >
          {{ errorMessage }}
        </small>
      </div>
    </template>
  </FormFieldContainer>
</template>

<script setup>
import { toRef, watch } from "vue";
import { useField } from "vee-validate";
import Dropdown from "primevue/dropdown";
import FormFieldContainer from "@/components/FormFieldContainer";

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: "",
  },
  options: {
    type: Array,
    required: true,
  },
  placeholder: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  optionDisabled: {
    type: [String, Function],
    default: "option-disabled",
  },
  optionGroupLabel: {
    type: String,
    default: "",
  },
  optionGroupChildren: {
    type: String,
    default: "",
  },
  showClear: {
    type: Boolean,
    default: false,
  },
  panelClass: {
    type: String,
    default: "",
  },
});

const isError = () => !!errorMessage.value;
const emit = defineEmits(["update:modelValue"]);

const fieldValue = toRef(props, "modelValue");
const fieldName = toRef(props, "name");

const options = toRef(props, "options");
const optionGroupChildren = toRef(props, "optionGroupChildren");

const _getLabelForGroup = (optionValue) => {
  for (let optionGroup of options.value) {
    for (let option of optionGroup[optionGroupChildren.value]) {
      if (option.id === optionValue) {
        return option.name;
      }
    }
  }
};
const _getLabel = (optionValue) => {
  return options.value.find(({ id }) => id === optionValue)?.name;
};
const getLabel = (optionValue) => {
  if (optionGroupChildren.value) return _getLabelForGroup(optionValue);
  return _getLabel(optionValue);
};

const onChange = (e) => {
  emit("update:modelValue", e);
  setValue(e);
};

watch(
  () => fieldValue.value,
  (newVal) => setValue(newVal),
);

const {
  errorMessage,
  value: innerValue,
  setValue,
} = useField(fieldName, undefined, {
  initialValue: fieldValue,
  syncVModel: false,
});
</script>
<style scoped>
:deep(.p-dropdown:not(.p-disabled).p-focus) {
  box-shadow: none;
  border: 1px solid #ced4da;
}

:deep(.p-inputwrapper-filled > .p-dropdown-label) {
  color: #495057;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
</style>
