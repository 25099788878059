import { markRaw, ref } from "vue";
import { defineStore } from "pinia";

export const useDynamicSidebar = defineStore("dynamicSidebar", () => {
  const isOpen = ref(false);
  const view = ref({});
  const viewProps = ref({});

  function open(viewToRender, props = {}) {
    viewProps.value = props;
    view.value = markRaw(viewToRender);
    isOpen.value = true;
  }

  function close() {
    isOpen.value = false;
    view.value = {};
    viewProps.value = {};
  }

  return { isOpen, open, close, view, viewProps };
});
