<template>
  <div class="main-cont" data-cy="import-csv-modal">
    <Transition
      :enter-active-class="enterAnimation"
      :leave-active-class="leaveAnimation"
    >
      <keep-alive>
        <component
          :is="isPreviewOpenComponent[isPreviewOpen]"
          data-cy="modal-dynamic-component"
          @on-preview-open="setPreviewOpen"
        ></component>
      </keep-alive>
    </Transition>
  </div>
</template>
<script setup>
import ImportCsvPreviewFile from "@/components/csv-import/ImportCsvPreviewFile.vue";
import ImportCsvUploadFile from "@/components/csv-import/ImportCsvUploadFile.vue";
import { computed, ref, shallowRef } from "vue";
import { useGlobalDialogService } from "@/services/modals/base";

const isPreviewOpenComponent = ref({
  true: shallowRef(ImportCsvPreviewFile),
  false: shallowRef(ImportCsvUploadFile),
});

const isPreviewOpen = ref(false);
const setPreviewOpen = (v) => {
  isPreviewOpen.value = v;
};

const enterAnimation = computed(() =>
  isPreviewOpen.value ? "animation slideInRight" : "animation slideInLeft",
);
const leaveAnimation = computed(() =>
  isPreviewOpen.value ? "animation fadeOutLeft" : "animation fadeOutRight",
);

const { closeOnRouteChange } = useGlobalDialogService();
closeOnRouteChange();
</script>
<style scoped lang="scss">
.csv-modal {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.csv-header {
  font-weight: 600;
  font-size: 21px;
  color: #495057;
}

.profile-actions {
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
}

.template-mapping-dropdown {
  flex-grow: 1;
}

.template-mapping__template-button {
  min-width: fit-content;
  height: 42px;
}

:deep(.file-upload) {
  background: #ffffff;
}

.csv-import-button {
  float: right;
  height: 42px;
}

.selected-profile-name {
  padding: 5.25px 10.5px;
  height: 31.5px;
  background: #dee2e6;
  border-radius: 16px;
}

.clear-icon {
  color: #495057;
}

.uploaded-icon {
  font-size: 35px;
}

.card-body {
  width: 100%;
  align-items: center;
  flex-direction: row;
  padding: 32px;
  gap: 28px;
}

.card {
  border: 1px solid #dadee3;
}

.header-option-row {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  justify-content: flex-start;
}

// TODO: MOVE THAT TO SEPARATE
:deep(.p-checkbox) {
  align-items: center;
  width: 22px;
  height: 22px;

  & .p-checkbox-box {
    border-radius: 6px;
    padding: 0;
    width: 22px;
    height: 22px;
  }

  & .p-checkbox-box:hover,
  & .p-checkbox-box.p-focus:hover {
    border: 2px solid #1f2a4e;
  }

  & .p-checkbox-box.p-highlight {
    background: #26d466;
    border: 2px solid #26d466;
  }

  & .p-checkbox-box.p-focus {
    box-shadow: none;
    border: 2px solid #ced4da;
  }

  & .p-checkbox-box.p-highlight.p-focus {
    box-shadow: none;
    border: 2px solid #26d466;
  }

  & .p-checkbox-box.p-highlight:hover {
    background: #199146;
    border: 2px solid #199146;
    box-shadow: none;
  }
}

.uploader-buttons {
  display: flex;
  gap: 4px;
  margin-left: auto;
}

.file-details__filename {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #495057;
}

.icon-filename {
  display: flex;
  align-items: center;
  gap: 18px;
}

.main-cont {
  display: flex;
  position: relative;
  height: 604px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  > div {
    position: absolute;
    width: 100%;
  }
}

.animation {
  animation-fill-mode: both;
  animation-duration: 1s;
  transition-duration: 1ms;
  animation-iteration-count: 1;
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

@keyframes fadeOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@keyframes fadeOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(100%, 0, 0);
  }
}

.fadeOutRight {
  animation-name: fadeOutRight;
}
</style>
