<template>
  <div>
    <ConfigurationTable
      :data-rows="actionCheckboxes"
      @duplicate-row="onDuplicateAction"
      @delete-rows="onDeleteActions"
      @edit-row="onEditRow"
    />
  </div>
</template>

<script setup>
import { useNotificationToastService } from "@/services/toast/notifications";
import ConfigurationTable from "@/components/ConfigurationTable.vue";
import { Navigator } from "@/services/utils/navigator";
import { toRefs } from "vue";
import { useConfigurationRequests } from "@/services/utils/configurationRequests";
import { useNotificationsConfirmDialog } from "@/components/notifications/helpers/confirmModalConfigs";
import NotificationsApiRequests from "@/components/notifications/helpers/apiRequests";

const props = defineProps({
  actionCheckboxes: {
    type: Array,
    default: () => [],
  },
});
const { actionCheckboxes } = toRefs(props);

const onDeleteActions = (actions) => {
  if (actions.length === 1)
    return confirmNotificationDelete({
      callback: async () => await deleteAction(actions[0]),
    });
  confirmSelectedAllNotificationDelete({
    callback: async () => await bulkDeleteActions(actions),
  });
};

const { makeRequest, makeBulkRequest } = useConfigurationRequests();
const bulkDeleteActions = async (actions) => {
  await makeBulkRequest({
    endpoint: NotificationsApiRequests.deleteSendgridAction.bind(
      NotificationsApiRequests,
    ),
    endpointArgs: actions.map((a) => a.id),
    onFail: () => displaySelectAllDeleteFailToast(),
    onPartialSuccess: () => displaySelectAllDeletePartialToast(),
    onSuccess: () => displaySelectAllDeleteSuccessToast(),
    onEachSuccessRequest: updateActions,
  });
};

const deleteAction = async (action) => {
  const { response, error } = await makeRequest({
    endpoint: () => NotificationsApiRequests.deleteSendgridAction(action.id),
    onSuccess: () => displayDeleteSuccessToast(action.name),
    onInvalidAction: () => displayDeleteInvalidActionToast(action.name),
    onFail: () => displayDeleteFailToast(action.name),
  });
  if (!error.value) {
    updateActions(response.value.data);
  }
};

const onDuplicateAction = (action) => {
  return confirmNotificationDuplicate({
    callback: () => duplicateAction(action),
  });
};
const duplicateAction = async (action) => {
  const { response, error } = await makeRequest({
    endpoint: () => NotificationsApiRequests.duplicateSendgridAction(action.id),
    onSuccess: () => {
      displayDuplicateSuccessToast(action.name);
    },
  });
  if (!error.value) {
    updateActions(response.value);
  }
};

const { navigateToView } = Navigator();
const onEditRow = (action) => {
  navigateToView("EditEmailNotification", { id: action.id });
};

const emit = defineEmits(["update:actionCheckboxes"]);
const updateActions = (actionCheckboxes) => {
  emit("update:actionCheckboxes", actionCheckboxes);
};

const {
  displaySelectAllDeleteSuccessToast,
  displaySelectAllDeletePartialToast,
  displaySelectAllDeleteFailToast,
  displayDeleteSuccessToast,
  displayDeleteInvalidActionToast,
  displayDeleteFailToast,
  displayDuplicateSuccessToast,
} = useNotificationToastService();

const {
  confirmSelectedAllNotificationDelete,
  confirmNotificationDelete,
  confirmNotificationDuplicate,
} = useNotificationsConfirmDialog();
</script>
