<template>
  <div class="card card-body">
    <div class="header">
      <span class="header__step">
        <slot name="header">
          {{ title }}
        </slot>
      </span>
      <span class="header__description">
        <slot name="header-description">
          {{ subtitle }}
        </slot>
      </span>
    </div>
    <slot name="content"></slot>
  </div>
  <div class="buttons">
    <div v-if="displayPreviousButton">
      <Button
        class="p-button prev-button header-btn"
        data-cy="prev-button"
        @click="onClickPrev"
      >
        <i class="pi pi-angle-left" />
        <span>{{ previousButtonLabel }}</span>
      </Button>
    </div>
    <div class="next-buttons">
      <Button
        v-if="displaySecondaryActionButton"
        class="p-button p-button-outlined"
        data-cy="second-action-button"
        @click="onSecondActionButtonClick"
      >
        <i :class="secondaryActionButtonIcon" />
        <span>{{ secondaryActionButtonLabel }}</span>
      </Button>
      <Button
        class="p-button next-button"
        data-cy="next-button"
        :disabled="isNextButtonDisabled"
        @click="onClickNext"
      >
        <span>{{ nextButtonLabel }}</span>
        <i :class="nextButtonIcon" />
      </Button>
    </div>
  </div>
</template>

<script setup>
import Button from "primevue/button";
defineProps({
  title: {
    type: String,
    default: "",
  },
  subtitle: {
    type: String,
    default: "",
  },
  nextButtonLabel: {
    type: String,
    default: "",
  },
  nextButtonIcon: {
    type: String,
    default: "",
  },
  isNextButtonDisabled: {
    type: Boolean,
    default: true,
  },
  displayPreviousButton: {
    type: Boolean,
    default: false,
  },
  previousButtonLabel: {
    type: String,
    default: "Previous Step",
  },
  displaySecondaryActionButton: {
    type: Boolean,
    default: false,
  },
  secondaryActionButtonLabel: {
    type: String,
    default: "",
  },
  secondaryActionButtonIcon: {
    type: String,
    default: "",
  },
});
const emit = defineEmits([
  "nextButtonClick",
  "prevButtonClick",
  "secondaryActionButtonClick",
]);
const onClickNext = () => emit("nextButtonClick");
const onClickPrev = () => emit("prevButtonClick");
const onSecondActionButtonClick = () => emit("secondaryActionButtonClick");
</script>

<style scoped>
.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  gap: 8px;
}
.header__step {
  font-size: 21px;
  line-height: 25px;
  font-weight: 600;
  color: #1f2a4e;
}
.header__description {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #495057;
}
.buttons {
  display: flex;
  flex-direction: row;
}
.next-buttons {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: auto;
}
.prev-button {
  margin-right: auto;
  height: 40px;
}
.card-body {
  padding: 32px;
}
.card {
  margin: 0 0 24px 0;
}
</style>
