import { useCsvProfileToastService } from "@/components/csv-import/helpers/toastConfigs";
import { handleErrors } from "@/services/utils/errorHandler";

const {
  displayCsvProfileNameNotUniqueToast,
  displayImportCsvInconsistentDataToast,
} = useCsvProfileToastService();

export const csvErrorMap = {
  CSV_PROFILE_NAME_NOT_UNIQUE: {
    fn: (args) => displayCsvProfileNameNotUniqueToast(args.profileName),
  },
  CSV_INCONSISTENT_SHIPMENTS_DATA: {
    fn: () => displayImportCsvInconsistentDataToast(),
  },
};

export const csvErrorMapper = (errors, profileName) => {
  return handleErrors(errors, csvErrorMap, {
    profileName: profileName,
  });
};
