<template>
  <StepContainer
    :title="pageTitle"
    :subtitle="pageSubtitle"
    next-button-label="Next Step"
    next-button-icon="pi pi-angle-right"
    previous-button-label="Back to Profiles"
    :is-next-button-disabled="disableNextButton"
    display-previous-button
    @prev-button-click="onClickPrev"
    @next-button-click="onClick"
  >
    <template #content>
      <FormInputText
        ref="profileNameInputRef"
        v-model="localProfileName"
        name="profileName"
        placeholder="Name of profile..."
        data-cy="csv-profile-name"
        :custom-rules="inputValidation"
        auto-focus
      />
    </template>
  </StepContainer>
</template>

<script setup>
import { computed, ref, toRefs } from "vue";
import { isValidString } from "@/services/validation-form-schemas/utils";
import StepContainer from "@/components/stepper/StepContainer.vue";
import { useRouter } from "vue-router";
import FormInputText from "@/components/FormInputText.vue";

const props = defineProps({
  profileName: {
    type: String,
    required: true,
  },
  pageTitle: {
    type: String,
    required: true,
  },
  pageSubtitle: {
    type: String,
    required: true,
  },
});

const { profileName } = toRefs(props);

const emit = defineEmits(["next-page", "prev-page", "update:profileName"]);

const localProfileName = computed({
  get() {
    return profileName.value;
  },
  set(newProfileName) {
    emit("update:profileName", newProfileName);
  },
});

const disableNextButton = computed(() => !profileName.value);

const inputValidation = isValidString({
  errorMessage: "Profile name is required",
});

// Reference to FormInputText component
const profileNameInputRef = ref(null);
const onClick = async () => {
  const errors = await profileNameInputRef.value.validateField();
  if (!errors.value) emit("next-page");
};

const router = useRouter();
const onClickPrev = async () => {
  router.push({ name: "ListCsvProfiles" });
};
</script>
