import { useQuery, useQueryClient } from "@tanstack/vue-query";
import CompanyApiRequests from "@/services/companyRequests";

const QUERY_KEYS = {
  companyState: ["companyState"],
};

export const useCompanyStateManagement = () => {
  const queryClient = useQueryClient();

  return {
    prefetchCompanyState: async () =>
      await queryClient.prefetchQuery({
        queryKey: QUERY_KEYS.companyState,
        queryFn: CompanyApiRequests.getCompanyState.bind(CompanyApiRequests),
        staleTime: 600000, // 10 minutes
      }),
    invalidateCompanyState: async () => {
      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.companyState,
      });
    },
    AsyncFetchAndVerifyFlag: async (featureName) => {
      try {
        const companyStateCache = await queryClient.ensureQueryData({
          queryKey: QUERY_KEYS.companyState,
          queryFn: CompanyApiRequests.getCompanyState.bind(CompanyApiRequests),
          onError: () => false,
        });
        if (companyStateCache) {
          return companyStateCache.featureFlags.includes(featureName);
        }
      } catch {
        return false;
      }
      return false;
    },
  };
};

export const useQueryCompanyState = () => {
  return useQuery({
    queryKey: QUERY_KEYS.companyState,
    queryFn: CompanyApiRequests.getCompanyState.bind(CompanyApiRequests),
    staleTime: 600000, // 10 minutes
    refetchOnMount: true,
  });
};
