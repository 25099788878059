import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { v4 as uuidv4 } from "uuid";

export const useToastManager = defineStore("utils/ToastManager", () => {
  // State
  const toastMessages = ref([]);

  // Actions
  const addToast = (toastConfig) => {
    const randomId = uuidv4();
    const toastToAdd = { id: randomId, seen: false, ...toastConfig };
    toastMessages.value.push(toastToAdd);
    return toastToAdd;
  };

  // Getters
  const newToastMessages = computed(() => {
    return toastMessages.value.filter((t) => !t.seen);
  });

  const setToastAsSeen = (notification) => {
    const notificationToArchive = toastMessages.value.find(
      (t) => t.id === notification.id,
    );
    notificationToArchive.seen = true;
  };
  return { toastMessages, addToast, newToastMessages, setToastAsSeen };
});

export const showToastMessage = (toastConfig) => {
  const toastManagerStore = useToastManager();
  const { addToast } = toastManagerStore;
  addToast(toastConfig);
};
