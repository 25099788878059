<template>
  <EmptyPage
    v-if="showEmptyPage"
    :empty-config="emptyMessageConfig"
    @button-primary-action="createWorkflow"
    @button-secondary-action="openListFiltersDialog"
  />
  <MainPage
    v-else
    :error-message="errorMessage"
    data-cy="list-configurations-page"
    :heading="'Automations'"
    :tagline="'Enhance your customers\' experience by automating delivery notifications.'"
    :loading="isLoading"
    @wipe-error="wipeError"
  >
    <template #actionsBar>
      <Button
        class="p-button p-button-outlined"
        data-cy="filters-list-link"
        @click="openListFiltersDialog"
      >
        <i :class="buttons.buttonSecondary.iconClass" />
        {{ buttons.buttonSecondary.label }}
      </Button>
      <Button
        class="p-button"
        data-cy="create-workflow-btn"
        @click="createWorkflow"
      >
        <i :class="buttons.buttonPrimary.iconClass" />
        {{ buttons.buttonPrimary.label }}
      </Button>
    </template>
    <template #body>
      <div>
        <div class="card shadow">
          <div class="card-body">
            <div class="container-fluid list-configurations">
              <WorkflowErrorBar
                v-if="noActiveWorkflow"
                :error-message="noActiveConfigMsg"
              />
              <table class="col-12">
                <RouteConfigRow
                  v-for="(workflow, index) in workflows"
                  :key="index"
                  class="d-flex route-config-list-item"
                  :configuration="workflow"
                  :has-active-workflow="!noActiveWorkflow"
                  @activate-route-configuration="onActivateWorkflow(workflow)"
                  @duplicate-route-configuration="onDuplicateWorkflow(workflow)"
                  @delete-route-configuration="onDeleteWorkflow(workflow)"
                  @deactivate-route-configuration="
                    onDeactivateWorkflow(workflow)
                  "
                  @navigate-to-edit="onNavigateToEdit(workflow)"
                />
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>
  </MainPage>
</template>

<script setup>
import MainPage from "@/components/MainPage.vue";
import { computed, onMounted, ref } from "vue";
import Button from "primevue/button";
import RouteConfigRow from "@/components/route-configurations/RouteConfigRow";
import EmptyPage from "@/components/EmptyPage";
import { Navigator } from "@/services/utils/navigator";
import ListFilters from "@/components/filters/ListFilters";
import { useInputDialog } from "@/services/utils/inputDialog";
import { useDynamicSidebar } from "@/store/dynamicSidebar";
import { useWorkflowStore } from "@/components/route-configurations/stores/workflow";
import { FAIL_STATUS } from "@/constants";
import { storeToRefs } from "pinia";
import WorkflowErrorBar from "@/components/route-configurations/WorkflowErrorBar.vue";
import { useQueryCompanyState } from "@/queries";
import { useRouteConfigurationsEmptyState } from "@/components/route-configurations/helpers/emptyStateConfig";

let errorMessage = ref(null);
const noActiveConfigMsg =
  "You currently have no active automation. Please activate one to allow shipments to access a campaign.";

const sidebar = useDynamicSidebar();
const openListFiltersDialog = () => {
  sidebar.open(ListFilters);
};

const { navigateToView } = Navigator();
const { createNewWorkflow } = useInputDialog();
const store = useWorkflowStore();
const {
  setName,
  duplicateWorkflow,
  getWorkflows,
  activateWorkflow,
  deactivateWorkflow,
  deleteWorkflow,
  resetWorkflow,
} = store;
const { workflows } = storeToRefs(store);

const createWorkflow = async () => {
  createNewWorkflow({
    callback: async (val) => {
      await resetWorkflow();
      await setName(val);
      navigateToView("CreateNotificationWorkflow");
    },
  });
};

const onNavigateToEdit = async (workflow) => {
  await resetWorkflow();
  navigateToView("EditNotificationWorkflow", { id: workflow.id });
};

const noActiveWorkflow = computed(() => {
  if (!isEmpty.value) {
    const activeConfig = workflows.value.find(
      (config) => config.isActive === true,
    );
    return !activeConfig;
  }
  return false;
});

const isLoading = ref(false);
const fetchWorkflows = async () => {
  isLoading.value = true;
  const res = await getWorkflows();
  if (res === FAIL_STATUS) errorMessage.value = "Failed to load.";
  isLoading.value = false;
};

const { initialConfig, emptyConfig, buttons } =
  useRouteConfigurationsEmptyState();
const { data, isFetched } = useQueryCompanyState();
const isEmpty = computed(() => !workflows.value.length);
const showEmptyPage = computed(() => {
  return !isLoading.value && isEmpty.value;
});
const emptyMessageConfig = computed(() => {
  if (isEmpty.value && isFetched.value) {
    if (data.value.hasCreatedWorkflow) {
      return emptyConfig;
    } else {
      return initialConfig;
    }
  }
  return {};
});

const onDuplicateWorkflow = async (workflow) => {
  await duplicateWorkflow(workflow);
};

const onActivateWorkflow = async (workflow) => {
  await activateWorkflow(workflow);
};

const onDeactivateWorkflow = async (workflow) => {
  await deactivateWorkflow(workflow);
};

const onDeleteWorkflow = async (workflow) => {
  isLoading.value = true;
  await deleteWorkflow(workflow);
  isLoading.value = false;
};

const wipeError = () => {
  errorMessage.value = null;
};

onMounted(async () => {
  await fetchWorkflows();
});
</script>
<style scoped lang="scss">
.card-body,
.container-fluid {
  padding: 0;
}

.list-configurations {
  padding: 32px;
}

.route-config-list-item {
  padding: 14px;
  gap: 12px;
  height: 56px;
}
</style>
