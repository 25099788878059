<template>
  <div class="route-option">
    <div class="route-option-group">
      <div class="route-status">
        <p data-cy="status-heading">{{ statusHeading }}</p>
        <div class="statuses-row">
          <StatusBadge
            v-for="status in statuses"
            :key="status.id"
            size="normal"
            :status="mapStatusToName(status)"
          >
            {{ mapStatusToName(status) }}
          </StatusBadge>
        </div>
      </div>
      <div class="route-notification">
        <p data-cy="notification-heading">{{ notificationHeading }}</p>
        <div class="route-actions-row">
          <span v-for="action in actions" :key="action">
            <div class="route-action-badge">
              <i class="pi pi-send" />
              {{ mapActionToName(action.actionId) }}
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import StatusBadge from "@/components/StatusBadge";
import { useWorkflowStore } from "@/components/route-configurations/stores/workflow";
import { storeToRefs } from "pinia/dist/pinia";
const store = useWorkflowStore();
const { statusOptions, notificationOptions } = storeToRefs(store);

const props = defineProps({
  statuses: {
    type: Array,
    default: () => [],
  },
  actions: {
    type: Array,
    default: () => [],
  },
});

const statusHeading = computed(() => {
  return props.statuses.length > 1
    ? "If these statuses are triggered:"
    : "If this status is triggered:";
});

const notificationHeading = computed(() => {
  const baseStr = "Then, send the following notification";
  return props.actions.length > 1 ? `${baseStr}s:` : `${baseStr}:`;
});

const mapStatusToName = (id) => {
  const statusWithName = statusOptions.value.find((status) => status.id === id);
  return statusWithName?.name;
};

const mapActionToName = (id) => {
  const actionWithName = notificationOptions.value.find(
    (action) => action.id === id,
  );
  return actionWithName?.name;
};
</script>
<style scoped lang="scss">
.route-option {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 32px;
  gap: 16px;

  .route-option-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 0 24px;
    gap: 16px;
    border-left: 1px solid #cccccc;

    .route-status,
    .route-notification {
      padding: 0;
      gap: 8px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    p {
      margin: 0;
    }
  }
}

.statuses-row {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.route-actions-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 12px;
}
.route-action-badge {
  color: #495057;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  height: 40px;
  padding: 8px 12px;
  font-size: 14px;
  gap: 4px;
  display: flex;
  align-items: center;

  .pi {
    color: #495057;
  }
}
</style>
