<template>
  <div class="spinner-container">
    <svg
      width="144"
      height="144"
      viewBox="0 0 144 144"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="topShape"
        d="M87.3638 42.4644V60.2099L72.004 69.0747L56.6362 60.2099V42.4644L72.004 33.5996L87.3638 42.4644Z"
        fill="#26D466"
      />
      <path
        id="bottomShape"
        d="M87.3638 83.7927V101.53L72.004 110.403L56.6362 101.53V83.7927L72.004 74.9199L87.3638 83.7927Z"
        fill="#26D466"
      />
    </svg>
  </div>
</template>

<script setup></script>
<style scoped lang="scss">
@keyframes topShapeAnimation {
  0%,
  100% {
    transform: translateY(-34px);
    opacity: 0;
  }
  25%,
  50%,
  75% {
    transform: translateY(0px);
    opacity: 1;
  }
}

#topShape {
  animation: topShapeAnimation 1.5s ease infinite;
  animation-delay: 750ms;
}

@keyframes bottomShapeAnimation {
  0%,
  75%,
  100% {
    transform: translateY(+34px);
    opacity: 0;
  }
  20%,
  25%,
  50% {
    transform: translateY(0px);
    opacity: 1;
  }
}

#bottomShape {
  animation: bottomShapeAnimation 1.5s ease infinite;
}

.spinner-container {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
