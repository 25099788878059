import { useConfirmDialog } from "@/services/confirm-modals/base";
import { getDiscardChangesConfig } from "@/services/utils/utils";

const confirmFiltersDuplicateConfig = () => {
  return {
    header: "Duplicate Segment?",
    message: "Are you sure you want to duplicate this segment?",
    acceptLabel: "Duplicate",
  };
};

const confirmFilterDeleteConfig = () => {
  return {
    header: "Delete Segment?",
    message: `You're about to delete this segment. This action cannot be undone.
    Are you sure you want to proceed?`,
    acceptLabel: "Delete",
    acceptIcon: "pi pi-trash",
    acceptClass: "p-button p-button-remove",
  };
};

const confirmFiltersDeletionSelectAllConfig = () => {
  return {
    header: "Delete Segments?",
    message: `You're about to delete multiple segments. This action cannot be undone.
    Are you sure you want to proceed?`,
    acceptLabel: "Delete",
    acceptIcon: "pi pi-trash",
    acceptClass: "p-button p-button-remove",
  };
};

const confirmFilterCancelEditingConfig = (action) => {
  return getDiscardChangesConfig("segment", action);
};

export const useFiltersConfirmDialog = () => {
  const { confirmAction } = useConfirmDialog();

  const confirmFilterDuplicate = ({ callback }) => {
    return confirmAction({
      callback,
      ...confirmFiltersDuplicateConfig(),
    });
  };

  const confirmSelectedAllFiltersDelete = ({ callback }) => {
    return confirmAction({
      callback,
      ...confirmFiltersDeletionSelectAllConfig(),
    });
  };

  const confirmFilterDelete = ({ callback }) => {
    return confirmAction({
      callback,
      ...confirmFilterDeleteConfig(),
    });
  };

  const confirmCancelFilterForm = ({ callback, action }) => {
    return confirmAction({
      callback,
      ...confirmFilterCancelEditingConfig(action),
    });
  };

  return {
    confirmFilterDuplicate,
    confirmSelectedAllFiltersDelete,
    confirmFilterDelete,
    confirmCancelFilterForm,
  };
};
