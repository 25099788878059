import { useConfirmDialog } from "@/services/confirm-modals/base";
import { getDiscardChangesConfig } from "@/services/utils/utils";

const confirmNotificationDeletionConfig = () => {
  return {
    header: "Delete Email Notification?",
    message: `You're about to delete this email notification. This action cannot be undone.
      Are you sure you want to proceed?`,
    acceptLabel: "Delete",
    acceptIcon: "pi pi-trash",
    acceptClass: "p-button p-button-remove",
  };
};

const confirmNotificationDeletionSelectAllConfig = () => {
  return {
    header: "Delete Email Notifications?",
    message: `You're about to delete multiple email notifications. This action cannot be undone.
        Are you sure you want to proceed?`,
    acceptLabel: "Delete",
    acceptIcon: "pi pi-trash",
    acceptClass: "p-button p-button-remove",
  };
};

const confirmNotificationDuplicateConfig = () => {
  return {
    header: "Duplicate Email Notification?",
    message: "Are you sure you want to duplicate this email notification?",
    acceptLabel: "Duplicate",
  };
};

const confirmNotificationCancelConfig = (action) => {
  return getDiscardChangesConfig("email notification", action);
};

export const useNotificationsConfirmDialog = () => {
  const { confirmAction } = useConfirmDialog();

  const confirmNotificationDelete = ({ callback }) => {
    return confirmAction({
      callback,
      ...confirmNotificationDeletionConfig(),
    });
  };

  const confirmNotificationDuplicate = ({ callback }) => {
    return confirmAction({
      callback,
      ...confirmNotificationDuplicateConfig(),
    });
  };

  const confirmSelectedAllNotificationDelete = ({ callback }) => {
    return confirmAction({
      callback,
      ...confirmNotificationDeletionSelectAllConfig(),
    });
  };

  const confirmCancelNotificationForm = ({ callback, action }) => {
    return confirmAction({
      callback,
      ...confirmNotificationCancelConfig(action),
    });
  };

  return {
    confirmNotificationDelete,
    confirmNotificationDuplicate,
    confirmSelectedAllNotificationDelete,
    confirmCancelNotificationForm,
  };
};
