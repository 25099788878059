<template>
  <MainPage>
    <template #body>
      <StepperContainer :steps="steps" />
    </template>
  </MainPage>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import MainPage from "@/components/MainPage.vue";
import StepperContainer from "@/components/stepper/StepperContainer.vue";
import { useRoute } from "vue-router";
import { useCsvProfileEditStore } from "@/components/csv-import/stores/csvProfile";
import { useCsvImportConfirmDialog } from "@/components/csv-import/helpers/confirmModalConfigs";
import { storeToRefs } from "pinia";
import { useCsvNavigationGuards } from "@/components/csv-import/composables/useCsvNavigationGuards.js";
const steps = ref([
  {
    label: "Profile Name",
    to: "profile-name",
    step: 0,
  },
  {
    label: "Field Mapping",
    to: "edit-mapping-field",
    step: 1,
  },
]);

const route = useRoute();
const profileIdRouteId = computed(() => {
  return route.params.id.toString();
});
const csvProfileEditStore = useCsvProfileEditStore();
const { isChange, isSubmitting } = storeToRefs(csvProfileEditStore);

onMounted(async () => {
  await csvProfileEditStore.getCsvProfileDetails(profileIdRouteId.value);
});

const { confirmDiscardCsvProfilesEdit } = useCsvImportConfirmDialog();
const onStateChange = (to, from, next) => {
  confirmDiscardCsvProfilesEdit({
    callback: () => {
      next();
      csvProfileEditStore.resetStore();
    },
  });
};

const { useOnBeforeRouteLeave } = useCsvNavigationGuards(steps);
useOnBeforeRouteLeave({
  isStateChanged: isChange,
  onConfirmation: onStateChange,
  isSubmitting,
});
</script>
