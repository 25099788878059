<template>
  <MainPage data-cy="create-configuration-route-page" :show-header="false">
    <template #body>
      <NotificationWorkflowForm :workflow-id="configurationRouteId" />
    </template>
  </MainPage>
</template>

<script setup>
import MainPage from "@/components/MainPage.vue";
import NotificationWorkflowForm from "@/components/route-configurations/NotificationWorkflowForm";
import { computed } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const configurationRouteId = computed(() => {
  return route.params.id.toString() || "";
});
</script>
