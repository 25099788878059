/* Wrapper component for form fields. Used to wrap components like FormInputText
or FormDropdown to provide common styling. */
<template>
  <div>
    <slot name="field"></slot>
  </div>
</template>

<script setup></script>

<style scoped lang="scss">
:slotted(.column) {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
:slotted(:deep(.p-inputtext)) {
  height: 42px !important;
  padding: 10.5px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  color: #495057;

  &:enabled:focus {
    border-color: #ced4da;
    box-shadow: none;
  }
}

:slotted(:deep(.p-dropdown)) {
  height: 42px !important;
  display: flex;
  align-items: center;
  border-radius: 6px;
}

:slotted(:deep(.p-multiselect)) {
  height: 42px !important;
  display: flex;
  align-items: center;
  border-radius: 6px;
}

:slotted(:deep(.error-label)) {
  font-size: 12px;
  color: #ed244e;
  font-weight: 400;
}
:slotted(:deep(.p-inputtext.p-invalid.p-component)) {
  border-color: #ed244e !important;
}
:slotted(:deep(.p-dropdown.p-invalid.p-component)) {
  border-color: #ed244e !important;
}
:slotted(:deep(.p-multiselect.p-invalid.p-component)) {
  border-color: #ed244e !important;
}
:slotted(.label) {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #212121;
}
</style>
