<template>
  <div class="placeholder-container">
    <div class="placeholder-content">
      <div :class="'placeholder-icon ' + iconClass"></div>
      <h2>{{ heading }}</h2>
    </div>
  </div>
</template>

<script setup>
import { toRefs } from "vue";

const props = defineProps({
  heading: {
    type: String,
    default: "",
  },
  iconClass: {
    type: String,
    default: "",
  },
});

const { heading, iconClass } = toRefs(props);
</script>
<style scoped lang="scss">
.placeholder-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #dadee3;
  background: #f2f2f2;
  height: 100%;
  flex: 1 0 0;

  .placeholder-content {
    width: 155px;
    height: 70px;
    padding: 0.5px 0 1.5px 0;

    .placeholder-icon {
      margin: 0 auto 16px;
      height: 32px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      @media screen and (min-width: 1441px) {
        height: 48px;
      }
    }

    h2 {
      color: #282e38;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }
}
</style>
