<template>
  <div>
    <div v-if="routes.length">
      <div class="workflow-routes-heading">
        <h5>Campaigns</h5>
        <p>{{ workflowRoutesHeading }}</p>
      </div>
      <WorkflowErrorBar
        v-if="areAllRoutesDisabled"
        :error-message="NOT_ACTIVE_CAMPAIGNS_TEXT"
      />
      <DataTable
        :value="routes"
        :reorderable-columns="false"
        data-cy="workflow-routes"
        :row-class="getRouteRowClass"
        @row-reorder="onRowReorder"
      >
        <Column
          row-reorder
          header-style="width: 3rem"
          :reorderable-column="false"
        >
          <template #body="{ data, index }">
            <i :class="routeIconClasses"></i>
            <span :id="formatHash(data.name)" class="route-name">
              <InputSwitch
                v-tooltip.top="{
                  value: data.isActive
                    ? 'Campaign Activated'
                    : 'Campaign Deactivated',
                  showDelay: 500,
                  hideDelay: 200,
                }"
                :model-value="data.isActive"
                :disabled="isViewOnly"
                data-cy="campaign-state-slider"
                @update:model-value="updateRouteActiveState(index, $event)"
              />
              {{ data.name }}
            </span>
          </template>
        </Column>
        <Column key="name" field="name">
          <template #body="{ data, index }">
            <Button
              class="p-button p-button-tertiary-outlined"
              data-cy="view-route"
              @click="openRouteOverviewDialog(data, index)"
            >
              View
            </Button>
            <Button
              v-if="!isViewOnly"
              class="p-button p-button-tertiary-outlined"
              data-cy="edit-route"
              @click="onEditClick(index)"
            >
              Edit
            </Button>
            <Button
              v-if="!isViewOnly"
              type="button"
              class="p-button p-button-text p-button-icon-only"
              data-cy="delete-route"
              @click="onDeleteClick(index)"
            >
              <i class="pi pi-trash" />
            </Button>
          </template>
        </Column>
      </DataTable>
      <Button
        v-if="!isViewOnly"
        class="p-button p-button-outlined add-route"
        data-cy="create-route-btn"
        @click="navigateToCreate"
      >
        <i class="pi pi-plus" />Add Campaign
      </Button>
    </div>
    <Dialog
      v-model:visible="displayRouteOverviewDialog"
      position="center"
      :modal="true"
      :dismissable-mask="true"
      :show-header="false"
      :closable="true"
      :content-style="modalContentStyle"
      append-to="self"
    >
      <RouteOverview
        :route-name="selectedRoute.name"
        :route-options="selectedRoute.routeOptions"
        :route-filters="selectedRoute.shipmentFilters"
        :display-edit="!isViewOnly"
        @close-dialog="closeRouteOverviewDialog()"
        @navigate-to-edit="navigateToEditFromDialog"
      />
    </Dialog>
  </div>
</template>
<script setup>
import { computed, onMounted, ref, toRefs } from "vue";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import RouteOverview from "@/components/route-configurations/RouteOverview";
import { useRouteStore } from "@/components/route-configurations/stores/route";
import { formatHash } from "@/services/utils/hashFormatter";
import { useWorkflowsConfirmDialog } from "@/components/route-configurations/helpers/confirmModals";
import InputSwitch from "primevue/inputswitch";
import WorkflowErrorBar from "@/components/route-configurations/WorkflowErrorBar.vue";

const { confirmDeleteWorkflowRoute } = useWorkflowsConfirmDialog();
const workflowRoutesHeading = `Personalise your customers post-purchase experience.`;
const modalContentStyle = {
  "border-radius": "6px",
  width: "720px !important",
  display: "flex",
  "flex-direction": "column",
  "align-items": "flex-start",
  padding: "40px",
  gap: "24px",
};

const NOT_ACTIVE_CAMPAIGNS_TEXT =
  "You currently have no active campaigns. This will prevent shipments from being assigned a campaign.";

const props = defineProps({
  routes: {
    type: Array,
    default: () => [],
  },
  isViewOnly: {
    type: Boolean,
    default: false,
  },
  assignedRoute: {
    type: String,
    default: "",
  },
});

const displayRouteOverviewDialog = ref(false);
const selectedRoute = ref({});
const { routes } = toRefs(props);
const store = useRouteStore();
const { setRoute } = store;

const routeIconClasses = computed(() => {
  const baseClass = "pi pi-bars";
  return !props.isViewOnly && routes.value.length > 1
    ? `p-datatable-reorderablerow-handle ${baseClass}`
    : baseClass;
});

const emit = defineEmits([
  "deleteRoute",
  "reorderRoutes",
  "navigate-to-edit",
  "navigate-to-create",
  "on-route-active-change",
]);

const areAllRoutesDisabled = computed(
  () => routes.value.filter((r) => r.isActive).length === 0,
);

const openRouteOverviewDialog = (selectedRouteVal, index) => {
  selectedRoute.value = selectedRouteVal;
  selectedRoute.value.position = index;
  displayRouteOverviewDialog.value = true;
};

const closeRouteOverviewDialog = () => {
  displayRouteOverviewDialog.value = false;
};

const onRowReorder = (event) => {
  emit("reorderRoutes", event.value);
};

const onDeleteClick = (index) => {
  confirmDeleteWorkflowRoute({
    callback: () => emit("deleteRoute", index),
  });
};

const onEditClick = async (index) => {
  // set the route in the store
  routes.value[index].position = index;
  await setRoute(routes.value[index]);
  emit("navigate-to-edit");
};

const navigateToEditFromDialog = async () => {
  await setRoute(selectedRoute.value);
  closeRouteOverviewDialog();
  emit("navigate-to-edit");
};

const navigateToCreate = () => {
  emit("navigate-to-create");
};

onMounted(async () => {
  if (props.assignedRoute) {
    const el = await document.querySelector(props.assignedRoute);
    if (el) {
      el.closest("tr").classList.add("route-focus");
    }
  }
});

const getRouteRowClass = (rowData) => {
  return rowData.isActive ? "" : "inactive-route";
};

const updateRouteActiveState = (routeIndex, newActiveState) => {
  emit("on-route-active-change", routeIndex, newActiveState);
};
</script>
<style scoped lang="scss">
@import "../../scss/colors.scss";
.workflow-routes-heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 8px;
  height: 50px;
  margin-bottom: 24px;

  h5 {
    font-weight: 600;
    font-size: 17.5px;
    line-height: 21px;
    color: #1f2a4e;
    margin: 0;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #212121;
  }
}

.add-route {
  margin-top: 24px;
}
:deep(.p-datatable-table) {
  min-width: 100%;
  .p-datatable-thead {
    display: none;
  }
  .p-datatable-tbody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 8px;

    tr {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 48px;
      width: 100%;
      background: #f7f8f9;
      border-radius: 4px;
      justify-content: space-between;
      transition: all 0.3s ease;
      padding-right: 14px;
      border: 1px solid #edf0f2;

      &:hover {
        background: #f0f1f2;
      }

      &.inactive-route {
        background: #ffffff;
        border: 1px solid #edf0f2;

        &:hover {
          border: 1px solid #dadee3;
          transition: border 0.2s ease;
        }
      }
    }

    td {
      border: none !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0;
      &:first-child {
        .route-name::before {
          content: "";
          width: 0;
          height: 24px;
          border: 1px solid #d9d9d9;
        }
        .pi {
          font-size: 18px;
          width: 24px;
          text-align: center;
          padding: 14px 32px 14px 16px;
        }
      }

      &:last-child {
        gap: 8px;
      }

      .route-name {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: #4d4d4d;
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }
  }
}

:deep(.route-focus) {
  border: 2px solid #bfdbfe;
}
</style>
