import { httpRequest } from "@/services/apiRequests";

class NotificationsApiRequests {
  static async deleteSendgridAction(id) {
    return await httpRequest.delete(`sendgrid-actions/${id}/`);
  }

  static mapActionList(data) {
    return data.map((action) => ({
      id: action.id,
      name: action.name,
    }));
  }

  static async duplicateSendgridAction(id) {
    const { data } = await httpRequest.post(
      `sendgrid-actions/${id}/duplicate/`,
    );
    return this.mapActionList(data);
  }

  static async getActions() {
    const { data } = await httpRequest.get(`sendgrid-actions/`);
    return this.mapActionList(data);
  }

  static async getSendgridActionById(id) {
    const { data } = await httpRequest.get(`sendgrid-actions/${id}/`);
    return {
      id: id,
      name: data.name,
      selectedConfiguration: data.sendgrid_configuration,
      selectedTemplates: data.sendgrid_template,
      templateDataMappingId: data.template_data_mapping,
    };
  }

  static async createSendgridAction({
    name,
    sendgridConfigId,
    sendgridTemplateId,
    templateDataMappingId,
  }) {
    const data = {
      name,
      sendgrid_configuration: sendgridConfigId,
      sendgrid_template: sendgridTemplateId,
      template_data_mapping: templateDataMappingId,
    };
    return httpRequest.post("sendgrid-actions/", data);
  }

  static async updateSendgridAction({
    id,
    name,
    sendgridConfigId,
    sendgridTemplateId,
    templateDataMappingId,
  }) {
    const data = {
      id,
      name,
      sendgrid_configuration: sendgridConfigId,
      sendgrid_template: sendgridTemplateId,
      template_data_mapping: templateDataMappingId,
    };
    return await httpRequest.put(`sendgrid-actions/${id}/`, data);
  }

  static async getSendgridConfigurations() {
    const { data } = await httpRequest.get(`sendgrid-configurations/`);
    return data.map((sendgridConfiguration) => ({
      id: sendgridConfiguration.id,
      name: sendgridConfiguration.name,
    }));
  }

  static async getSendgridTemplates(sendgridConfigId) {
    const { data } = await httpRequest.get(
      `sendgrid-templates/${sendgridConfigId}/`,
    );
    return data.map((sendgridTemplate) => ({
      id: sendgridTemplate.template_id,
      name: sendgridTemplate.name,
    }));
  }
}

export default NotificationsApiRequests;
