import { useToastService } from "@/services/toast/utils";

export const useNotificationToastService = () => {
  const {
    displaySuccessToast,
    displayWarnToast,
    displayErrorToast,
    displayErrorGenericToast,
  } = useToastService();

  const displaySelectAllDeleteSuccessToast = () => {
    return displaySuccessToast({
      message: "Multiple email notifications have been successfully deleted.",
      summary: "Email Notifications Deleted",
    });
  };

  const displaySelectAllDeletePartialToast = () => {
    return displayWarnToast({
      message:
        "Some email notifications have been successfully deleted, while others could not be deleted.",
      summary: "Email Notifications Partial Deletion",
    });
  };

  const displaySelectAllDeleteFailToast = () => {
    return displayErrorToast({
      message: "Multiple email notifications have failed to be deleted.",
      summary: "Email Notifications Deletion Failed",
    });
  };

  const displayDeleteSuccessToast = (name) => {
    return displaySuccessToast({
      message: `The email notification ${name} has been successfully deleted.`,
      summary: "Email Notification Deleted",
    });
  };

  const displayDeleteInvalidActionToast = (name) => {
    return displayErrorToast({
      message: `Deletion was unsuccessful as the email notification ${name} is currently in use.`,
      summary: "Email Notification Deletion Failed",
    });
  };

  const displayDeleteFailToast = (name) => {
    return displayErrorToast({
      message: `The email notification ${name} has failed to delete.`,
      summary: "Email Notification Deletion Failed",
    });
  };

  const displayDuplicateSuccessToast = (name) => {
    return displaySuccessToast({
      message: `The email notification ${name} has been successfully duplicated.`,
      summary: "Email Notification Duplicated",
    });
  };

  const displayCreateSuccessToast = (name) => {
    return displaySuccessToast({
      message: `Sendgrid Action ${name} been created.`,
      summary: "Email Notification Created",
    });
  };

  const displayCreateNameAlreadyExists = (name) => {
    return displayErrorToast({
      message: `Creation was unsuccessful as the notification with ${name} already exists.`,
      summary: "Email Notification Creation Failed",
    });
  };

  const displayEditSuccessToast = (name) => {
    return displaySuccessToast({
      message: `Sendgrid Action ${name} been updated.`,
      summary: "Email Notification Updated",
    });
  };

  const displayEditNameAlreadyExists = (name) => {
    return displayErrorToast({
      message: `Editing was unsuccessful as the notification with ${name} already exists.`,
      summary: "Email Notification Edit Failed",
    });
  };

  return {
    displaySelectAllDeleteSuccessToast,
    displaySelectAllDeletePartialToast,
    displaySelectAllDeleteFailToast,
    displayDeleteSuccessToast,
    displayDeleteInvalidActionToast,
    displayDeleteFailToast,
    displayDuplicateSuccessToast,
    displayCreateSuccessToast,
    displayEditSuccessToast,
    displayErrorGenericToast,
    displayCreateNameAlreadyExists,
    displayEditNameAlreadyExists,
  };
};
