import { useConfirmDialog } from "@/services/confirm-modals/base";

const confirmUnmappedCsvColumnsConfig = () => {
  return {
    header: "Unmapped CSV Columns",
    message: `It appears that there are some columns in your CSV file that have not been mapped to any fields.
      Are you sure you want to proceed with creating this field mapping profile?`,
    acceptLabel: "Yes, continue",
    rejectLabel: "Cancel",
    rejectClass: "p button p-button-text",
  };
};

const confirmDeleteCsvProfileConfig = () => {
  return {
    header: "Delete Mapping Profile?",
    message: `Are you sure you want to delete this field mapping profile?
      Note: this action cannot be undone.`,
    acceptLabel: "Delete",
    acceptIcon: "pi pi-trash",
    acceptClass: "p-button p-button-remove",
  };
};

const confirmRedirectToCsvProfilesConfig = () => {
  return {
    header: "Unfinished CSV import",
    message: `Are you sure you want to leave the CSV Importer?
      Note any unsaved changes will be lost. If you proceed, you will be taken to the Field Mapping Profile creation page.`,
    acceptLabel: "Yes, continue",
    rejectLabel: "Cancel",
    rejectClass: "p-button p-button-text",
  };
};

const confirmDiscardChangesConfig = () => {
  return {
    header: "Discard CSV Import?",
    message: `Are you sure you want to discard this CSV import? Note that any unsaved changes will be lost.`,
    acceptLabel: "Discard",
    acceptClass: "p-button p-button-remove",
    rejectLabel: "Cancel",
    rejectClass: "p-button p-button-text",
  };
};

const confirmDiscardCsvProfilesCreateConfig = () => {
  return {
    header: "Discard Mapping Profile?",
    message: `Are you sure you want to discard this mapping profile? Note that any unsaved changes will be lost.`,
    acceptLabel: "Discard",
    acceptClass: "p-button p-button-remove",
    rejectLabel: "Cancel",
    rejectClass: "p-button p-button-text",
  };
};

const confirmDiscardCsvProfilesEditConfig = () => {
  return {
    header: "Discard changes?",
    message: `Are you sure you want to discard changes to this mapping profile? Note that any unsaved changes will be lost.`,
    acceptLabel: "Discard",
    acceptClass: "p-button p-button-remove",
    rejectLabel: "Cancel",
    rejectClass: "p-button p-button-text",
  };
};

export const useCsvImportConfirmDialog = () => {
  const { confirmAction } = useConfirmDialog();

  const confirmUnmappedCsvColumns = ({ callback }) => {
    return confirmAction({
      callback,
      ...confirmUnmappedCsvColumnsConfig(),
    });
  };

  const confirmDeleteCsvProfile = ({ callback }) => {
    return confirmAction({
      callback,
      ...confirmDeleteCsvProfileConfig(),
    });
  };

  const confirmRedirectToCsvProfiles = ({ callback }) => {
    return confirmAction({
      callback,
      ...confirmRedirectToCsvProfilesConfig(),
    });
  };

  const confirmDiscardChanges = ({ callback }) => {
    return confirmAction({
      callback,
      ...confirmDiscardChangesConfig(),
    });
  };

  const confirmDiscardCsvProfilesCreate = ({ callback }) => {
    return confirmAction({
      callback,
      ...confirmDiscardCsvProfilesCreateConfig(),
    });
  };

  const confirmDiscardCsvProfilesEdit = ({ callback }) => {
    return confirmAction({
      callback,
      ...confirmDiscardCsvProfilesEditConfig(),
    });
  };

  return {
    confirmUnmappedCsvColumns,
    confirmDeleteCsvProfile,
    confirmRedirectToCsvProfiles,
    confirmDiscardChanges,
    confirmDiscardCsvProfilesCreate,
    confirmDiscardCsvProfilesEdit,
  };
};
