import { useRouter } from "vue-router/dist/vue-router";
import { SUPPORT_LINK } from "@/constants";

export const Navigator = () => {
  const router = useRouter();
  const navigateToView = (viewName, params = {}) => {
    router.push({ name: viewName, params: params });
  };
  return {
    navigateToView,
  };
};

export const openExternalLinkInNewTab = (link) => {
  window.open(link, "_blank");
};

export const openSupportLink = () => {
  openExternalLinkInNewTab(SUPPORT_LINK);
};
