import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const getFormatedAwareDate = (date) => {
  if (!date) return "N/A";
  return dayjs(date).format("DD/MM/YYYY");
};

export const getFormatedAwareTime = (date) => {
  if (!date) return "N/A";
  return dayjs(date).format("HH:mm (z)");
};

export const getFormattedAwareDateTimeWithoutSeconds = (date) => {
  if (!date) return "N/A";
  return dayjs(date).format("DD/MM/YYYY HH:mm");
};

export const getFormatedNaiveDate = (date) => {
  if (!date) return "N/A";
  return dayjs(date).utc().format("DD/MM/YYYY");
};

export const getFormatedNaiveTime = (date) => {
  if (!date) return "N/A";
  return dayjs(date).utc().format("HH:mm");
};
