<template>
  <LoginPageLayout
    :error-message="errorMessage"
    :is-loading="isLoading"
    @on-login="onLogin"
  />
</template>

<script setup>
import { useAuthStore } from "@/store/authStore";
import { onMounted, ref } from "vue";
import LoginPageLayout from "@/components/login/LoginPageLayout.vue";
import { useRouter, useRoute } from "vue-router";
import {
  AUTH_ERROR_MESSAGE,
  GENERIC_ERROR_MESSAGE,
} from "@/components/login/helpers/errors";

const isLoading = ref(false);
const errorMessage = ref({});
const sessionToken = ref(null);
onMounted(async () => {
  await getSessionTokenQuery();
});
const router = useRouter();
const redirectToUrl = (redirectUrl) => {
  window.location.href = redirectUrl;
};
const route = useRoute();
const getSessionTokenQuery = async () => {
  await router.isReady();
  if (route.query.session_token) {
    sessionToken.value = route.query.session_token;
  } else {
    await router.push({ name: "PreLoginPage" });
  }
};
const clearError = () => (errorMessage.value = {});
const setErrorMessage = ({ isAuthenticationError, message }) => {
  errorMessage.value = { isAuthenticationError, message };
};
const authStore = useAuthStore();
const onLogin = async (credentials) => {
  isLoading.value = true;
  clearError();
  const { username, password } = credentials;
  const { redirectUrl, error } = await authStore.logIn(
    username,
    password,
    sessionToken.value,
  );
  if (!error) {
    redirectToUrl(redirectUrl);
  } else {
    if (error?.status === 401) {
      setErrorMessage({
        isAuthenticationError: true,
        message: AUTH_ERROR_MESSAGE,
      });
    } else {
      setErrorMessage({
        isAuthenticationError: false,
        message: GENERIC_ERROR_MESSAGE,
      });
    }
  }
  isLoading.value = false;
};
</script>
