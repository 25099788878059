import ImportCsvModal from "@/components/csv-import/ImportCsvModal.vue";
import { useGlobalDialogService } from "@/services/modals/base";
import ImportProfileDetailsErrorSummary from "@/components/csv-import/ImportProfileDetailsErrorSummary.vue";

export const useCsvImportDialog = () => {
  const { openDialog } = useGlobalDialogService();

  const openDialogImportShipmentDialog = ({ profileId }) => {
    return openDialog({
      component: ImportCsvModal,
      dialogProps: {
        modal: true,
        dismissableMask: false,
        showHeader: false,
        style: {
          width: "880px",
          // That's workaround to have 32px padding at end.
          // p-dialog-content, which cannot be override is using 1 rem padding
          padding: "calc(32px - 1rem)",
        },
      },
      componentData: {
        profileId,
      },
    });
  };
  return { openDialogImportShipmentDialog };
};

export const useCsvProfileErrorSummaryDialog = () => {
  const { openDialog } = useGlobalDialogService();

  const openErrorSummaryDialog = ({
    rowsFeedback,
    filename,
    status,
    totalAdded,
    totalFailed,
  }) => {
    return openDialog({
      component: ImportProfileDetailsErrorSummary,
      dialogProps: {
        modal: true,
        dismissableMask: true,
        showHeader: false,
        style: {
          width: "800px",
          // That's workaround to have 32px padding at end.
          // p-dialog-content, which cannot be override is using 1 rem padding
          padding: "calc(32px - 1rem)",
        },
      },
      componentData: {
        rowsFeedback,
        filename,
        status,
        totalAdded,
        totalFailed,
      },
    });
  };
  return { openErrorSummaryDialog };
};
