import { computed, ref } from "vue";
import Papa from "papaparse";

export const formatSize = (bytes) => {
  if (bytes === 0) {
    return "0 B";
  }

  let k = 1000,
    dm = 3,
    sizes = ["B", "KB", "MB", "GB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const isWildcard = (fileType) => {
  return fileType.indexOf("*") !== -1;
};
export const getFileExtension = (file) => {
  return "." + file.name.split(".").pop();
};
export const getTypeClass = (fileType) => {
  return fileType.substring(0, fileType.indexOf("/"));
};

export const readFiles = async (filesToUpload) => {
  const errorMessage = ref("");
  const readFilesContent = ref([]);

  const reader = new FileReader();
  const files = Array.from(filesToUpload).map((file) => {
    return new Promise((resolve) => {
      reader.readAsText(file);
      reader.onload = async () => {
        resolve(reader.result);
      };
      reader.onerror = async () => {
        errorMessage.value = `Oops! Something went wrong while uploading your file.
            Please try again by refreshing the page or uploading the file again.

            If the issue persists, please contact our support team for further assistance.`;
        resolve();
      };
    });
  });
  readFilesContent.value = await Promise.all(files);
  return { errorMessage, readFilesContent };
};

export const readImage = async (filesToUpload, reqWidth, reqHeight) => {
  const errorMessage = ref("");
  const readFilesContent = ref([]);

  const img = new Image();

  const reader = new FileReader();
  const files = Array.from(filesToUpload).map((file) => {
    return new Promise((resolve) => {
      reader.readAsDataURL(file);
      reader.onload = async (e) => {
        img.src = e.target.result;
      };
      reader.onerror = async () => {
        errorMessage.value = `Oops! Something went wrong while uploading your image.
            Please try again by refreshing the page or uploading the image file again.
            If the issue persists, please contact our support team for further assistance.`;
        resolve();
      };
      img.onload = () => {
        // check image dimensions
        const isValidDimensions =
          img.width >= reqWidth && img.height >= reqHeight;
        if (isValidDimensions) {
          resolve(reader.result);
        } else {
          errorMessage.value = `Invalid image dimensions. The image must be 
            minimum ${reqWidth}(w) and ${reqHeight}(h) px`;
          resolve();
        }
      };
    });
  });
  readFilesContent.value = await Promise.all(files);
  return { errorMessage, readFilesContent };
};

export const useCsvFileReader = () => {
  const parsedCsvFile = ref([]);
  const rawCsvFiles = ref([]);

  const filename = computed(() => {
    return firstFile.value.name;
  });

  const firstFile = computed(() => {
    return rawCsvFiles.value[0];
  });

  const numberOfColumns = computed(() => {
    if (!parsedCsvFile.value.length) return 0;
    return Math.max(...parsedCsvFile.value.map((row) => row.length));
  });

  const uploadCsvFile = async ({
    readFilesContent,
    selectedFiles,
    callbackOnLoad,
  }) => {
    rawCsvFiles.value = selectedFiles;
    const papaParsedFile = Papa.parse(readFilesContent[0], {
      skipEmptyLines: true,
      preview: 5,
    });
    parsedCsvFile.value = papaParsedFile.data;
    await callbackOnLoad?.();
  };

  const getRawSampleFile = (parsedCsvFile) => {
    return Papa.unparse(parsedCsvFile.value, { newline: "\n" });
  };

  return {
    parsedCsvFile,
    rawCsvFiles,
    firstFile,
    filename,
    numberOfColumns,
    uploadCsvFile,
    getRawSampleFile,
  };
};
