import { useConfirmDialog } from "@/services/confirm-modals/base";
import { getDiscardChangesConfig } from "@/services/utils/utils";

const confirmTemplateDataMappingDeletionConfig = () => {
  return {
    header: "Delete Data Mapping?",
    message: `You're about to delete this data mapping. This action cannot be undone.
        Are you sure you want to proceed?`,
    acceptLabel: "Delete",
    acceptIcon: "pi pi-trash",
    acceptClass: "p-button p-button-remove",
  };
};

const confirmTemplateDataMappingDeletionSelectAllConfig = () => {
  return {
    header: "Delete Data Mappings?",
    message: `You're about to delete multiple data mappings. This action cannot be undone.
      Are you sure you want to proceed?`,
    acceptLabel: "Delete",
    acceptIcon: "pi pi-trash",
    acceptClass: "p-button p-button-remove",
  };
};

const confirmTemplateDataMappingDuplicateConfig = () => {
  return {
    header: "Duplicate Data Mapping?",
    message: "Are you sure you want to duplicate this data mapping?",
    acceptLabel: "Duplicate",
  };
};

const confirmTemplateDataCancelEditingConfig = (action) => {
  return getDiscardChangesConfig("template mapping", action);
};

export const useTemplateMappingsConfirmDialog = () => {
  const { confirmAction } = useConfirmDialog();

  const confirmTemplateDataMappingDelete = ({ callback }) => {
    return confirmAction({
      callback,
      ...confirmTemplateDataMappingDeletionConfig(),
    });
  };

  const confirmTemplateDataMappingDuplicate = ({ callback }) => {
    return confirmAction({
      callback,
      ...confirmTemplateDataMappingDuplicateConfig(),
    });
  };

  const confirmSelectedAllTemplateDataMappingDelete = ({ callback }) => {
    return confirmAction({
      callback,
      ...confirmTemplateDataMappingDeletionSelectAllConfig(),
    });
  };

  const confirmTemplateDataCancelEditing = (action, { callback }) => {
    return confirmAction({
      callback,
      ...confirmTemplateDataCancelEditingConfig(action),
    });
  };

  return {
    confirmTemplateDataMappingDelete,
    confirmTemplateDataMappingDuplicate,
    confirmSelectedAllTemplateDataMappingDelete,
    confirmTemplateDataCancelEditing,
  };
};
