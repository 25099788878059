import * as zod from "zod";
import {
  isValidString,
  isValidUrl,
} from "@/services/validation-form-schemas/utils";

export const useTrackingPageImageSchema = () => {
  return zod.object({
    desktop: isValidString({
      errorMessage: "Please provide a desktop image",
    }),
    mobile: isValidString({
      errorMessage: "Please provide a mobile image",
    }),
    url: isValidUrl({ errorMessage: "Please provide a valid url" }).or(
      zod.literal(""),
    ),
  });
};
