<template>
  <div class="tracking-page-table__container">
    <table class="tracking-page-table">
      <TrackingPageRow
        v-for="(trackingPage, index) in trackingPages"
        :key="index"
        :tracking-page="trackingPage"
      />
    </table>
  </div>
</template>
<script setup>
import TrackingPageRow from "@/components/tracking-pages/TrackingPageRow";
import { toRefs } from "vue";

const props = defineProps({
  trackingPages: {
    type: Array,
    default: () => [],
  },
});

const { trackingPages } = toRefs(props);
</script>
<style scoped lang="scss">
.tracking-page-table__container {
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  .tracking-page-table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 16px;
  }
}
</style>
