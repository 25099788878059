<template>
  <div>
    <div v-if="scheduledActions.length">
      <p>
        The following notifications will be sent for this shipment based on the
        assigned campaign:
      </p>
      <DataTable
        :value="scheduledActions"
        group-rows-by="scheduledActionStatus"
        row-group-mode="rowspan"
        data-cy="scheduled-actions"
      >
        <Column field="scheduledActionStatus" header="Event Trigger(s)">
          <template #body="{ data }">
            <StatusBadge size="normal" :status="data.scheduledActionStatus">
              {{ data.scheduledActionStatus }}
            </StatusBadge>
          </template>
        </Column>
        <Column field="scheduledActionName" header="Notification">
          <template #body="{ data }">
            <ActionBadge>
              <template #label> {{ data.scheduledActionName }} </template>
            </ActionBadge>
          </template>
        </Column>
        <Column field="notificationStatus" header="Notification Status">
          <template #body="{ data }">
            <ActionBadge :icon-for-action="data.notificationStatus">
              <template #label> {{ data.notificationStatus }} </template>
            </ActionBadge>
          </template>
        </Column>
      </DataTable>
    </div>
    <div v-else>
      <div v-if="isRoutingFailed" class="routing-failed">
        <NoRouteAssignedSection
          description="No campaign could be assigned as there were no matching segment filters."
          data-cy="no-route-assigned"
          @confirm-re-evaluate="confirmReEvaluateShipment"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ActionBadge from "@/components/ActionBadge";

import StatusBadge from "@/components/StatusBadge";
import { ROUTING_FAILED_STATUS } from "@/constants";
import { computed } from "vue";
import NoRouteAssignedSection from "@/components/shipments/NoRouteAssignedSection.vue";
import { useShipmentsConfirmDialog } from "@/components/shipments/helpers/confirmModalConfigs";

const props = defineProps({
  scheduledActions: {
    type: Array,
    default: () => [],
  },
  shipmentStatus: {
    type: String,
    default: "",
  },
});
const emit = defineEmits(["reevaluate-shipment"]);

const isRoutingFailed = computed(
  () => props.shipmentStatus === ROUTING_FAILED_STATUS,
);

const { confirmReEvaluateSingleShipment } = useShipmentsConfirmDialog();
const confirmReEvaluateShipment = () =>
  confirmReEvaluateSingleShipment({
    callback: async () => await emit("reevaluate-shipment"),
  });
</script>
<style scoped lang="scss">
:deep(.p-datatable) {
  .p-datatable-tbody > tr > td {
    vertical-align: top;
    border-bottom: 1px solid #dee2e6;
  }
}
:deep(.p-datatable .p-datatable-thead > tr > th) {
  background-color: #fafafa;
}

.routing-failed :deep(.p-message),
.p-message {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
  height: 80px;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
}

.routing-failed :deep(.p-message),
.routing-failed :deep(.mb-4) {
  margin: 0 !important;
}

.p-message-warn {
  background: #fffbf2;
  border: 1px solid #ffeecc;
  color: #664400;
}

.p-message-error {
  background: #fff2f5;
  border: 1px solid #fccad5;
  color: #73081e;
}

.p-message-text {
  display: flex;
  align-items: center;
  gap: 24px;
}
</style>
