<template>
  <div>
    <ConfigurationTable
      :key="key"
      :data-rows="brands"
      @edit-row="openBrandDialog"
    />
  </div>
</template>
<script setup>
import { ref, toRefs } from "vue";
import ConfigurationTable from "@/components/ConfigurationTable.vue";

const props = defineProps({
  brands: {
    type: Array,
    default: () => [],
  },
});

const key = ref(0);
const { brands } = toRefs(props);

const openBrandDialog = (row) => {
  // TODO: Implement Edit Dialog
  console.log("opening brand to edit....", row.id);
};
</script>
