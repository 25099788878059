<template>
  <div
    class="status-badge"
    :class="[status_class, size, isRounded ? 'round' : '']"
  >
    <slot>
      {{ status }}
    </slot>
  </div>
</template>

<script setup>
import { computed, toRef } from "vue";
import { convertToClass } from "@/services/utils/utils";

const props = defineProps({
  status: {
    type: String,
    default: "status-unavailable",
  },
  size: {
    type: String,
    default: "normal",
  },
  isRounded: {
    type: Boolean,
    default: false,
  },
});
const badge_status = toRef(props, "status");
const status_class = computed(
  () => "status-" + convertToClass(badge_status.value),
);
</script>

<style lang="scss" scoped>
.status-badge {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  letter-spacing: 0.03em;
  border-radius: 2px;
  text-transform: uppercase;
  padding: 6px 8px;
  white-space: nowrap;
}
.small {
  height: 24px;
  font-size: 11px;
}
.normal {
  height: 28px;
  font-size: 13px;
}
.large {
  height: 32px;
}
.status-imported,
.status-first-scan,
.status-manifested,
.status-unknown-status,
.status-information,
.status-unavailable,
.status-in_progress {
  background: #dadee3;
  color: #282e38;
}
.status-out-for-delivery {
  background: #d0e1fd;
  color: #183462;
}
.status-delivered,
.status-success {
  background: #cff7de;
  color: #0f5428;
}
.status-dispatched {
  background: #ffeecc;
  color: #664400;
}
.status-in-transit {
  background: #fad3e7;
  color: #5e1d3d;
}
.status-attempted-delivery,
.status-partial-success {
  background: #ffe1d9;
  color: #66220f;
}
.status-exception,
.status-failed {
  background: #fccad5;
  color: #73081e;
}

.round {
  border-radius: 100px;
}
</style>
