<template>
  <form class="image-config-form" @submit="onSubmit">
    <div
      v-for="({ label, width, height }, fieldName, index) in imageFieldsConfig"
      :key="index"
      class="image-config image-form-field"
    >
      <ImageUploader
        v-model:field-error="errors[fieldName]"
        :selected-files="selectedImageFiles[fieldName]"
        :req-width="width"
        :req-height="height"
        :label="label"
        @update:selected-files="updateFilesData($event, fieldName)"
      />
    </div>
    <div class="image-form-field">
      <FormInputText
        v-model="url"
        name="url"
        placeholder="https://www.yourstore.com"
        label="Image link (destination URL):"
        data-cy="image-url"
      />
    </div>
    <div class="d-flex justify-content-end action-buttons">
      <Button
        class="p-button p-button-text"
        data-cy="cancel-btn"
        label="Cancel"
        :disabled="isSubmitting"
        @click="onCloseModal"
      />
      <Button
        class="p-button-primary"
        data-cy="submit-image-form-btn"
        type="submit"
        :disabled="isSubmitBtnDisabled"
      >
        Update Images
      </Button>
    </div>
  </form>
</template>
<script setup>
import FormInputText from "@/components/FormInputText";
import Button from "primevue/button";
import { computed, inject, ref } from "vue";
import { EDIT, IMAGE_DIMENSIONS_MAP } from "@/constants";
import { useTrackingPagesConfirmDialog } from "@/components/tracking-pages/helpers/confirmModalConfigs";
import { useForm } from "vee-validate";

import { useTrackingPageEditStore } from "@/components/tracking-pages/stores/tracking-page";
import { toTypedSchema } from "@vee-validate/zod";
import { useTrackingPageImageSchema } from "@/services/validation-form-schemas/trackingPageImageSchema";
import _ from "lodash";
import ImageUploader from "@/components/ImageUploader.vue";

const trackingPageEditStore = useTrackingPageEditStore();
const { confirmCancelTrackingPageImageForm } = useTrackingPagesConfirmDialog();

const dialogRef = inject("dialogRef");
const { imageType, currentImage } = dialogRef.value.data;

const url = ref(currentImage.url);
const requiredDimensions = IMAGE_DIMENSIONS_MAP[imageType];

const imageFieldsConfig = {
  desktop: {
    label: "Desktop",
    width: requiredDimensions.desktop.width,
    height: requiredDimensions.desktop.height,
  },
  mobile: {
    label: "Mobile",
    width: requiredDimensions.mobile.width,
    height: requiredDimensions.mobile.height,
  },
};
const selectedImageFiles = ref({
  desktop: [currentImage.desktop],
  mobile: [currentImage.mobile],
});

const isSubmitBtnDisabled = computed(() => {
  return isSubmitting.value || !meta.value.valid;
});

const validationSchema = toTypedSchema(useTrackingPageImageSchema());

const { handleSubmit, isSubmitting, meta, setFieldValue, errors, resetForm } =
  useForm({
    validationSchema,
    initialValues: {
      desktop: "",
      mobile: "",
      url: "",
    },
  });

if (currentImage) {
  resetForm({
    values: {
      desktop: currentImage.desktop,
      mobile: currentImage.mobile,
      url: currentImage.url,
    },
  });
}

const updateFilesData = (newFiles, fieldName) => {
  selectedImageFiles.value[fieldName] = newFiles;
  // update form field value
  setFieldValue(fieldName, newFiles[0] ? newFiles[0].data : "");
};

const onSubmit = handleSubmit(async (values) => {
  const imageConfig = {
    desktop: values.desktop,
    mobile: values.mobile,
    url: values.url,
  };
  const imageHasChanged = !_.isEqual(currentImage, imageConfig);
  if (imageHasChanged) {
    imageConfig.hasChanged = imageHasChanged;
    trackingPageEditStore.setImage(imageType, imageConfig);
  }
  dialogRef.value.close(imageHasChanged);
});

const onCloseModal = () => {
  if (meta.value.dirty) {
    confirmCancelTrackingPageImageForm({
      callback: () => dialogRef.value.close(),
      action: EDIT,
    });
  } else {
    dialogRef.value.close();
  }
};
</script>
<style scoped lang="scss">
.image-config-form {
  padding: calc(32px - 1rem);

  .image-form-field {
    margin-bottom: 24px;
  }
}
</style>
