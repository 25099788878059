import { ref } from "vue";
import { defineStore } from "pinia";
import { useConfigurationRequests } from "@/services/utils/configurationRequests";
import { CREATE, DIALOG_RESULTS, EDIT } from "@/constants";
import { useFiltersToastService } from "@/components/filters/helpers/toastConfigs";
import { filtersErrorMapper } from "@/components/filters/helpers/errors";
import FilterApiRequests from "@/components/filters/helpers/apiRequests";

export const useFilterStore = defineStore("filters", () => {
  // State
  const filters = ref([]);
  const lastAddedFilter = ref({});

  // Actions
  async function getFilters() {
    const { response } = await makeRequest({
      endpoint: async () => await FilterApiRequests.getFilters(),
    });
    replaceFilters(response.value);
  }

  async function createFilter(filterData) {
    const { response, error } = await makeRequest({
      endpoint: () => FilterApiRequests.createFilter(filterData),
      onSuccess: () => displayCreateSuccessToast(filterData.name),
      errorMapper: (errors) =>
        filtersErrorMapper(errors, {
          action: CREATE,
          filterName: filterData.name,
        }),
    });
    if (!error.value) {
      lastAddedFilter.value = response.value.data
        .filter(
          ({ id: id1 }) => !filters.value.some(({ id: id2 }) => id2 === id1),
        )
        .find((elem) => elem.name === filterData.name);
      replaceFilters(response.value.data);
      return DIALOG_RESULTS.CREATED;
    }
    return DIALOG_RESULTS.FAILED;
  }

  async function updateFilter(filterData) {
    const { response, error } = await makeRequest({
      endpoint: () => FilterApiRequests.updateFilter(filterData),
      onSuccess: () => displayEditSuccessToast(filterData.name),
      errorMapper: (errors) =>
        filtersErrorMapper(errors, {
          action: EDIT,
          filterName: filterData.name,
        }),
    });
    if (!error.value) {
      replaceFilters(response.value.data);
      return DIALOG_RESULTS.UPDATED;
    }
    return DIALOG_RESULTS.FAILED;
  }

  async function deleteFilter(filter) {
    const { response, error } = await makeRequest({
      endpoint: () => FilterApiRequests.deleteFilter(filter.id),
      onSuccess: () => displayDeleteSuccessToast(filter.name),
      onInvalidAction: () => displayDeleteInvalidActionToast(filter.name),
      onFail: () => displayDeleteFailToast(filter.name),
    });
    if (!error.value) {
      replaceFilters(response.value.data);
    }
  }

  async function bulkDeleteFilters(filters) {
    await makeBulkRequest({
      endpoint: FilterApiRequests.deleteFilter.bind(FilterApiRequests),
      endpointArgs: filters.map((t) => t.id),
      onFail: () => displaySelectAllDeleteFailToast(),
      onPartialSuccess: () => displaySelectAllDeletePartialToast(),
      onSuccess: () => displaySelectAllDeleteSuccessToast(),
      onEachSuccessRequest: (_response) => replaceFilters(_response),
    });
  }
  function replaceFilters(updatedFilters) {
    filters.value = updatedFilters;
  }

  async function duplicateFilter(filter) {
    const { response, error } = await makeRequest({
      endpoint: () => FilterApiRequests.duplicateFilter(filter.id),
      onSuccess: () => {
        displayDuplicateSuccessToast(filter.name);
      },
    });
    if (!error.value) {
      replaceFilters(response.value.data);
    }
  }

  function isFilterNameValid(filterId, proposedName) {
    const filterWithSameName = filters.value.find((filter) => {
      return filterId
        ? filterId !== filter.id && filter.name === proposedName
        : filter.name === proposedName;
    });
    return filterWithSameName === undefined;
  }

  const { makeRequest, makeBulkRequest } = useConfigurationRequests();
  const {
    displaySelectAllDeleteSuccessToast,
    displaySelectAllDeletePartialToast,
    displaySelectAllDeleteFailToast,
    displayDeleteSuccessToast,
    displayDeleteInvalidActionToast,
    displayDeleteFailToast,
    displayCreateSuccessToast,
    displayEditSuccessToast,
    displayDuplicateSuccessToast,
  } = useFiltersToastService();

  return {
    filters,
    lastAddedFilter,
    getFilters,
    createFilter,
    updateFilter,
    deleteFilter,
    bulkDeleteFilters,
    duplicateFilter,
    isFilterNameValid,
  };
});
