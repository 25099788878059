<template>
  <div class="card card-body stepper">
    <Steps :model="steps" aria-label="Form Steps" :readonly="true" />
  </div>
  <router-view
    v-slot="{ Component }"
    @prev-page="prevPage"
    @next-page="nextPage"
  >
    <component :is="Component" />
  </router-view>
</template>

<script setup>
import { onBeforeRouteUpdate, useRouter } from "vue-router";
import { ref, toRefs } from "vue";
import Steps from "primevue/steps";

const props = defineProps({
  steps: {
    type: Array,
    default: () => [],
  },
});
const { steps } = toRefs(props);

const currentStep = ref(0);
const nextPage = () => {
  currentStep.value += 1;
  moveToNextStep();
};
const prevPage = () => {
  currentStep.value -= 1;
  moveToNextStep();
};

const router = useRouter();
const moveToNextStep = () => {
  router.push(steps.value.find((step) => step.step === currentStep.value).to);
};

const getRouteNameByPath = (path) => {
  const resolvedRoute = router.resolve({ path: path });
  return resolvedRoute.name;
};

onBeforeRouteUpdate((to) => {
  // Make sure that we will have correct step set also when navigating via browser buttons
  const stepToEnter = steps.value.find(
    (step) => getRouteNameByPath(step.to) === to.name,
  );
  if (stepToEnter) currentStep.value = stepToEnter.step;
  return true;
});
</script>
<style scoped>
:deep(.p-steps .p-steps-item .p-menuitem-link .p-steps-number) {
  border-radius: 14px;
  background: #f2f2f2;
  border-color: #f2f2f2;
  color: #333333;
  font-size: 17.5px;
  line-height: 21px;
}
:deep(.p-steps .p-steps-item.p-highlight .p-steps-number) {
  background: #26d466;
  color: #1f2a4e;
  font-weight: 600;
}
.stepper {
  padding: 20px 32px;
}
</style>
