import { onBeforeRouteLeave } from "vue-router";

export const useCsvNavigationGuards = (steps) => {
  const useOnBeforeRouteLeave = ({
    isStateChanged,
    onConfirmation,
    isSubmitting,
  }) => {
    onBeforeRouteLeave((to, from, next) => {
      const isPathAmongSteps = (redirectTo) => {
        return steps.value.map((s) => s.to).includes(redirectTo.path);
      };
      const shouldShowConfirmation =
        !isPathAmongSteps(to) && !isSubmitting.value && isStateChanged.value;
      if (shouldShowConfirmation) {
        onConfirmation(to, from, next);
      } else {
        next();
      }
      isSubmitting.value = false;
    });
  };
  return { useOnBeforeRouteLeave };
};
