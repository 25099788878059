export const useRouteConfigurationsEmptyState = () => {
  const buttons = {
    buttonPrimary: {
      iconClass: "pi pi-plus",
      label: "New Automation",
    },
    buttonSecondary: {
      iconClass: "pi pi-chart-pie",
      label: "Segments",
    },
  };

  const emptyConfigTagline =
    "Enhance your customers' experience by automating delivery notifications.";

  const initialConfig = {
    heading: "Create your first automation",
    tagline: emptyConfigTagline,
    iconClass: "icon-diamond-workflow",
    ...buttons,
  };

  const emptyConfig = {
    heading: "Oops! You have no automations",
    tagline: emptyConfigTagline,
    iconClass: "icon-diamond-workflow-empty",
    ...buttons,
  };

  return { initialConfig, emptyConfig, buttons };
};
