<template>
  <MainPage
    :error-message="errorMessage"
    data-cy="past-shipments-list-page"
    main-class="list-shipments"
    :loading="loading"
    @wipe-error="wipeError"
  >
    <template #headingBar>
      <h3 class="m-0 shipments-list-heading">
        <span>Past Shipments</span
        ><i
          v-tooltip.right="{
            value:
              'Shipments older than six months will be automatically deleted.',
          }"
          class="pi pi-question-circle"
        />
      </h3>
    </template>
    <template #actionsBar>
      <ListShipmentsActionBar
        :filter-query="filterQuery"
        @refresh-shipments="reload"
      />
    </template>
    <template #body>
      <div v-if="isContentLoaded" class="card shadow">
        <TabView
          :active-index="activeIndex"
          @tab-change="toggleLifecycleStatus"
        >
          <template #TabPanels>
            <TabPanel>
              <template #header>
                <span>All</span>
              </template>
            </TabPanel>
            <TabPanel>
              <template #header>
                <i class="fas fa-custom-completed-lifecycle" /><span
                  >Completed</span
                >
              </template>
            </TabPanel>
            <TabPanel>
              <template #header>
                <i class="pi pi-calendar-times" /><span>Expired</span>
              </template>
            </TabPanel>
          </template>
        </TabView>
        <div class="card-body">
          <EmptyShipmentsMessage
            v-if="hideTable"
            :lifecycle-status="filterQuery.lifecycle_status"
            :tracking-number="filterQuery.tracking_number"
            data-cy="past-shipments-empty-msg"
          />
          <ListShipmentsTable
            v-else
            :key="listShipmentsKey"
            v-model:latest-event="latestEvent"
            :is-past-shipment="true"
            :tracking-number="filterQuery.tracking_number"
            :lifecycle-status="filterQuery.lifecycle_status"
            @fetching-success="setVisibility($event)"
            @fetching-failed="onFetchingFailed"
          />
        </div>
      </div>
    </template>
  </MainPage>
</template>

<script setup>
import ListShipmentsTable from "./ListShipmentsTable.vue";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import ListShipmentsActionBar from "@/components/shipments/ListShipmentsActionBar";
import { COMPLETED, EXPIRED } from "@/constants";
import EmptyShipmentsMessage from "@/components/shipments/EmptyShipmentsMessage";
import { UrlQuery } from "@/services/utils/urlQuery";
const { addQueryParam } = UrlQuery();
import TabView from "@/components/TabView";
import TabPanel from "primevue/tabpanel";

const loading = ref(false);
const errorMessage = ref("");
const listShipmentsKey = ref(0);
const route = useRoute();
const filterQuery = ref(route.query);
const displayEmpty = ref(false);
const latestEvent = ref("");

const indexMap = {
  [`${COMPLETED},${EXPIRED}`]: 0,
  [COMPLETED]: 1,
  [EXPIRED]: 2,
};

const tabs = ref([
  { title: "All", filter: `${COMPLETED},${EXPIRED}` },
  { title: "Completed", filter: COMPLETED },
  { title: "Expired", filter: EXPIRED },
]);

const isContentLoaded = computed(() => {
  return !loading.value;
});

const activeIndex = computed(() => {
  return indexMap[filterQuery.value.lifecycle_status];
});

const reload = async () => {
  wipeError();
  displayEmpty.value = false;
  loading.value = true;
  listShipmentsKey.value++;
  loading.value = false;
};

const wipeError = () => {
  errorMessage.value = null;
};

const onFetchingFailed = () => {
  errorMessage.value = "Fetching shipments has failed.";
};

const setQuery = (query = null) => {
  filterQuery.value = query;
};

const setVisibility = (e) => {
  displayEmpty.value = e.length === 0;
};

const toggleLifecycleStatus = (e) => {
  const lifecycleStatus = tabs.value[e.index].filter;
  addQueryParam(filterQuery.value, "lifecycle_status", lifecycleStatus);
};

const hideTable = computed(() => {
  return (
    displayEmpty.value &&
    !latestEvent.value &&
    !filterQuery.value.tracking_number
  );
});

const setupQueryWatcher = () => {
  watch(
    () => route.query,
    (toQuery) => {
      setQuery(toQuery);
      reload();
    },
  );
};
const setupPage = () => {
  setupQueryWatcher();
  setQuery(route.query);
  if (!filterQuery.value.lifecycle_status) {
    filterQuery.value.lifecycle_status = `${COMPLETED},${EXPIRED}`;
  }
  reload();
};
setupPage();
</script>
<style scoped lang="scss">
:deep(.p-tabview) {
  padding: 32px 32px 0 32px;
}

:deep(.card-body) {
  padding-top: 0;
}

:deep(.p-tabview-nav-link) {
  display: flex;
  padding: 17.5px;
  align-items: center;
  gap: 7px;
  align-self: stretch;
}
</style>
