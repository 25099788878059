import { useRouter } from "vue-router";
import { useConfigurationRequests } from "@/services/utils/configurationRequests";

export const useCsvProfileCreationRequest = () => {
  const router = useRouter();
  const { makeRequest } = useConfigurationRequests();

  const makeCsvProfileRequest = async ({
    endpoint,
    onSuccess,
    errorMapper,
  }) => {
    const { error } = await makeRequest({
      endpoint: () => endpoint,
      onSuccess: onSuccess,
      errorMapper: errorMapper,
    });
    if (!error.value) {
      await router.push({ name: "ListCsvProfiles" });
    }
    return { error };
  };
  return { makeCsvProfileRequest };
};
