import * as zod from "zod";
import { isValidString } from "@/services/validation-form-schemas/utils";

export const useTemplateMappingSchema = () => {
  return zod.object({
    templateMappingName: isValidString({
      errorMessage: "Please enter a data mapping name.",
    }),
    templateMappings: zod.array(
      zod.object({
        attributeId: isValidString({
          errorMessage: "Please select a mapping attribute.",
        }),
        handlebar: isValidString({
          errorMessage: "Please enter an attribute value.",
        }),
      }),
    ),
  });
};
