<template>
  <StepContainer
    :title="pageTitle"
    :next-button-label="buttonSubmitLabel"
    next-button-icon="pi pi-save"
    display-previous-button
    :is-next-button-disabled="!areAllRequiredFieldsSelected"
    @prev-button-click="onClickPrev"
    @next-button-click="onCreate"
  >
    <template #header-description>
      <span>
        To ensure proper import of your shipment data, please map all required
        and necessary fields in the section below.
      </span>
      <span v-if="areRecommendedFields">
        Note: For shipments with multiple packages, mapping the
        <span class="highlight-text">'Package Count'</span> field is recommended
        to ensure import accuracy.
      </span>
    </template>
    <template #content>
      <div class="tags-container">
        <div class="tags-group-container">
          <span class="tags-group-header"> Required: </span>
          <div class="tags-row">
            <div
              v-for="(field, index) in requiredFields"
              :key="index"
              class="tags-row__tag"
              :class="{ 'tags-row__tag-checked': selectedFieldById(field.id) }"
              data-cy="required-field-tag"
            >
              <i class="pi pi-check-circle" /> {{ field.name }}
            </div>
          </div>
        </div>
        <divider v-if="areRecommendedFields" layout="vertical" />
        <div v-if="areRecommendedFields" class="tags-group-container">
          <span class="tags-group-header"> Recommended: </span>
          <div
            v-for="(field, index) in recommendedFields"
            :key="index"
            class="tags-row--recommended"
          >
            <div
              class="tags-row__tag"
              :class="{ 'tags-row__tag-checked': selectedFieldById(field.id) }"
            >
              <i class="pi pi-check-circle" />
              {{ field.name }}
            </div>
            <i
              v-tooltip.top="{ value: `${field.helpText}` }"
              class="pi pi-question-circle"
            />
          </div>
        </div>
      </div>
      <DataTable :value="parsedCsvFile">
        <Column
          v-for="(_, columnIndex) in numberOfColumns"
          :key="columnIndex"
          :column-key="`${columnIndex}`"
          class="mapping-column"
        >
          <template #header>
            <FormDropdown
              :name="`mappingDropdown-${columnIndex}`"
              :model-value="getIdOfSelectedField(columnIndex)"
              :options="dropdownOptionGroups"
              option-group-label="required"
              option-group-children="items"
              option-label="name"
              option-value="id"
              placeholder="Select mapping..."
              :option-disabled="(option) => selectedFieldById(option.id)"
              panel-class="dropdown-list"
              :auto-option-focus="false"
              :show-clear="!!getIdOfSelectedField(columnIndex)"
              data-cy="mapping-dropdown"
              @update:model-value="onDropdownValueChange($event, columnIndex)"
            >
              <template #option="{ option }">
                {{ getDropdownOptionsListValue(option) }}
              </template>
              <template #optiongroup="{ index }">
                <divider
                  v-if="index === requiredFields.length + 1"
                  layout="horizontal"
                />
              </template>
            </FormDropdown>
          </template>
          <template #body="slotProps">
            {{ slotProps.data[columnIndex] }}
          </template>
        </Column>
      </DataTable>
    </template>
  </StepContainer>
</template>

<script setup>
import Divider from "primevue/divider";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { computed, onMounted, ref, toRefs } from "vue";
import FormDropdown from "@/components/FormDropdown.vue";
import { useCsvImportConfirmDialog } from "@/components/csv-import/helpers/confirmModalConfigs";
import { useCsvFileReader } from "@/services/utils/filesUtils";
import StepContainer from "@/components/stepper/StepContainer";
import CsvApiRequests from "@/components/csv-import/helpers/apiRequests";

const props = defineProps({
  parsedCsvFile: {
    type: Array,
    required: true,
  },
  profileName: {
    type: String,
    required: true,
  },
  numberOfColumns: {
    type: Number,
    default: 0,
  },
  selectedAttributes: {
    type: Array,
    default: () => [],
  },
  pageTitle: {
    type: String,
    required: true,
  },
  buttonSubmitLabel: {
    type: String,
    required: true,
  },
});

const { parsedCsvFile, profileName, numberOfColumns, selectedAttributes } =
  toRefs(props);
const { getRawSampleFile } = useCsvFileReader();
const shipmentAttributes = ref([]);
onMounted(async () => {
  shipmentAttributes.value =
    await CsvApiRequests.getShipmentAttributesCsvImport();
});

const emit = defineEmits([
  "next-page",
  "prev-page",
  "onSubmitCsvProfile",
  "update:selectedAttributes",
]);

const onClickPrev = () => emit("prev-page");

const requiredFields = computed(() => {
  return shipmentAttributes.value.filter((o) => o.required);
});
const nonRequiredFields = computed(() => {
  return shipmentAttributes.value.filter((o) => !o.required);
});
const recommendedFields = computed(() => {
  return nonRequiredFields.value.filter((o) => o.recommended);
});
const areRecommendedFields = computed(() => {
  return !!recommendedFields.value.length;
});

const selectedFieldByIndex = computed(() => {
  return (index) => selectedAttributes.value.find((o) => o.index === index);
});

const selectedFieldById = computed(() => {
  return (id) => selectedAttributes.value.find((o) => o.id === id);
});

const getIdOfSelectedField = computed(() => {
  return (index) => selectedFieldByIndex.value(index)?.id;
});

const dropdownOptionGroups = computed(() => {
  return [
    {
      required: true,
      items: requiredFields.value,
    },
    {
      required: false,
      items: nonRequiredFields.value,
    },
  ];
});

const setSelectedAttributes = (newSelectedAttributes) => {
  emit("update:selectedAttributes", newSelectedAttributes);
};

const clearDropdownField = (index) => {
  const selectedAttributesAfterClear = selectedAttributes.value.filter(
    (attr) => attr.index !== index,
  );
  setSelectedAttributes(selectedAttributesAfterClear);
};
const onDropdownValueChange = (id, index) => {
  if (id === null) {
    return clearDropdownField(index);
  }
  const selectedOption = shipmentAttributeById(id);
  const fieldByIndex = selectedFieldByIndex.value(index);
  const newElement = {
    ...selectedOption,
    index,
  };
  if (fieldByIndex) {
    Object.assign(fieldByIndex, newElement);
  } else {
    setSelectedAttributes([...selectedAttributes.value, newElement]);
  }
};

const areAllRequiredFieldsSelected = computed(() => {
  return (
    requiredFields.value.length &&
    requiredFields.value.every((field) =>
      selectedAttributes.value.some((selected) => selected.id === field.id),
    )
  );
});

const shipmentAttributeById = (id) => {
  return shipmentAttributes.value.find((o) => o.id === id);
};

const getDropdownOptionsListValue = (option) => {
  if (option.required) return `${option.name}*`;
  return option.name;
};

const { confirmUnmappedCsvColumns } = useCsvImportConfirmDialog();
const onCreate = async () => {
  if (selectedAttributes.value.length !== numberOfColumns.value) {
    return confirmUnmappedCsvColumns({
      callback: async () => await createCsvProfile(),
    });
  } else {
    await createCsvProfile();
  }
};

const createCsvProfile = async () => {
  emit("onSubmitCsvProfile", {
    profileName: profileName.value,
    selectedAttributes: selectedAttributes.value,
    sample: getRawSampleFile(parsedCsvFile),
  });
};
</script>

<style scoped lang="scss">
.tags-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 16px;
}

.tags-group-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.tags-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.tags-group-header {
  font-size: 14px;
  line-height: 21px;
}

.tags-row--recommended {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.tags-row__tag {
  display: flex;
  width: fit-content;
  border: 1px solid #dadee3;
  border-radius: 16px;
  height: 28px;

  color: #556376;

  align-items: center;
  gap: 4px;
  padding: 0 12px 0 8px;

  font-size: 14px;
  line-height: 14px;

  @media only screen and (max-width: 1400px) {
    padding: 0 8px 0 8px;
  }

  &-checked {
    background: #cff7de;
    color: #0f5428;
    border: 1px solid #a3ebbe;

    .pi {
      color: #0f5428;
    }
  }
}

.pi-check-circle {
  color: #9fa9b7;
}

.pi-question-circle {
  font-size: 1.25rem;
  color: #6c757d;
}

:deep(.p-dropdown) {
  width: 192px;
}

.highlight-text {
  font-weight: 600;
}

.mapping-column {
  min-width: 220px;
}

.header__description span {
  display: inline-block;
}
</style>
