<template>
  <MainPage :heading="name" data-cy="view-configuration-route-page">
    <template #body>
      <NotificationWorkflowForm
        :workflow-id="configurationRouteId"
        :version="version"
        :assigned-route="assignedRoute"
        :is-view-only="true"
        @navigate-to-latest="navigateToView()"
      />
    </template>
    <template #actionsBar>
      <Button v-if="name" class="p-button" @click="navigateToView()">
        <i v-if="btnConfig.icon" :class="btnConfig.icon" />
        {{ btnConfig.name }}
      </Button>
    </template>
  </MainPage>
</template>

<script setup>
import MainPage from "@/components/MainPage.vue";
import NotificationWorkflowForm from "@/components/route-configurations/NotificationWorkflowForm";
import Button from "primevue/button";
import { LATEST_VERSION } from "@/constants";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useWorkflowStore } from "@/components/route-configurations/stores/workflow";
import { storeToRefs } from "pinia/dist/pinia";

const store = useWorkflowStore();
const { name, deleted, isLatestVersion } = storeToRefs(store);

const route = useRoute();
const router = useRouter();

const assignedRoute = computed(() => {
  return route.hash;
});

const configurationRouteId = computed(() => {
  return route.params.id.toString() || "";
});

const version = computed(() => {
  return route.params.version.toString() || LATEST_VERSION;
});

const btnConfig = computed(() => {
  if (isLatestVersion.value) {
    return {
      name: "Edit Workflow",
      icon: "pi pi-pencil",
    };
  } else if (deleted.value) {
    return {
      name: "View Workflows",
    };
  }
  return {
    name: "View Current Version",
    icon: "pi pi-eye",
  };
});

const navigateToView = () => {
  if (deleted.value) {
    router.push({
      name: "ListNotificationWorkflows",
    });
  } else if (isLatestVersion.value) {
    router.push({
      name: "EditNotificationWorkflow",
      params: {
        id: configurationRouteId.value,
      },
    });
  } else {
    router.push({
      name: "ViewNotificationWorkflow",
      params: {
        id: configurationRouteId.value,
        version: LATEST_VERSION,
      },
    });
  }
};
</script>
