import { computed, ref } from "vue";
import { defineStore } from "pinia";
import AuthApiRequests from "@/services/authRequests";
import authRequests from "@/services/authRequests";

export const useAuthStore = defineStore("authStore", () => {
  const isAuthenticated = ref(false);
  const csrfToken = ref(null);
  const _lastVisitedPage = ref("/");

  const lastVisitedPage = computed(() => _lastVisitedPage.value);

  const setSessionInfo = (sessionInfo) => {
    isAuthenticated.value = sessionInfo.isAuthenticated;
    csrfToken.value = sessionInfo.csrfToken;
  };

  const getCsrfToken = async () => {
    const sessionInfo = await AuthApiRequests.getCsrfTokenFromBackend();
    setSessionInfo(sessionInfo);
  };

  const initSSO = async () => {
    const { redirectUrl } = await authRequests.initSSO(lastVisitedPage.value);
    return { redirectUrl };
  };

  const logIn = async (username, password, sessionToken) => {
    const { redirectUrl, error } = await AuthApiRequests.loginIn(
      { username, password },
      sessionToken,
    );
    return { redirectUrl, error };
  };

  const completeLogin = async (authToken) => {
    const { redirectUrl, error } =
      await AuthApiRequests.completeLogin(authToken);
    await getCsrfToken();
    return { redirectUrl, error };
  };

  const logOut = async () => {
    const { error } = await AuthApiRequests.logout();
    if (!error) await setUserAsUnAuthenticated();
    return { error };
  };

  const setUserAsUnAuthenticated = async () => {
    isAuthenticated.value = false;
  };

  const saveLastVisitedPage = (lastVisitedPage) => {
    _lastVisitedPage.value = lastVisitedPage;
  };

  return {
    csrfToken,
    isAuthenticated,
    lastVisitedPage,
    saveLastVisitedPage,
    setUserAsUnAuthenticated,
    getCsrfToken,
    logIn,
    logOut,
    initSSO,
    completeLogin,
  };
});
