import { ref } from "vue";
import { defineStore } from "pinia";
import { cloneDeep } from "lodash";

const initialRouteOptions = [
  {
    triggeredStatuses: [],
    scheduledActions: [{ actionId: "", scheduleId: "" }],
  },
];

export const useRouteStore = defineStore("route", () => {
  // State
  const name = ref("");
  const shipmentFilters = ref([]);
  const routeOptions = ref(cloneDeep(initialRouteOptions));
  const isEdit = ref(false);
  const currentRoute = ref({});
  const position = ref(0);

  // Actions
  function setRoute(route) {
    // sets the route to be edited
    isEdit.value = true;
    position.value = route.position;
    name.value = route.name;
    shipmentFilters.value = route.shipmentFilters;
    routeOptions.value = route.routeOptions;
    // keep a copy to compare
    currentRoute.value = {
      name: route.name,
      shipmentFilters: cloneDeep(route.shipmentFilters),
      routeOptions: cloneDeep(route.routeOptions),
    };
  }

  function setName(nameVal) {
    name.value = nameVal;
  }

  function setFilters(filtersVal) {
    shipmentFilters.value = filtersVal;
  }

  function setRouteOptions(routeOpts) {
    routeOptions.value = routeOpts;
  }

  function addNotification(routeOptionIndex) {
    routeOptions.value[routeOptionIndex].scheduledActions.push({
      actionId: "",
      scheduleId: "",
    });
  }

  function deleteNotification(routeOptionIndex, notificationIndex) {
    routeOptions.value[routeOptionIndex].scheduledActions.splice(
      notificationIndex,
      1,
    );
  }

  function setNotification(routeOptionIndex, notificationIndex, newActionId) {
    routeOptions.value[routeOptionIndex].scheduledActions[
      notificationIndex
    ].actionId = newActionId;
  }

  function resetRoute() {
    // resets the store
    position.value = 0;
    isEdit.value = false;
    name.value = "";
    shipmentFilters.value = [];
    routeOptions.value = cloneDeep(initialRouteOptions);
    currentRoute.value = {};
  }

  return {
    isEdit,
    name,
    shipmentFilters,
    routeOptions,
    position,
    currentRoute,
    setName,
    setFilters,
    resetRoute,
    setRoute,
    deleteNotification,
    addNotification,
    setNotification,
    setRouteOptions,
  };
});
