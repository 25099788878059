export const useBrandsEmptyState = () => {
  const buttons = {
    buttonPrimary: {
      iconClass: "pi pi-plus",
      label: "New Brand",
    },
  };

  const brandsTagline =
    "Maintain brand consistency across all your tracking pages with ease.";

  const initialConfig = {
    heading: "Create your first brand",
    tagline: brandsTagline,
    iconClass: "icon-diamond-brand small-diamond",
    extraId: "nested-empty-page",
    ...buttons,
  };

  const emptyConfig = {
    heading: "No brands!",
    tagline: brandsTagline,
    iconClass: "icon-diamond-brand-empty small-diamond",
    extraId: "nested-empty-page",
    ...buttons,
  };
  return { initialConfig, emptyConfig, brandsTagline };
};
