import {
  FAILED_NOTIFICATION_STATUS,
  PENDING_NOTIFICATION_STATUS,
  TRIGGERED_NOTIFICATION_STATUS,
} from "@/constants";
import { isRef } from "vue";
import { useRouter } from "vue-router";

export const getNotificationStatusMapDisplay = (notificationStatus) => {
  if (!notificationStatus) {
    return PENDING_NOTIFICATION_STATUS;
  }
  if (notificationStatus === FAILED_NOTIFICATION_STATUS) {
    return FAILED_NOTIFICATION_STATUS;
  }
  return TRIGGERED_NOTIFICATION_STATUS;
};

export const useOpenInNewTab = () => {
  const router = useRouter();

  const getNewTabUrl = (shipmentId) => {
    if (isRef(shipmentId)) {
      shipmentId = shipmentId.value;
    }
    return router.resolve({
      name: "ShipmentDetails",
      params: { id: shipmentId },
    }).href;
  };
  const openInNewTab = (shipmentId) => {
    const url = getNewTabUrl(shipmentId);
    window.open(url, "_blank");
  };
  return { openInNewTab, getNewTabUrl };
};
