<template>
  <div class="filter-statement" data-cy="filter-row">
    <div class="property-field">
      <FormDropdown
        :model-value="statement.propertyId"
        placeholder="Choose condition"
        :options="setups"
        :name="propertyFieldName"
        class="template-mapping-dropdown"
        data-cy="property-select"
        @update:model-value="updatedProperty($event)"
      />
    </div>
    <div class="operator-field">
      <FormDropdown
        :model-value="statement.operatorId"
        placeholder="Choose function"
        :options="statementOptions"
        :name="operatorFieldName"
        :disabled="isOperatorInputDisabled"
        class="template-mapping-dropdown"
        data-cy="operator-select"
        @update:model-value="updatedOperator($event)"
      />
    </div>
    <div class="value-field">
      <FormDropdown
        v-show="isSelectSelector"
        v-model="statement.selectedValue"
        placeholder="Choose value"
        :options="valueOptions"
        :name="selectedValueFieldName"
        class="template-mapping-dropdown"
        data-cy="value-select"
        :disabled="isValueInputDisabled && isSelectSelector"
      />
      <FormMultiDropdown
        v-show="isMultiselectSelector"
        v-model="statement.multiSelectedValues"
        :name="multiSelectValuesFieldName"
        :options="valueOptions"
        :disabled="isValueInputDisabled && isMultiselectSelector"
        placeholder="Select value(s)"
        data-cy="values-multi-select"
      />
      <FormInputText
        v-show="isInputSelector"
        v-model="statement.inputValue"
        :disabled="isValueInputDisabled && isInputSelector"
        :name="inputValueFieldName"
        :type="inputType"
        :step="inputStep"
        placeholder="Enter value"
        data-cy="value-input"
      />
      <FormInputText
        v-show="false"
        v-model="statement.valueSelector"
        :name="valueSelectorFieldName"
        :type="inputType"
        :step="inputStep"
        placeholder="Enter value"
        data-cy="value-selector"
      />
    </div>
    <Button
      v-if="canBeDeleted"
      class="p-button p-button-text p-button-icon-only"
      icon="pi pi-trash"
      :disabled="disabled"
      data-cy="delete-btn"
      @click="handleStatementDelete"
    />
  </div>
</template>

<script setup>
import FormDropdown from "@/components/FormDropdown.vue";
import FormInputText from "@/components/FormInputText.vue";
import Button from "primevue/button";
import { computed, toRefs } from "vue";
import FormMultiDropdown from "@/components/FormMultiDropdown";
import { useValueSelectorGetter } from "@/components/filters/helpers/utils";
import {
  MULTISELECT_SELECTOR_NAME,
  SELECT_SELECTOR_NAME,
} from "@/components/filters/helpers/constants";

const props = defineProps({
  index: {
    type: Number,
    default: null,
  },
  setups: {
    type: Array,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  canBeDeleted: {
    type: Boolean,
    default: false,
  },
  statement: {
    type: Object,
    default: null,
  },
});

const { statement, setups, index } = toRefs(props);

const fieldNameBase = computed(() => {
  return `statements[${index.value}]`;
});

const propertyFieldName = computed(() => {
  return `${fieldNameBase.value}.propertyId`;
});

const operatorFieldName = computed(() => {
  return `${fieldNameBase.value}.operatorId`;
});

const inputValueFieldName = computed(() => {
  return `${fieldNameBase.value}.inputValue`;
});

const selectedValueFieldName = computed(() => {
  return `${fieldNameBase.value}.selectedValue`;
});

const multiSelectValuesFieldName = computed(() => {
  return `${fieldNameBase.value}.multiSelectedValues`;
});

const valueSelectorFieldName = computed(() => {
  return `${fieldNameBase.value}.valueSelector`;
});

const isOperatorInputDisabled = computed(() => {
  return !statement.value.propertyId || props.disabled;
});

const isValueInputDisabled = computed(() => {
  return !statement.value.operatorId || props.disabled;
});

const inputType = computed(() => {
  return ["decimal_input", "integer_input"].includes(valueUiType.value)
    ? "number"
    : "text";
});

const inputStep = computed(() => {
  return valueUiType.value === "decimal_input" ? "0.01" : "1";
});

const updatedProperty = (newProperty) => {
  statement.value.propertyId = newProperty;
  statement.value.operatorId = "";
  statement.value.valueSelector = "";
  setEmptyValues();
};

const { getValueSelectorFor, getStatementOptions } = useValueSelectorGetter();

const updatedOperator = (selectedOperatorId) => {
  statement.value.operatorId = selectedOperatorId;
  statement.value.valueSelector = getValueSelectorFor(
    setups.value,
    statement.value.propertyId,
    selectedOperatorId,
  );
  setEmptyValues();
};

const statementOptions = computed(() => {
  const selectedPropertyId = statement.value.propertyId;
  return getStatementOptions(setups.value, selectedPropertyId);
});

const valueOptions = computed(() => {
  const selectedOperatorId = statement.value.operatorId;
  if (!selectedOperatorId || !statementOptions.value.length) return [];
  return statementOptions.value.find(
    (option) => option.id === selectedOperatorId,
  )?.valueOptions;
});

const valueUiType = computed(() => {
  const selectedOperatorId = statement.value.operatorId;
  if (!selectedOperatorId || !statementOptions.value.length) return "unknown";
  return statementOptions.value.find(
    (option) => option.id === selectedOperatorId,
  )?.uiType;
});

const emit = defineEmits(["deleteStatement"]);

const handleStatementDelete = () => {
  emit("deleteStatement", props.index);
};

const setEmptyValues = () => {
  statement.value.inputValue = "";
  statement.value.selectedValue = "";
  statement.value.multiSelectedValues = [];
};

const isMultiselectSelector = computed(() => {
  return statement.value.valueSelector === MULTISELECT_SELECTOR_NAME;
});
const isSelectSelector = computed(() => {
  return statement.value.valueSelector === SELECT_SELECTOR_NAME;
});
const isInputSelector = computed(
  () => !isMultiselectSelector.value && !isSelectSelector.value,
);
</script>
<style scoped lang="scss">
.filter-statement {
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
  gap: 16px;

  .property-field,
  .operator-field,
  .value-field {
    width: 176px;
    flex: 1 1 auto;
  }
}
</style>
