<template>
  <div class="filter-tabs row">
    <div
      v-for="(config, index) in tabsConfigs"
      :key="index"
      class="col col-sm-12 col-md-12 col-lg-4 col-xxl-4"
    >
      <ListShipmentsFilterTab
        :label="config.label"
        :filter="config.filter"
        :icon="config.icon"
        :is-active="isFilterActive(config.filter)"
        :total-count="totalCounts[config.totalCountKey]"
        :data-cy="config.dataCy"
        @toggle-status="toggleStatus"
      />
    </div>
  </div>
</template>

<script setup>
import ListShipmentsFilterTab from "./ListShipmentsFilterTab.vue";
import {
  ERROR_STATUS,
  ROUTING_FAILED_STATUS,
  ROUTED_STATUS,
  STATUS_DISPLAY_MAP,
} from "@/constants";
import { getShipmentIconForStatus } from "@/services/utils/iconsMapper";
import { toRefs } from "vue";

const props = defineProps({
  status: {
    default: "",
    type: String,
  },
  totalCounts: {
    default: () => {},
    type: Object,
  },
});

const { status, totalCounts } = toRefs(props);

const tabsConfigs = {
  routeAssigned: {
    label: STATUS_DISPLAY_MAP[ROUTED_STATUS],
    filter: ROUTED_STATUS,
    icon: getShipmentIconForStatus(ROUTED_STATUS),
    totalCountKey: "routeAssigned",
    dataCy: "routed-filter-tab",
  },
  noRouteAssigned: {
    label: STATUS_DISPLAY_MAP[ROUTING_FAILED_STATUS],
    filter: ROUTING_FAILED_STATUS,
    icon: getShipmentIconForStatus(ROUTING_FAILED_STATUS),
    totalCountKey: "noRouteAssigned",
    dataCy: "routing-failed-filter-tab",
  },
  importError: {
    label: STATUS_DISPLAY_MAP[ERROR_STATUS],
    filter: ERROR_STATUS,
    icon: getShipmentIconForStatus(ERROR_STATUS),
    totalCountKey: "error",
    dataCy: "error-filter-tab",
  },
};

const emit = defineEmits(["toggleStatus"]);

const isFilterActive = (statusToCheck) => {
  return status.value.includes(statusToCheck);
};

const toggleStatus = (filter, isActive) => {
  emit("toggleStatus", filter, isActive);
};
</script>
