import {
  INPUT_SELECTOR_NAME,
  MULTISELECT_SELECTOR_NAME,
  SELECT_SELECTOR_NAME,
} from "@/components/filters/helpers/constants";

export const useValueSelectorGetter = () => {
  const getStatementOptions = (filterSetups, propertyId) => {
    if (!propertyId) {
      return [];
    }
    return filterSetups.find((filterSetup) => filterSetup.id === propertyId)
      ?.statementOptions;
  };

  const getValueSelectorFor = (filterSetups, propertyId, operatorId) => {
    const statementOptions =
      getStatementOptions(filterSetups, propertyId) || [];
    const operatorSetup = statementOptions.find((statementOption) => {
      return statementOption.id === operatorId;
    });
    return {
      input: INPUT_SELECTOR_NAME,
      integer_input: INPUT_SELECTOR_NAME,
      decimal_input: INPUT_SELECTOR_NAME,
      datetime: INPUT_SELECTOR_NAME,
      date: INPUT_SELECTOR_NAME,
      multiselect: MULTISELECT_SELECTOR_NAME,
      select: SELECT_SELECTOR_NAME,
    }[operatorSetup.uiType];
  };

  return { getValueSelectorFor, getStatementOptions };
};
