<template>
  <div v-if="errorMessage" class="error-bar" data-cy="error-message">
    <Message severity="error" :closable="closable" @close="wipeError">
      <div v-if="displayErrorsList" class="error-messages">
        <ul>
          <li v-for="(message, key) in errorMessage" :key="key">
            {{ message }}
          </li>
        </ul>
      </div>
      <div v-else class="error-message">{{ errorMessage }}</div>
    </Message>
  </div>
</template>

<script setup>
import { computed, toRefs } from "vue";
import Message from "primevue/message";

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  errorMessage: {
    type: [String, Array],
    default: null,
  },
  closable: {
    type: Boolean,
    default: true,
  },
});

const { errorMessage, closable } = toRefs(props);

const emit = defineEmits(["wipeError"]);

const displayErrorsList = computed(() => {
  return Array.isArray(errorMessage.value);
});

const wipeError = () => {
  emit("wipeError");
};
</script>
