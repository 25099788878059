import { httpRequest } from "@/services/apiRequests";

class BrandApiRequests {
  static async getBrands() {
    const { data } = await httpRequest.get("brands/");

    return data.map((brand) => ({
      id: brand.id,
      name: brand.name,
    }));
  }
}

export default BrandApiRequests;
