import { defineStore } from "pinia";
import { ref } from "vue";
import { useCsvFileReader } from "@/services/utils/filesUtils";
import Papa from "papaparse";
import CsvApiRequests from "@/components/csv-import/helpers/apiRequests";

export const useCsvProfileCreateStore = defineStore("csvProfile/create", () => {
  return useCsvProfileStoreCommon();
});

export const useCsvProfileEditStore = defineStore("csvProfile/edit", () => {
  const commonProfile = useCsvProfileStoreCommon();
  const { profileName, parsedCsvFile, selectedAttributes, resetStore } =
    commonProfile;

  const getCsvProfileDetails = async (id) => {
    const response = await CsvApiRequests.getCsvProfileDetails(id);
    profileName.value = response.name;
    parsedCsvFile.value = Papa.parse(response.sample, {
      skipEmptyLines: true,
      preview: 5,
    }).data;
    selectedAttributes.value = response.shipmentMappings;
  };
  return {
    ...commonProfile,
    resetStore,
    getCsvProfileDetails,
  };
});

const useCsvProfileStoreCommon = () => {
  const profileName = ref("");
  const selectedAttributes = ref([]);
  const isChange = ref(false);
  const isSubmitting = ref(false);

  const { parsedCsvFile, rawCsvFiles, uploadCsvFile, numberOfColumns } =
    useCsvFileReader();

  const doAction = (callback) => {
    callback();
    isChange.value = true;
  };

  const setProfileName = (name) => {
    doAction(() => (profileName.value = name));
  };

  const setSelectedAttributes = (attrs) => {
    doAction(() => (selectedAttributes.value = attrs));
  };

  const setSelectedFiles = (files) => {
    doAction(() => (rawCsvFiles.value = files));
  };

  const resetStore = () => {
    profileName.value = "";
    parsedCsvFile.value = [];
    rawCsvFiles.value = [];
    selectedAttributes.value = [];
    isChange.value = false;
  };

  return {
    profileName,
    parsedCsvFile,
    rawCsvFiles,
    numberOfColumns,
    selectedAttributes,
    isChange,
    isSubmitting,
    setProfileName,
    setSelectedAttributes,
    setSelectedFiles,
    resetStore,
    uploadCsvFile,
  };
};
