import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useCsvFileReader } from "@/services/utils/filesUtils";

export const useCsvImportStore = defineStore("csv/ShipmentImport", () => {
  // State
  const {
    parsedCsvFile,
    rawCsvFiles,
    numberOfColumns,
    filename,
    firstFile,
    uploadCsvFile,
  } = useCsvFileReader();

  const isFirstRowHeader = ref(true);

  // Getters
  const fileContent = computed(() => {
    const data = { headers: [], rows: [] };
    if (isFirstRowHeader.value) {
      data.headers = parsedCsvFile.value[0];
      data.rows = parsedCsvFile.value.slice(1);
    } else {
      data.headers = [];
      data.rows = parsedCsvFile.value;
    }
    return data;
  });

  const resetStore = () => {
    isFirstRowHeader.value = true;
    rawCsvFiles.value = [];
    parsedCsvFile.value = [];
  };

  return {
    filename,
    parsedCsvFile,
    fileContent,
    firstFile,
    rawCsvFiles,
    numberOfColumns,
    isFirstRowHeader,
    uploadCsvFile,
    resetStore,
  };
});
