<template>
  <FormFieldContainer>
    <template #field>
      <div class="column">
        <label v-if="label" for="type" class="label">{{ label }}</label>
        <MultiSelect
          ref="multiselect"
          :model-value="innerValue"
          :name="name"
          :options="options"
          option-label="name"
          option-value="id"
          :option-disabled="optionDisabled"
          :panel-class="`${name}-multi-dropdown-options`"
          :placeholder="placeholder"
          :class="[$attrs.class, { 'p-invalid': isError() }]"
          :data-cy="`multi-dropdown-${name}`"
          :disabled="disabled"
          display="chip"
          @update:model-value="onChange"
        >
          <template #value>
            <StatusBadge
              v-for="statusName in statusNames"
              :key="statusName"
              size="normal"
              :status="statusName"
            >
              {{ statusName }}
            </StatusBadge>
          </template>
          <template #option="slotProps">
            <StatusBadge size="normal" :status="slotProps.option.name">
              {{ slotProps.option.name }}
            </StatusBadge>
          </template>
        </MultiSelect>
        <small
          v-if="isError()"
          id="email-help"
          class="p-error error-label"
          data-cy="form-error-msg"
        >
          {{ errorMessage }}
        </small>
      </div>
    </template>
  </FormFieldContainer>
</template>

<script setup>
import { computed, ref, toRef, watch } from "vue";
import { useField } from "vee-validate";
import MultiSelect from "primevue/multiselect";
import FormFieldContainer from "@/components/FormFieldContainer";
import StatusBadge from "@/components/StatusBadge";

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: "",
  },
  options: {
    type: Array,
    required: true,
  },
  placeholder: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  optionDisabled: {
    type: [String, Function],
    default: "option-disabled",
  },
  hideOnSelect: {
    type: Boolean,
    default: false,
  },
});

const isError = () => !!errorMessage.value;
const emit = defineEmits(["update:modelValue"]);

const fieldValue = toRef(props, "modelValue");
const fieldName = toRef(props, "name");
const hideOnSelect = toRef(props, "hideOnSelect");

const statusNames = computed(() => {
  const mappedValues = [];
  fieldValue.value.forEach((fv) => {
    mappedValues.push(props.options.find((opt) => opt.id === fv).name);
  });
  return mappedValues;
});

const multiselect = ref();
const onChange = (e) => {
  if (hideOnSelect.value) {
    // When selecting an option hide multiselect
    multiselect.value.hide();
  }
  emit("update:modelValue", e);
  setValue(e);
};

watch(
  () => fieldValue.value,
  (newVal) => setValue(newVal),
);

const {
  errorMessage,
  value: innerValue,
  setValue,
} = useField(fieldName, undefined, {
  syncVModel: false,
  initialValue: fieldValue,
});
</script>
<style scoped lang="scss">
:deep(.status-badge) {
  margin-right: 0.5rem;
}
</style>
