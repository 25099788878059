<template>
  <LogoSpinner />
</template>

<script setup>
import LogoSpinner from "@/components/spinners/LogoSpinner.vue";
import { onMounted } from "vue";
import { useAuthStore } from "@/store/authStore";

const authStore = useAuthStore();
onMounted(async () => {
  const { redirectUrl } = await authStore.initSSO();
  if (redirectUrl) {
    window.location.href = redirectUrl;
  } else {
    //   Add sentry error here
  }
});
</script>
