<template>
  <Timeline :value="events" data-cy="timeline-events">
    <template #marker>
      <div
        v-if="isOneElemTimeline"
        class="p-timeline-event-connector connector"
        :class="{
          'connector__one-elem': isOneElemTimeline,
        }"
      ></div>
      <i class="pi pi-circle-fill"></i>
      <div
        class="p-timeline-event-connector connector connector__last-divider"
      ></div>
    </template>
    <template #opposite="slotProps">
      <div :class="{ 'opposite__one-elem': isOneElemTimeline }">
        <h4 class="opposite__date-header">
          {{ slotProps.item.date }}
        </h4>
        <span class="opposite__hours-subheader">{{ slotProps.item.time }}</span>
      </div>
    </template>
    <template #content="slotProps">
      <div class="content">
        <div
          class="event"
          :class="{
            'event__one-elem': isOneElemTimeline,
          }"
        >
          <StatusBadge size="normal" :status="slotProps.item.status">
            {{ slotProps.item.status }}
          </StatusBadge>
          <span class="event__description">
            {{ slotProps.item.description }}
          </span>
          <div v-if="slotProps.item.location" class="event__additional-info">
            <span
              ><i class="pi pi-map-marker" />
              {{ slotProps.item.location }}</span
            >
          </div>
        </div>
        <div
          class="notification"
          :class="{ 'notification__one-elem': isOneElemTimeline }"
        >
          <Divider layout="vertical" />
          <div
            v-if="slotProps.item.actionStatuses.length"
            class="notification__statuses"
          >
            <span class="notification__header">Notifications:</span>
            <span v-for="status in slotProps.item.actionStatuses" :key="status">
              <ActionBadge :icon-for-action="status.status">
                <template #label>
                  <span>{{ status.name }}</span>
                </template>
              </ActionBadge>
            </span>
          </div>
          <div v-else class="notification__no-badge">
            <span>No notifications were triggered</span>
          </div>
        </div>
      </div>
    </template>
  </Timeline>
</template>
<script setup>
import Timeline from "primevue/timeline";
import Divider from "primevue/divider";
import ActionBadge from "@/components/ActionBadge";
import StatusBadge from "@/components/StatusBadge";

const props = defineProps({
  events: {
    type: Array,
    default: () => [],
  },
});

const isOneElemTimeline = props.events.length === 1;
</script>
<style lang="scss" scoped>
$space-one-elem-timeline: 18px;

:deep(.p-timeline-event-opposite) {
  min-width: 121px;
  flex-grow: 0;
}

:deep(.p-timeline-event) {
  gap: 32px;

  &:first-of-type {
    padding-top: 32px;
  }
}

:deep(.pi) {
  font-size: 1.25em;
}
:deep(.pi-circle-fill) {
  color: #1f2a4e;
  font-size: 24px;
}

:deep(.p-divider-vertical.p-divider-center) {
  margin: 0 40px 0 40px;
}

// Overrides status from action-badge component
:deep(.action-badge) {
  padding: 12px;
  margin-bottom: 6px;
  height: unset;
  line-height: unset;
  align-items: flex-start;
}

:deep(.action-badge__icon) {
  height: 21px;
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: row;
  margin-bottom: 88px;
}

:deep(.p-timeline-event:last-of-type .content) {
  margin-bottom: 44px;
}

.connector {
  background-color: #e6e6e6;

  &__one-elem {
    max-height: $space-one-elem-timeline;
  }

  &__last-divider:last-of-type {
    margin-bottom: 18px;
  }
}

.opposite {
  &__one-elem {
    margin-top: $space-one-elem-timeline;
  }
  &__date-header {
    color: #333333;
    font-size: 14px;
    font-weight: 600;
  }
  &__hours-subheader {
    color: #666666;
    font-size: 14px;
    font-weight: 400;
  }
}

.event {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
  width: 232px;

  &__one-elem {
    margin-top: $space-one-elem-timeline;
  }

  &__description {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #595959;
    width: 100%;
  }

  &__additional-info {
    gap: 12px;
    color: #666666;
    font-weight: 400;
    font-size: 14px;
  }
}

.notification {
  display: flex;
  flex-direction: row;
  flex: 1;

  &__one-elem {
    margin-top: $space-one-elem-timeline;
  }

  &__header {
    color: #595959;
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 14px;
  }

  &__no-badge {
    padding: 24px;

    background: #f2f2f2;
    border: 1px solid #e6e6e6;
    border-radius: 8px;

    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
  }

  &__statuses {
    display: flex;
    flex-direction: column;
  }
}
</style>
