<template>
  <div class="empty-widget-page" data-cy="empty-widget-page">
    <div class="empty-widget-container">
      <div class="empty-widget-body">
        <div class="cols">
          <div class="col-1">
            <div class="embed-widget-info">
              <div class="embed-widget-text">
                <ErrorBar
                  v-if="hasError"
                  :error-message="WIDGET_ERROR_MESSAGE"
                  :closable="false"
                />
                <h1>Embedded Tracking Widget</h1>
                <div class="embed-widget-body">
                  <p>
                    Create a seamless post-purchase journey and increase traffic
                    back to your website, with minimal development work.
                  </p>
                  <div class="widget-info-checks">
                    <div
                      v-for="(listItem, index) in listItems"
                      :key="index"
                      class="checkpoint"
                    >
                      <span class="check-circle"
                        ><i class="pi pi-check"></i></span
                      ><span>{{ listItem }}</span>
                    </div>
                  </div>
                  <p>
                    Link the tracking timeline from your post-purchase
                    communications or embed within the customers order details.
                  </p>
                </div>
              </div>

              <div :class="'widget-buttons-section ' + btnsSectionClass">
                <Button
                  v-if="displayGenerateBtn"
                  data-cy="generate-code-btn"
                  @click="generateInitialWidgetSnippet"
                  >Generate Code</Button
                >
                <LearnMoreBtn :learn-more-link="learnMoreLink" />
              </div>
            </div>
          </div>
          <div class="col-2">
            <img src="@/assets/img/widget-preview.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Button from "primevue/button";
import LearnMoreBtn from "@/components/LearnMoreBtn";
import ErrorBar from "@/components/ErrorBar";
import { computed, toRefs } from "vue";
import { WIDGET_ERROR_MESSAGE } from "@/components/widget/helpers/constants";

const listItems = [
  "Host on your domain",
  "Control the page content",
  "Customise messaging",
];

const learnMoreLink =
  "https://support.scurri.co.uk/hc/en-us/articles/18182292112914-Tracking-Timeline-Widget";

const props = defineProps({
  hasError: {
    default: false,
    type: Boolean,
  },
});

const { hasError } = toRefs(props);

const btnsSectionClass = computed(() => {
  return hasError.value ? "has-error" : "initial";
});

const displayGenerateBtn = computed(() => {
  return !hasError.value;
});

const emit = defineEmits(["generateWidgetSnippet"]);

const generateInitialWidgetSnippet = () => {
  const isInitial = true;
  emit("generateWidgetSnippet", isInitial);
};
</script>
<style scoped lang="scss">
@import "@/scss/colors.scss";

.empty-widget-page {
  display: flex;
  padding-left: 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;
  height: 100vh;

  .empty-widget-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;

    .empty-widget-body {
      display: flex;
      align-items: center;
      gap: 72px;
      flex: 1 0 0;
      align-self: stretch;

      .cols {
        display: flex;
        align-items: center;
        gap: 72px;
        flex: 1 0 0;
        align-self: stretch;

        .col-1 {
          display: flex;
          max-width: 33%;
          padding-left: 32px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 20px;
          flex: 1 0 0;
          align-self: stretch;
          z-index: 1;

          .embed-widget-info {
            display: flex;
            max-width: 720px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 32px;
            align-self: stretch;

            .embed-widget-text {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 12px;
              align-self: stretch;
            }

            .embed-widget-body {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 16px;
              align-self: stretch;
            }
          }

          .has-error .learn-more-btn {
            padding-left: 0;
          }
        }

        .col-2 {
          display: flex;
          height: 100vh;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 50%;
          z-index: 0;
          justify-content: flex-end;

          @media only screen and (max-width: 1280px) {
            max-width: 45%;
            justify-content: flex-start;
          }

          img {
            height: 100vh;
          }
        }
      }

      h1 {
        color: $mid-blue;
        font-size: 24.5px;
        font-style: normal;
        font-weight: 600;
        line-height: 29px;
      }

      p,
      span {
        color: #495057;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        margin: 0;
      }

      .widget-info-checks {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;

        .checkpoint {
          display: flex;
          align-items: center;
          gap: 8px;
          align-self: stretch;

          .check-circle {
            display: flex;
            width: 18px;
            height: 18px;
            padding: 4px;
            justify-content: center;
            align-items: center;
            background: $green;
            border-radius: 100px;

            i {
              font-size: 10px;
              flex-shrink: 0;
              color: $white;
            }
          }
        }
      }
    }
  }
}
</style>
