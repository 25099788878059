import { httpRequest } from "@/services/apiRequests";
import { useConfigurationRequests } from "@/services/utils/configurationRequests";

const { makeRequest } = useConfigurationRequests();

class WidgetApiRequests {
  static async getTimelineWidgetCodeSnippet() {
    const { error, response } = await makeRequest({
      endpoint: async () =>
        await httpRequest.get("tracking-page/timeline-widget/script/"),
    });
    if (error.value) {
      return { error: error.value };
    }
    return { data: response.value.data };
  }
}

export default WidgetApiRequests;
