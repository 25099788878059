<template>
  <MainPage>
    <template #body>
      <StepperContainer :steps="steps" />
    </template>
  </MainPage>
</template>

<script setup>
import { ref } from "vue";
import MainPage from "@/components/MainPage.vue";
import StepperContainer from "@/components/stepper/StepperContainer.vue";
import { useCsvImportConfirmDialog } from "@/components/csv-import/helpers/confirmModalConfigs";
import { useCsvNavigationGuards } from "@/components/csv-import/composables/useCsvNavigationGuards.js";
import { useCsvProfileCreateStore } from "@/components/csv-import/stores/csvProfile";
import { storeToRefs } from "pinia";

const steps = ref([
  {
    label: "Profile Name",
    to: "profile-name",
    step: 0,
  },
  {
    label: "CSV Upload",
    to: "csv-file",
    step: 1,
  },
  {
    label: "Field Mapping",
    to: "mapping-field",
    step: 2,
  },
]);

const csvProfileCreateStore = useCsvProfileCreateStore();
const { isChange, isSubmitting } = storeToRefs(csvProfileCreateStore);
const { confirmDiscardCsvProfilesCreate } = useCsvImportConfirmDialog();
const onStateChange = (to, from, next) => {
  confirmDiscardCsvProfilesCreate({
    callback: () => {
      next();
      csvProfileCreateStore.resetStore();
    },
  });
};

const { useOnBeforeRouteLeave } = useCsvNavigationGuards(steps);
useOnBeforeRouteLeave({
  isStateChanged: isChange,
  onConfirmation: onStateChange,
  isSubmitting,
});
</script>
