<template>
  <div class="header-shipment card">
    <i :class="`${icon}`" class="card__icon" />
    <span class="header-shipment__content">
      <span class="header-shipment__title">
        <slot name="title" />
      </span>
      <span
        :id="extraId"
        v-tooltip.top="{
          value: tooltipText,
          disabled: showTooltip,
        }"
        class="header-shipment__value"
      >
        <slot name="value" />
      </span>
    </span>
  </div>
</template>
<script setup>
import { ROUTE_NAME_MAX_WIDTH } from "@/components/shipments/helpers/constants";

defineProps({
  icon: {
    type: String,
    default: "",
  },
  showTooltip: {
    type: Boolean,
    default: false,
  },
  tooltipText: {
    type: String,
    default: "",
  },
  extraId: {
    type: String,
    default: "",
  },
});

const valueMaxWidth = `${ROUTE_NAME_MAX_WIDTH}px`;
</script>
<style scoped lang="scss">
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card__icon {
  width: 64px;
  height: 64px;
  font-size: 28px;

  display: flex;
  background: #ffffff;
  box-shadow: -2px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 6px;

  justify-content: center;
  align-items: center;
}

.header-shipment {
  gap: 16px;
  flex-grow: 1;

  width: 252px;
  height: 183px;
  background: #ffffff;
}

.header-shipment__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.header-shipment__title {
  color: #616161;
  font-weight: 500;
  font-size: 13px;
  height: 13px;
}

.header-shipment__value {
  font-weight: 700;
  font-size: 17.5px;
  color: #1f2a4e !important;

  max-width: v-bind(valueMaxWidth);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#overflow-visible {
  overflow: visible;
}

:deep(.header-shipment__value > a) {
  @extend .header-shipment__value;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
</style>
