import { httpRequest } from "@/services/apiRequests";

class TemplateMappingsApiRequests {
  static async getShipmentAttributes() {
    const { data } = await httpRequest.get("shipment-attributes/");
    return data.map((shipmentAttribute) => ({
      id: shipmentAttribute.id,
      name: shipmentAttribute.name,
    }));
  }

  static async getDataMappingById(id) {
    const { data } = await httpRequest.get(`template-data-mappings/${id}/`);
    return {
      name: data.name,
      handlebarMappings: data.handlebar_mappings.map((handlebarMapping) => ({
        attributeId: handlebarMapping.attribute_id,
        handlebar: handlebarMapping.handlebar_name,
      })),
    };
  }

  static mapTemplateDataMappings(data) {
    return data.map((templateDataMapping) => ({
      id: templateDataMapping.id,
      name: templateDataMapping.name,
    }));
  }

  static async createTemplateDataMapping({ name, handlebarMappings }) {
    const details = {
      name,
      handlebar_mappings: handlebarMappings.map((handlebarMapping) => ({
        attribute_id: handlebarMapping.attributeId,
        handlebar_name: handlebarMapping.handlebar,
      })),
    };
    const { data } = await httpRequest.post("template-data-mappings/", details);
    return this.mapTemplateDataMappings(data);
  }

  static async getDataMappings() {
    const { data } = await httpRequest.get("template-data-mappings/");
    return this.mapTemplateDataMappings(data);
  }

  static async updateTemplateDataMapping({ id, name, handlebarMappings }) {
    const details = {
      name,
      handlebar_mappings: handlebarMappings.map((handlebarMapping) => ({
        attribute_id: handlebarMapping.attributeId,
        handlebar_name: handlebarMapping.handlebar,
      })),
    };
    const { data } = await httpRequest.put(
      `template-data-mappings/${id}/`,
      details,
    );
    return this.mapTemplateDataMappings(data);
  }

  static async deleteTemplateDataMapping(id) {
    return await httpRequest.delete(`template-data-mappings/${id}/`);
  }
  static async duplicateDataMapping(id) {
    const { data } = await httpRequest.post(
      `template-data-mappings/${id}/duplicate/`,
    );
    return this.mapTemplateDataMappings(data);
  }
}

export default TemplateMappingsApiRequests;
