<template>
  <div
    class="import-error-details mb-4"
    data-cy="import-error-shipments-details"
  >
    <ShipmentsActionBar
      message="The following shipments failed to import due to errors."
      severity="danger"
      icon="pi pi-info-circle"
      :btn-config="btnConfig"
      @button-primary-action="confirmBulkDelete"
    />
    <div class="tab-actions-bar d-flex flex-wrap justify-content-end"></div>
  </div>
</template>

<script setup>
import ShipmentsActionBar from "@/components/shipments/ShipmentsActionBar.vue";
import { useShipmentsConfirmDialog } from "@/components/shipments/helpers/confirmModalConfigs";
const emit = defineEmits(["re-evaluate-shipments", "bulk-delete-import-error"]);
const { confirmBulkDeleteImportError } = useShipmentsConfirmDialog();

const btnConfig = {
  btnPrimary: {
    icon: "pi pi-trash",
    label: "Delete Shipments",
  },
};

const confirmBulkDelete = () => {
  confirmBulkDeleteImportError({
    callback: async () => await emit("bulk-delete-import-error"),
  });
};
</script>
