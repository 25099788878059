<template>
  <h5 data-cy="route-overview-name">Campaign Name: {{ routeName }}</h5>
  <div class="p-fieldset">
    <div v-if="routeFilters.length" class="route-filters">
      <p data-cy="filter-heading">{{ filterHeading }}</p>
      <div class="route-filters-row">
        <Chip
          v-for="filter in routeFilters"
          :key="filter"
          class="route-filter-chip"
          >{{ mapFilterToName(filter) }}</Chip
        >
      </div>
    </div>
    <RouteOptionOverview
      v-for="routeOption in routeOptions"
      :key="routeOption.key"
      :statuses="routeOption?.triggeredStatuses"
      :actions="routeOption?.scheduledActions"
    />
  </div>
  <div class="col-12 route-overview-btns">
    <Button
      v-if="displayEdit"
      class="p-button p-button-outlined"
      data-cy="edit-route-btn"
      @click="emit('navigate-to-edit')"
    >
      Edit Campaign
    </Button>
    <Button
      v-if="displayClose"
      class="p-button"
      data-cy="close-route-dialog"
      @click="closeDialog()"
    >
      Close
    </Button>
  </div>
</template>
<script setup>
import { toRefs } from "vue";
import Chip from "primevue/chip";
import Button from "primevue/button";
import RouteOptionOverview from "@/components/route-configurations/RouteOptionOverview";
import { useWorkflowStore } from "@/components/route-configurations/stores/workflow";
import { storeToRefs } from "pinia";

const store = useWorkflowStore();
const { filterOptions } = storeToRefs(store);

const props = defineProps({
  displayEdit: {
    type: Boolean,
    default: false,
  },
  displayClose: {
    type: Boolean,
    default: true,
  },
  routeName: {
    type: String,
    default: "",
  },
  routeOptions: {
    type: Array,
    default: () => [],
  },
  routeFilters: {
    type: Array,
    default: () => [],
  },
});

const { routeName, routeOptions, routeFilters } = toRefs(props);

const filterHeading =
  "The campaign will be sent to customers within the following segment(s):";

const emit = defineEmits(["close-dialog", "navigate-to-edit"]);

const mapFilterToName = (id) => {
  const filterWithName = filterOptions.value.find((filter) => filter.id === id);
  return filterWithName?.name;
};

const closeDialog = () => {
  emit("close-dialog");
};
</script>
<style scoped lang="scss">
:deep(p) {
  font-size: 14px;
}
h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 17.5px;
  line-height: 21px;
  margin-bottom: -8px;
}
.p-fieldset {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 0;
  gap: 40px;
  background: #fafafa;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  width: 100%;
}

.route-filters {
  padding: 0 32px;

  .route-filters-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 8px;
  }

  .route-filter-chip {
    height: 31.5px;
    padding: 5.25px 10.5px;
    color: #495057;
  }
}

.route-overview-btns {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}
</style>
