import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/vue-query";
import SettingsApiRequests from "@/components/settings/helpers/apiRequests";

const QUERY_KEYS = {
  userPrivacy: ["userPrivacySettings"],
};

const getUserAnalyticsAgreementsQueryOptions = (options) => {
  return queryOptions({
    queryKey: QUERY_KEYS.userPrivacy,
    queryFn: () => SettingsApiRequests.getUserAnalyticsAgreement(),
    staleTime: 60000,
    ...options,
  });
};

export const useUserPrivacySettings = () => {
  const queryClient = useQueryClient();

  return {
    prefetchAndVerifyUserDisableTracking: async () => {
      await queryClient.prefetchQuery(
        getUserAnalyticsAgreementsQueryOptions({}),
      );

      const userPrivacySettings = queryClient.getQueryData(
        QUERY_KEYS.userPrivacy,
      );
      if (userPrivacySettings) {
        return userPrivacySettings.acceptAnalyticsAgreement === false;
      }
      return true;
    },
  };
};

export const useQueryUserPrivacySettings = () => {
  return useQuery(
    getUserAnalyticsAgreementsQueryOptions({
      refetchOnMount: true,
      retry: 1,
      select: (data) => data.acceptAnalyticsAgreement,
    }),
  );
};

export const useMutateUserPrivacySettings = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (isAgreementChecked) =>
      SettingsApiRequests.updateUserAnalyticsAgreement(isAgreementChecked),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.userPrivacy });
    },
  });
};
