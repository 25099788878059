<template>
  <MainPage
    data-cy="csv-profile-details-page"
    :show-footer="false"
    :heading="`Profile: ${profileName}`"
    tagline="Below are a list of all recent CSV imports associated with this import profile."
  >
    <template #actionsBar>
      <Button
        class="p-button p-button-outlined"
        data-cy="refresh-page"
        @click="onEdit()"
      >
        <i class="pi pi-pencil" />
        Edit Profile
      </Button>
      <Button
        label="Import Shipments"
        class="p-button"
        icon="pi pi-external-link"
        data-cy="csv-import-shipments"
        @click="onImportCsv()"
      >
        <i class="pi pi-plus" />
        Import Shipments
      </Button>
    </template>
    <template #body>
      <ImportProfileDetailsTable :profile-id="profileId" />
    </template>
  </MainPage>
</template>

<script setup>
import Button from "primevue/button";
import MainPage from "@/components/MainPage.vue";
import ImportProfileDetailsTable from "@/components/csv-import/ImportProfileDetailsTable.vue";
import { useCsvImportDialog } from "@/components/csv-import/helpers/modalConfigs";
import { toRefs } from "vue";
import { useRouter } from "vue-router";

const props = defineProps({
  profileName: {
    type: String,
    required: true,
  },
  profileId: {
    type: String,
    required: true,
  },
});

const { profileId } = toRefs(props);

const router = useRouter();
const onEdit = () => {
  router.push({
    name: "EditCsvProfileStepName",
    params: { id: profileId.value },
  });
};

const { openDialogImportShipmentDialog } = useCsvImportDialog();
const onImportCsv = () => {
  openDialogImportShipmentDialog({ profileId: profileId.value });
};
</script>

<style scoped></style>
