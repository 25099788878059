<template>
  <!-- Input is hidden and we just refer to that triggering actions -->
  <input
    id="fileInput"
    ref="fileInputRef"
    :accept="accept"
    :disabled="disabled"
    :multiple="multiple"
    type="file"
    data-cy="file-input"
    @change="onFilesSelect"
  />
  <div
    v-if="!localFiles.length && !isLoading"
    id="dragArea"
    ref="fileUploaderContentRef"
    class="file-upload"
    data-cy="empty-content"
    @dragenter="onDragEnter"
    @dragover="onDragOver"
    @drop="onDrop"
    @dragleave.self="onDragLeave"
  >
    <slot
      name="empty"
      :files="localFiles"
      :error-messages="errorMessages"
      :on-choose="onFileInputClick"
      :upload-files="uploadFiles"
      :on-drop="onDrop"
    >
      <div class="file-upload__content-container" :class="emptyClass">
        <div class="icon-container">
          <i :class="emptyIcon" class="empty-icon" />
        </div>
        <Button
          class="p-button p-button-tertiary-outlined"
          data-cy="file-input-button"
          @mouseup="onFileInputClick"
          @keydown.enter="onFileInputClick"
        >
          <i :class="selectButtonIcon" />
          <span class="p-button-label">{{ selectButtonLabel }}</span>
        </Button>
      </div>
      <div class="file-upload__description-container">
        <span class="file-upload--instruction">
          or drag and drop a {{ accept }} file here to upload
        </span>
        <span>(max. file size {{ formatSize(sizeLimit) }})</span>
      </div>
      <div v-if="areErrors">
        <Message
          v-for="(error, i) in errorMessages"
          :key="i"
          severity="error"
          data-cy="error-messages"
        >
          {{ error }}
        </Message>
      </div>
    </slot>
  </div>
  <div
    v-if="isContent && showContentSlot"
    class="file-upload space"
    :class="contentClass"
    data-cy="content"
  >
    <slot name="content" :files="localFiles" :remove-file="onRemove">
      <div v-if="multiple">
        <!-- At the moment - we do not have default design for multiple files -->
      </div>
      <div class="card card-body file-details">
        <div class="icon-container">
          <i :class="contentIcon" class="empty-icon" />
        </div>
        <span class="file-details__filename">
          {{ files[0].name }}
        </span>
        <span> {{ formatSize(files[0].size) }}</span>
        <Button
          class="p-button p-button-tertiary-outlined"
          data-cy="clear-button"
          @click="onRemove()"
        >
          <i class="pi pi-times" /> Remove
        </Button>
      </div>
    </slot>
  </div>
  <div
    v-if="isLoading && !areErrors"
    class="file-upload space"
    :class="loadingClass"
    data-cy="loading-content"
  >
    <slot name="loading"> <ProgressSpinner class="centered-spinner" /></slot>
  </div>
</template>

<script setup>
import Button from "primevue/button";
import Message from "primevue/message";
import ProgressSpinner from "primevue/progressspinner";
import { computed, ref, toRefs, watch } from "vue";
import { DomHandler } from "primevue/utils";
import {
  formatSize,
  getFileExtension,
  getTypeClass,
  isWildcard,
  readFiles,
} from "@/services/utils/filesUtils";

const props = defineProps({
  // Optional v-model
  files: {
    type: Array,
    default: () => [],
  },
  accept: {
    type: String,
    required: true,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  selectButtonLabel: {
    type: String,
    default: "Select File",
  },
  selectButtonIcon: {
    type: String,
    default: "pi pi-plus",
  },
  emptyIcon: {
    type: String,
    default: "pi pi-cloud-upload",
  },
  contentIcon: {
    type: String,
    default: "pi pi-file",
  },
  sizeLimit: {
    type: String,
    default: "3000000",
  },
  autoUpload: {
    type: Boolean,
    default: false,
  },
  clearOnUpload: {
    type: Boolean,
    default: false,
  },
  showContentSlot: {
    type: Boolean,
    default: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  emptyClass: {
    type: String,
    default: "",
  },
  contentClass: {
    type: String,
    default: "",
  },
  loadingClass: {
    type: String,
    default: "",
  },
});

const {
  disabled,
  multiple,
  autoUpload,
  accept,
  sizeLimit,
  clearOnUpload,
  isLoading,
  files,
} = toRefs(props);

const emit = defineEmits([
  "onSelect",
  "onRemove",
  "onUpload",
  "update:files",
  "update:isLoading",
  "onError",
]);

// Reference to input with file type
const fileInputRef = ref(null);
const fileUploaderContentRef = ref(null);

const localFiles = ref(files.value);
watch(
  () => localFiles.value,
  () => {
    if (localFiles.value !== files.value)
      emit("update:files", localFiles.value);
  },
);

const errorMessages = ref([]);
const sizeToUse = ref(Number(sizeLimit.value));
const maxFileNameLength = 50;

const areErrors = computed(() => {
  return !!errorMessages.value.length;
});
// When error is raised while reading a file - reset a local state so error can be displayed
watch(
  () => areErrors.value,
  () => {
    if (areErrors.value) onError();
  },
);

const onError = () => {
  emit("update:isLoading", false);
  emit("onError", errorMessages.value);
  localFiles.value = [];
};

const onFileInputClick = () => {
  clearFileInput();
  fileInputRef.value.click();
};

const isContent = computed(() => {
  return localFiles.value.length && !isLoading.value;
});

const onFilesSelect = async (event) => {
  errorMessages.value = [];
  const filesToAdd = getFilesFromEvent(event);
  for (let file of filesToAdd) {
    const errors = validateFile(file);
    if (!errors.length) {
      // Make a copy of file - so whenever file will change in background, we still use old version
      // and browser do not break
      localFiles.value.push(
        new File([await file.arrayBuffer()], file.name, { type: file.type }),
      );
      sizeToUse.value -= file.size;
      emit("onSelect", localFiles.value);
    } else {
      setErrors(errors);
      clearFileInput();
    }
  }
  if (autoUpload.value && localFiles.value.length) {
    await uploadFiles();
  }
};

const clearAndSetErrors = (errorMessage) => {
  clear();
  setErrors([errorMessage]);
};

const uploadFiles = async () => {
  const { errorMessage, readFilesContent } = await readFiles(localFiles.value);
  if (errorMessage.value) {
    clearAndSetErrors(errorMessage);
  } else {
    await emit("onUpload", { uploadedFilesContent: readFilesContent.value });
  }
  if (clearOnUpload.value) {
    clear();
  }
};

defineExpose({ uploadFiles, clearAndSetErrors });

const getFilesFromEvent = (event) => {
  return event.dataTransfer ? event.dataTransfer.files : event.target.files;
};

const isFileTypeValid = (file) => {
  const acceptableTypes = accept.value.split(",").map((type) => type.trim());

  for (let type of acceptableTypes) {
    let acceptable = isWildcard(type)
      ? getTypeClass(file.type) === getTypeClass(type)
      : file.type === type ||
        getFileExtension(file).toLowerCase() === type.toLowerCase();
    if (acceptable) {
      return true;
    }
  }
  return false;
};

const invalidTypeError = () => {
  return `Invalid file type, allowed file types: ${accept.value}.`;
};
const invalidSizeError = () => {
  return `Invalid file size, file size should be smaller than ${formatSize(
    sizeToUse.value,
  )}.`;
};

const fileNameTooLongError = () => {
  return `Filename is too long. Filename must be ${maxFileNameLength} characters or less.`;
};

const fileNameInvalidError = () => {
  return `Filename contains invalid characters. Avoid using the following: /\\?%*:;,|"<>`;
};

const validateFile = (file) => {
  const errors = [];
  if (!isFileTypeValid(file)) {
    errors.push(invalidTypeError());
  }

  if (sizeLimit.value && file.size > sizeToUse.value) {
    errors.push(invalidSizeError());
  }

  if (file.name.length > maxFileNameLength) {
    errors.push(fileNameTooLongError());
  }

  const invalidFileChars = /[/\\?%*:;,|"<>]/;
  if (invalidFileChars.test(file.name)) {
    errors.push(fileNameInvalidError());
  }
  return errors;
};
const setErrors = (errors) => {
  errors.forEach((e) => errorMessages.value.push(e));
  emit("onError", errorMessages.value);
};

/*
Drag enter and drag leave events are triggered also on child divs.
It means - whenever we drag over icon or Select button - we quickly leave drag area and back.
That results in changing background ("file-upload-highlight" or not).

To avoid that situation - we check parent class which access drag area.
On first enter we assign that to list.
When leaving drag - we remove highlight only on drop or when fromElement is the same as when
we entered for the first time.
*/
const isChild = (event) => {
  const parent = document.getElementById("dragArea").children;
  const parentsChildClassNames = Array.from(parent).map((c) => c.className);

  if (parentsChildClassNames.includes(event.fromElement.className)) {
    return true;
  }

  const parentEvent = event.fromElement.parentElement.className;
  return parentsChildClassNames.includes(parentEvent);
};

const onDragEnter = (event) => {
  if (!disabled.value) {
    event.preventDefault();
    DomHandler.addClass(fileUploaderContentRef.value, "file-upload-highlight");
  }
};
const onDragLeave = (event) => {
  if (!disabled.value) {
    event.preventDefault();
    event.stopPropagation();
    if (!isChild(event)) {
      DomHandler.removeClass(
        fileUploaderContentRef.value,
        "file-upload-highlight",
      );
    }
  }
};
const onDragOver = (event) => {
  if (!disabled.value) {
    event.stopPropagation();
    event.preventDefault();
  }
};
const onDrop = (event) => {
  if (!disabled.value) {
    DomHandler.removeClass(
      fileUploaderContentRef.value,
      "file-upload-highlight",
    );
    event.stopPropagation();
    event.preventDefault();
    const filesToAdd = getFilesFromEvent(event);
    const allowDrop = multiple.value || (filesToAdd && filesToAdd.length === 1);

    if (allowDrop) {
      onFilesSelect(event);
    }
  }
};

const onRemove = () => {
  // ensure size available is no more than max size limit
  localFiles.value.map((localFile) => {
    sizeToUse.value += Number(localFile.size);
  });
  const maxSize = Number(sizeLimit.value);
  if (sizeToUse.value > maxSize) {
    sizeToUse.value = maxSize;
  }
  emit("onRemove", localFiles.value);
  clear();
};

const clearFileInput = () => {
  const fileInputId = document.getElementById("fileInput");
  if (fileInputId) fileInputId.value = null;
};

const clear = () => {
  localFiles.value = [];
  errorMessages.value = [];
  sizeToUse.value = sizeLimit.value;
  clearFileInput();
};
</script>

<style scoped lang="scss">
.file-upload {
  min-height: 306px;
  width: 100%;
  background: #f7f8f9;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;

  border: 2px dashed #ccc;
  border-radius: 6px;
}

.file-upload__content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.file-upload__description-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

input[type="file"] {
  display: none;
}

.icon-container {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-icon {
  font-size: 60px;
  color: #495057;
}

.space {
  padding: 32px;
}

.file-details {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  margin-bottom: 0;

  gap: 4px;
}

.file-details__filename {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #495057;
}

.file-upload--instruction {
  font-weight: 600;
  font-size: 14px;
  color: #495057;
}

.file-upload-highlight {
  border: 2px dashed #85b2f9;
  background: #ffffff;
}
</style>
