import { CREATE } from "@/constants";
import { computed, ref } from "vue";

export const getTextWidth = (text) => {
  // Return width of text
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  context.font = getComputedStyle(document.body).font;

  return context.measureText(text).width;
};

export const mapToSentenceCase = (sentences) => {
  // If there are no sentences - there are null, empty strings - return value
  // Otherwise, make all sentence as lowercase, find first character and all
  // first characters next to .!? and make then uppercase.
  if (!sentences) return sentences;
  return sentences.toLowerCase().replace(/(^\s*\w|[.!?]\s*\w)/g, function (c) {
    return c.toUpperCase();
  });
};

export const getDiscardChangesConfig = (item, action) => {
  const itemLowerCase = item.toLowerCase();
  const itemTitleCase = itemLowerCase.replace(
    /(^\w{1})|(\s+\w{1})/g,
    (letter) => letter.toUpperCase(),
  );
  const header =
    action === CREATE ? `Discard ${itemTitleCase}?` : "Discard Changes?";
  const messageBase =
    action === CREATE
      ? "Are you sure you want to discard"
      : "Are you sure you want to discard changes to";
  const message = `${messageBase} this ${itemLowerCase}? Note that any unsaved changes will be lost.`;
  return {
    header,
    message,
    acceptLabel: "Discard",
    rejectLabel: "Cancel",
    rejectClass: "p button p-button-text",
    acceptClass: "p-button p-button-remove",
  };
};

export const useCopiedState = (copyText, copiedText) => {
  const isCopied = ref(false);
  const copyButtonText = computed(() =>
    isCopied.value ? copiedText : copyText,
  );
  const makeCopyDisabled = () => (isCopied.value = true);
  const makeCopyEnabled = () => (isCopied.value = false);

  const changeStateOnClick = () => {
    makeCopyDisabled();
    setTimeout(() => {
      makeCopyEnabled();
    }, 1000);
  };

  return { copyButtonText, changeStateOnClick, isCopied };
};

export const convertToClass = (val) => {
  return val.toLowerCase().replaceAll(" ", "-").replaceAll("_", "-");
};

export const formatNumberWithComma = (val) => {
  return Intl.NumberFormat().format(val);
};

export const isEnvVariableTrue = (envVar) => {
  const _envVar = String(envVar).toLowerCase();
  return _envVar === "true" || _envVar === "1";
};

export const useDomainMapping = () => {
  const domainMap = {
    "scurritrackplus.com": "prod",
    "sandbox.scurritrackplus.com": "sandbox",
    "demo.scurritrackplus.com": "sales",
    "dev.scurritrackplus.com": "dev",
  };

  const currentDomain = window.location.hostname;
  const mappedEnvToDomain = domainMap[currentDomain] || "unknown";
  return { mappedEnvToDomain };
};
