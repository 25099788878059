<template>
  <LogoSpinner />
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router/dist/vue-router";
import LogoSpinner from "@/components/spinners/LogoSpinner.vue";
import { useAuthStore } from "@/store/authStore";

const authToken = ref(null);
const route = useRoute();
const router = useRouter();
const getAuthToken = async () => {
  await router.isReady();
  authToken.value = route.query.auth_token;
};

const redirectOnSuccessfulLogin = (redirectUrl) => {
  router.push({ path: redirectUrl });
};

const authStore = useAuthStore();
onMounted(async () => {
  await getAuthToken();
  const { redirectUrl, error } = await authStore.completeLogin(authToken.value);
  if (!error) {
    redirectOnSuccessfulLogin(redirectUrl);
  }
  if (error) {
    await router.push({ name: "PreLoginPage" });
  }
});
</script>
