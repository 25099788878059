import { useToastService } from "@/services/toast/utils";
import {
  CREATE_ACTION,
  EDIT_ACTION,
} from "@/components/template-data-mapping/helpers/constants";

export const useTemplateMappingsToastService = () => {
  const { displaySuccessToast, displayWarnToast, displayErrorToast } =
    useToastService();

  const displaySelectAllDeleteSuccessToast = () => {
    return displaySuccessToast({
      message: "Multiple dynamic data mapping have been successfully deleted.",
      summary: "Dynamic Data Mappings Deleted",
    });
  };

  const displaySelectAllDeletePartialToast = () => {
    return displayWarnToast({
      message:
        "Some dynamic data mapping have been successfully deleted, while others could not be deleted.",
      summary: "Dynamic Data Mappings Partial Deletion",
    });
  };

  const displaySelectAllDeleteFailToast = () => {
    return displayErrorToast({
      message: "Multiple dynamic data mapping have failed to be deleted.",
      summary: "Dynamic Data Mappings Deletion Failed",
    });
  };

  const displayDeleteSuccessToast = (name) => {
    return displaySuccessToast({
      message: `The dynamic data mapping ${name} has been successfully deleted.`,
      summary: "Dynamic Data Mapping Deleted",
    });
  };

  const displayTemplateMappingAlreadyInUseToast = (name) => {
    return displayErrorToast({
      message: `Deletion was unsuccessful as the dynamic data mapping ${name} is currently in use.`,
      summary: "Dynamic Data Mapping Deletion Failed",
    });
  };

  const displayDeleteFailToast = (name) => {
    return displayErrorToast({
      message: `The dynamic data mapping ${name} has failed to delete.`,
      summary: "Dynamic Data Mapping Deletion Failed",
    });
  };

  const displayDuplicateSuccessToast = (name) => {
    return displaySuccessToast({
      message: `The dynamic data mapping ${name} has been successfully duplicated.`,
      summary: "Dynamic Data Mapping Duplicated",
    });
  };

  const displayTemplateMappingCreated = (name) => {
    return displaySuccessToast({
      message: `The dynamic data mapping ${name} has been successfully created.`,
      summary: "Dynamic Data Mapping Created",
    });
  };

  const displayTemplateMappingNameAlreadyExists = (name, action) => {
    if (action === EDIT_ACTION)
      return displayTemplateMappingEditNameAlreadyExists(name);
    if (action === CREATE_ACTION)
      return displayTemplateMappingCreatedNameAlreadyExists(name);
  };

  const displayTemplateMappingCreatedNameAlreadyExists = (name) => {
    return displayErrorToast({
      message: `Creation was unsuccessful as the dynamic data mapping with ${name} already exists.`,
      summary: "Dynamic Data Mapping Creation Failed",
    });
  };

  const displayTemplateMappingEditNameAlreadyExists = (name) => {
    return displayErrorToast({
      message: `Editing was unsuccessful as the dynamic data mapping with ${name} already exists.`,
      summary: "Dynamic Data Mapping Edit Failed",
    });
  };

  const displayTemplateMappingUpdated = (name) => {
    return displaySuccessToast({
      message: `The dynamic data mapping ${name} has been successfully updated.`,
      summary: "Dynamic Data Mapping Updated",
    });
  };

  const displayTemplateMappingInvalidHandlebar = (action) => {
    if (action === EDIT_ACTION)
      return displayTemplateMappingEditInvalidHandlebar();
    if (action === CREATE_ACTION)
      return displayTemplateMappingCreateInvalidHandlebar();
  };

  const displayTemplateMappingEditInvalidHandlebar = () => {
    return displayErrorToast({
      message: `Editing was unsuccessful as the dynamic data attribute have incorrect value.`,
      summary: "Dynamic Data Mapping Edit Failed",
    });
  };

  const displayTemplateMappingCreateInvalidHandlebar = () => {
    return displayErrorToast({
      message: `Creation was unsuccessful as the dynamic data attribute have incorrect value.`,
      summary: "Dynamic Data Mapping Creation Failed",
    });
  };

  const displayTemplateMappingNotUniqueHandlebar = (action) => {
    if (action === EDIT_ACTION)
      return displayTemplateMappingCreateNotUniqueHandlebar();
    if (action === CREATE_ACTION)
      return displayTemplateMappingEditNotUniqueHandlebar();
  };

  const displayTemplateMappingCreateNotUniqueHandlebar = () => {
    return displayErrorToast({
      message: `Creation was unsuccessful as the dynamic data attribute's values are not unique.`,
      summary: "Dynamic Data Mapping Creation Failed",
    });
  };

  const displayTemplateMappingEditNotUniqueHandlebar = () => {
    return displayErrorToast({
      message: `Editing was unsuccessful as the dynamic data attribute's values are not unique.`,
      summary: "Dynamic Data Mapping Creation Failed",
    });
  };

  return {
    displaySelectAllDeleteSuccessToast,
    displaySelectAllDeletePartialToast,
    displaySelectAllDeleteFailToast,
    displayDeleteSuccessToast,
    displayTemplateMappingAlreadyInUseToast,
    displayDeleteFailToast,
    displayDuplicateSuccessToast,
    displayTemplateMappingCreated,
    displayTemplateMappingUpdated,
    displayTemplateMappingInvalidHandlebar,
    displayTemplateMappingNameAlreadyExists,
    displayTemplateMappingNotUniqueHandlebar,
  };
};
