import DataMappingForm from "@/components/template-data-mapping/DataMappingForm";
import { useGlobalDialogService } from "@/services/modals/base";

export const useTemplateMappingDialog = () => {
  const { openDialog } = useGlobalDialogService();

  const openTemplateMappingDialog = ({ templateId, onClose }) => {
    return openDialog({
      component: DataMappingForm,
      dialogProps: {
        modal: true,
        dismissableMask: false,
        showHeader: false,
        style: {
          width: "720px",
          // That's workaround to have 32px padding at end.
          // p-dialog-content, which cannot be override is using 1 rem padding
          padding: "calc(32px - 1rem)",
        },
      },
      componentData: { dataMappingId: templateId },
      onClose: (options) => {
        onClose?.(options.data);
      },
    });
  };
  return { openTemplateMappingDialog };
};
