<template>
  <div v-if="areSomeRowsSelected()" class="select-all">
    <div class="select-all__checkbox">
      <Checkbox :model-value="isChecked" :binary="true" @input="onSelectAll" />
      <span>Select All</span>
    </div>
    <Button
      label="Delete"
      class="p-button p-button-remove"
      icon="pi pi-trash"
      data-cy="delete-row"
      @click="deleteRows"
    />
  </div>
  <div>
    <ConfigurationTableRow
      v-for="row in localDataRow"
      :key="row.id"
      v-model:isSelected="row.isSelected"
      :data-row="row"
      data-cy="configuration-table-row"
      @delete-row="deleteRow"
      @duplicate-row="duplicateRow"
      @edit-row="editRow"
    />
  </div>
</template>

<script setup>
import { ref, toRefs, watch } from "vue";
import Checkbox from "primevue/checkbox";
import Button from "primevue/button";
import ConfigurationTableRow from "@/components/ConfigurationTableRow.vue";

const isChecked = ref(false);

const props = defineProps({
  dataRows: {
    type: Array,
    default: () => [],
  },
});

const { dataRows } = toRefs(props);
const localDataRow = ref(dataRows.value);
watch(
  () => dataRows.value,
  () => {
    localDataRow.value = dataRows.value.map((row) => {
      return {
        id: row.id,
        name: row.name,
        isSelected: false,
      };
    });
  },
);

const selectAllCheckboxes = (isSelected) => {
  localDataRow.value.forEach((row) => (row.isSelected = isSelected));
};
const onSelectAll = (isSelected) => {
  isChecked.value = !isChecked.value;
  selectAllCheckboxes(isSelected);
};
const areAllRowsSelected = () => {
  return localDataRow.value.every((a) => a.isSelected);
};
const areSomeRowsSelected = () => {
  return localDataRow.value.some((e) => e.isSelected === true);
};

watch(
  () => areAllRowsSelected(),
  () => (isChecked.value = !!areAllRowsSelected()),
);

const emit = defineEmits(["duplicateRow", "deleteRows", "editRow"]);
const editRow = (filterId) => {
  emit("editRow", filterId);
};

const onRowsDelete = (rows) => {
  emit("deleteRows", rows);
};
const deleteRows = async () => {
  const selectedRows = localDataRow.value.filter((row) => {
    return row.isSelected;
  });
  onRowsDelete(selectedRows);
};
const deleteRow = async (row) => {
  onRowsDelete([row]);
};

const duplicateRow = async (row) => {
  emit("duplicateRow", row);
};
</script>

<style scoped lang="scss">
.select-all {
  display: flex;
  gap: 24px;
  align-items: center;
}

.select-all__checkbox {
  display: flex;
  gap: 9px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
}

:deep(.p-checkbox) {
  align-items: center;
  width: 22px;
  height: 22px;

  & .p-checkbox-box {
    border-radius: 6px;
    padding: 0;
    width: 22px;
    height: 22px;
  }

  & .p-checkbox-box:hover,
  & .p-checkbox-box.p-focus:hover {
    border: 2px solid #1f2a4e;
  }

  & .p-checkbox-box.p-highlight {
    background: #26d466;
    border: 2px solid #26d466;
  }

  & .p-checkbox-box.p-focus {
    box-shadow: none;
    border: 2px solid #ced4da;
  }

  & .p-checkbox-box.p-highlight.p-focus {
    box-shadow: none;
    border: 2px solid #26d466;
  }

  & .p-checkbox-box.p-highlight:hover {
    background: #199146;
    border: 2px solid #199146;
    box-shadow: none;
  }
}
</style>
