import { useRouter, useRoute } from "vue-router/dist/vue-router";

export const UrlQuery = () => {
  const router = useRouter();
  const route = useRoute();

  const setUrlQuery = (query) => {
    router.push({
      path: route.path,
      query,
    });
  };

  const updateUrlQuery = (currentQuery, newQuery) => {
    const updatedQuery = { ...currentQuery, ...newQuery };
    setUrlQuery(updatedQuery);
  };

  const addQueryParam = (currentQuery, key, value) => {
    updateUrlQuery(currentQuery, { [key]: value });
  };

  const updateQueryParam = (currentQuery, key, value) => {
    updateUrlQuery(currentQuery, { [key]: value });
  };

  const reduceQueryParam = (currentQuery, key) => {
    removeQueryParam(currentQuery, key);
  };

  const removeQueryParam = (currentQuery, key) => {
    const query = { ...currentQuery };
    delete query[key];
    setUrlQuery(query);
  };

  return {
    setUrlQuery,
    addQueryParam,
    updateQueryParam,
    removeQueryParam,
    reduceQueryParam,
    updateUrlQuery,
  };
};
