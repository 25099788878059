<template>
  <ImportProfileStepMapping
    :selected-attributes="selectedAttributes"
    :parsed-csv-file="parsedCsvFile"
    :profile-name="profileName"
    :number-of-columns="numberOfColumns"
    page-title="Field Mapping"
    button-submit-label="Update Profile"
    @on-submit-csv-profile="editCsvProfile"
    @update:selected-attributes="setSelectedAttributes"
  />
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useCsvProfileEditStore } from "@/components/csv-import/stores/csvProfile";
import ImportProfileStepMapping from "@/components/csv-import/ImportProfileStepMapping.vue";
import { useRoute } from "vue-router";
import { useCsvProfileToastService } from "@/components/csv-import/helpers/toastConfigs.js";
import { computed } from "vue";
import { useCsvProfileCreationRequest } from "@/components/csv-import/helpers/csvProfileCreateEditRequests";
import CsvApiRequests from "@/components/csv-import/helpers/apiRequests";

const csvImportProfileStore = useCsvProfileEditStore();
const {
  parsedCsvFile,
  profileName,
  numberOfColumns,
  selectedAttributes,
  isSubmitting,
} = storeToRefs(csvImportProfileStore);
const { setSelectedAttributes } = csvImportProfileStore;

const route = useRoute();
const profileIdRouteId = computed(() => {
  return route.params.id.toString();
});

const { displayCsvProfileEditSuccessToast } = useCsvProfileToastService();
const { makeCsvProfileRequest } = useCsvProfileCreationRequest();
const editCsvProfile = async (profileDetails) => {
  isSubmitting.value = true;
  await makeCsvProfileRequest({
    endpoint: CsvApiRequests.editCsvImportProfile({
      profileId: profileIdRouteId.value,
      profileName: profileDetails.profileName,
      mappings: profileDetails.selectedAttributes,
      sample: profileDetails.sample,
    }),
    onSuccess: () => displayCsvProfileEditSuccessToast(profileName.value),
  });
};
</script>
