<template>
  <ImportProfileStepName
    :profile-name="profileName"
    page-title="Profile Name"
    page-subtitle="Edit the profile name below:"
    @next-page="emitNextPage"
    @update:profile-name="setProfileName"
  />
</template>

<script setup>
import { useCsvProfileEditStore } from "@/components/csv-import/stores/csvProfile";
import { storeToRefs } from "pinia";
import ImportProfileStepName from "@/components/csv-import/ImportProfileStepName.vue";

const emit = defineEmits(["next-page", "prev-page"]);

const csvEditProfileStore = useCsvProfileEditStore();
const { profileName } = storeToRefs(csvEditProfileStore);
const { setProfileName } = csvEditProfileStore;

const emitNextPage = () => {
  emit("next-page");
};
</script>
