import { useConfirm } from "primevue/useconfirm";

export const useConfirmActionMethod = () => {
  const confirm = useConfirm();

  // TODO: Remove overlapping mask
  const confirmAction = async ({
    message,
    acceptLabel,
    callback,
    header,
    reject,
    rejectClass = "",
    rejectLabel = "Cancel",
    acceptIcon = "",
    rejectIcon = "",
    acceptClass = "p-button",
  }) => {
    await confirm.require({
      message,
      acceptLabel,
      header,
      acceptIcon,
      rejectIcon,
      acceptClass,
      rejectLabel,
      rejectClass,
      accept: callback,
      reject: reject,
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "reject",
    });
  };

  return { confirmAction };
};
