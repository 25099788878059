export const useNotificationEmptyState = () => {
  const buttons = {
    buttonPrimary: {
      iconClass: "pi pi-plus",
      label: "New Import Profile",
    },
    buttonSecondary: {
      label: "Back to Shipments",
    },
  };

  const emptyConfigTagline =
    "Map your CSV rows to required fields by creating a CSV import profile.";
  const initialConfig = {
    heading: "Create your first CSV import profile",
    tagline: emptyConfigTagline,
    iconClass: "icon-csv-profile",
    ...buttons,
  };
  const emptyConfig = {
    heading: "Oops! You have no import profiles",
    tagline: emptyConfigTagline,
    iconClass: "icon-csv-profile-empty",
    ...buttons,
  };

  return { initialConfig, emptyConfig };
};

export const useCsvProfileDetailsEmptyState = () => {
  const emptyConfigTagline =
    "You have not imported any CSV files to this profile yet. " +
    "Import a CSV file to get started.";
  const emptyConfig = {
    heading: "No CSV imports!",
    tagline: emptyConfigTagline,
    iconClass: "icon-csv-profiles-details-empty-state",
  };
  return { emptyConfig };
};
