<template>
  <div>
    <ConfigurationTable
      :data-rows="templateMappings"
      @duplicate-row="onDuplicateRow"
      @delete-rows="onDeleteRows"
      @edit-row="onEditRow"
    />
  </div>
</template>

<script setup>
import ConfigurationTable from "@/components/ConfigurationTable.vue";
import { useTemplateMappingsStore } from "@/components//template-data-mapping/stores/templateMappings.js";
import { storeToRefs } from "pinia";
import { useTemplateMappingsConfirmDialog } from "@/components/template-data-mapping/helpers/confirmModalConfigs";
import { useTemplateMappingDialog } from "@/components/template-data-mapping/helpers/modalConfigs";

const store = useTemplateMappingsStore();
const { templateMappings } = storeToRefs(store);
const {
  deleteTemplateMapping,
  bulkDeleteTemplateMappings,
  duplicateTemplateMapping,
} = store;

const onDeleteRows = (templates) => {
  if (templates.length === 1)
    return confirmTemplateDataMappingDelete({
      callback: async () => await deleteTemplate(templates[0]),
    });
  confirmSelectedAllTemplateDataMappingDelete({
    callback: async () => await bulkDeleteTemplates(templates),
  });
};

const bulkDeleteTemplates = async (templates) => {
  await bulkDeleteTemplateMappings(templates);
};

const deleteTemplate = async (template) => {
  await deleteTemplateMapping(template);
};

const onDuplicateRow = (row) => {
  return confirmTemplateDataMappingDuplicate({
    callback: () => duplicateTemplate(row),
  });
};
const duplicateTemplate = async (template) => {
  await duplicateTemplateMapping(template);
};

const { openTemplateMappingDialog } = useTemplateMappingDialog();

const onEditRow = (templateMapping) => {
  openTemplateMappingDialog({
    templateId: templateMapping.id,
  });
};

const {
  confirmTemplateDataMappingDelete,
  confirmTemplateDataMappingDuplicate,
  confirmSelectedAllTemplateDataMappingDelete,
} = useTemplateMappingsConfirmDialog();
</script>
