import { useToastService } from "@/services/toast/utils";

export const useCsvProfileToastService = () => {
  const { displayErrorToast, displaySuccessToast } = useToastService();

  const displayCsvProfileCreationSuccessToast = (profileName) => {
    return displaySuccessToast({
      message: `The CSV import profile ${profileName} has been successfully created.`,
      summary: "CSV Import Profile Created",
    });
  };

  const displayCsvProfileEditSuccessToast = (profileName) => {
    return displaySuccessToast({
      message: `The CSV import profile ${profileName} has been successfully updated.`,
      summary: "CSV Import Profile Updated",
    });
  };

  const displayCsvProfileDeleteSuccessToast = (name) => {
    return displaySuccessToast({
      message: `The CSV import profile ${name} has been successfully deleted.`,
      summary: "CSV Import Profile Deleted",
    });
  };

  const displayCsvShipmentImportSuccessToast = () => {
    return displaySuccessToast({
      message: `The CSV import process has started. Please wait while we process your shipment data.`,
      summary: "CSV Import In Progress",
    });
  };

  const displayCsvProfileNameNotUniqueToast = (name) => {
    return displayErrorToast({
      message: `Creation was unsuccessful as the template with ${name} name already exists.`,
      summary: "CSV Import Profile Creation Failed",
    });
  };

  const displayImportCsvInconsistentDataToast = () => {
    return displayErrorToast({
      message: `The CSV import was unsuccessful due to inconsistencies in the data within the CSV file.
       Please check your file and try again.`,
      summary: "CSV Import Unsuccessful",
    });
  };

  return {
    displayCsvProfileCreationSuccessToast,
    displayCsvProfileDeleteSuccessToast,
    displayCsvProfileEditSuccessToast,
    displayCsvShipmentImportSuccessToast,
    displayCsvProfileNameNotUniqueToast,
    displayImportCsvInconsistentDataToast,
  };
};
