<template>
  <div v-if="isLoading">
    <ProgressSpinner />
  </div>
  <div v-else class="privacy-policy">
    <div class="privacy-policy__text">
      <span>
        Scurri collects information about how customers use the platform, for
        instance which pages are visited most often and which links and buttons
        are clicked. These cookies do not collect any personal information, the
        sole purpose is to help improve website function.
      </span>
      <span>
        Strictly necessary cookies are always active as they are required for
        basic website functionality. Please see our
        <a :href="getCookiePolicyUrl()" target="_blank" class="cookie-policy"
          >cookie policy</a
        >
        for more information.
      </span>
    </div>
    <div class="privacy-policy__analytics-toggle" data-cy="agreement-button">
      <span class="privacy-policy__analytics-text">Analytics</span>
      <InputSwitch v-model="isAgreementChecked" @input="updateAgreement" />
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect } from "vue";
import InputSwitch from "primevue/inputswitch";
import ProgressSpinner from "primevue/progressspinner";
import { getPoliciesUrl } from "@/services/utils/policiesService";
import {
  useMutateUserPrivacySettings,
  useQueryUserPrivacySettings,
} from "@/components/settings/helpers/queries";

const isAgreementChecked = ref(false);

const emit = defineEmits(["onError"]);
const setError = (errorMessage) => {
  emit("onError", errorMessage);
};

const {
  data: userAgreement,
  isError,
  isLoading,
} = useQueryUserPrivacySettings();

const { mutate: mutateUserAgreement } = useMutateUserPrivacySettings();
const updateAgreement = async (agreementValue) => {
  await mutateUserAgreement(agreementValue, {
    onSuccess: () => {
      window.location.reload();
    },
    onError: () => {
      setError("Updating user details has failed.");
    },
  });
};

watchEffect(() => {
  if (isError.value) setError("Fetching user details has failed.");
  if (!isLoading.value) isAgreementChecked.value = userAgreement.value;
});

const { getCookiePolicyUrl } = getPoliciesUrl();
</script>

<style lang="scss" scoped>
.privacy-policy {
  display: flex;
  flex-direction: column;
  gap: 32px;

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    width: auto;
    color: #212121;
  }

  &__analytics-toggle {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }

  &__analytics-text {
    font-weight: 600;
    font-size: 14px;
    color: #212121;
  }
}

:deep(.cookie-policy) {
  color: #212121;
}

:deep(.p-inputswitch-slider) {
  border-radius: 30px;
  padding: 0 3.5px;
  background: #dadada;

  &:hover {
    background: #bfbfbf;
  }

  &::before {
    border-radius: 8.75px;
    background: #ffffff;
  }
}

:deep(.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider) {
  background: #26d466;

  &:hover {
    background: #1fb256;
  }
}

:deep(.p-inputswitch.p-focus .p-inputswitch-slider) {
  box-shadow: none;
}
</style>
