<template>
  <div class="p-fieldset route-option">
    <div class="route-status">
      <span>{{ statusTagline }}</span>
      <StatusMultiDropdown
        :model-value="routeOption.triggeredStatuses"
        :options="filteredStatusOptions"
        :name="statusesFieldName"
        data-cy="statuses-select"
        placeholder="Choose status(es)"
        :option-disabled="(option) => isOptionDisabled(option.id)"
        hide-on-select
        @update:model-value="onTriggeredStatusChange"
      />
    </div>
    <div class="route-notifications">
      <span>{{ notificationTagline }}</span>
      <RouteNotification
        v-for="(routeNotification, index) in routeOption.scheduledActions"
        :id="`notification-row-${index}`"
        :key="routeNotification.key"
        :route-option-index="routeOptionIndex"
        :route-notification="routeNotification"
        :notification-options="notificationOptions"
        :index="index"
        :total-count="routeOption.scheduledActions.length"
      />
    </div>
    <div class="route-option-btns">
      <Button
        class="p-button p-button-tertiary-outlined"
        data-cy="add-notification"
        @click="onAddNewNotification"
        ><i class="pi pi-plus" /> Add Notification</Button
      >
      <Button
        v-if="displayDeleteRouteOption"
        class="p-button p-button-tertiary-outlined"
        data-cy="delete-route-option"
        @click="onDeleteRouteOption"
        ><i class="pi pi-trash" /> Remove Trigger</Button
      >
    </div>
  </div>
</template>
<script setup>
import Button from "primevue/button";
import RouteNotification from "@/components/route-configurations/form/RouteNotification";
import { computed } from "vue";
import { useRouteStore } from "@/components/route-configurations/stores/route";
import StatusMultiDropdown from "@/components/StatusMultiDropdown";
import { storeToRefs } from "pinia/dist/pinia";

const routeStore = useRouteStore();
const { addNotification } = routeStore;

const props = defineProps({
  routeOptionField: {
    type: Object,
    default: () => {},
  },
  statusOptions: {
    type: Array,
    default: () => [],
  },
  notificationOptions: {
    type: Array,
    default: () => [],
  },
  routeOptionIndex: {
    type: Number,
    default: 0,
  },
  totalCount: {
    type: Number,
    default: 0,
  },
});

const { routeOptions } = storeToRefs(routeStore);

const statusesFieldName = `routeOptions[${props.routeOptionIndex}].triggeredStatuses`;
const routeOption = routeOptions.value[props.routeOptionIndex];
const displayDeleteRouteOption = computed(() => {
  return props.totalCount > 1;
});

const filteredStatusOptions = computed(() => {
  const filteredOptions = [];
  props.statusOptions.map((statusOption) => {
    if (!statusOption.deleted) filteredOptions.push(statusOption);
  });
  return filteredOptions;
});

const notificationTagline = computed(() => {
  const w =
    routeOption.scheduledActions.length > 1 ? "notifications" : "notification";
  return `Then, send the following ${w}...`;
});

const statusTagline = computed(() => {
  const s =
    routeOption.triggeredStatuses?.length > 1
      ? "these statuses are"
      : "this status is";
  return `If ${s} triggered...`;
});

const onAddNewNotification = () => {
  addNotification(props.routeOptionIndex);
};

const onDeleteRouteOption = () => {
  emit("delete-route-option", props.routeOptionIndex);
};

const statusAny = computed(() => {
  return props.statusOptions.find((status) => status.isAny);
});

const isOptionDisabled = (id) => {
  return (
    routeOption.triggeredStatuses?.length === 1 &&
    routeOption.triggeredStatuses[0] === statusAny.value.id &&
    id !== statusAny.value.id
  );
};

const onTriggeredStatusChange = async (newValue) => {
  let _triggeredStatuses = newValue || [];

  // remove other statuses from selected if "Any Status" selected
  const statusAnySelected = _triggeredStatuses.find(
    (choice) => choice === statusAny.value.id,
  );
  if (statusAnySelected) {
    _triggeredStatuses = _triggeredStatuses.filter((selectedStatus) => {
      return selectedStatus === statusAny.value.id;
    });
  }
  routeOption.triggeredStatuses = _triggeredStatuses;
};

const emit = defineEmits(["reset-form", "delete-route-option"]);
</script>
<style scoped lang="scss">
.route-option {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
  background: #fafafa;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  margin-bottom: 12px;

  .route-status {
    width: 100%;
  }

  span {
    font-size: 14px;
    display: inline-block;
    margin-bottom: 4px;
  }

  .route-notifications {
    width: 100%;
    display: flex;
    gap: 4px;
    flex-direction: column;
    align-items: flex-start;
  }

  .route-option-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 8px;

    height: 32px;

    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
  }
}
</style>
