<template>
  <div class="main-page" :class="mainClass" :data-cy="dataCy">
    <div v-if="loading" class="spinner">
      <ProgressSpinner />
    </div>
    <ErrorBar v-bind="$attrs" />
    <div>
      <div class="main-content">
        <div v-if="showHeader" class="main-page-header flex-row-container">
          <div class="heading-bar flex-row-container">
            <div v-if="heading">
              <h3 class="m-0">{{ heading }}</h3>
              <p v-if="tagline" class="mt-2 heading-tagline">{{ tagline }}</p>
            </div>
            <slot v-else name="headingBar"></slot>
          </div>
          <div class="actions-bar flex-row-container">
            <slot name="actionsBar"></slot>
          </div>
        </div>
        <div class="main-page-body">
          <slot name="body"></slot>
        </div>
      </div>
    </div>
    <div v-if="showFooter" class="main-page-footer">
      <a :href="getPrivacyPolicyUrl()" target="_blank">Privacy Policy</a>
      <divider layout="vertical" class="card__divider" />
      <a :href="getCookiePolicyUrl()" target="_blank">Cookie Policy</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainPage",
  inheritAttrs: false,
};
</script>
<script setup>
import ErrorBar from "@/components/ErrorBar.vue";
import ProgressSpinner from "primevue/progressspinner";
import Divider from "primevue/divider";
import { ref, useAttrs } from "vue";
import { getPoliciesUrl } from "@/services/utils/policiesService";

defineProps({
  heading: {
    default: "",
    type: String,
  },
  tagline: {
    default: "",
    type: String,
  },
  loading: {
    default: false,
    type: Boolean,
  },
  mainClass: {
    default: "",
    type: String,
  },
  showFooter: {
    default: true,
    type: Boolean,
  },
  showHeader: {
    type: Boolean,
    default: true,
  },
});

const attrs = useAttrs();
const dataCy = ref(attrs["data-cy"]);

const { getPrivacyPolicyUrl, getCookiePolicyUrl } = getPoliciesUrl();
</script>
<style scoped lang="scss">
$top-padding: 32px;
$padding: 48px;

.main-page {
  position: relative;
  height: 100%;
  padding: 32px $padding $padding $padding;
}

.flex-row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 0;
}

.main-page-header {
  height: auto;
  margin-bottom: 24px;

  h3 {
    font-size: 24.5px;
    color: #1f2a4e;
    font-weight: 600;
    line-height: 29px;
  }

  .heading-tagline {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #495057;
    margin: 0;
  }
}

.heading-bar {
  font-size: 24.5px;
}

.actions-bar {
  gap: 12px;
  margin-left: auto;
}

.main-content {
  z-index: 1;
  margin-bottom: 40px;
}

.main-page-footer {
  display: flex;
  gap: 12px;
  justify-content: end;
  padding: 12px;
  align-items: center;
  border-top: 1px solid #e6eafa;
  position: absolute;
  bottom: 0;
  left: 48px;
  right: 48px;

  & > a {
    text-decoration: none;
    color: #5c6799;
    font-weight: 400;
    font-size: 12px;
  }
  & > a:hover {
    text-decoration: underline;
  }
}

.card__divider {
  padding: 0;
  margin: 0;
  color: #c3cae5;
  height: 12px;
}
</style>
