import { handleErrors } from "@/services/utils/errorHandler";
import { useShipmentsToastService } from "@/components/shipments/helpers/toastConfigs";

const {
  displayPreviewPortalMissingShipmentIdFail,
  displayManyReroutesAlreadyInProgress,
  displaySingleReroutesAlreadyInProgress,
} = useShipmentsToastService();

export const shipmentDetailsErrorMap = {
  SHIPMENT_MISSING_ATTRIBUTE_FOR_PATH: {
    fn: () => displayPreviewPortalMissingShipmentIdFail(),
  },
};

export const shipmentDetailsErrorMapper = (errors) => {
  return handleErrors(errors, shipmentDetailsErrorMap, {});
};

export const shipmentRerouteErrorMap = {
  SHIPMENT_REROUTE_ALREADY_IN_PROGRESS: {
    fn: () => displayManyReroutesAlreadyInProgress(),
  },
};

export const shipmentSingleRerouteErrorMap = {
  SHIPMENT_REROUTE_ALREADY_IN_PROGRESS: {
    fn: () => displaySingleReroutesAlreadyInProgress(),
  },
};

export const shipmentRerouteErrorMapper = (
  errors,
  errorMap,
  defaultGenericErrorAction,
) => {
  return handleErrors(errors, errorMap, {}, defaultGenericErrorAction);
};
