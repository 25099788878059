<template>
  <div class="template-row" data-cy="data-mapping-row">
    <FormDropdown
      v-model="mappingStatement.attributeId"
      placeholder="Choose attribute"
      :options="attributeOptions"
      :name="`templateMappings[${index}].attributeId`"
      class="configuration-field"
      data-cy="template-mapping-attribute"
    />
    <FormInputText
      v-model="mappingStatement.handlebar"
      placeholder="Enter value"
      :name="`templateMappings[${index}].handlebar`"
      class="configuration-field"
      data-cy="template-mapping-handlebar"
    />
    <div v-if="withDeleteOption" class="button-container">
      <Button
        class="p-button p-button-text delete-icon"
        icon="pi pi-trash"
        data-cy="delete-btn"
        @click="handleStatementDelete"
      />
    </div>
  </div>
</template>

<script setup>
import FormInputText from "@/components/FormInputText";
import FormDropdown from "@/components/FormDropdown.vue";

import Button from "primevue/button";
import { toRefs } from "vue";

const props = defineProps({
  index: {
    type: Number,
    default: null,
  },
  mappingStatement: {
    type: Object,
    default: null,
  },
  attributeOptions: {
    type: Object,
    default: null,
  },
  withDeleteOption: {
    type: Boolean,
    default: false,
  },
});

const { index, mappingStatement, attributeOptions, withDeleteOption } =
  toRefs(props);

const emit = defineEmits(["deleteMapping"]);

const handleStatementDelete = () => {
  emit("deleteMapping", index.value);
};
</script>
<style scoped>
.template-row {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 16px;
}

.configuration-field {
  flex: 1 1 auto;
  width: 272px;
}

.delete-icon {
  height: 32px;
  width: 32px;
}

.button-container {
  height: 42px;
  display: flex;
  align-items: center;
}
</style>
