import { ref } from "vue";
import { defineStore } from "pinia";
import { useConfigurationRequests } from "@/services/utils/configurationRequests";
import BrandApiRequests from "@/components/brands/helpers/apiRequests";

export const useBrandStore = defineStore("brands", () => {
  // State
  const brands = ref([]);

  // Actions
  async function getBrands() {
    const { response } = await makeRequest({
      endpoint: async () => await BrandApiRequests.getBrands(),
    });
    replaceBrands(response.value);
  }

  function replaceBrands(updatedBrands) {
    brands.value = updatedBrands;
  }

  const { makeRequest } = useConfigurationRequests();

  return {
    brands,
    getBrands,
  };
});
