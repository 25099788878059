import { httpRequest } from "@/services/apiRequests";

class CompanyApiRequests {
  static async getCompanyState() {
    const { data } = await httpRequest.get("company-config/");
    const companyState = data.company_state;
    return {
      hasCreatedWorkflow: companyState.has_created_workflow,
      hasCreatedAction: companyState.has_created_action,
      hasCreatedTemplateMapping: companyState.has_created_template_mapping,
      hasCreatedFilter: companyState.has_created_filter,
      hasCreatedCsvProfiles: companyState.has_created_csv_profiles,
      hasGeneratedWidgetCode: companyState.has_generated_widget_code,
      hasCreatedBrand: companyState.has_created_brand,
      featureFlags: data.feature_flags,
    };
  }
}

export default CompanyApiRequests;
