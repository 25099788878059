<template>
  <div :id="styleId" class="empty-page">
    <div class="empty-message-container">
      <div :class="iconClass"></div>
      <div class="text-container">
        <h2 data-cy="empty-message-heading">{{ heading }}</h2>
        <p data-cy="empty-message-container">
          {{ message }}
        </p>
        <div class="empty-message-buttons">
          <Button class="p-button" data-cy="empty-btn" @click="onButtonClick">
            <i :class="btnIconClass" /> {{ btnLabel }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Button from "primevue/button";

const props = defineProps({
  heading: {
    type: String,
    default: "",
  },
  message: {
    type: String,
    default: "",
  },
  iconClass: {
    type: String,
    default: "",
  },
  btnIconClass: {
    type: String,
    default: "",
  },
  btnLabel: {
    type: String,
    default: "",
  },
  styleId: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["button-action"]);

const onButtonClick = () => {
  emit("button-action");
};

const iconClass = `empty-icon-image ${props.iconClass} small-diamond`;
</script>
<style scoped lang="scss">
.empty-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .empty-message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    text-align: center;
    vertical-align: center;

    .empty-icon-image {
      background-repeat: no-repeat;
    }

    .small-diamond {
      width: 125px;
      height: 144px;
      margin: 0 auto;
    }

    h2 {
      font-weight: 600;
      font-size: 21px;
      color: #556376;
      line-height: 25px;
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #64748b;
      margin: 0 auto;
    }
    .empty-message-buttons {
      margin-top: 24px;
    }
  }
}

#empty-routes {
  height: 415px;
}

#config-warning {
  height: 80vh;
  p {
    width: 400px;
  }
}
</style>
