import { httpRequest } from "@/services/apiRequests";

class SettingsApiRequests {
  static async updateUserAnalyticsAgreement(acceptAnalyticsAgreement) {
    return await httpRequest.patch("users/analytics-agreement/", {
      accept_analytics_agreement: acceptAnalyticsAgreement,
    });
  }

  static async getUserAnalyticsAgreement() {
    const { data } = await httpRequest.get("users/analytics-agreement/");
    return { acceptAnalyticsAgreement: data.accept_analytics_agreement };
  }
}

export default SettingsApiRequests;
