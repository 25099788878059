<template>
  <MainPage
    heading="Edit Email Notification"
    data-cy="create-action-page"
    :loading="loading"
  >
    <template #body>
      <suspense>
        <NotificationsForm
          v-show="!loading"
          v-model:loading="loading"
          :action-id="actionId"
        />
      </suspense>
    </template>
  </MainPage>
</template>

<script setup>
import NotificationsForm from "@/components/notifications/NotificationsForm";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const actionId = computed(() => {
  return route.params.id.toString() || "";
});

const loading = ref(true);
</script>
