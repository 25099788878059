import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { VueQueryPlugin } from "@tanstack/vue-query";

import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "./styles.scss";

import App from "./App.vue";
import router from "./routes/routes";
import MainPage from "@/components/MainPage.vue";
import DialogService from "primevue/dialogservice";
import { completeLifecycleStatusIcon, workflowIcon } from "@/customicons";
import { initSentry } from "@/services/sentry";

import "highlight.js/styles/stackoverflow-light.css";
import hljs from "highlight.js/lib/core";
import javascript from "highlight.js/lib/languages/javascript";
import hljsVuePlugin from "@highlightjs/vue-plugin";
import { queryClient } from "./queryClient";
import { useAuthStore } from "@/store/authStore";

library.add(fas, workflowIcon, completeLifecycleStatusIcon);
dom.watch();

hljs.registerLanguage("javascript", javascript);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);

initSentry(app, router);
app.use(VueQueryPlugin, { queryClient });
app.use(PrimeVue);
app.use(ConfirmationService);
app.use(ToastService);
app.use(DialogService);
app.use(hljsVuePlugin);

app.directive("tooltip", Tooltip);

app.use(pinia);
const authStore = useAuthStore();
authStore.getCsrfToken().then(() => {
  app.use(router);
  app.mount("#app");
  // Globaly registered components:
  app.component("MainPage", MainPage);
});
