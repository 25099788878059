/* That component is obsolete. Use FormDropdown or FormInputText instead. *
TODO: Delete that component */
<template>
  <div :class="'form-field' + (isError ? ' invalid-field' : '')">
    <slot></slot>
    <div v-if="isError" class="d-flex invalid-feedback">
      <div v-if="isSingleFeedback" class="ps-1">
        {{ errors[0] }}
      </div>
      <div v-else>
        <ul>
          <li v-for="(message, key) in errors" :key="key" class="ps-1 mb-1">
            {{ message }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormField",
  props: {
    errors: {
      default: () => [],
      type: Array,
    },
  },
  computed: {
    isError() {
      return !!this.errors.length;
    },
    isSingleFeedback() {
      return this.errors.length === 1;
    },
  },
};
</script>
