<template>
  <div class="flex-container center">
    <div class="card" data-cy="login-page">
      <form class="form-signin flex-container" @submit.prevent="onLoginSubmit">
        <div class="heading">
          <img src="@/assets/img/Scurri-Logo-Symbol.svg" alt="Scurri Logo" />
          <div class="heading__text">
            <h1 class="heading__signin">Sign in</h1>
            <span class="heading__details">
              Enter your details below to sign in.
            </span>
          </div>
        </div>
        <div class="container-inputs width-100">
          <div class="inputs width-100">
            <InputText
              id="username"
              v-model="username"
              class="form-control width-100"
              :class="{ 'inputs--error': isAuthenticatedError }"
              placeholder="Username"
              required
              autofocus
            />
            <Password
              id="password"
              v-model="password"
              class="form-control p-0 width-100"
              :class="{ 'inputs--error': isAuthenticatedError }"
              placeholder="Password"
              required
              :feedback="false"
              toggle-mask
            />
          </div>
          <ErrorBar
            v-if="errorMessage.message"
            :error-message="errorMessage.message"
            :closable="false"
          />
        </div>
        <div class="container-inputs width-100">
          <Button
            :label="textButton"
            class="p-button-primary"
            data-cy="login-btn"
            type="submit"
            :loading="isLoading"
          />
          <span class="policy-text">
            By proceeding, you agree to Scurri's
            <a :href="getPrivacyPolicyUrl()" target="_blank">Privacy Policy</a>
          </span>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import ErrorBar from "@/components/ErrorBar";
import { computed, ref, toRefs } from "vue";
import { getPoliciesUrl } from "@/services/utils/policiesService";

const props = defineProps({
  isLoading: {
    type: Boolean,
    required: true,
  },
  errorMessage: {
    type: Object,
    default: () => ({
      isAuthenticationError: false,
      message: "",
    }),
  },
});
const emit = defineEmits(["onLogin"]);

const { isLoading, errorMessage } = toRefs(props);

const username = ref("");
const password = ref("");
const onLoginSubmit = () => {
  emit("onLogin", { username: username.value, password: password.value });
};

const textButton = computed(() => {
  return isLoading.value ? "Signing in" : "Sign in";
});
const isAuthenticatedError = computed(
  () => !!errorMessage.value.isAuthenticationError,
);

const { getPrivacyPolicyUrl } = getPoliciesUrl();
</script>
<style scoped lang="scss">
:deep(.p-password-input) {
  width: 100%;
  border: none;
}

:deep(.p-inputtext) {
  padding: 16px;

  &:enabled:focus {
    border-color: #ced4da;
    box-shadow: none;
  }
}

.width-100 {
  width: 100%;
}

.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 24px;
}

.center {
  min-height: 100vh;
  position: relative;
  top: 0;

  @media only screen and (max-width: 576px) {
    padding: 16px;
  }
}

.card {
  padding: 80px;
  width: 560px;
  height: auto;

  @media only screen and (max-width: 1200px) {
    width: 520px;
    height: 486.5px;
    padding: 64px;
  }
  @media only screen and (max-width: 992px) {
    width: 480px;
    height: 454.5px;
    padding: 48px;
  }
  @media only screen and (max-width: 576px) {
    width: 100%;
    height: 454.5px;
    padding: 32px;
  }
}

.container-inputs {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > * {
    height: 42px !important;
    border-radius: 6px;
    border: 1px solid #ced4da;
  }

  &--error {
    border: 1px solid #ed244e;
  }
  &--error:enabled:focus,
  &--error:enabled:hover {
    border: 1px solid #ed244e;
  }
}

.heading {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-wrap: wrap;

  &__signin {
    font-weight: 600;
    font-size: 35px;
    line-height: 35px;
    color: #1f2a4e;
  }

  &__details {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #666666;
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-wrap: wrap;
  }
}

// Unify that with other .p-message once doing messages card
:deep(.p-message-error) {
  margin: 0;
  background: #fff2f5;
  border: 1px solid #fccad5;
  color: #a60d2d;
}

:deep(.p-message) {
  border-radius: 6px;
}

:deep(.p-message-wrapper) {
  padding: 12px 16px;
}

:deep(.p-message-text) {
  font-weight: 400;
  font-size: 14px;
}

:deep(.p-message-icon) {
  display: none;
}

:deep(.pi-eye:hover) {
  cursor: pointer;
}

:deep(.pi-eye-slash:hover) {
  cursor: pointer;
}

:deep(.p-button-label) {
  flex: none;
}

:deep(.p-button.p-button-loading-label-only .p-button-label) {
  margin-left: 0;
}

:deep(.p-button) {
  display: flex;
  justify-content: center;
  gap: 7px;
}

.p-button-primary {
  border-radius: 6px;
}

.policy-text {
  font-weight: 400;
  font-size: 12px;

  color: #808080;

  & > a {
    text-decoration: none;
    font-weight: 600;
    font-size: 12px;
    color: #808080;
  }

  & > a:hover {
    text-decoration: underline;
  }
}
</style>
