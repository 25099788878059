<template>
  <div class="csv-modal" data-cy="csv-upload-modal">
    <div class="content-modal">
      <h3 class="csv-header no-margin">Import Shipments via CSV</h3>
      <div class="p-fieldset">
        <label for="type" class="csv-import-label">
          Select a CSV import profile or create a new one.
          <i
            v-tooltip.top="{ value: TOOLTIP_TEXT }"
            class="pi pi-question-circle"
          />
        </label>
        <div class="profile-actions">
          <FormDropdown
            v-model="selectedProfile"
            placeholder="Choose a profile..."
            :options="profiles"
            name="csvImportProfiles"
            class="template-mapping-dropdown"
          >
            <template #value="{ parsedValue, placeholder }">
              <div :class="{ 'selected-profile-name': !!parsedValue }">
                <span>{{ parsedValue || placeholder }}</span>
                <span>
                  <i
                    v-if="!!parsedValue"
                    class="pi pi-times-circle clear-icon"
                    @click="clearValue"
                  />
                </span>
              </div>
            </template>
          </FormDropdown>
          <Button
            class="p-button p-button-outlined template-mapping__template-button"
            @click="onRedirectToCreateProfile"
          >
            <i class="pi pi-plus" /> New Import Profile
          </Button>
        </div>
        <FileUploader
          accept=".csv"
          :is-loading="isLoading"
          auto-upload
          @on-upload="uploadCsv"
          @on-select="onFileSelect"
          @on-remove="onRemoveFile"
        >
          <template #content="{ files, removeFile }">
            <div class="card card-body no-margin">
              <div class="icon-filename">
                <i class="pi pi-file uploaded-icon" />
                <span class="file-details__filename">
                  {{ files[0].name }}
                </span>
              </div>
              <span class="file-details__filesize">
                {{ formatSize(files[0].size) }}
              </span>
              <div class="uploader-buttons">
                <Button
                  class="p-button p-button-tertiary-outlined p-button-icon csv-action-button"
                  data-cy="show-preview-button"
                  @click="showPreview"
                >
                  <i class="pi pi-eye" />
                </Button>
                <Button
                  class="p-button p-button-tertiary-outlined p-button-icon csv-action-button"
                  data-cy="remove-file-button"
                  @click="removeFile"
                >
                  <i class="pi pi-times" />
                </Button>
              </div>
            </div>
            <div class="header-option-row">
              <Checkbox
                id="firstRowCheck"
                v-model="isFirstRowHeader"
                name="first_row_header"
                :binary="true"
              />
              <label for="firstRowCheck" class="first-row-check-label">
                The first row of the CSV file contains column headers.
              </label>
            </div>
          </template>
        </FileUploader>
      </div>
    </div>
    <div>
      <Button
        label="Import Shipments"
        class="p-button csv-import-button"
        icon="pi pi-upload"
        data-cy="import-shipments-button"
        :disabled="isButtonDisabled"
        @click="sendShipment()"
      />
      <Button
        label="Cancel"
        class="p-button p-button-text csv-import-button"
        data-cy="confirm-phrase-reject-btn"
        @click="onCloseModal"
      />
    </div>
  </div>
</template>
<script setup>
import FormDropdown from "@/components/FormDropdown.vue";
import Button from "primevue/button";
import FileUploader from "@/components/FileUploader.vue";
import Checkbox from "primevue/checkbox";
import { useRouter } from "vue-router";
import { computed, inject, onMounted, ref } from "vue";
import { formatSize } from "@/services/utils/filesUtils";
import { useCsvImportStore } from "@/components/csv-import/stores/csvImport";
import { storeToRefs } from "pinia";
import { useCsvImportConfirmDialog } from "@/components/csv-import/helpers/confirmModalConfigs";
import { useCsvProfileResultsStore } from "@/components/csv-import/stores/csvProfileResults";
import { useConfigurationRequests } from "@/services/utils/configurationRequests";
import { useCsvProfileToastService } from "@/components/csv-import/helpers/toastConfigs.js";
import { csvErrorMapper } from "@/components/csv-import/helpers/errors";
import CsvApiRequests from "@/components/csv-import/helpers/apiRequests";

const TOOLTIP_TEXT =
  "A field mapping profile is required in order to accurately import your shipment data.";

const dialogRef = inject("dialogRef");
const closeDialog = () => {
  dialogRef.value.close();
};

const { confirmDiscardChanges } = useCsvImportConfirmDialog();

const onCloseModal = () => {
  if (filesSelected.value.length) {
    confirmDiscardChanges({
      callback: () => dialogRef.value.close(),
    });
  } else {
    dialogRef.value.close();
  }
};

const selectedProfile = ref("");
const profiles = ref([]);
onMounted(async () => {
  profiles.value = await CsvApiRequests.getCsvProfiles();
  const profileId = dialogRef.value.data.profileId;
  if (profileId) {
    selectedProfile.value = profileId;
  }
});

const getProfileNameById = (id) => {
  return profiles.value.find((profile) => profile.id === id).name;
};

const { confirmRedirectToCsvProfiles } = useCsvImportConfirmDialog();
const onRedirectToCreateProfile = () => {
  confirmRedirectToCsvProfiles({
    callback: async () => await redirectToCreateProfile(),
  });
};
const router = useRouter();
const redirectToCreateProfile = () => {
  closeDialog();
  return router.push({ name: "CreateCsvProfileStepName" });
};

const clearValue = () => {
  selectedProfile.value = "";
};

const csvImportStore = useCsvImportStore();
const { isFirstRowHeader, firstFile } = storeToRefs(csvImportStore);
const uploadCsv = async ({ uploadedFilesContent }) => {
  await csvImportStore.uploadCsvFile({
    readFilesContent: uploadedFilesContent,
    selectedFiles: filesSelected.value,
  });
};

const filesSelected = ref([]);
const onFileSelect = (files) => {
  filesSelected.value = files;
};

const isPreviewOpen = ref(false);

const emit = defineEmits(["onPreviewOpen"]);

const showPreview = () => {
  isPreviewOpen.value = true;
  emit("onPreviewOpen", true);
};

const isButtonDisabled = computed(() => {
  return !(selectedProfile.value && filesSelected.value?.length);
});

const { makeRequest } = useConfigurationRequests();
const { displayCsvShipmentImportSuccessToast } = useCsvProfileToastService();

const isLoading = ref(false);
const sendShipment = async () => {
  isLoading.value = true;
  const detailsStore = useCsvProfileResultsStore(selectedProfile.value);
  await makeRequest({
    endpoint: () =>
      CsvApiRequests.importCsvShipment({
        skipFirstRow: isFirstRowHeader.value,
        csvFile: firstFile.value,
        profileName: getProfileNameById(selectedProfile.value),
      }),
    onSuccess: () => displayCsvShipmentImportSuccessToast(),
    errorMapper: (errors) => csvErrorMapper(errors),
  });
  // TODO: Review that once TanStack Query will be in place
  // We want to call getImportFeedbacks to update details table when importing from there
  await detailsStore.getImportFeedbacks();
  isLoading.value = false;
  closeDialog();
};

const onRemoveFile = () => {
  filesSelected.value = [];
};
</script>
<style scoped lang="scss">
.csv-modal {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.csv-header {
  font-weight: 600;
  font-size: 21px;
  color: #495057;
}

.profile-actions {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
}

.template-mapping-dropdown {
  flex-grow: 1;
}

.template-mapping__template-button {
  min-width: fit-content;
  height: 42px;
}

:deep(.file-upload) {
  background: #ffffff;
}

.csv-import-button {
  float: right;
  height: 42px;
}

.selected-profile-name {
  display: flex;
  width: fit-content;
  padding: 5px 10px;
  background: #dee2e6;
  border-radius: 16px;
  align-items: center;
  gap: 8px;
}

.clear-icon {
  color: #495057;
  vertical-align: middle;
}

.uploaded-icon {
  font-size: 35px;
}

.card-body {
  width: 100%;
  align-items: center;
  flex-direction: row;
  padding: 32px;
  gap: 28px;
}

.card {
  border: 1px solid #dadee3;
}

.header-option-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
  justify-content: flex-start;
}

// TODO: MOVE THAT TO SEPARATE
:deep(.p-checkbox) {
  align-items: center;
  width: 22px;
  height: 22px;

  & .p-checkbox-box {
    border-radius: 6px;
    padding: 0;
    width: 22px;
    height: 22px;
  }

  & .p-checkbox-box:hover,
  & .p-checkbox-box.p-focus:hover {
    border: 2px solid #1f2a4e;
  }

  & .p-checkbox-box.p-highlight {
    background: #26d466;
    border: 2px solid #26d466;
  }

  & .p-checkbox-box.p-focus {
    box-shadow: none;
    border: 2px solid #ced4da;
  }

  & .p-checkbox-box.p-highlight.p-focus {
    box-shadow: none;
    border: 2px solid #26d466;
  }

  & .p-checkbox-box.p-highlight:hover {
    background: #199146;
    border: 2px solid #199146;
    box-shadow: none;
  }
}

.uploader-buttons {
  display: flex;
  gap: 8px;
  margin-left: auto;

  button {
    width: 32px;
    height: 32px;

    .pi {
      line-height: unset !important;
    }
  }
}

.file-details__filename {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #495057;
}

.icon-filename {
  display: flex;
  align-items: center;
  gap: 18px;
}

.content-modal {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.csv-import-label {
  display: flex;
  gap: 8px;
  align-items: center;

  & .pi.pi-question-circle {
    font-size: 1.25rem;
    color: #6c757d;
  }
}

.no-margin {
  margin: 0;
}

:deep(.p-inputwrapper-filled > .p-dropdown-label) {
  padding: 10.5px 7px 10.5px 10.5px;
  display: flex;
  align-items: center;
}
</style>
