import { useRouter } from "vue-router";

export const getPoliciesUrl = () => {
  const router = useRouter();

  const getPrivacyPolicyUrl = () => {
    return router.resolve({
      name: "PrivacyPolicy",
    }).href;
  };
  const getCookiePolicyUrl = () => {
    return router.resolve({
      name: "CookiePolicy",
    }).href;
  };
  return { getPrivacyPolicyUrl, getCookiePolicyUrl };
};
