import { httpRequest } from "@/services/apiRequests";

class FilterApiRequests {
  static prepareStatements(statements) {
    return statements.map(
      ({
        propertyId,
        operatorId,
        inputValue,
        selectedValue,
        multiSelectedValues,
        valueSelector,
      }) => ({
        property_id: propertyId,
        operator_id: operatorId,
        ...{
          input: { value: inputValue },
          multiSelect: { selected_values: multiSelectedValues },
          select: { value: selectedValue },
        }[valueSelector],
      }),
    );
  }

  static async createFilter({ name, statements }) {
    const data = {
      name,
      statements: this.prepareStatements(statements),
    };
    return await httpRequest.post("filters/", data);
  }

  static async updateFilter({ id, name, statements }) {
    const data = {
      name,
      statements: this.prepareStatements(statements),
    };
    return await httpRequest.put(`filters/${id}/`, data);
  }

  static async deleteFilter(id) {
    return await httpRequest.delete(`filters/${id}/`);
  }

  static async duplicateFilter(id) {
    return await httpRequest.post(`filters/${id}/duplicate/`);
  }

  static async getFilters() {
    const { data } = await httpRequest.get("filters/");

    return data.map((filter) => ({
      id: filter.id,
      name: filter.name,
    }));
  }

  static async getPropertyFilterSetups() {
    const { data } = await httpRequest.get("property-filter-setups/");
    return data.map((filter) => ({
      id: filter.id,
      name: filter.name,
      statementOptions: filter.statement_options.map((option) => ({
        name: option.name,
        id: option.operator_id,
        uiType: option.ui_type,
        valueOptions: option.value_options.map((option) => ({
          name: option.name,
          id: option.id,
        })),
      })),
    }));
  }

  static async getFilterById(id) {
    const { data } = await httpRequest.get(`filters/${id}/`);
    return {
      id: data.id,
      name: data.name,
      statements: data.statements.map((filter) => ({
        propertyId: filter.property_id,
        operatorId: filter.operator_id,
        ...(filter.value ? { value: filter.value } : {}),
        ...(filter.selected_values
          ? { selectedValues: filter.selected_values }
          : {}),
      })),
    };
  }
}

export default FilterApiRequests;
