<template>
  <div v-if="message" :id="barId" class="shipment-action-bar">
    <Message
      :severity="severity"
      :closable="false"
      :icon="icon"
      data-cy="warn-message"
    >
      <div class="message-content">
        <span>
          {{ message }}
        </span>
        <div class="shipment-action-btns">
          <Button
            :class="btnClassMap[severity]"
            data-cy="shipment-primary-action-btn"
            @click="emit('buttonPrimaryAction')"
          >
            <i :class="btnConfig.btnPrimary.icon" />
            {{ btnConfig.btnPrimary.label }}
          </Button>
          <Button
            v-if="btnConfig.btnSecondary"
            :class="`${btnClassMap[severity]} p-button-outlined`"
            data-cy="shipment-secondary-action-btn"
            @click="emit('buttonSecondaryAction')"
          >
            <i :class="btnConfig.btnSecondary.icon" />
            {{ btnConfig.btnSecondary.label }}
          </Button>
        </div>
      </div>
    </Message>
  </div>
</template>

<script setup>
import Message from "primevue/message";
import Button from "primevue/button";

const props = defineProps({
  message: {
    type: String,
    default: "",
  },
  severity: {
    type: String,
    default: "",
  },
  icon: {
    type: String,
    default: "",
  },
  btnConfig: {
    type: Object,
    default: () => {},
  },
});

const barId = `${props.severity}-bar`;
const btnClassMap = {
  warn: "p-button-warning",
  danger: "p-button-danger",
};
const emit = defineEmits(["buttonPrimaryAction", "buttonSecondaryAction"]);
</script>
<style scoped lang="scss">
.shipment-action-bar {
  .p-message {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 32px;
    gap: 24px;
    height: 64px;
    border-radius: 4px;

    :deep(.p-message-text) {
      display: flex;
      flex-direction: row;
      align-items: center;
      .message-content {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 24px;
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
      }
      span {
        font-weight: 600;
      }
    }

    :deep(.p-button) {
      min-width: 140px;
    }
  }
}
#warn-bar {
  .p-message {
    background: #fffbf2;
    border: 1px solid #ffeecc;

    :deep(.p-message-text) {
      color: #664400;
    }
  }
}

#danger-bar {
  .p-message {
    background: #fff2f5;
    border: 1px solid #fccad5;
  }

  :deep(.p-message-text) {
    color: #73081e;
  }

  :deep(.p-message-icon) {
    color: #73081e;
  }
}

.shipment-action-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
</style>
