<template>
  <div v-if="showEmptyPage" class="card card-body card-body-empty">
    <EmptyPage :empty-config="emptyConfig" />
  </div>
  <div v-else class="card card-body card-body-content">
    <DataTable
      :value="importsFeedback"
      :rows="ROWS_PER_PAGE"
      paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
      :rows-per-page-options="getNumberOfRows"
      data-cy="imports-feedback-table"
      :paginator="showPaginator"
      :row-hover="true"
      :row-class="rowClass"
      @row-click="onRowClick($event)"
    >
      <Column header="Import Status">
        <template #body="{ data }">
          <StatusBadge size="small" :status="data.status" is-rounded />
        </template>
      </Column>
      <Column field="filename" header="File Name" />
      <Column header="Uploaded By">
        <template #body="{ data }">
          <div class="uploaded-by-row">
            <div class="uploaded-by-row__tag">
              {{ data.uploadedBy[0].toUpperCase() }}
            </div>
            {{ data.uploadedBy }}
          </div>
        </template>
      </Column>
      <Column header="Upload Date">
        <template #body="{ data }">
          {{ getFormattedAwareDateTimeWithoutSeconds(data.uploadDate) }}
        </template>
      </Column>
      <Column header="Import Breakdown">
        <template #body="{ data }">
          <div
            v-if="isImportInProgress(data.status)"
            class="notification-in-progress"
          >
            <span>Import in progress...</span>
          </div>
          <div v-else class="import-breakdown-row">
            <ActionBadge v-if="data.totalAdded">
              <template #label>
                <i class="pi pi-check-circle" /> {{ data.totalAdded }}
              </template>
            </ActionBadge>
            <ActionBadge
              v-if="data.totalFailed"
              v-tooltip.top="{
                value: getTooltipText(data.totalFailed),
              }"
            >
              <template #label>
                <i class="pi pi-times-circle" /> {{ data.totalFailed }}
              </template>
            </ActionBadge>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script setup>
import Column from "primevue/column";
import DataTable from "primevue/datatable";

import { useCsvProfileResultsStore } from "@/components/csv-import/stores/csvProfileResults";
import { computed, toRefs } from "vue";
import { storeToRefs } from "pinia";
import StatusBadge from "@/components/StatusBadge.vue";
import { getFormattedAwareDateTimeWithoutSeconds } from "@/services/utils/datesFormatter";
import ActionBadge from "@/components/ActionBadge.vue";
import EmptyPage from "@/components/EmptyPage.vue";
import { useCsvProfileDetailsEmptyState } from "@/services/empty-states-config/importCsvProfileEmptyState";
import { useCsvProfileErrorSummaryDialog } from "@/components/csv-import/helpers/modalConfigs";

const props = defineProps({
  profileId: {
    type: String,
    required: true,
  },
});

const { profileId } = toRefs(props);
const resultsStore = useCsvProfileResultsStore(profileId.value);
const { importsFeedback } = storeToRefs(resultsStore);

const ROWS_PER_PAGE = 10;
const showPaginator = computed(() => {
  return importsFeedback.value.length > ROWS_PER_PAGE;
});
const getNumberOfRows = computed(() => {
  const numberOfRows = importsFeedback.value.length;
  return new Array(Math.floor(numberOfRows / ROWS_PER_PAGE))
    .fill(ROWS_PER_PAGE)
    .concat(numberOfRows % 100);
});

const isImportInProgress = (status) => {
  return status === "IN PROGRESS";
};

const isRowFailed = (rowData) => {
  return !!rowData.totalFailed;
};

const { openErrorSummaryDialog } = useCsvProfileErrorSummaryDialog();
const onRowClick = (row) => {
  const rowData = row.data;
  if (isRowFailed(rowData)) {
    return openErrorSummaryDialog({
      rowsFeedback: rowData.rowFeedback,
      filename: rowData.filename,
      status: rowData.status,
      totalFailed: rowData.totalFailed,
      totalAdded: rowData.totalAdded,
    });
  }
};

const getTooltipText = (totalFailed) =>
  `${totalFailed} failed to import.
  Click for more details.`;

const rowClass = (rowData) => {
  return isRowFailed(rowData) ? "failed-row" : "";
};

const { emptyConfig } = useCsvProfileDetailsEmptyState();
const showEmptyPage = computed(() => {
  return !importsFeedback.value.length;
});
</script>

<style scoped>
.uploaded-by-row__tag {
  width: 28px;
  height: 28px;
  border-radius: 16px;
  background: #dee2e6;
  color: #495057;

  display: flex;
  justify-content: center;
  align-items: center;
}

.uploaded-by-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
}

.import-breakdown-row {
  display: flex;
  gap: 4px;
  flex-direction: row;
  flex-wrap: wrap;
}

.notification-in-progress {
  display: flex;
  justify-content: center;
  align-items: center;

  font-style: oblique 6deg;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #495057;

  padding: 0 8px;
  gap: 4px;

  height: 24px;

  border: 1px solid #e6e6e6;
  border-radius: 4px;
  background: none;
  max-width: fit-content;
}

:deep(.failed-row) {
  cursor: pointer;
}

/* Overrides for empty state
   Do not remove even if IDE says that style is unused */
.empty-page {
  height: unset;
  padding: calc(32px - 1rem);
}
:deep(.empty-icon-image) {
  width: 124px !important;
  height: 144px !important;
}

:deep(.empty-message-container h2) {
  font-size: 21px !important;
}

.card {
  margin: 0;
}

.card-body {
  padding: 0;
}

.card-body-content {
  padding: 32px;
}

.card-body-empty {
  padding: 24px 0 32px;
  margin: 32px;
}

:deep(
    .p-datatable.p-datatable-hoverable-rows
      .p-datatable-tbody
      > tr:not(.p-highlight):hover
  ) {
  background: #f7f7f7;
}

:deep(.p-datatable tbody tr) {
  transition: 0.3s ease !important;
}
</style>
