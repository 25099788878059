<template>
  <StepContainer
    title="Step 4: Review Campaign"
    :next-button-label="finishBtnLabel"
    display-previous-button
    :is-next-button-disabled="false"
    @next-button-click="saveRoute"
    @prev-button-click="onPrevClick"
  >
    <template #content>
      <div class="route-review">
        <RouteOverview
          :route-name="name"
          :route-options="routeOptions"
          :route-filters="shipmentFilters"
          :display-close="false"
        />
      </div>
    </template>
  </StepContainer>
</template>

<script setup>
import RouteOverview from "@/components/route-configurations/RouteOverview";
import StepContainer from "@/components/stepper/StepContainer";
import { storeToRefs } from "pinia";
import { Navigator } from "@/services/utils/navigator";
import { useWorkflowStore } from "@/components/route-configurations/stores/workflow";
import { useRouteStore } from "@/components/route-configurations/stores/route";
import { computed } from "vue";

const { navigateToView } = Navigator();
const workflowStore = useWorkflowStore();
const routeStore = useRouteStore();
const { name, routeOptions, position, shipmentFilters, isEdit } =
  storeToRefs(routeStore);
const { resetRoute } = routeStore;
const { id, immediatelySchedule } = storeToRefs(workflowStore);
const { addWorkflowRoute, updateWorkflowRoute, isRouteActive } = workflowStore;
const emit = defineEmits(["prev-page", "next-page"]);

routeOptions.value.map((ro) =>
  ro.scheduledActions.map((a) => (a.scheduleId = immediatelySchedule.value)),
);

const finishBtnLabel = computed(() => {
  return isEdit.value ? "Update Campaign" : "Create Campaign";
});

const saveRoute = async () => {
  const routeData = {
    name: name.value,
    shipmentFilters: shipmentFilters.value,
    routeOptions: routeOptions.value,
    position: position.value,
    isActive: isEdit.value ? isRouteActive(position.value) : false,
  };
  if (isEdit.value) {
    await updateRoute(routeData);
  } else {
    await createRoute(routeData);
  }
};

const navigateToPage = async () => {
  await resetRoute();
  if (id.value) {
    await navigateToView("EditNotificationWorkflow", { id: id.value });
  } else {
    await navigateToView("CreateNotificationWorkflow");
  }
};

const createRoute = async (routeFromForm) => {
  await addWorkflowRoute(routeFromForm);
  await navigateToPage();
};

const updateRoute = async (routeFromForm) => {
  await updateWorkflowRoute(routeFromForm);
  await navigateToPage();
};

const onPrevClick = () => {
  emit("prev-page");
};
</script>

<style scoped lang="scss">
.route-review {
  :deep(h5) {
    margin-bottom: 16px;
  }
}
</style>
