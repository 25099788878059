<template>
  <div class="page-404 container">
    <div class="card shadow">
      <div class="card-body">
        <div class="text-wrapper">
          <h1>404</h1>
          <h2>Page Not Found</h2>
          <p>
            Oops! The page you're looking for cannot be found, it may have been
            removed or renamed.
          </p>
        </div>
        <Button data-cy="shipments-router-link" @click="navigateToShipments">
          <i class="pi pi-arrow-left" />
          Back to Shipments
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import Button from "primevue/button";
import { Navigator } from "@/services/utils/navigator";

const { navigateToView } = Navigator();
const navigateToShipments = () => {
  return navigateToView("ListShipments");
};
</script>
<style scoped lang="scss">
.card,
.card-body,
.text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.card {
  padding: 96px 80px;
  gap: 24px;
  width: 560px;
  height: 468px;
  position: absolute;
  left: calc(50% - 560px / 2);
  top: calc(50% - 468px / 2);

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    height: 464px;
  }

  @media only screen and (min-width: 576px) and (max-width: 767px) {
    padding: 24px;
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    inset: 16px;
  }

  @media only screen and (max-width: 575px) {
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    inset: 8px;
  }
}

.card-body,
.text-wrapper {
  padding: 0;
}

.card-body {
  text-align: center;
  gap: 32px;
}

.text-wrapper {
  gap: 8px;
}

h1,
h2 {
  font-weight: 700;
  margin: 0;
}

h1 {
  font-size: 112px;
  line-height: 112px;
  letter-spacing: 4px;
  color: #ced5f2;
}

h2 {
  font-size: 28px;
  line-height: 34px;
  color: #1f2a4e;
}

p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #6c757d;
  width: 400px;
  margin: 0;

  @media only screen and (max-width: 575px) {
    width: 100%;
  }
}
</style>
