<template>
  <ImportProfileStepFile
    :selected-files="rawCsvFiles"
    :is-loading="isLoading"
    @prev-page="emitPrevPage"
    @on-files-upload="uploadCsv($event)"
    @update:selected-files="setSelectedFiles"
  />
</template>
<script setup>
import { storeToRefs } from "pinia";
import ImportProfileStepFile from "@/components/csv-import/ImportProfileStepFile.vue";
import { ref } from "vue";
import { useCsvProfileCreateStore } from "@/components/csv-import/stores/csvProfile";

const emit = defineEmits(["next-page", "prev-page"]);
const emitNextPage = async () => await emit("next-page");
const emitPrevPage = () => emit("prev-page");

const csvProfileStore = useCsvProfileCreateStore();
const { rawCsvFiles } = storeToRefs(csvProfileStore);
const { setSelectedFiles } = csvProfileStore;

const isLoading = ref(false);

const onParsedFile = async () => {
  isLoading.value = false;
  await emitNextPage();
};

const uploadCsv = async ({ uploadedFilesContent }) => {
  isLoading.value = true;
  await csvProfileStore.uploadCsvFile({
    readFilesContent: uploadedFilesContent,
    selectedFiles: rawCsvFiles.value,
    callbackOnLoad: onParsedFile,
  });
};
</script>
