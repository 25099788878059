<template>
  <Sidebar
    v-model:visible="isOpen"
    :base-z-index="10000"
    position="right"
    :show-close-icon="true"
    @hide="onClose"
    @show="onShow"
  >
    <div class="component-container">
      <component :is="view" v-bind="viewProps" />
    </div>
  </Sidebar>
</template>
<script setup>
import Sidebar from "primevue/sidebar";
import { useDynamicSidebar } from "@/store/dynamicSidebar";
import { storeToRefs } from "pinia";
import { watch } from "vue";
import { useRoute } from "vue-router";

const sidebar = useDynamicSidebar();
const { isOpen, view, viewProps } = storeToRefs(sidebar);
const { close } = sidebar;

// Remove scrollbar from parent component
const onClose = () => {
  close();
  document.body.style.setProperty("overflow", "visible");
};
const onShow = () => {
  document.body.style.setProperty("overflow", "hidden");
};

// Close modal on escape button
document.onkeydown = function (evt) {
  if (evt.key === "Escape" && isOpen.value) {
    onClose();
  }
};

const route = useRoute();
watch(
  () => route.fullPath,
  async () => {
    onClose();
  },
);
</script>
<style scoped>
/* Rest styles are defined globally in frontend/tracking/src/scss/components/DynamicSidebar.scss */

.component-container {
  display: flex;
  flex-direction: column;
}
</style>
