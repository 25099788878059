<template>
  <div class="empty-shipments-message" data-cy="empty-shipments">
    <div
      :id="emptyMessageId"
      class="empty-icon-image icon-shipments-empty-state"
    ></div>
    <div v-if="displayTrackingStatusMsg" class="message-container">
      <h4>No <StatusBadge :status="trackingStatus" /> shipments found!</h4>
      <p>You currently have no shipments that match that event.</p>
    </div>
    <div v-else class="message-container">
      <h4 v-if="displayLifecycleStatusHeading">{{ pastShipmentHeading }}</h4>
      <h4 v-else>No shipments found!</h4>
      <p>{{ emptyShipmentMessage }}</p>
    </div>
    <Button
      v-if="displayWorkflowsBtn"
      data-cy="empty-shipments-action-btn"
      class="p-button p-button-tertiary-outlined"
      label="Automations"
      icon="fas fa-custom-workflow"
      @click="navigateToWorkflows"
    />
  </div>
</template>
<script setup>
import Button from "primevue/button";
import StatusBadge from "@/components/StatusBadge";
import { computed, toRefs } from "vue";
import { Navigator } from "@/services/utils/navigator";

import {
  STATUS_DISPLAY_MAP,
  ERROR_STATUS,
  ROUTING_FAILED_STATUS,
  ROUTED_STATUS,
  COMPLETED,
  EXPIRED,
} from "@/constants";
const props = defineProps({
  trackingStatus: {
    type: String,
    default: "",
  },
  routeStatus: {
    type: String,
    default: "",
  },
  trackingNumber: {
    type: String,
    default: "",
  },
  lifecycleStatus: {
    type: String,
    default: "",
  },
});

const { trackingStatus, trackingNumber, routeStatus, lifecycleStatus } =
  toRefs(props);

const emptyRouteMsgMapping = {
  [STATUS_DISPLAY_MAP[ERROR_STATUS]]:
    "All of your shipments have been successfully imported.",
  [STATUS_DISPLAY_MAP[ROUTING_FAILED_STATUS]]:
    "All of your shipments have been successfully assigned to a campaign.",
  [STATUS_DISPLAY_MAP[ROUTED_STATUS]]:
    "None of your shipments are assigned to a campaign. Please review your automations.",
};

const emptyLifecycleStatusMapping = {
  [COMPLETED]: COMPLETED,
  [EXPIRED]: EXPIRED,
  [`${COMPLETED},${EXPIRED}`]: "past",
};

const { navigateToView } = Navigator();
const navigateToWorkflows = () => {
  return navigateToView("ListNotificationWorkflows");
};

const emptyShipmentMessage = computed(() => {
  if (trackingNumber.value) {
    return `No shipments could be found for tracking number ${trackingNumber.value}.`;
  } else if (lifecycleStatus.value) {
    return `You currently have no ${
      emptyLifecycleStatusMapping[lifecycleStatus.value]
    } shipments.`;
  } else if (routeStatus.value) {
    return emptyRouteMsgMapping[routeStatus.value];
  } else {
    return "Uh oh! You currently have no shipments imported into the system.";
  }
});

const emptyMessageId = computed(() => {
  return lifecycleStatus.value ? "past" : "active";
});

const displayTrackingStatusMsg = computed(() => {
  return trackingStatus.value;
});

const displayLifecycleStatusHeading = computed(() => {
  return lifecycleStatus.value;
});

const pastShipmentHeading = computed(() => {
  return `No ${
    emptyLifecycleStatusMapping[lifecycleStatus.value]
  } shipments found!`;
});

const displayWorkflowsBtn = computed(() => {
  return props.routeStatus === STATUS_DISPLAY_MAP[ROUTED_STATUS];
});
</script>
<style scoped lang="scss">
.empty-shipments-message {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0 40px;
  gap: 16px;

  .icon-shipments-empty-state {
    width: 83.33px;
    height: 96px;
  }

  #past {
    width: 125px;
    height: 144px;
    background-size: cover;
  }

  .message-container {
    align-items: center;
    padding: 0;
    gap: 24px;
    margin: 0 auto;

    h4 {
      font-weight: 600;
      font-size: 17.5px;
      color: #556376;
      text-align: center;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      color: #64748b;
      margin-bottom: 0;
    }
  }
}
</style>
