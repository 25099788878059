import * as zod from "zod";
import {
  isNotEmptyArray,
  isValidString,
} from "@/services/validation-form-schemas/utils";

export const useRouteOptionSchema = () => {
  return zod.object({
    routeOptions: zod.array(
      zod.object({
        // multi select
        triggeredStatuses: zod.union([
          isNotEmptyArray({ errorMessage: "Please select a status" }),
          isValidString({ errorMessage: "Please select a status" }),
        ]),
        scheduledActions: zod.array(
          zod.object({
            actionId: isValidString({
              errorMessage: "Please select a notification",
            }),
          }),
        ),
      }),
    ),
  });
};
