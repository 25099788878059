export const useDataMappingEmptyState = () => {
  const buttons = {
    buttonPrimary: {
      iconClass: "pi pi-plus",
      label: "New Data Mapping",
    },
  };

  const tagline =
    "Empower your email templates with dynamic content by creating data mappings.";

  const initialConfig = {
    heading: "Get started with dynamic content",
    tagline: tagline,
    iconClass: "icon-template-mappings-empty-state small-diamond",
    extraId: "nested-empty-page",
    ...buttons,
  };
  const emptyConfig = {
    heading: "No dynamic content!",
    tagline: tagline,
    iconClass: "icon-template-mappings-empty-state small-diamond",
    extraId: "nested-empty-page",
    ...buttons,
  };

  return { initialConfig, emptyConfig, tagline };
};
