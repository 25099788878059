/*
Increment "e00" for each custom icon
Use class name "fa-{custom-icon-name}" to use icon in template
 */
export const workflowIcon = {
  prefix: "fas",
  iconName: "custom-workflow",
  icon: [
    12,
    14,
    [],
    "e001",
    `M2.82796 4.952C1.46796 4.952 0.355957 3.84 0.355957 2.472C0.355957 1.104 1.46796 0 2.82796 0C4.18796 0 5.29996 
    1.112 5.29996 2.472C5.29996 3.832 4.18796 4.944 2.82796 4.944V4.952ZM2.82796 0.8C1.90796 0.8 1.15596 1.552 1.15596 
    2.472C1.15596 3.392 1.90796 4.144 2.82796 4.144C3.74796 4.144 4.49996 3.392 4.49996 2.472C4.49996 1.552 3.74796 0.8 
    2.82796 0.8Z M11.9 14H6.948V9.056H11.9V14ZM7.748 13.2H11.1V9.856H7.748V13.2Z M11.892 4.37599C11.788 3.03999 10.692 
    1.95999 9.43598 1.95999H6.48398V2.94399H9.52398C10.228 3.00799 10.844 3.67999 10.908 4.45599C10.948 4.95199 10.868 
    5.32799 10.668 5.61599C10.46 5.91199 10.124 6.11199 9.64398 6.23199C9.21198 6.34399 8.41198 6.39199 7.09198 
    6.45599L5.50798 6.52799C3.65198 6.61599 2.78798 6.70399 2.21998 6.85599L2.07598 6.89599C1.37198 7.11999 0.875976 
    7.45599 0.555976 7.91999C0.251976 8.35999 0.0999756 8.92799 0.0999756 9.65599C0.0999756 11.032 1.03598 12.112 
    2.22798 12.112H3.57998V13.584L5.87598 11.616L3.57998 9.64799V11.12H2.13998C1.47598 11.056 1.07598 10.32 1.07598 
    9.64799C1.07598 9.11999 1.16398 8.75999 1.35598 8.47999C1.55598 8.19199 1.87598 7.98399 2.43598 7.80799C2.95598 
    7.65599 3.97998 7.58399 5.34798 7.51199L7.82798 7.39199C8.82798 7.33599 9.36398 7.28799 9.76398 7.19999L9.88398 
    7.16799C10.596 6.99199 11.132 6.64799 11.476 6.15999C11.804 5.68799 11.948 5.07999 11.892 4.35999V4.37599V4.37599Z`,
  ],
};

export const completeLifecycleStatusIcon = {
  prefix: "fas",
  iconName: "custom-completed-lifecycle",
  icon: [
    13.5,
    13.5,
    [],
    "e002",
    `M6.04853 9.04495C6.01524 9.04383 5.98252 9.03592 5.95239 9.02171C5.92226 9.0075 5.89535 8.98728 5.87331 8.9623L4.22025 
    7.30924C4.18873 7.28881 4.16227 7.26148 4.14286 7.22932C4.12345 7.19716 4.1116 7.16101 4.1082 7.1236C4.1048 7.08619 
    4.10995 7.0485 4.12325 7.01337C4.13656 6.97824 4.15767 6.9466 4.18499 6.92082C4.21231 6.89504 4.24513 6.87581 
    4.28098 6.86458C4.31682 6.85334 4.35475 6.8504 4.3919 6.85596C4.42904 6.86153 4.46444 6.87547 4.49542 6.89672C4.52639 
    6.91797 4.55213 6.94597 4.5707 6.97863L6.04853 8.44655L9.51004 4.99495C9.55665 4.96474 9.61204 4.95105 9.66736 
    4.95607C9.72267 4.96109 9.77469 4.98453 9.81509 5.02265C9.85549 5.06076 9.88193 5.11133 9.89016 5.16626C9.89839 
    5.22118 9.88794 5.27729 9.86049 5.32557L6.22376 8.9623C6.20172 8.98728 6.17481 9.0075 6.14468 9.02171C6.11455 
    9.03592 6.08183 9.04383 6.04853 9.04495Z M6.04853 9.43089L6.03556 9.43045C5.94977 9.42756 5.86548 9.40718 5.78784 
    9.37056C5.71468 9.33606 5.64889 9.28781 5.59403 9.2285L3.9696 7.60408C3.9069 7.556 3.85361 7.49653 3.81263 
    7.42864C3.76303 7.34645 3.73274 7.25407 3.72406 7.15847C3.71539 7.06287 3.72854 6.96655 3.76254 6.87678C3.79653 
    6.787 3.85048 6.70613 3.9203 6.64025C3.99013 6.57438 4.07401 6.52523 4.16561 6.49652C4.25721 6.46781 4.35413 
    6.46028 4.44907 6.47451C4.544 6.48874 4.63446 6.52435 4.71362 6.57865C4.77495 6.62073 4.82825 6.67317 4.87122 
    6.73348L6.04801 7.90237L9.26631 4.69329L9.30021 4.67131C9.4193 4.5941 9.56087 4.5591 9.70223 4.57193C9.84358 
    4.58476 9.97654 4.64468 10.0798 4.74208C10.183 4.83948 10.2506 4.96872 10.2716 5.10909C10.2927 5.24946 10.266 
    5.39282 10.1958 5.51621L10.1701 5.56148L6.50304 9.2285C6.44818 9.28781 6.38239 9.33606 6.30923 9.37056C6.23159 
    9.40718 6.1473 9.42756 6.06151 9.43045L6.04853 9.43089ZM6.22376 8.9623L9.86049 5.32557C9.88794 5.27729 9.89839 
    5.22118 9.89016 5.16626C9.88193 5.11133 9.85549 5.06076 9.81509 5.02265C9.77469 4.98453 9.72267 4.96109 9.66736 
    4.95607C9.61204 4.95105 9.55665 4.96474 9.51004 4.99495L6.04853 8.44655L4.5707 6.97863C4.55213 6.94597 4.52639 
    6.91797 4.49542 6.89672C4.46444 6.87547 4.42904 6.86153 4.3919 6.85596C4.35475 6.8504 4.31682 6.85334 4.28098 
    6.86458C4.24513 6.87581 4.21231 6.89504 4.18499 6.92082C4.15767 6.9466 4.13656 6.97824 4.12325 7.01337C4.10995 
    7.0485 4.1048 7.08619 4.1082 7.1236C4.1116 7.16101 4.12345 7.19716 4.14286 7.22932C4.16227 7.26148 4.18873 7.28881 
    4.22025 7.30924L5.87331 8.9623C5.89535 8.98728 5.92226 9.0075 5.95239 9.02171C5.98252 9.03592 6.01524 9.04383 
    6.04853 9.04495C6.08183 9.04383 6.11455 9.03592 6.14468 9.02171C6.17481 9.0075 6.20172 8.98728 6.22376 8.9623Z 
    M7 13.75C5.66498 13.75 4.35994 13.3541 3.2499 12.6124C2.13987 11.8707 1.27471 10.8165 0.763816 9.58312C0.252925 
    8.34971 0.119252 6.99252 0.379702 5.68314C0.640153 4.37377 1.28303 3.17104 2.22703 2.22703C3.17104 1.28303 4.37377 
    0.640153 5.68314 0.379702C6.99252 0.119252 8.34971 0.252925 9.58312 0.763816C10.8165 1.27471 11.8707 2.13987 
    12.6124 3.2499C13.3541 4.35994 13.75 5.66498 13.75 7C13.75 8.79021 13.0388 10.5071 11.773 11.773C10.5071 
    13.0388 8.79021 13.75 7 13.75V13.75ZM7 1.375C5.88748 1.375 4.79995 1.7049 3.87492 2.32299C2.94989 2.94107 
    2.22892 3.81957 1.80318 4.84741C1.37744 5.87524 1.26604 7.00624 1.48309 8.09739C1.70013 9.18853 2.23586 10.1908 
    3.02253 10.9775C3.8092 11.7641 4.81148 12.2999 5.90262 12.5169C6.99376 12.734 8.12476 12.6226 9.1526 
    12.1968C10.1804 11.7711 11.0589 11.0501 11.677 10.1251C12.2951 9.20006 12.625 8.11252 12.625 7C12.625 5.50816 
    12.0324 4.07742 10.9775 3.02253C9.92259 1.96764 8.49185 1.375 7 1.375Z`,
  ],
};
