<template>
  <section data-cy="shipment-overview">
    <ShipmentDetailsContentHeader
      v-if="displayDetailsHeader"
      :shipment-created-timestamp="preparedData.shipmentCreatedTimestamp"
      :latest-event="preparedData.latestEvent"
      :shipment-status="preparedData.shipmentStatus"
      :route-url-config="routeUrlConfig"
      :route-name="preparedData.routeName"
    />
  </section>
  <div class="card shadow">
    <div class="card-body card-body--details" data-cy="content-tabs">
      <Message
        v-if="isImportFailedShipment"
        severity="error"
        :closable="false"
        data-cy="shipment-details-import-failed-msg"
        icon="pi pi-ban"
      >
        <div>
          This shipment failed to import due to errors. See details for more
          information.
        </div>
      </Message>
      <TabView>
        <template #TabPanels>
          <TabPanel v-if="displayNotifications" header="Notifications">
            <ShipmentDetailsContentNotifications
              :scheduled-actions="preparedData.scheduledActionsStatuses"
              :shipment-status="preparedData.shipmentStatus"
              data-cy="shipment-notifications"
              @reevaluate-shipment="reEvaluateShipment"
            />
          </TabPanel>
          <TabPanel v-if="displayTimeline" header="Timeline">
            <ShipmentDetailsContentTimeline
              :shipment-id="shipmentId"
              data-cy="shipment-timeline"
            />
          </TabPanel>
          <TabPanel header="Details">
            <ShipmentDetailsContentDetails
              :extraction-results="preparedData.extractionResults"
              :shipment-status="preparedData.shipmentStatus"
              :shipment-raw-data="preparedData.shipmentRawData"
            />
          </TabPanel>
          <TabPanel v-if="displayDummyEventForm" header="Test Automation">
            <ShipmentDetailsContentTestWorkflow
              :shipment-id="shipmentId"
              data-cy="test-shipment"
              @update-data="updateData"
            />
          </TabPanel>
        </template>
      </TabView>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, reactive, ref, toRefs, watchEffect } from "vue";
import { useRouter } from "vue-router";
import TabPanel from "primevue/tabpanel";
import TabView from "@/components/TabView";
import {
  ERROR_STATUS,
  IMPORTED_STATUS,
  INITIALIZED_STATUS,
  ROUTED_STATUS,
  ROUTING_FAILED_STATUS,
} from "@/constants";
import ShipmentDetailsContentTimeline from "./ShipmentDetailsContentTimeline";
import ShipmentDetailsContentHeader from "@/components/shipments/ShipmentDetailsContentHeader";
import ShipmentDetailsContentNotifications from "@/components/shipments/ShipmentDetailsContentNotifications";
import ShipmentDetailsContentDetails from "@/components/shipments/ShipmentDetailsContentDetails";
import ShipmentDetailsContentTestWorkflow from "@/components/shipments/ShipmentDetailsContentTestWorkflow";
import { useCompanyStateManagement } from "@/queries";
import { useConfigurationRequests } from "@/services/utils/configurationRequests";
import {
  shipmentRerouteErrorMapper,
  shipmentSingleRerouteErrorMap,
} from "@/components/shipments/helpers/errors";
import { useShipmentsToastService } from "@/components/shipments/helpers/toastConfigs";
import Message from "primevue/message";
import ShipmentsApiRequests from "@/components/shipments/helpers/apiRequests";

const props = defineProps({
  shipmentId: {
    type: String,
    default: "",
  },
});
const emit = defineEmits([
  "update:errorMessage",
  "update:trackingNumber",
  "update:lifecycleStatus",
  "update:isPastShipment",
  "updateData",
]);

const { shipmentId } = toRefs(props);
const { preparedData, error } = toRefs(
  await ShipmentsApiRequests.getShipmentDetails(shipmentId.value),
);

const isTestDummyEventsEnabled = ref(false);
onMounted(async () => {
  isTestDummyEventsEnabled.value = await AsyncFetchAndVerifyFlag(
    "create_test_dummy_events",
  );
});

const isPastShipment = preparedData.value.isPastShipment;
const isImportFailedShipment =
  preparedData.value.shipmentStatus === ERROR_STATUS;

const displayDetailsHeader = computed(() => {
  return !isPastShipment && !isImportFailedShipment;
});

const displayNotifications = computed(() => {
  return !isPastShipment && !isImportFailedShipment;
});

const displayTimeline = computed(() => {
  return [ROUTED_STATUS, ROUTING_FAILED_STATUS].includes(
    preparedData.value.shipmentStatus,
  );
});

const { AsyncFetchAndVerifyFlag } = useCompanyStateManagement();
const displayDummyEventForm = computed(() => {
  return (
    isTestDummyEventsEnabled.value && !isPastShipment && !isImportFailedShipment
  );
});

const router = useRouter();
if (
  [INITIALIZED_STATUS, IMPORTED_STATUS].includes(
    preparedData.value.shipmentStatus,
  )
) {
  await router.push({ name: "NotFound" });
}

const routeUrlConfig = reactive({
  name: "ViewNotificationWorkflow",
  params: {
    id: preparedData.value.configId,
    version: preparedData.value.version,
  },
  hash: "#" + preparedData.value.routeLink,
});

const updateData = (event) => {
  preparedData.value = event.value;
  emit("updateData");
};

watchEffect(() => {
  if (error.value) {
    const errorMessage = "Data failed to load";
    emit("update:errorMessage", errorMessage);
  }
  if (preparedData.value) {
    emit("update:trackingNumber", preparedData.value.trackingNumber);
    emit("update:lifecycleStatus", preparedData.value.lifecycleStatus);
    emit("update:isPastShipment", isPastShipment);
  }
});

const { makeRequest } = useConfigurationRequests();
const {
  displaySingleReevaluationStartedToast,
  displaySingleReevaluationFailedToast,
} = useShipmentsToastService();
const reEvaluateShipment = async () => {
  await makeRequest({
    endpoint: async () =>
      await ShipmentsApiRequests.reRouteSingleShipment(shipmentId.value),
    onSuccess: () => {
      displaySingleReevaluationStartedToast();
    },
    errorMapper: (errors) =>
      shipmentRerouteErrorMapper(errors, shipmentSingleRerouteErrorMap, () =>
        displaySingleReevaluationFailedToast(),
      ),
  });
};
</script>
<style scoped lang="scss">
.shadow {
  box-shadow: 0 2px 4px #e4eaf2 !important;
}
.card-body--details {
  padding: 32px;
  border: none;
}

:deep(.p-message) {
  display: flex;
  padding: 10px 32px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;

  .p-message-text {
    font-weight: 600;
  }
}

.p-message-error {
  border-radius: 4px;
  border: 1px solid var(--lara-red-red-100, #fccad5);
  background: var(--lara-red-red-50, #fff2f5);
}
</style>
