/* Component to wrap logic around Ellipsis menus used in Tables. Due to Vue
limitations - events needs to pre-defined. Component is suppose to cover basic
cases - edit, delete, duplicate, which repeats the most. */
<template>
  <Button
    type="button"
    class="p-button p-button-text p-button-icon-only"
    aria-haspopup="true"
    aria-controls="overlay_menu"
    data-cy="more-options-btn"
    @click="toggle"
  >
    <i class="pi pi-ellipsis-v" />
  </Button>
  <Menu
    id="overlay_menu"
    ref="menu"
    class="overlay-menu"
    :model="subMenuItems"
    :popup="true"
    data-cy="more-options-menu"
  />
</template>

<script setup>
import Menu from "primevue/menu";
import Button from "primevue/button";
import { computed, ref, toRefs } from "vue";

const props = defineProps({
  menuItems: {
    type: Array,
    default: () => ["Delete", "Duplicate"],
  },
});
const { menuItems } = toRefs(props);
const emit = defineEmits(["onDelete", "onDuplicate", "onEdit"]);

const MENU_ITEMS_MAP = {
  Delete: {
    label: "Delete",
    icon: "pi pi-trash",
    command: () => emit("onDelete"),
  },
  Duplicate: {
    label: "Duplicate",
    icon: "pi pi-clone",
    command: () => emit("onDuplicate"),
  },
  Edit: {
    label: "Edit",
    icon: "pi pi-pencil",
    command: () => emit("onEdit"),
  },
};

const subMenuItems = computed(() => {
  return menuItems.value.map((item) => MENU_ITEMS_MAP[item]);
});

const menu = ref();
const toggle = (event) => {
  event.stopPropagation();
  menu.value.toggle(event);
};
</script>

<style scoped></style>
