import { handleErrors } from "@/services/utils/errorHandler";
import { useTemplateMappingsToastService } from "@/components/template-data-mapping/helpers/toastConfigs";

const {
  displayTemplateMappingNameAlreadyExists,
  displayTemplateMappingInvalidHandlebar,
  displayTemplateMappingAlreadyInUseToast,
  displayTemplateMappingNotUniqueHandlebar,
} = useTemplateMappingsToastService();

export const templateMappingErrorMap = {
  TEMPLATE_NAME_NOT_UNIQUE: {
    fn: (args) =>
      displayTemplateMappingNameAlreadyExists(
        args.templateMappingName,
        args.action,
      ),
  },
  TEMPLATE_INVALID_HANDLEBAR_NAME: {
    fn: (args) => displayTemplateMappingInvalidHandlebar(args.action),
  },
  TEMPLATE_ALREADY_IN_USE: {
    fn: (args) =>
      displayTemplateMappingAlreadyInUseToast(args.templateMappingName),
  },
  TEMPLATE_HANDLEBAR_NAME_NOT_UNIQUE: {
    fn: (args) => displayTemplateMappingNotUniqueHandlebar(args.action),
  },
};

export const templateMappingErrorMapper = (
  errors,
  { templateMappingName, action },
) => {
  return handleErrors(errors, templateMappingErrorMap, {
    templateMappingName,
    action,
  });
};
