import { useToastService } from "@/services/toast/utils";
import {
  CREATE_ACTION,
  EDIT_ACTION,
} from "@/components/template-data-mapping/helpers/constants";

export const useFiltersToastService = () => {
  const {
    displaySuccessToast,
    displayWarnToast,
    displayErrorToast,
    displayErrorGenericToast,
  } = useToastService();

  const displayFilterNameAlreadyExistsWhenCreate = (filterName) => {
    return displayErrorToast({
      message: `The segment name '${filterName}' already exists. Please choose a unique name and try again.`,
      summary: "Segment Creation Failed",
    });
  };

  const displayFilterNameAlreadyExistsWhenEdit = (filterName) => {
    return displayErrorToast({
      message: `The segment name '${filterName}' already exists. Please choose a unique name and try again.`,
      summary: "Segment Update Failed",
    });
  };

  const displayFilterNameAlreadyExistsToast = (action, filterName) => {
    if (action === EDIT_ACTION)
      return displayFilterNameAlreadyExistsWhenEdit(filterName);
    if (action === CREATE_ACTION)
      return displayFilterNameAlreadyExistsWhenCreate(filterName);
  };

  const displaySelectAllDeleteSuccessToast = () => {
    return displaySuccessToast({
      message: "Multiple segments have been successfully deleted.",
      summary: "Segments Deleted",
    });
  };

  const displaySelectAllDeletePartialToast = () => {
    return displayWarnToast({
      message:
        "Some segments have been successfully deleted, while others could not be deleted.",
      summary: "Segments Partial Deletion",
    });
  };

  const displaySelectAllDeleteFailToast = () => {
    return displayErrorToast({
      message: "Multiple segments have failed to be deleted.",
      summary: "Segments Deletion Failed",
    });
  };

  const displayDeleteSuccessToast = (name) => {
    return displaySuccessToast({
      message: `The segment ${name} has been successfully deleted`,
      summary: "Segment Deleted",
    });
  };

  const displayDeleteInvalidActionToast = (name) => {
    return displayErrorToast({
      message: `Deletion was unsuccessful as the segment ${name} is currently in use.`,
      summary: "Segment Deletion Failed",
    });
  };

  const displayDeleteFailToast = (name) => {
    return displayErrorToast({
      message: `The segment ${name} has failed to delete.`,
      summary: "Segment Deletion Failed",
    });
  };

  const displayDuplicateSuccessToast = (name) => {
    return displaySuccessToast({
      message: `The segment ${name} has been successfully duplicated.`,
      summary: "Segment Duplicated",
    });
  };

  const displayCreateSuccessToast = (name) => {
    return displaySuccessToast({
      message: `The segment ${name} has been created.`,
      summary: "Segment Created",
    });
  };

  const displayEditSuccessToast = (name) => {
    return displaySuccessToast({
      message: `The segment ${name} has been updated.`,
      summary: "Segment Updated",
    });
  };

  const displaySegmentNotFound = (filterName) => {
    return displayErrorToast({
      message: `The segment '${filterName}' that you're attempting to edit may no longer exist. Please refresh your page and try again.`,
      summary: "Segment Not Found",
    });
  };

  const displayWrongDateFormat = () => {
    return displayErrorToast({
      message:
        "Please ensure date follows the format YYYY-MM-DD (e.g., 2030-07-14).",
      summary: "Invalid Date Format",
    });
  };

  const displayWrongDatetimeFormat = () => {
    return displayErrorToast({
      message:
        "Please ensure date follows the format YYYY-MM-DD HH:MM (e.g., 2030-07-14 13:42).",
      summary: "Invalid Date Format",
    });
  };

  return {
    displayFilterNameAlreadyExistsToast,
    displaySelectAllDeleteSuccessToast,
    displaySelectAllDeletePartialToast,
    displaySelectAllDeleteFailToast,
    displayDeleteSuccessToast,
    displayDeleteInvalidActionToast,
    displayDeleteFailToast,
    displayDuplicateSuccessToast,
    displayCreateSuccessToast,
    displayEditSuccessToast,
    displayErrorGenericToast,
    displaySegmentNotFound,
    displayWrongDatetimeFormat,
    displayWrongDateFormat,
  };
};
