<template>
  <Button
    class="p-button p-button-text learn-more-btn"
    data-cy="learn-more-btn"
    @click="openLearnMoreLink"
  >
    Learn More
  </Button>
</template>
<script setup>
import { toRefs } from "vue";
import Button from "primevue/button";
import { openExternalLinkInNewTab } from "@/services/utils/navigator";

const props = defineProps({
  learnMoreLink: {
    default: "",
    type: String,
  },
});

const { learnMoreLink } = toRefs(props);

const openLearnMoreLink = () => {
  openExternalLinkInNewTab(learnMoreLink.value);
};
</script>
