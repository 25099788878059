import { handleErrors } from "@/services/utils/errorHandler";
import { useFiltersToastService } from "@/components/filters/helpers/toastConfigs";

const {
  displayFilterNameAlreadyExistsToast,
  displaySegmentNotFound,
  displayWrongDatetimeFormat,
  displayWrongDateFormat,
} = useFiltersToastService();

const filtersErrorMap = {
  FILTERS_NAME_ALREADY_EXISTS: {
    fn: (args) =>
      displayFilterNameAlreadyExistsToast(args.action, args.filterName),
  },
  FILTERS_NOT_FOUND: {
    fn: (args) => displaySegmentNotFound(args.filterName),
  },
  TEMPLATE_INVALID_DATETIME_FORMAT: {
    fn: () => displayWrongDatetimeFormat(),
  },
  TEMPLATE_INVALID_DATE_FORMAT: {
    fn: () => displayWrongDateFormat(),
  },
};

export const filtersErrorMapper = (errors, { action, filterName }) => {
  return handleErrors(errors, filtersErrorMap, {
    action,
    filterName,
  });
};
