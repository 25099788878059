import * as zod from "zod";

export const isValidString = ({ errorMessage }) => {
  return zod
    .string({ required_error: errorMessage })
    .trim()
    .min(1, { message: errorMessage });
};

export const isString = ({ errorMessage }) => {
  return zod.string({ required_error: errorMessage });
};

export const isNotEmptyArray = ({ errorMessage }) => {
  return zod
    .array(isValidString({ errorMessage }))
    .min(1, { message: errorMessage });
};

export const isValidUrl = ({ errorMessage }) => {
  return zod
    .string({ required_error: errorMessage })
    .trim()
    .url({ message: errorMessage });
};
