<template>
  <MainPage :show-footer="false" main-class="branded-tracking-page">
    <template #body>
      <div class="branded-tracking-container" data-cy="empty-tracking-page">
        <div class="branded-tracking-header">
          <div class="branded-tracking-text">
            <h1>Branded Tracking Page</h1>
            <p>
              Create a seamless post-purchase journey by directing your
              communications to a branded tracking page. Customise the imagery
              to extend brand messaging and drive engagement back to your
              website.
            </p>
          </div>
          <div>
            <ContactSupportBtn />
            <LearnMoreBtn :learn-more-link="learnMoreLink" />
          </div>
        </div>

        <!-- Template Previews -->
        <div class="template-preview-container">
          <div
            v-for="(templatePreview, index) in templatePreviews"
            :key="index"
            class="template-card-wrapper"
            data-cy="template-preview"
          >
            <Card class="preview-card">
              <template #content>
                <div
                  :id="templatePreview.id"
                  class="card-img-header-container"
                ></div>
                <div class="preview-text-container">
                  <div :class="['preview-badge', templatePreview.id]">
                    {{ templatePreview.name }}
                  </div>
                  <p class="m-0">
                    {{ templatePreview.description }}
                  </p>
                </div>
              </template>
              <template #footer>
                <Button
                  class="p-button-outlined"
                  @click="openExternalLinkInNewTab(templatePreview.link)"
                  >View Template</Button
                >
              </template>
            </Card>
          </div>
        </div>
      </div>
      <div class="branded-page-background"></div>
    </template>
  </MainPage>
</template>

<script setup>
import Button from "primevue/button";
import Card from "primevue/card";
import MainPage from "@/components/MainPage.vue";
import ContactSupportBtn from "@/components/ContactSupportBtn";
import LearnMoreBtn from "@/components/LearnMoreBtn";
import { openExternalLinkInNewTab } from "@/services/utils/navigator";

const learnMoreLink =
  "https://support.scurri.co.uk/hc/en-us/articles/18182518420242-Tracking-Page-Requirements";
const templatePreviews = [
  {
    name: "Template 1",
    id: "template-1",
    description:
      "Add images either side of the tracking timeline and an image below it.",
    link: "https://lemark.scurri.com/tracking-page",
  },
  {
    name: "Template 2",
    id: "template-2",
    description:
      "Add images to the left and below the tracking timeline, with order information displayed on the right.",
    link: "https://lemark.scurri.com/tracking-page-2",
  },
];
</script>

<style scoped lang="scss">
.branded-tracking-page {
  display: flex;
  vertical-align: middle;
  flex-direction: column;
  justify-content: center;
}
:deep(.main-page-body) {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.branded-tracking-container {
  display: flex;
  padding: 0 48px 0 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  flex: 0 1 0;
  align-self: stretch;
  z-index: 10;

  .branded-tracking-header {
    display: flex;
    max-width: 720px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    margin: 0 auto;

    .branded-tracking-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;

      p {
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        margin-bottom: 0;
      }
    }

    .contact-learn-more-btns {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  .template-preview-container {
    display: flex;
    padding: 0px 40px;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
  }

  .template-card-wrapper {
    display: flex;
    padding: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 2px 4px 0 #e4eaf2;

    :deep(.preview-card) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      align-self: stretch;

      #template-1 {
        background: url("@/assets/img/template-1-preview.png") no-repeat bottom
          center;
      }

      #template-2 {
        background: url("@/assets/img/template-2-preview.png") no-repeat bottom
          center;
      }

      #template-1,
      #template-2 {
        background-color: #f0f2f5;
        background-size: 320px;
      }

      .card-img-header-container {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-radius: 6px;
        border: 1px solid #e1e5eb;
        height: 200px;
      }

      .preview-text-container {
        display: flex;
        padding: 0 24px;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        align-self: stretch;

        p {
          width: 384px;
          text-align: center;
          font-size: 14px;
        }
      }

      .preview-badge {
        display: flex;
        padding: 8px 12px;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
      }

      .template-1 {
        background: #ccf3ff;
        color: #013b4d;
      }

      .template-2 {
        background: #ead6fd;
        color: #432263;
      }
    }

    :deep(.p-card) {
      box-shadow: none;
    }

    :deep(.p-card-body),
    :deep(.p-card-content) {
      padding: 0;
      gap: 16px;
      display: flex;
      flex-direction: column;
    }

    :deep(.p-card-footer) {
      text-align: center;
      padding: 0;
    }
  }
}
.branded-page-background {
  background-image: url("@/assets/img/branded-tracking-page-background.svg");
  position: absolute;
  bottom: 0;
  z-index: 5;
  flex-shrink: 0;
  width: 2880px;
  height: 400px;
}
</style>
