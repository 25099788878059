<template>
  <StepContainer
    title="Step 2: CSV Upload"
    subtitle="Please upload a sample CSV file that you would like to use as the basis
        for creating a field mapping profile."
    next-button-label="Upload"
    next-button-icon="pi pi-upload"
    display-previous-button
    :is-next-button-disabled="!isNextButtonEnabled"
    @next-button-click="onUpload"
    @prev-button-click="onClickPrev"
  >
    <template #content>
      <FileUploader
        ref="fileUploader"
        v-model:files="localSelectedFiles"
        accept=".csv"
        :is-loading="isLoading"
        @on-upload="onUploadFiles"
      />
    </template>
  </StepContainer>
</template>
<script setup>
import StepContainer from "@/components/stepper/StepContainer.vue";
import { computed, ref, toRefs } from "vue";
import FileUploader from "@/components/FileUploader.vue";

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  selectedFiles: {
    type: Array,
    default: () => [],
  },
});

const { selectedFiles } = toRefs(props);
const localSelectedFiles = computed({
  get() {
    return selectedFiles.value;
  },
  set(newFile) {
    emit("update:selectedFiles", newFile);
  },
});

const emit = defineEmits([
  "prev-page",
  "onFilesUpload",
  "update:selectedFiles",
]);
const onClickPrev = () => emit("prev-page");

const onUploadFiles = async (uploadedFilesContent) => {
  emit("onFilesUpload", uploadedFilesContent);
};

const isNextButtonEnabled = computed(() => {
  return localSelectedFiles.value.length;
});

const fileUploader = ref(null);
const onUpload = () => {
  fileUploader.value.uploadFiles();
};
</script>
