<template>
  <div class="lifecycle-status">
    <span
      v-tooltip.top="{
        value: tooltip,
        id: 'ListShipmentsTableLifeCycleStatusTooltip',
      }"
      ><i :class="icon" data-cy="lifecycle-status-icon"
    /></span>
  </div>
</template>

<script setup>
import { toRefs } from "vue";
import { COMPLETED, EXPIRED } from "@/constants";

const props = defineProps({
  lifecycleStatus: {
    default: "",
    type: String,
  },
});

const { lifecycleStatus } = toRefs(props);
const lifecycleStatusMap = {
  [COMPLETED]: {
    icon: "fas fa-custom-completed-lifecycle",
    tooltip: "Shipment complete.\n" + "No further updates from the carrier.",
  },
  [EXPIRED]: {
    icon: "pi pi-calendar-times",
    tooltip:
      "Shipment expired.\n" +
      "No update received from the carrier in over 30 days.",
  },
};

const { tooltip, icon } = lifecycleStatusMap[lifecycleStatus.value];
</script>
<style lang="scss" scoped>
.lifecycle-status {
  text-align: center;

  .fa-custom-completed-lifecycle {
    color: #1fb256;
  }

  .pi-calendar-times {
    color: #d96341;
  }
}
</style>
