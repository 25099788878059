<template>
  <div class="notification-statement">
    <FormDropdown
      :model-value="routeNotification.actionId"
      :name="notificationFieldName"
      :options="notificationOptions"
      placeholder="Choose notification..."
      :option-disabled="(option) => selectedFieldById(option.id)"
      data-cy="action-select"
      @update:model-value="updateNotification($event)"
    />
    <Button
      v-if="displayBtn"
      class="p-button p-button-text p-button-icon-only"
      icon="pi pi-trash"
      data-cy="delete-notification"
      @click="onNotificationDelete"
    />
  </div>
</template>
<script setup>
import FormDropdown from "@/components/FormDropdown";
import Button from "primevue/button";
import { computed, toRefs } from "vue";
import { useRouteStore } from "@/components/route-configurations/stores/route";
import { storeToRefs } from "pinia";

const routeStore = useRouteStore();
const { deleteNotification, setNotification } = routeStore;
const { routeOptions } = storeToRefs(routeStore);

const props = defineProps({
  routeNotification: {
    type: Object,
    default: () => {},
  },
  notificationOptions: {
    type: Array,
    default: () => [],
  },
  index: {
    type: Number,
    default: 0,
  },
  routeOptionIndex: {
    type: Number,
    default: 0,
  },
  totalCount: {
    type: Number,
    default: 0,
  },
});

const { routeNotification, routeOptionIndex, index } = toRefs(props);

const displayBtn = computed(() => {
  return props.totalCount > 1;
});
const notificationFieldName = computed(
  () =>
    `routeOptions[${props.routeOptionIndex}].scheduledActions[${props.index}].actionId`,
);
const updateNotification = async (val) => {
  routeNotification.value.actionId = val;
  const scheduledAction =
    routeOptions.value[routeOptionIndex.value]?.scheduledActions[index.value];
  if (scheduledAction) {
    setNotification(routeOptionIndex.value, index.value, val);
  }
};

const onNotificationDelete = () => {
  deleteNotification(props.routeOptionIndex, props.index);
};

const selectedFieldById = (id) => {
  return routeOptions.value[props.routeOptionIndex].scheduledActions.find(
    (s) => s.actionId === id,
  );
};
</script>
<style scoped lang="scss">
.notification-statement {
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
  gap: 16px;

  div:first-child {
    width: 100%;
  }
}
</style>
