<template>
  <div>
    <p>
      The following are the tracking events and notifications triggered for this
      shipment. Please note, times are shown as received by the carrier.
    </p>
    <section v-if="errorMessage">
      <Message
        v-if="errorMessage"
        severity="error"
        class="col-lg-12"
        :closable="false"
        >{{ errorMessage }}</Message
      >
    </section>
    <section v-else>
      <ShipmentDetailsContentTimelineEvents :events="events" />
    </section>
  </div>
</template>
<script setup>
import ShipmentDetailsContentTimelineEvents from "./ShipmentDetailsContentTimelineEvents";
import { EVENT_STATUS_DISPLAY_MAP } from "@/constants";
import { ref } from "vue";
import { getNotificationStatusMapDisplay } from "@/components/shipments/helpers/utils";
import {
  getFormatedNaiveDate,
  getFormatedNaiveTime,
} from "@/services/utils/datesFormatter";
import Message from "primevue/message";
import { mapToSentenceCase } from "@/services/utils/utils";
import ShipmentsApiRequests from "@/components/shipments/helpers/apiRequests";

const props = defineProps({
  shipmentId: {
    type: String,
    default: "",
  },
});

const errorMessage = ref("");

const events = ref([]);

const prepareEventsData = (events) => {
  return events
    .map((event) => ({
      date: getFormatedNaiveDate(event.timestamp),
      time: getFormatedNaiveTime(event.timestamp),
      status: EVENT_STATUS_DISPLAY_MAP[event.status],
      description: mapToSentenceCase(event.description),
      location: mapToSentenceCase(event.location),
      actionStatuses: event.actionStatuses.map((action) => ({
        name: action.name,
        status: getNotificationStatusMapDisplay(action.status),
      })),
    }))
    .reverse();
};

try {
  const res = await ShipmentsApiRequests.getTrackingEvents(props.shipmentId);
  events.value = prepareEventsData(res.events);
} catch (error) {
  errorMessage.value = "Tracking events could not be retrieved.";
}
</script>
