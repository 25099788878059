import { useDialog } from "primevue/usedialog";
import InputDialog from "@/components/InputDialog";

const createNewWorkflowConfig = (callback) => {
  return {
    header: "Automation Name",
    message: "Set the automation name below:",
    confirmBtnLabel: "Create Automation",
    confirmBtnClass: "p-button-small p-button-success",
    callback: callback,
  };
};

export const useInputDialog = () => {
  const dialog = useDialog();

  const openDialog = ({
    message,
    callback,
    header,
    confirmBtnLabel,
    confirmBtnClass = "",
    confirmBtnIcon = "",
  }) => {
    dialog.open(InputDialog, {
      props: {
        header: header,
        message: message,
        modal: true,
        confirmBtn: {
          label: confirmBtnLabel,
          class: confirmBtnClass,
          icon: confirmBtnIcon,
          callback: callback,
        },
      },
    });
  };

  const createNewWorkflow = ({ callback }) => {
    return openDialog(createNewWorkflowConfig(callback));
  };

  return {
    openDialog,
    createNewWorkflow,
  };
};
