<template>
  <Panel :header="profileName" class="panel" data-cy="csv-profile-card">
    <template #icons>
      <BasicEllipsisMenu
        :menu-items="['Delete', 'Edit']"
        @on-delete="onDelete"
        @on-edit="onEdit"
      />
    </template>
    <div v-if="isLoading">
      <ProgressSpinner />
    </div>
    <div v-else-if="!areAnyImports">
      <div class="card card-body empty-state-card">
        <span class="empty-state-card__icon-container">
          <i class="pi pi-file-excel empty-state-card__icon" />
        </span>
        <span class="empty-state-card__text">No CSV imports yet!</span>
      </div>
    </div>
    <div v-else class="panel-content">
      <span class="panel-content-row">
        <span class="panel-content-row__header">Times used:</span>
        {{ timesUsed }}
      </span>
      <span class="panel-content-row">
        <span class="panel-content-row__header">Latest import:</span>
        {{ latestDate }}
      </span>
      <span class="panel-content-row">
        <span class="panel-content-row__header">Latest status:</span>
        <StatusBadge size="small" :status="latestStatus" is-rounded>
          {{ latestStatus }}
        </StatusBadge>
      </span>
    </div>
  </Panel>
</template>

<script setup>
import Panel from "primevue/panel";
import BasicEllipsisMenu from "@/components/BasicEllipsisMenu.vue";
import StatusBadge from "@/components/StatusBadge.vue";
import { computed, onMounted, ref, toRefs } from "vue";
import { useConfigurationRequests } from "@/services/utils/configurationRequests";
import { useCsvProfileToastService } from "@/components/csv-import/helpers/toastConfigs.js";
import { getFormatedAwareDate } from "@/services/utils/datesFormatter";
import { useCsvImportConfirmDialog } from "@/components/csv-import/helpers/confirmModalConfigs";
import { useCsvProfileResultsStore } from "@/components/csv-import/stores/csvProfileResults";
import { storeToRefs } from "pinia";
import ProgressSpinner from "primevue/progressspinner";
import { useRouter } from "vue-router";
import CsvApiRequests from "@/components/csv-import/helpers/apiRequests";

const props = defineProps({
  profileName: {
    type: String,
    default: "",
  },
  profileId: {
    type: String,
    default: "",
  },
});
const { profileId, profileName } = toRefs(props);

const emit = defineEmits(["onProfileDelete"]);
const { makeRequest } = useConfigurationRequests();
const { displayCsvProfileDeleteSuccessToast } = useCsvProfileToastService();
const deleteCsvProfile = async (id) => {
  const { error } = await makeRequest({
    endpoint: () => CsvApiRequests.deleteCsvProfile(id),
    onSuccess: () => displayCsvProfileDeleteSuccessToast(profileName.value),
  });
  if (!error.value) emit("onProfileDelete");
};

const areAnyImports = computed(() => !!importsFeedback.value.length);

const { confirmDeleteCsvProfile } = useCsvImportConfirmDialog();
const onDelete = () => {
  confirmDeleteCsvProfile({
    callback: async () => await deleteCsvProfile(profileId.value),
  });
  // Remove store from registry
  detailsStore.$dispose();
};

const detailsStore = useCsvProfileResultsStore(profileId.value);
const { importsFeedback } = storeToRefs(detailsStore);
const { getImportFeedbacks } = detailsStore;

const isLoading = ref(true);
onMounted(async () => {
  isLoading.value = true;
  await getImportFeedbacks();
  isLoading.value = false;
});

const timesUsed = computed(() => {
  return importsFeedback.value.length;
});

const latestImport = computed(() => {
  return importsFeedback.value.reduce(
    (detail1, detail2) =>
      new Date(detail1.uploadDate) > new Date(detail2.uploadDate)
        ? detail1
        : detail2,
    {},
  );
});

const latestDate = computed(() => {
  return getFormatedAwareDate(latestImport.value.uploadDate);
});

const latestStatus = computed(() => {
  if (!latestImport.value.status) return "Unavailable";
  return latestImport.value.status;
});

const router = useRouter();
const onEdit = () => {
  router.push({
    name: "EditCsvProfileStepName",
    params: { id: profileId.value },
  });
};
</script>

<style scoped lang="scss">
.panel {
  min-width: 320px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 6px;

  & :deep(.p-panel-header) {
    background: #f7f8f9;
  }

  &:hover {
    border: 1px solid #e6e6e6;
    cursor: pointer;

    & :deep(.p-panel-header) {
      background: #edf1f5;
    }
  }
}

:deep(.p-panel-header) {
  border: none;
  font-size: 18px;
  line-height: 21px;
  color: #495057;
}

:deep(.p-panel-content) {
  border: none;
  padding: 24px;
}

.panel-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.panel-content-row {
  display: flex;
  gap: 4px;
  align-items: center;
}

.panel-content-row__header {
  font-weight: 600;
  font-size: 14px;
  color: #495057;
}

:deep(.status-badge) {
  padding: 6px 12px;
}

.empty-state-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 4px;

  height: 82px;
  margin: 0;

  border: 1px solid #dadee3;
  border-radius: 4px;
}

.empty-state-card__text {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #495057;
}

.empty-state-card__icon-container {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-state-card__icon {
  color: #6c757d;
  font-size: 2em;
}
</style>
