<template>
  <div>
    <ConfigurationTable
      :key="key"
      :data-rows="filters"
      @delete-rows="onDeleteRows"
      @duplicate-row="onDuplicateRow"
      @edit-row="openFilterDialog"
    />
  </div>
</template>
<script setup>
import { ref, toRefs } from "vue";
import ConfigurationTable from "@/components/ConfigurationTable.vue";
import { useFilterStore } from "@/components/filters/stores/filter";
import { useFiltersConfirmDialog } from "@/components/filters/helpers/confirmModalConfigs";
import { useFilterFormDialog } from "@/components/filters/helpers/modalConfigs";

const store = useFilterStore();
const { deleteFilter, bulkDeleteFilters, duplicateFilter } = store;

const {
  confirmFilterDelete,
  confirmSelectedAllFiltersDelete,
  confirmFilterDuplicate,
} = useFiltersConfirmDialog();

const props = defineProps({
  filters: {
    type: Array,
    default: () => [],
  },
});

const key = ref(0);

const { filters } = toRefs(props);

const { openFilterFormDialog } = useFilterFormDialog();

const openFilterDialog = (row) => {
  openFilterFormDialog({ filterId: row.id });
};

const onDeleteRows = (filters) => {
  if (filters.length === 1)
    return confirmFilterDelete({
      callback: async () => await deleteFilter(filters[0]),
    });
  confirmSelectedAllFiltersDelete({
    callback: async () => await bulkDeleteFilters(filters),
  });
};

const onDuplicateRow = (row) => {
  return confirmFilterDuplicate({
    callback: () => duplicateFilter(row),
  });
};
</script>
