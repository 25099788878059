import ImageConfigForm from "@/components/tracking-pages/ImageConfigForm";
import TrackingPagePreview from "@/components/tracking-pages/TrackingPagePreview";
import { useGlobalDialogService } from "@/services/modals/base";

export const useImageConfigFormDialog = () => {
  const { openDialog } = useGlobalDialogService();

  const openImageConfigFormDialog = ({ imageType, currentImage, onClose }) => {
    return openDialog({
      component: ImageConfigForm,
      dialogProps: {
        modal: true,
        appendTo: "self",
        dismissableMask: false,
        showHeader: false,
        style: {
          width: "720px",
          height: "536px",
          color: "#212529",
          padding: "0",
        },
      },
      componentData: {
        imageType: imageType,
        currentImage: currentImage,
      },
      onClose: (options) => {
        onClose?.(options.data);
      },
    });
  };
  return { openImageConfigFormDialog };
};

export const useTrackingPagePreviewDialog = () => {
  const { openDialog } = useGlobalDialogService();

  const openTrackingPagePreviewDialog = ({ onClose }) => {
    return openDialog({
      component: TrackingPagePreview,
      dialogProps: {
        modal: true,
        appendTo: "self",
        dismissableMask: true,
        showHeader: false,
        style: {
          width: "95%",
          display: "flex",
          height: "800px",
          gap: "24px",
          background: "rgb(242, 242, 242) !important",
          "flex-direction": "column",
          "max-width": "1440px",
        },
        contentStyle: {
          background: "#f2f2f2",
          padding: "0",
          "overflow-x": "hidden",
          height: "100%",
        },
      },
      onClose: (options) => {
        onClose?.(options.data);
      },
    });
  };
  return { openTrackingPagePreviewDialog };
};
