import { defineStore } from "pinia";
import { ref } from "vue";
import CsvApiRequests from "@/components/csv-import/helpers/apiRequests";

export const useCsvProfileResultsStore = (profileId) =>
  defineStore(`csvProfileResults/${profileId}`, () => {
    // State
    const importsFeedback = ref([]);

    // Actions
    const getImportFeedbacks = async () => {
      importsFeedback.value =
        await CsvApiRequests.getCsvProfileFeedback(profileId);
    };

    return { importsFeedback, getImportFeedbacks };
  })();
