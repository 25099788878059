<template>
  <ProgressSpinner v-show="displaySpinner" />
  <form v-show="!displaySpinner" data-cy="filter-form" @submit="onSubmit">
    <FormInputText
      name="filterName"
      label="Segment Name:"
      placeholder="Segment name here..."
      data-cy="filter-name"
    />
    <div class="card-body p-statementset">
      <div class="p-fieldset">
        <div class="statement-rows">
          <label>Add filters below:</label>
          <FilterRow
            v-for="(filterStatement, idx) in filterStatements"
            :id="`filter-row-${idx}`"
            :key="filterStatement.key"
            :statement="filterStatement.value"
            :index="idx"
            :can-be-deleted="withDeleteOption"
            :setups="filterSetups"
            @delete-statement="removeStatement"
          />
        </div>
        <Button
          class="p-button p-button-tertiary-outlined add-statement"
          data-cy="add-row-btn"
          @click="createStatement"
          ><i class="pi pi-plus" />Add Filter</Button
        >
      </div>
    </div>
    <div class="d-flex justify-content-end action-buttons">
      <Button
        class="p-button p-button-text"
        data-cy="cancel-btn"
        label="Cancel"
        :disabled="isSubmitting"
        @click="onCloseModal"
      />
      <Button
        class="p-button-primary"
        data-cy="submit-btn"
        type="submit"
        :disabled="isSubmitting"
      >
        {{ submitBtnText }}
      </Button>
    </div>
  </form>
</template>

<script setup>
import Button from "primevue/button";
import FilterRow from "@/components/filters/FilterRow";
import { computed, inject, onMounted, ref } from "vue";
import { useConfigurationRequests } from "@/services/utils/configurationRequests";
import ProgressSpinner from "primevue/progressspinner";
import { toTypedSchema } from "@vee-validate/zod";
import { useFieldArray, useForm } from "vee-validate";
import FormInputText from "@/components/FormInputText";
import { useFilterSchema } from "@/components/filters/schemas/filterSchema";
import { useFilterStore } from "@/components/filters/stores/filter";
import { useFiltersConfirmDialog } from "@/components/filters/helpers/confirmModalConfigs";
import { CREATE, DIALOG_RESULTS, EDIT } from "@/constants";
import { useValueSelectorGetter } from "@/components/filters/helpers/utils";
import { useCompanyStateManagement } from "@/queries";
import FilterApiRequests from "@/components/filters/helpers/apiRequests";

const { makeRequest, isLoading } = useConfigurationRequests();

const makeInitialStatement = {
  propertyId: "",
  operatorId: "",
  inputValue: "",
  selectedValue: "",
  multiSelectedValues: [],
  valueSelector: "",
};

const filterSetups = ref([]);
const errorMessage = ref("");

const dialogRef = inject("dialogRef");
const filterId = dialogRef.value.data.filterId;

const store = useFilterStore();
const { isFilterNameValid, createFilter, updateFilter } = store;

const validationSchema = toTypedSchema(
  useFilterSchema(filterId, isFilterNameValid),
);
const { handleSubmit, isSubmitting, resetForm, meta } = useForm({
  validationSchema,
  initialValues: {
    filterName: "",
    statements: [makeInitialStatement],
  },
});
const {
  remove: deleteFilterStatement,
  push: addFilterStatement,
  fields: filterStatements,
} = useFieldArray("statements");

const { confirmCancelFilterForm } = useFiltersConfirmDialog();

const createStatement = () => {
  addFilterStatement(makeInitialStatement);
};

const removeStatement = (idx) => {
  deleteFilterStatement(idx);
};

const displaySpinner = computed(() => {
  return isLoading.value;
});

const withDeleteOption = computed(() => {
  return filterStatements.value.length > 1;
});

const submitBtnText = computed(() => {
  return filterId ? "Update Segment" : "Create Segment";
});

const { invalidateCompanyState } = useCompanyStateManagement();
const onSubmit = handleSubmit(async (values) => {
  const filterData = setupFilter(values);
  const result = filterId
    ? await updateCurFilter(filterData)
    : await createNewFilter(filterData);
  if (result !== DIALOG_RESULTS.FAILED) {
    dialogRef.value.close(result);
    await invalidateCompanyState();
  }
});

const setupFilter = (submitData) => {
  return {
    id: filterId,
    name: submitData.filterName,
    statements: submitData.statements,
  };
};

const getFilterSetups = async () => {
  const { error, response } = await makeRequest({
    endpoint: () => FilterApiRequests.getPropertyFilterSetups(),
  });
  if (error.value) errorMessage.value = "Failed to load.";
  else if (response) filterSetups.value = response.value;
};

const { getValueSelectorFor } = useValueSelectorGetter();
const makeFormData = (filterResponse) => {
  const newStatements = filterResponse.statements.map((statement) => {
    const valueSelector = getValueSelectorFor(
      filterSetups.value,
      statement.propertyId,
      statement.operatorId,
    );

    const inputValue = valueSelector === "input" ? statement.value : "";
    const selectedValue = valueSelector === "select" ? statement.value : "";
    const multiSelectedValues =
      valueSelector === "multiSelect" ? statement.selectedValues : [];
    return {
      propertyId: statement.propertyId,
      operatorId: statement.operatorId,
      inputValue,
      selectedValue,
      multiSelectedValues,
      valueSelector,
    };
  });
  return {
    filterName: filterResponse.name,
    statements: newStatements,
  };
};

const getFilterDetails = async () => {
  const { error, response } = await makeRequest({
    endpoint: () => FilterApiRequests.getFilterById(filterId),
  });
  if (error.value) errorMessage.value = "Failed to load.";
  else if (response) {
    resetForm({ values: makeFormData(response.value, filterSetups.value) });
  }
};

const updateCurFilter = async (filterData) => {
  return await updateFilter(filterData);
};

const createNewFilter = async (filterData) => {
  return await createFilter(filterData);
};

onMounted(async () => {
  await (async () => {
    await getFilterSetups();
  })();
  if (filterId) {
    await getFilterDetails();
  }
});

const onCloseModal = () => {
  if (meta.value.dirty) {
    confirmCancelFilterForm({
      callback: () => dialogRef.value.close(),
      action: filterId ? EDIT : CREATE,
    });
  } else {
    dialogRef.value.close();
  }
};
</script>
<style scoped>
form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.statement-rows {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
</style>
