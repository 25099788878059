<template>
  <Button
    ref="copyBtn"
    v-tooltip.top="{
      value: copyButtonText,
      showDelay: 300,
      hideDelay: 300,
      id: 'CopyButtonTooltip',
    }"
    :class="btnClass"
    data-cy="copy-button"
    @click="copyValAndDisplayTooltip"
  >
    <i :class="btnIcon" />
  </Button>
</template>
<script setup>
import Button from "primevue/button";

const props = defineProps({
  itemToCopy: {
    type: String,
    required: true,
  },
  btnClass: {
    type: String,
    default: "p-button p-button-tertiary-outlined",
  },
  btnIcon: {
    type: String,
    default: "pi pi-link",
  },
  copyText: {
    type: String,
    required: true,
  },
  copiedText: {
    type: String,
    required: true,
  },
});

const { itemToCopy, btnClass, btnIcon, copyText, copiedText } = toRefs(props);

const copyBtn = ref(null);
import { useCopiedState } from "@/services/utils/utils";
import { ref, toRefs } from "vue";

const { copyButtonText, changeStateOnClick } = useCopiedState(
  copyText.value,
  copiedText.value,
);

const copyValAndDisplayTooltip = async () => {
  await navigator.clipboard.writeText(itemToCopy.value);
  changeStateOnClick();
  const mouseEnterEvent = new Event("mouseenter");
  copyBtn.value.$el.dispatchEvent(mouseEnterEvent);
};
</script>
