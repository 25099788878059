<template>
  <div class="d-flex">
    <div class="main-policy-content">
      <div class="policy-content">
        <slot name="policy-text"></slot>
      </div>
    </div>
  </div>
</template>
<style scoped>
.main-policy-content {
  width: 100%;
  max-width: 1024px;
  margin: 50px auto;
}

.policy-content {
  width: 80%;
  max-width: 800px;
  height: auto;
  margin-left: 10%;
  font-size: 18px;
}

:deep(.policy-content h2, .policy-content h3) {
  color: #25d366;
}

:deep(.policy-content h4) {
  color: #1f2a4e;
}

:deep(.policy-content h1),
:deep(.policy-content h2),
:deep(.policy-content h3),
:deep(.policy-content h4) {
  font-weight: bold;
  padding-bottom: 15px;
  padding-top: 15px;
}

:deep(.policy-content ul li) {
  list-style-type: disc;
  line-height: 1.6;
}

:deep(.policy-content p) {
  line-height: 1.6;
  margin-bottom: 30px;
}
</style>
