export const useNotificationsEmptyState = () => {
  const buttons = {
    buttonPrimary: {
      iconClass: "pi pi-plus",
      label: "New Email Notification",
    },
    buttonSecondary: {
      iconClass: "pi pi-bolt",
      label: "Dynamic Content",
    },
  };

  const emptyConfigTagline =
    "Map your email templates to create personalised customer messaging.";

  const initialConfig = {
    heading: "Create your first notification",
    tagline: emptyConfigTagline,
    iconClass: "icon-action-empty-state",
    ...buttons,
  };

  const emptyConfig = {
    heading: "Oops! You have no notifications",
    tagline: emptyConfigTagline,
    iconClass: "icon-action-empty-state",
    ...buttons,
  };

  return { initialConfig, emptyConfig };
};
