import { httpRequest } from "@/services/apiRequests";

class CsvApiRequests {
  static async getCsvProfileFeedback(profileId) {
    const { data } = await httpRequest.get(
      `csv-import/profiles/${profileId}/results/`,
    );

    const transformRowFeedback = (rowFeedback) => {
      // We receive data from the backend in format:
      //   "2": {
      //     "Carrier": "Required field. No value found.",
      //     "Email Address": "Required field. No value found."
      //    }
      // Which is hard to parse for PrimeVue Datatable.
      // To make it easier to parse, we map that to:
      //   [ { indexRow: 2, attributeName: "Carrier", error: "Required field. No value found."},
      //     { indexRow: 2, attributeName: "Email Address", error: "Required field. No value found."} ]
      const results = [];

      Object.entries(rowFeedback).forEach(([indexRow, errors]) => {
        Object.entries(errors).forEach(([attributeName, error]) => {
          results.push({ indexRow, attributeName, error });
        });
      });
      return results;
    };

    return data.map((importFeedback) => {
      return {
        id: importFeedback.id,
        status: importFeedback.status,
        uploadedBy: importFeedback.uploaded_by,
        filename: importFeedback.filename,
        rowFeedback: transformRowFeedback(importFeedback.row_feedback),
        uploadDate: importFeedback.upload_date,
        totalAdded: importFeedback.total_added,
        totalFailed: importFeedback.total_failed,
      };
    });
  }

  static async getShipmentAttributesCsvImport() {
    const { data } = await httpRequest.get("csv-import/shipment-attributes/");
    return data.map((shipmentAttribute) => ({
      id: shipmentAttribute.id,
      name: shipmentAttribute.name,
      required: shipmentAttribute.required,
      recommended: shipmentAttribute.recommended,
      helpText: shipmentAttribute.text_helper,
    }));
  }

  static async deleteCsvProfile(profileId) {
    const { data } = await httpRequest.delete(
      `csv-import/profiles/${profileId}/`,
    );
    return data;
  }

  static async getCsvProfiles() {
    const { data } = await httpRequest.get("csv-import/profiles/");
    return data;
  }

  static async mapCsvProfile({ profileName, mappings, sample }) {
    return {
      name: profileName,
      shipment_mappings: mappings.map((attr) => {
        return {
          attribute_id: attr.id,
          column_index: attr.index,
          column_name: attr.name,
        };
      }),
      sample: sample,
    };
  }

  static async editCsvImportProfile({
    profileId,
    profileName,
    mappings,
    sample,
  }) {
    const csvProfile = await this.mapCsvProfile({
      profileName,
      mappings,
      sample,
    });
    const { data } = await httpRequest.put(
      `csv-import/profiles/${profileId}/`,
      csvProfile,
    );
    return data;
  }

  static async createCsvImportProfile({ profileName, mappings, sample }) {
    const csvProfile = await this.mapCsvProfile({
      profileName,
      mappings,
      sample,
    });
    const { data } = await httpRequest.post("csv-import/profiles/", csvProfile);
    return data;
  }

  static async getCsvProfileDetails(profileId) {
    const { data } = await httpRequest.get(`csv-import/profiles/${profileId}/`);
    return {
      id: data.id,
      name: data.name,
      sample: data.sample,
      shipmentMappings: data.shipment_mappings.map((m) => {
        return {
          index: m.column_index,
          name: m.column_name,
          id: m.attribute_id,
        };
      }),
    };
  }

  static async importCsvShipment({ skipFirstRow, csvFile, profileName }) {
    const form = new FormData();
    form.append("file", csvFile);
    form.append("skip_first_row", skipFirstRow);
    await httpRequest.post(`csv-import/import/${profileName}/`, form, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
}

export default CsvApiRequests;
