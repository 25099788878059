<template>
  <div class="image-config-container" data-cy="image-config">
    <div
      v-if="displayImageUpdatedMsg"
      class="image-info"
      data-cy="image-edited-msg"
    >
      <i class="image-config-icon pi pi-check-circle" />
      <p class="image-edited-label">Images edited!</p>
    </div>

    <div v-else class="image-info" data-cy="image-unchanged-msg">
      <i class="image-config-icon pi pi-image" />
    </div>

    <Button class="p-button-tertiary-outlined" @click="openDialog"
      ><i class="pi pi-pencil"></i>Edit Images</Button
    >
  </div>
</template>
<script setup>
import Button from "primevue/button";
import { useImageConfigFormDialog } from "@/components/tracking-pages/helpers/modalConfigs";
import { ref, toRefs } from "vue";
const { openImageConfigFormDialog } = useImageConfigFormDialog();
const openDialog = () => {
  openImageConfigFormDialog({
    imageType: imageType.value,
    currentImage: image.value,
    onClose: updateDisplayImageUpdatedMsg,
  });
};
const props = defineProps({
  imageType: {
    type: String,
    default: "",
  },
  image: {
    type: Object,
    default: () => {},
  },
});
const { imageType, image } = toRefs(props);
const displayImageUpdatedMsg = ref(false);

const updateDisplayImageUpdatedMsg = (imageUpdated) => {
  if (imageUpdated) {
    displayImageUpdatedMsg.value = imageUpdated;
  }
};
</script>
<style scoped lang="scss">
.image-config-container {
  display: flex;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;

  &:has(.image-edited-label) {
    gap: 16px;
  }

  .image-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    p {
      color: #212121;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      margin-bottom: 0;
    }
  }
  .image-config-icon {
    font-size: 60px;
  }

  .pi-image {
    color: #bcc3cd;
  }
}
</style>
