import { inject, watch } from "vue";
import { useRoute } from "vue-router";

export const useGlobalDialogService = () => {
  const openDialog = inject("openDialog");

  const closeOnRouteChange = () => {
    const dialogRef = inject("dialogRef");
    const route = useRoute();
    watch(
      () => route.fullPath,
      async () => {
        dialogRef.value.close();
      },
    );
  };

  return { openDialog, closeOnRouteChange };
};
