<template>
  <form @submit="onSubmit">
    <div class="card shadow">
      <div class="card-body action-form">
        <FormInputText
          v-model="configurationName"
          name="configurationName"
          label="Email notification name:"
          placeholder="Email notification name here..."
          data-cy="action-name"
        />
        <div class="column">
          <span class="label">Email integration:</span>
          <div class="integration-options">
            <Button
              v-tooltip.top="'SendGrid'"
              class="integration-button active-button"
            >
              <div class="integration-options--option">
                <img src="@/assets/img/sendgrid-logo.svg" alt="_sendgrid" />
                <div class="circle"></div>
              </div>
            </Button>
          </div>
        </div>
        <div class="column">
          <label for="email" class="label">Dynamic content:</label>
          <div class="template-mapping">
            <FormDropdown
              v-model="selectedTemplateMapping"
              placeholder="Choose data mapping"
              :options="templateMappings"
              name="selectedTemplateMapping"
              class="template-mapping-dropdown"
              data-cy="template-mapping"
            />
            <Button
              class="p-button p-button-outlined template-mapping__template-button"
              @click="
                openTemplateMappingDialog({ onClose: updateTemplateMapping })
              "
            >
              <i class="pi pi-plus" /> New Data Mapping
            </Button>
          </div>
        </div>
        <keep-alive>
          <Suspense>
            <component
              :is="integrationFields[selectedIntegration]"
              :integration-details="integrationDetails"
            />
            <template #fallback>
              <ProgressSpinner />
            </template>
          </Suspense>
        </keep-alive>
      </div>
    </div>
    <Button
      class="p-button left-position header-btn exit-btn"
      data-cy="exit-btn"
      @click="navigateToView('NotificationsPage')"
    >
      <i class="pi pi-chevron-left" /> Back to Emails
    </Button>
    <Button class="p-button right-position" data-cy="submit-btn" type="submit">
      {{ submitButtonText() }}
    </Button>
  </form>
</template>
<script setup>
import Button from "primevue/button";
import { onMounted, ref } from "vue";
import { Navigator } from "@/services/utils/navigator";
import { useForm } from "vee-validate";
import { toTypedSchema } from "@vee-validate/zod";
import * as zod from "zod";
import NotificationsFormSendgrid from "@/components/notifications/NotificationsFormSendgrid";
import ProgressSpinner from "primevue/progressspinner";
import { getNotificationSchema } from "@/services/validation-form-schemas/notificationSchema";
import { useNotificationToastService } from "@/services/toast/notifications";
import FormDropdown from "@/components/FormDropdown.vue";
import { useTemplateMappingsStore } from "@/components//template-data-mapping/stores/templateMappings.js";
import { storeToRefs } from "pinia";
import { useConfigurationRequests } from "@/services/utils/configurationRequests";
import { CREATE, DIALOG_RESULTS, EDIT } from "@/constants";
import { useTemplateMappingDialog } from "@/components/template-data-mapping/helpers/modalConfigs";
import { onBeforeRouteLeave } from "vue-router";
import { useNotificationsConfirmDialog } from "@/components/notifications/helpers/confirmModalConfigs";
import FormInputText from "@/components/FormInputText.vue";
import { useCompanyStateManagement } from "@/queries";
import NotificationsApiRequests from "@/components/notifications/helpers/apiRequests";

const props = defineProps({
  actionId: {
    default: "",
    type: String,
  },
  loading: {
    default: false,
    type: Boolean,
  },
});

const emit = defineEmits(["update:loading"]);

const setLoading = (value) => {
  emit("update:loading", value);
};

const integrationDetails = ref({});
const configurationName = ref("");
const selectedTemplateMapping = ref("");

const dataHasChanged = ref(false);
const getSendgridAction = async () => {
  const { name, templateDataMappingId, ..._integrationDetails } =
    await NotificationsApiRequests.getSendgridActionById(props.actionId);
  integrationDetails.value = _integrationDetails;
  resetForm({
    values: {
      configurationName: name,
      selectedTemplateMapping: templateDataMappingId,
      integrationDetails: _integrationDetails,
    },
  });
};

const store = useTemplateMappingsStore();
const { templateMappings, lastAddedMapping } = storeToRefs(store);
const { getTemplateMappings } = store;
onMounted(async () => {
  lastAddedMapping.value = "";
  await getTemplateMappings();
  if (props.actionId) {
    setLoading(true);
    await getSendgridAction();
    setLoading(false);
  }
});

const integrationFields = {
  sendgrid: NotificationsFormSendgrid,
};
const selectedIntegration = ref("sendgrid");

const { schema } = getNotificationSchema(selectedIntegration.value);
const validationSchema = toTypedSchema(zod.object(schema));
const { handleSubmit, meta, resetForm } = useForm({
  validationSchema,
});
const { invalidateCompanyState } = useCompanyStateManagement();
const onSubmit = handleSubmit(async (values) => {
  props.actionId ? await updateAction(values) : await createAction(values);
  await invalidateCompanyState();
});

const { navigateToView } = Navigator();
const submitButtonText = () => {
  return props.actionId
    ? "Update Email Notification"
    : "Create Email Notification";
};

const {
  displayCreateSuccessToast,
  displayEditSuccessToast,
  displayErrorGenericToast,
  displayCreateNameAlreadyExists,
  displayEditNameAlreadyExists,
} = useNotificationToastService();
const { makeRequest } = useConfigurationRequests();

const isSubmitting = ref(false);
const setAsSubmitting = () => (isSubmitting.value = true);
const cancelSubmitting = () => (isSubmitting.value = false);

const createAction = async (submittedValues) => {
  const { error } = await makeRequest({
    endpoint: () =>
      NotificationsApiRequests.createSendgridAction(
        setupAction(submittedValues),
      ),
    onSuccess: () => displayCreateSuccessToast(configurationName.value),
    onInvalidInput: () =>
      displayCreateNameAlreadyExists(configurationName.value),
    onFail: () => displayErrorGenericToast(),
  });

  if (!error.value) {
    setAsSubmitting();
    await navigateToView("NotificationsPage");
  }
};
const updateAction = async (submittedValues) => {
  const { error } = await makeRequest({
    endpoint: () =>
      NotificationsApiRequests.updateSendgridAction(
        setupAction(submittedValues),
      ),
    onSuccess: () => displayEditSuccessToast(configurationName.value),
    onInvalidInput: () => displayEditNameAlreadyExists(configurationName.value),
    onFail: () => displayErrorGenericToast(),
  });

  if (!error.value) {
    setAsSubmitting();
    await navigateToView("NotificationsPage");
  }
};

const updateTemplateMapping = async (result) => {
  if (result === DIALOG_RESULTS.CREATED) {
    selectedTemplateMapping.value = lastAddedMapping.value.id;
  }
};

const { openTemplateMappingDialog } = useTemplateMappingDialog();
const setupAction = (submittedValues) => {
  return {
    id: props.actionId,
    name: submittedValues.configurationName,
    templateDataMappingId: submittedValues.selectedTemplateMapping,
    sendgridConfigId: submittedValues.integrationDetails.selectedConfiguration,
    sendgridTemplateId: submittedValues.integrationDetails.selectedTemplates,
  };
};

const { confirmCancelNotificationForm } = useNotificationsConfirmDialog();
onBeforeRouteLeave((to, from, next) => {
  dataHasChanged.value = meta.value.dirty;
  if (dataHasChanged.value && !isSubmitting.value) {
    confirmCancelNotificationForm({
      callback: () => next(),
      action: props.actionId ? EDIT : CREATE,
    });
    cancelSubmitting();
  } else {
    next();
  }
});
</script>

<style scoped lang="scss">
:deep(.column) {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.action-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
}

:deep(.label) {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #212121;
}

:deep(.integration-button) {
  padding: 16px 24px;
  width: 208px;
  height: 54px;
  background: #ffffff;
  border: 1px solid #ced4da !important;
  border-radius: 6px;

  &:hover {
    background: #fafafa;
    & > div.integration-options--option > div.circle {
      border: 2px solid #1f2a4e;
    }
  }
}

:deep(.active-button) {
  border: 1px solid #26d466 !important;
  background: #fafafa !important;
  & > div.integration-options--option > div.circle {
    border: 5px solid #26d466 !important;
    border-radius: 11px !important;
  }
}

.integration-options {
  display: flex;
  flex-direction: row;
  gap: 16px;
  &--option {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: center;
  }
}

.circle {
  width: 22px;
  height: 22px;
  background: #ffffff;
  border: 2px solid #ced4da;
  border-radius: 11px;
}

.subheader {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #212121;
}

.template-mapping {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.template-mapping__template-button {
  min-width: fit-content;
  height: 42px;
}

.template-mapping-dropdown {
  flex: 1;
}

:deep(.card) {
  margin-bottom: 24px;
}

.exit-btn {
  height: 40px;
}
</style>
