import ConfirmPhraseDialog from "@/components/ConfirmPhraseDialog";
import { useGlobalDialogService } from "@/services/modals/base";

const confirmWorkflowDeletionConfig = (callback, isActive) => {
  let message = isActive
    ? "Deleting this automation will prevent shipments from being assigned a campaign."
    : "Deleting this automation will permanently remove all associated campaigns.";
  message += " This action cannot be undone. Are you sure you want to proceed?";
  const header = isActive ? "Delete Active Automation?" : "Delete Automation?";
  return {
    header,
    message,
    confirmPhrase: "DELETE AUTOMATION",
    confirmBtnLabel: "Delete",
    confirmBtnClass: "p-button-small p-button-danger",
    confirmBtnIcon: "pi pi-trash",
    callback: callback,
  };
};

const confirmWorkflowDeactivationConfig = (callback) => {
  return {
    header: "Deactivate Automation?",
    message: `Deactivating this automation will prevent shipments from being assigned a campaign.
  Are you sure you wish to proceed?`,
    confirmPhrase: "DEACTIVATE AUTOMATION",
    confirmBtnLabel: "Deactivate",
    confirmBtnClass: "p-button-small p-button-danger",
    callback: callback,
  };
};

export const useConfirmPhraseDialog = () => {
  const { openDialog } = useGlobalDialogService();

  const openConfirmPhraseDialog = ({
    message,
    callback,
    header,
    confirmPhrase,
    confirmBtnLabel,
    confirmBtnClass = "p-button-success",
    confirmBtnIcon = "",
  }) => {
    return openDialog({
      component: ConfirmPhraseDialog,
      dialogProps: {
        header: header,
        message: message,
        confirmPhrase: confirmPhrase,
        modal: true,
        confirmBtn: {
          label: confirmBtnLabel,
          class: confirmBtnClass,
          icon: confirmBtnIcon,
          callback: callback,
        },
      },
    });
  };

  const confirmWorkflowDeletion = ({ callback, isActive }) => {
    return openConfirmPhraseDialog(
      confirmWorkflowDeletionConfig(callback, isActive),
    );
  };

  const confirmWorkflowDeactivation = ({ callback }) => {
    return openConfirmPhraseDialog(confirmWorkflowDeactivationConfig(callback));
  };

  return {
    openConfirmPhraseDialog,
    confirmWorkflowDeletion,
    confirmWorkflowDeactivation,
  };
};
