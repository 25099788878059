export const useFiltersEmptyState = () => {
  const buttons = {
    buttonPrimary: {
      iconClass: "pi pi-plus",
      label: "New Segment",
    },
  };

  const filtersTagline =
    "Create segments to target customers by location, carrier, order value, and more.";

  const initialConfig = {
    heading: "Create your first segment",
    tagline: filtersTagline,
    iconClass: "icon-diamond-filter small-diamond",
    extraId: "nested-empty-page",
    ...buttons,
  };

  const emptyConfig = {
    heading: "No segments!",
    tagline: filtersTagline,
    iconClass: "icon-diamond-filter-empty small-diamond",
    extraId: "nested-empty-page",
    ...buttons,
  };
  return { initialConfig, emptyConfig, filtersTagline };
};
