<template>
  <div class="filter-tab">
    <button :class="buttonClass" class="rounded-pill" @click="toggleStatus">
      <div class="filter-type">
        <span class="filter-icon">
          <i :class="iconClass"></i>
        </span>
        <span class="filter-text">
          <span class="text-nowrap me-1 filter-label">{{ label }}:</span>
          <span
            class="text-nowrap fw-bold filter-num"
            data-cy="filter-tab-value"
          >
            {{ formatNumberWithComma(totalCount) }}
          </span>
        </span>
      </div>
    </button>
  </div>
</template>

<script setup>
import {
  ERROR_STATUS,
  ROUTING_FAILED_STATUS,
  ROUTED_STATUS,
} from "@/constants";
import { computed, toRefs } from "vue";
import { formatNumberWithComma } from "@/services/utils/utils";

const props = defineProps({
  label: {
    required: true,
    type: String,
  },
  filter: {
    default: "",
    type: String,
  },
  icon: {
    default: "",
    type: String,
  },
  isActive: {
    default: false,
    type: Boolean,
  },
  totalCount: {
    default: 0,
    type: Number,
  },
});

const { label, filter, icon, isActive, totalCount } = toRefs(props);
const emit = defineEmits(["toggleStatus"]);

const iconClass = computed(() => {
  return [
    "my-auto",
    icon.value ? icon.value : "",
    isActive.value
      ? { "text-light": filter.value === ROUTING_FAILED_STATUS }
      : {
          "text-danger": filter.value === ERROR_STATUS,
          "text-warning": filter.value === ROUTING_FAILED_STATUS,
          "text-success": filter.value === ROUTED_STATUS,
        },
  ];
});

const buttonActiveClass = computed(() => {
  return [
    {
      "btn-danger": filter.value === ERROR_STATUS,
      "btn-warning": filter.value === ROUTING_FAILED_STATUS,
      "btn-success": filter.value === ROUTED_STATUS,
      "text-light": filter.value === ROUTING_FAILED_STATUS,
    },
  ];
});

const buttonClass = computed(() => {
  const defaultClass =
    "btn btn-lg w-100 py-2 px-4 h3 d-flex flex-nowrap justify-content-between";
  const buttonInactiveClass = "btn-light";
  return [
    defaultClass,
    filter.value,
    isActive.value ? buttonActiveClass.value : buttonInactiveClass,
  ];
});

const toggleStatus = () => {
  emit("toggleStatus", filter.value, isActive.value);
};
</script>
