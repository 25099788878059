<template>
  <PoliciesBase>
    <template #policy-text>
      <h1>Scurri Cookie Policy</h1>
      <h4>This Cookie Policy was last modified on July 23rd, 2024.</h4>

      <h2>What is a Cookie?</h2>
      <p>
        A cookie is a small text file stored on your computer or mobile device
        that contains data related to a website you visit. Cookies have many
        uses. They ensure that a website functions properly as you might expect
        it to. Cookies also allow websites to ‘remember’ your browsing habits or
        preferences over a period of time. Cookies may be set by Scurri as the
        website operator directly (“first-party cookies”) or, in some cases, by
        another third-party service provider (“third-party cookies”). In the
        latter scenario, cookies are deployed for social media, advertising or
        analytics purposes. Scurri will only be able to directly recognise and
        read the information contained on first-party cookies.
      </p>

      <h2>Other Tracking Technologies</h2>
      <p>
        There are other technologies which we deploy that track behaviour on our
        website. ‘Tracker Pixels’ are a means of allowing us to check whether
        you have viewed our content. These “pixels” are essentially tiny,
        invisible images on our web pages that, once loaded, will communicate
        your interaction with our content. This allows
        <strong>Scurri</strong> and our partners to gauge the validity of the
        material being presented to you.
      </p>

      <h2>Third Parties</h2>
      <p>
        We avail of third-party technologies across our site. These technologies
        help us to understand how you use the site and the ways in which we can
        improve your user experience. These technologies may track certain
        information, such as the pages you visit across our site and how long
        your visit lasts. This allows us to continue providing engaging content.
      </p>

      <p>
        We may also use social media plugins on this site that allow you to view
        videos and interact with specific content. In order for these
        technologies to work, trusted third parties will set cookies and tracker
        pixels throughout our site. Once enabled, these technologies may be used
        to enhance your profile or to contribute to the data they hold for
        various purposes outlined in their respective privacy policies. For any
        third-party technology used on our site, we will provide a link to that
        third party’s respective cookie/privacy policy for your information. We
        encourage our visitors to review these policies in advance of granting
        consent to deployment.
      </p>

      <h2>What Information Do We Obtain from You?</h2>
      <p>
        For each visitor to our site, we automatically gather certain
        potentially identifying information and store it. This information
        includes your Internet Protocol (IP) address, your company name, your
        browser type, internet service provider (ISP), referring/exit pages,
        your operating system, date/time stamp, processor or device serial
        number, unique device identifier, and clickstream data. We collect this
        information on an individual basis and in the aggregate or combined
        form.
      </p>

      <h2>Our Use of Cookies and Similar Tracking Technologies</h2>
      <p>
        We use cookies to provide you with the best user experience on our
        website. This includes:
      </p>
      <ul>
        <li>Network Optimisation</li>
        <li>Remembering you when you wish to log in to your account</li>
        <li>Allowing you to view embedded content</li>
        <li>Analysing visitor traffic across our site</li>
        <li>Providing you with more relevant content</li>
      </ul>

      <h2>Categories of Tracking Technologies That We Set</h2>
      <p>
        We avail of both first and third-party technologies across our site.
        Scurri takes our visitors’ right to privacy seriously in keeping with
        our obligations. Should you consent to deploying any category of
        optional technology, we will record your consent based on usage and
        login to our application where users can opt out at any time.
      </p>

      <h2>Strictly necessary cookies</h2>
      <p>
        These cookies are necessary for the website to function. They are set
        for security purposes and in response to actions made by you that amount
        to a service request such as logging in to our platform or as you fill
        out forms on our site. You have the option to disable these cookies via
        your browser settings, but should you do so, certain aspects of our site
        will no longer function.
      </p>

      <h2>Third Party Analytics Cookies</h2>
      <p>
        These cookies allow us to determine the number of visits to our site as
        well as where that traffic originates. This helps us understand which
        pages are the most popular as well as how it is that you come to view
        them. For example, when enabled, we use Google Analytics for aggregated,
        anonymized website traffic analysis. In order to track your session
        usage, Google drops a cookie (_ga) with a randomly-generated ClientID in
        your browser. This ID is anonymized and contains no identifiable
        information like email, phone number, name, etc. We also send Google
        your masked IP Address. We use GA to track aggregated website behaviour,
        such as what pages you looked at, for how long, etc. This information is
        important to us for improving the user experience and determining site
        effectiveness.
      </p>

      <p>
        This information will not be combined by Scurri for profiling purposes,
        however, certain third parties such as Google may use this information
        in aggregate form for the purposes outlined in their respective privacy
        notices. If you disable this category of cookie our site will still
        work, though its improvement over time will be hampered.
      </p>

      <p>
        For more information on Google Analytics as well as how you may opt-out
        of being tracked on this and other sites, please see the following
        links:
      </p>
      <ul>
        <li>
          <a href="https://support.google.com/analytics/answer/6004245"
            >Information for visitors of sites and apps using Google
            Analytics</a
          >
        </li>
        <li>
          <a href="https://tools.google.com/dlpage/gaoptout"
            >Google Analytics Opt-Out</a
          >
        </li>
      </ul>

      <p>
        If you would like to opt-out of all third party analytics cookies
        collected on Scurri, you can do so through your account settings.
      </p>

      <p>
        For more information on the specific technologies we and our partners
        use for this purpose, please see below. Google Analytics – Scurri uses
        Google Analytics which is one of the most widespread and trusted
        analytics solutions on the web for helping us to understand how you use
        the site and ways that we can improve your experience. These cookies may
        track things such as how long you spend on the Scurri platform and the
        pages that you visit so we can continue to improve our feature offering.
      </p>

      <p>
        <strong>Name, Purpose & Expiry</strong>: Google analytics sets a number
        of cookies:
      </p>
      <ul>
        <li>
          <strong>_utma Cookie</strong>: A persistent cookie – remains on a
          computer, unless it expires or the cookie cache is cleared. It tracks
          visitors. Metrics associated with the Google _utma cookie include:
          first visit (unique visit), last visit (returning visit). This also
          includes Days and Visits to purchase calculations which afford
          ecommerce websites with data intelligence around purchasing sales
          funnels. This cookie expires after 2 years.
        </li>
        <li>
          <strong>_utmb Cookie & _utmc Cookie</strong>: These cookies work in
          tandem to calculate visit length. Google _utmb cookie demarks the
          exact arrival time, then Google __utmc registers the precise exit time
          of the user. Because _utmb counts entrance visits, it is a session
          cookie, and expires at the end of the session, e.g. when the user
          leaves the page. A timestamp of 30 minutes must pass before Google
          cookie _utmc expires. Given_utmc cannot tell if a browser or website
          session ends. Therefore, if no new page view is recorded in 30 minutes
          the cookie is expired. This is a standard ‘grace period’ in web
          analytics. Omniture and WebTrends among many others follow the same
          procedure.
        </li>
        <li>
          <strong>_utmz Cookie</strong>: Cookie _utmz monitors the HTTP Referrer
          and notes where a visitor arrived from, with the referrer siloed into
          type (Search engine (organic or cpc), direct, social and unaccounted).
          From the HTTP Referrer the _utmz Cookie also registers what keyword
          generated the visit plus geolocation data. This cookie lasts six
          months.
        </li>
        <li>
          <strong>_utmv Cookie</strong>: Google _utmv Cookie lasts “forever”. It
          is a persistent cookie. It is used for segmentation, data
          experimentation and the _utmv works hand in hand with the _utmz cookie
          to improve cookie targeting capabilities.
        </li>
        <li>
          <strong>_ga</strong>: This cookie is associated with Google Universal
          Analytics. It is used to distinguish unique users by assigning a
          randomly-generated number as a client identifier. It is used to
          calculate visitor, session and campaign data for our analytical
          reports. It expires after 2 years.
        </li>
        <li>
          <strong>_gid</strong>: This cookie name is associated with Google
          Universal Analytics. It may store and update a unique value for each
          page visited. It expires after 1 day.
        </li>
      </ul>

      <br />
      <p>
        Clarity – We partner with Microsoft Clarity and Microsoft Advertising to
        capture how you use and interact with our website through behavioural
        metrics, heatmaps, and session replay to improve and market our
        products/services. Website usage data is captured using first and
        third-party cookies and other tracking technologies to determine the
        popularity of products/services and online activity. Additionally, we
        use this information for site optimization, fraud/security purposes, and
        advertising. For more information about how Microsoft collects and uses
        your data, visit the Microsoft Privacy Statement.
      </p>

      <DataTable :value="clarityCookies">
        <Column field="name" header="Name"></Column>
        <Column field="purpose" header="Purpose"></Column>
        <Column field="valuePassed" header="Value Passed"></Column>
      </DataTable>
      <br />

      <h2>Further Information</h2>
      <p>
        We regularly scan our website using a third-party tool to maintain a
        record of the tracking technologies we deploy. Scurri only processes
        your personal information in accordance with this notice as well as our
        Privacy Notice.
      </p>
      <p>
        Should you require additional information then you can contact our
        Support team.
      </p>
      <p>
        Should you wish to make a complaint regarding the processing of your
        personal data or are unsatisfied with how we have handled your
        information, you have the right to lodge a complaint with the Irish
        Supervisory Authority. You may lodge a complaint with the Office of the
        Data Protection Commissioner via their
        <a href="https://forms.dataprotection.ie/contact">online form</a>.
      </p>
    </template>
  </PoliciesBase>
</template>
<script setup>
import PoliciesBase from "@/components/policies/PoliciesBase";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

const clarityCookies = [
  {
    name: "_clck",
    purpose: `Persists the Clarity User ID and preferences, unique to that site
      is attributed to the same user ID.`,
    valuePassed: "String",
  },
  {
    name: "_clsk",
    purpose:
      "Connects multiple page views by a user into a single Clarity session recording.",
    valuePassed: "String",
  },
  {
    name: "CLID",
    purpose:
      "Identifies the first-time Clarity saw this user on any site using Clarity.",
    valuePassed: "String",
  },
  {
    name: "ANONCHK",
    purpose: `Indicates whether MUID is transferred to ANID, a cookie used for advertising.
      Clarity doesn't use ANID and so this is always set to 0.`,
    valuePassed: "Flag",
  },
  {
    name: "MR",
    purpose: "Indicates whether to refresh MUID.",
    valuePassed: "Flag",
  },
  {
    name: "MUID",
    purpose: `Identifies unique web browsers visiting Microsoft sites.
      These cookies are used for advertising, site analytics, and other operational purposes.`,
    valuePassed: "GUID",
  },
  {
    name: "SM",
    purpose: "Used in synchronising the MUID across Microsoft domains.",
    valuePassed: "Character Flags",
  },
];
</script>
