import axios from "axios";
import { useAuthStore } from "@/store/authStore";
import router from "@/routes/routes";
import { storeToRefs } from "pinia";
import { isEnvVariableTrue } from "@/services/utils/utils";

export const BASE_URL = process.env.VUE_APP_BACKEND_URL + "/api/";
const SEND_WITH_CREDENTIALS = isEnvVariableTrue(
  process.env.VUE_APP_SEND_WITH_CREDENTIALS,
);
export const httpRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: SEND_WITH_CREDENTIALS,
});

const handleResponseErrors = async (error) => {
  const authStore = useAuthStore();

  if (error.response?.status === 401 && authStore.isAuthenticated) {
    await authStore.setUserAsUnAuthenticated();
    await authStore.saveLastVisitedPage(window.location.pathname);
    await router.push({
      path: "/pre-login",
    });
  }
  if (error.response.status === 404) {
    await router.push({
      path: "/not-found",
    });
  }
  return Promise.reject(error.response);
};

httpRequest.interceptors.response.use(
  (response) => response,
  async (error) => await handleResponseErrors(error),
);

httpRequest.interceptors.request.use(
  (config) => {
    const authStore = useAuthStore();
    const { csrfToken } = storeToRefs(authStore);
    if (authStore.csrfToken) {
      config.headers["X-CSRFToken"] = csrfToken.value;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
