<template>
  <ProgressSpinner v-if="isLoading" />
  <div v-else :class="previewClassList">
    <Button
      icon="pi pi-times"
      rounded
      class="close-modal-btn"
      @click="onCloseModal"
    />
    <TrackingPagePreviewError v-if="errorMessage" />
    <div v-else class="preview-content">
      <div class="preview-buttons">
        <PreviewViewButton
          v-for="(viewButton, index) in viewButtons"
          :key="index"
          :view="viewButton.view"
          :icon="viewButton.icon"
          :label="viewButton.label"
          :current-view="currentView"
          :data-cy="'btn-' + viewButton.view"
          @set-view="setView"
        />
      </div>
      <div>
        <!-- Desktop Preview -->
        <iframe
          v-if="displayDesktop"
          :srcdoc="trackingPagePreview"
          :class="desktopView"
          data-cy="desktop-preview"
        />
        <!-- Mobile Preview -->
        <iframe
          v-else
          :srcdoc="trackingPagePreview"
          :class="[mobileView, templateName]"
          data-cy="mobile-preview"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, inject, onMounted, ref } from "vue";
import { useTrackingPageEditStore } from "@/components/tracking-pages/stores/tracking-page";
import { storeToRefs } from "pinia/dist/pinia";
import TrackingPageApiRequests from "@/components/tracking-pages/helpers/apiRequests";
import PreviewViewButton from "@/components/tracking-pages/PreviewViewButton";
import ProgressSpinner from "primevue/progressspinner";
import Button from "primevue/button";
import { useConfigurationRequests } from "@/services/utils/configurationRequests";
import TrackingPagePreviewError from "@/components/tracking-pages/TrackingPagePreviewError";

const trackingPageEditStore = useTrackingPageEditStore();
const { id, templateName, leftImage, rightImage, bottomImage } = storeToRefs(
  trackingPageEditStore,
);
const { makeRequest, isLoading } = useConfigurationRequests();

const desktopView = "desktop";
const mobileView = "mobile";

const viewButtons = [
  {
    view: desktopView,
    label: "Desktop",
    icon: "pi pi-desktop",
  },
  {
    view: mobileView,
    label: "Mobile",
    icon: "pi pi-mobile",
  },
];

const currentView = ref(desktopView);
const trackingPagePreview = ref("");
const dialogRef = inject("dialogRef");
const errorMessage = ref("");

const generateTrackingPreview = async () => {
  const { error, response } = await makeRequest({
    endpoint: () =>
      TrackingPageApiRequests.generateTrackingPagePreview(
        id.value,
        templateName.value,
        leftImage.value,
        rightImage.value,
        bottomImage.value,
      ),
  });
  if (error.value) errorMessage.value = "Failed to load.";
  else if (response) trackingPagePreview.value = response.value.preview;
};

const setView = (type) => {
  currentView.value = type;
};

const onCloseModal = () => {
  dialogRef.value.close();
};

const displayDesktop = computed(() => {
  return currentView.value === desktopView;
});

const previewClassList = computed(() => {
  return ["preview-container", { "preview-with-error": errorMessage.value }];
});

onMounted(async () => {
  await generateTrackingPreview();
});
</script>
<style scoped lang="scss">
.preview-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 0;

  .preview-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .preview-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  iframe {
    height: 1132px;
    display: flex;
    margin: 0 auto;
    border-radius: 4px;
    border: 1px solid #dadee3;
    background: #fff;
  }

  .mobile {
    width: 360px;
  }

  .order_details {
    height: 941px;
  }

  .generic {
    height: 1158px;
  }

  .desktop {
    width: 1442px;
    transform: scale(0.85);
    margin: -85px 0px;

    @media screen and (max-width: 1400px) {
      transform: scale(0.75);
      margin: -142px 0px;
    }

    @media screen and (max-width: 1200px) {
      transform: scale(0.7);
      margin: -160px 0px;
    }
  }

  .close-modal-btn {
    background: none;
    border-radius: 4px;
    color: #212529;
    width: 40px;
    height: 40px;
    transition: background-color 0.3s ease;
    border: none;
    position: absolute;
    left: 4px;
    top: 4px;

    &:hover {
      background: #ccc;
    }

    .pi-times {
      font-size: 16px;
    }
  }
}

.preview-with-error {
  height: 100%;
}
</style>
