<template>
  <ProgressSpinner v-show="isLoading" />
  <StepContainer
    v-if="!isLoading"
    title="Step 2: Segmentation"
    subtitle="Specify which shipments this campaign should apply to. (Optional)"
    next-button-label="Next Step"
    next-button-icon="pi pi-angle-right"
    display-previous-button
    :is-next-button-disabled="false"
    @next-button-click="onNextClick"
    @prev-button-click="onPrevClick"
  >
    <template #content>
      <div class="route-filter-form">
        <div class="filter-dropdown">
          <FormMultiDropdown
            v-model="shipmentFilters"
            :options="filters"
            name="routeFilters"
            data-cy="filters-select"
            placeholder="Choose segment(s)"
          />
        </div>
        <div class="add-filter">
          <Button
            class="p-button p-button-outlined"
            data-cy="create-new-filter"
            @click="openFilterDialog"
            ><i class="pi pi-plus" /> New Segment</Button
          >
        </div>
      </div>
    </template>
  </StepContainer>
</template>

<script setup>
import Button from "primevue/button";
import FormMultiDropdown from "@/components/FormMultiDropdown";
import StepContainer from "@/components/stepper/StepContainer";
import ProgressSpinner from "primevue/progressspinner";
import { useFilterStore } from "@/components/filters/stores/filter";
import { storeToRefs } from "pinia";
import { useRouteStore } from "@/components/route-configurations/stores/route";
import { useFilterFormDialog } from "@/components/filters/helpers/modalConfigs";
import { DIALOG_RESULTS } from "@/constants";
import { useWorkflowStore } from "@/components/route-configurations/stores/workflow";
import { onMounted, ref } from "vue";

const filterStore = useFilterStore();
const { getFilters } = filterStore;
const { filters, lastAddedFilter } = storeToRefs(filterStore);

const routeStore = useRouteStore();
const { shipmentFilters } = storeToRefs(routeStore);

const workflowStore = useWorkflowStore();
const { getAvailableOptions } = workflowStore;

const isLoading = ref(false);

const emit = defineEmits(["next-page", "prev-page"]);
const onPrevClick = async () => {
  emit("prev-page");
};

const onNextClick = async () => {
  emit("next-page");
};

const { openFilterFormDialog } = useFilterFormDialog();
const openFilterDialog = () => {
  openFilterFormDialog({ onClose: setSelectedFilter });
};

const setSelectedFilter = async (result) => {
  if (result === DIALOG_RESULTS.CREATED) {
    shipmentFilters.value = [lastAddedFilter.value.id];
    //update available options so mapping is updated for route review
    await getAvailableOptions();
  }
};

onMounted(async () => {
  isLoading.value = true;
  await getFilters();
  isLoading.value = false;
});
</script>
<style scoped lang="scss">
.route-filter-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 16px;
  align-self: stretch;

  button {
    width: 100%;
  }

  .filter-dropdown {
    flex-grow: 1;
  }

  .add-filter {
    flex-grow: 0;
  }

  :deep(.add-filter button) {
    white-space: nowrap;
  }
}
</style>
