<template>
  <ProgressSpinner v-if="isLoading" />
  <MainPage v-else>
    <template #body>
      <StepContainer
        title="Tracking Page Content"
        subtitle="Upload your imagery for both desktop and mobile."
        next-button-label="Update Tracking Page"
        :is-next-button-disabled="isSubmitDisabled"
        :display-secondary-action-button="true"
        secondary-action-button-icon="pi pi-eye"
        secondary-action-button-label="Preview Page"
        @next-button-click="onSubmit"
        @secondary-action-button-click="onPagePreview"
      >
        <template #content>
          <TrackingPageImagesConfig :template-name="templateName" />
        </template>
      </StepContainer>
    </template>
  </MainPage>
</template>
<script setup>
import MainPage from "@/components/MainPage";
import ProgressSpinner from "primevue/progressspinner";
import { useRoute } from "vue-router/dist/vue-router";
import { computed, onMounted, ref } from "vue";
import { storeToRefs } from "pinia/dist/pinia";
import { useTrackingPageEditStore } from "@/components/tracking-pages/stores/tracking-page";
import StepContainer from "@/components/stepper/StepContainer";
import TrackingPageImagesConfig from "@/components/tracking-pages/TrackingPageImagesConfig";
import { useTrackingPagePreviewDialog } from "@/components/tracking-pages/helpers/modalConfigs";
import { Navigator } from "@/services/utils/navigator";
import { EDIT, SUCCESS_STATUS } from "@/constants";
import { onBeforeRouteLeave } from "vue-router";
import { useTrackingPagesConfirmDialog } from "@/components/tracking-pages/helpers/confirmModalConfigs";

const { navigateToView } = Navigator();
const { openTrackingPagePreviewDialog } = useTrackingPagePreviewDialog();
const trackingPageEditStore = useTrackingPageEditStore();
const { templateName, hasLocalChanges } = storeToRefs(trackingPageEditStore);

const isLoading = ref(true);
const route = useRoute();
const isSubmitting = ref(false);

const trackingPageId = computed(() => {
  return route.params.id.toString() || "";
});

const isSubmitDisabled = computed(() => {
  return !hasLocalChanges.value || isSubmitting.value;
});

const onSubmit = async () => {
  isSubmitting.value = true;
  const result = await trackingPageEditStore.updateTrackingPage();
  if (result === SUCCESS_STATUS) {
    navigateToView("ListTrackingPages");
  } else {
    isSubmitting.value = false;
  }
};

const onPagePreview = () => {
  openTrackingPagePreviewDialog({});
};

onMounted(async () => {
  if (trackingPageId.value) {
    await trackingPageEditStore.getTrackingPageDetails(trackingPageId.value);
  }
  isLoading.value = false;
});

const { confirmCancelTrackingPageForm } = useTrackingPagesConfirmDialog();
onBeforeRouteLeave((to, from, next) => {
  if (hasLocalChanges.value) {
    confirmCancelTrackingPageForm({
      callback: () => next(),
      action: EDIT,
    });
  } else {
    next();
  }
});
</script>
