import FilterForm from "@/components/filters/FilterForm";
import { useGlobalDialogService } from "@/services/modals/base";

export const useFilterFormDialog = () => {
  const { openDialog } = useGlobalDialogService();

  const openFilterFormDialog = ({ filterId, onClose }) => {
    return openDialog({
      component: FilterForm,
      dialogProps: {
        modal: true,
        appendTo: "self",
        dismissableMask: false,
        showHeader: false,
        style: {
          width: "720px",
          padding: "calc(32px - 1rem)",
        },
      },
      componentData: {
        filterId: filterId,
      },
      onClose: (options) => {
        onClose?.(options.data);
      },
    });
  };
  return { openFilterFormDialog };
};
