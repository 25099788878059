import { useToastService } from "@/services/toast/utils";

const callDefaultAction = (errorAction = undefined) => {
  const { displayErrorGenericToast } = useToastService();

  const funcToCall = errorAction ?? displayErrorGenericToast;
  return funcToCall();
};

export const handleErrors = (
  errors,
  errorMap,
  { ...args },
  defaultGenericErrorAction = undefined,
) => {
  let toastShowCounter = 0;

  const errorCodesForErrors = (errors) => {
    let areDetailsPresent;
    try {
      areDetailsPresent =
        "detail" in errors.data && Object.keys(errors.data.detail).length;
    } catch (TypeError) {
      return [];
    }

    if (areDetailsPresent) {
      if (typeof errors.data.detail !== "object") {
        return [errors.data.detail];
      }
      const details = Object.values(errors.data.detail);
      return details.reduce((acc, curr) => {
        acc = acc.concat(curr.map((e) => e.code));
        return acc;
      }, []);
    }
    return [errors.data.code];
  };

  const errorCodes = errorCodesForErrors(errors);
  errorCodes.forEach((errorCode, index) => {
    if (errorCode in errorMap) {
      errorMap[errorCode].fn(args);
      toastShowCounter++;
    } else if (index + 1 === errorCodes.length && !toastShowCounter) {
      return callDefaultAction(defaultGenericErrorAction);
    }
  });
  if (!errorCodes.length) {
    callDefaultAction(defaultGenericErrorAction);
  }
};
