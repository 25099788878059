<template>
  <tr class="tracking-page-row" data-cy="tracking-page-row">
    <td class="tracking-page-row__info">
      <div class="tracking-page-row__info__text">
        <span
          :class="['template-icon', templateIconClass]"
          data-cy="template-icon"
        ></span>
        <h6 class="tracking-page-name">{{ trackingPage.name }}</h6>
      </div>
    </td>
    <td class="tracking-page__buttons__container">
      <div class="tracking-page__buttons">
        <CopyWithTooltip
          copied-text="Copied!"
          copy-text="Copy Url"
          data-cy="copy-tracking-link"
          :item-to-copy="trackingPage.urlWithHandlebar"
        />
        <Button
          v-tooltip.top="{
            value: 'View',
            showDelay: 300,
            hideDelay: 300,
          }"
          class="p-button p-button-tertiary-outlined"
          data-cy="preview-button"
          @click="openPreviewLink"
        >
          <i class="pi pi-eye"></i>
        </Button>
        <Button
          class="p-button p-button-tertiary-outlined"
          data-cy="edit-button"
          @click="navigateToEdit"
        >
          Edit
        </Button>
      </div>
    </td>
  </tr>
</template>
<script setup>
import Button from "primevue/button";
import { convertToClass } from "@/services/utils/utils";
import { computed, toRefs } from "vue";
import { Navigator } from "@/services/utils/navigator";
import CopyWithTooltip from "@/components/tracking-pages/CopyWithTooltip.vue";
import { openExternalLinkInNewTab } from "@/services/utils/navigator";

const { navigateToView } = Navigator();

const props = defineProps({
  trackingPage: {
    type: Object,
    default: () => {},
  },
});

const { trackingPage } = toRefs(props);

const templateIconClass = computed(
  () => "icon-" + convertToClass(trackingPage.value.templateName),
);

const openPreviewLink = () => {
  openExternalLinkInNewTab(trackingPage.value.url);
};

const navigateToEdit = () => {
  navigateToView("EditTrackingPage", { id: trackingPage.value.id });
};
</script>
<style scoped lang="scss">
.tracking-page-row {
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;

  .tracking-page-row__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    .tracking-page-row__info__text {
      display: flex;
      align-items: center;
      gap: 8px;

      .template-icon {
        width: 40px;
        height: 40px;
      }

      .tracking-page-name {
        margin: 0;
      }
    }
  }

  .tracking-page__buttons__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;

    .tracking-page__buttons {
      display: flex;
      justify-content: flex-end;
      flex: 1 0 0;
      align-self: stretch;
      align-items: center;
      gap: 8px;
      padding-right: 14px;
    }
  }
}
</style>
