import { ref } from "vue";
import { defineStore } from "pinia";
import { useConfigurationRequests } from "@/services/utils/configurationRequests";
import { useWorkflowToastService } from "@/components/route-configurations/helpers/toastConfigs";
import { useToastService } from "@/services/toast/utils";
import { FAIL_STATUS, LATEST_VERSION, SUCCESS_STATUS } from "@/constants";
import { useWorkflowsConfirmDialog } from "@/components/route-configurations/helpers/confirmModals";
import RoutesApiRequests from "@/components/route-configurations/helpers/apiRequests";

export const useWorkflowStore = defineStore("workflow", () => {
  // State
  // workflow details
  const id = ref("");
  const name = ref("");
  const deleted = ref(false);
  const workflowRoutes = ref([]);
  const statusOptions = ref([]);
  const notificationOptions = ref([]);
  const filterOptions = ref([]);
  const immediatelySchedule = ref("");
  const isLatestVersion = ref(true);
  const version = ref(LATEST_VERSION);
  const hasChanged = ref(false);

  // workflows list
  const workflows = ref([]);

  // Actions
  async function getWorkflow(workflowId, workflowVersion) {
    const workflowResponse = await makeRequest({
      endpoint: async () =>
        await RoutesApiRequests.getRouteConfiguration(
          workflowId,
          workflowVersion,
        ),
    });
    id.value = workflowId;
    name.value = workflowResponse.response.value.name;
    deleted.value = workflowResponse.response.value.sinceBeenDeleted;
    workflowRoutes.value = workflowResponse.response.value.routes;
    isLatestVersion.value = workflowResponse.response.value.isLatestVersion;
    version.value = workflowVersion;
  }

  async function getAvailableOptions() {
    const workflowOptionsResponse = await makeRequest({
      endpoint: async () =>
        await RoutesApiRequests.getRouteConfigurationSetups(
          id.value,
          version.value,
        ),
    });
    statusOptions.value = workflowOptionsResponse.response.value.statuses;
    notificationOptions.value = workflowOptionsResponse.response.value.actions;
    filterOptions.value =
      workflowOptionsResponse.response.value.shipmentFilters;
    const schedules = workflowOptionsResponse.response.value.schedules;
    if (schedules.length) {
      immediatelySchedule.value = schedules[0].id;
    }
  }

  function setName(nameVal) {
    name.value = nameVal;
    hasChanged.value = true;
  }

  function addWorkflowRoute(route) {
    workflowRoutes.value.push(route);
    hasChanged.value = true;
    displayRouteSuccessToast("Created", route.name);
  }

  function updateWorkflowRoute(route) {
    workflowRoutes.value[route.position] = route;
    hasChanged.value = true;
    displayRouteSuccessToast("Edited", route.name);
  }

  function deleteWorkflowRoute(index) {
    const routeName = workflowRoutes.value[index].name;
    workflowRoutes.value.splice(index, 1);
    hasChanged.value = true;
    displayRouteSuccessToast("Deleted", routeName);
  }

  function setWorkflowRoutesOrder(routes) {
    workflowRoutes.value = routes;
    hasChanged.value = true;
    displayWorkflowRoutesReorderedToast();
  }

  const setWorkflowRoutesActiveState = (index, newActiveState) => {
    const isRouteSetToInactive = newActiveState === false;
    const isTheLastEnabledRoute =
      workflowRoutes.value.filter((r) => r.isActive).length === 1;
    const isWorkflowActive = isWorkflowActiveForId(id.value);
    const showRouteDeactivationConfirmPage =
      isRouteSetToInactive && isTheLastEnabledRoute && isWorkflowActive;

    if (showRouteDeactivationConfirmPage) {
      confirmSetToInactiveLastRoute({
        callback: () => {
          setWorkflowRoutesActiveStateValue(index, newActiveState);
        },
      });
    } else {
      setWorkflowRoutesActiveStateValue(index, newActiveState);
    }
  };

  function isRouteActive(routeIndex) {
    const route = workflowRoutes.value[routeIndex];
    if (route) {
      return route.isActive;
    }
    return false;
  }

  function setWorkflowRoutesActiveStateValue(routeIndex, newActiveState) {
    const route = workflowRoutes.value[routeIndex];
    route.isActive = newActiveState;
    hasChanged.value = true;
  }

  async function createWorkflow(workflowName, workflowRoutes) {
    const { response, error } = await makeRequest({
      endpoint: () =>
        RoutesApiRequests.createConfiguration({
          name: workflowName,
          routes: workflowRoutes,
        }),
      onSuccess: async () => {
        await resetWorkflow();
        displayCreateSuccessToast(workflowName);
      },
      onInvalidInput: () =>
        displayWorkflowCreateNameAlreadyExists(workflowName),
      onFail: () => displayErrorGenericToast(),
    });
    if (!error.value) {
      workflows.value = response.value;
      return SUCCESS_STATUS;
    }
    return FAIL_STATUS;
  }

  function resetWorkflow() {
    id.value = "";
    name.value = "";
    deleted.value = false;
    workflowRoutes.value = [];
    statusOptions.value = [];
    notificationOptions.value = [];
    filterOptions.value = [];
    immediatelySchedule.value = "";
    isLatestVersion.value = true;
    version.value = LATEST_VERSION;
    hasChanged.value = false;
  }

  async function updateWorkflow(workflowId, workflowName, workflowRoutes) {
    const { response, error } = await makeRequest({
      endpoint: () =>
        RoutesApiRequests.updateRouteConfiguration({
          id: workflowId,
          name: workflowName,
          routes: workflowRoutes,
        }),
      onSuccess: async () => {
        await resetWorkflow();
        displayEditSuccessToast(workflowName);
      },
      onInvalidInput: () => displayWorkflowEditNameAlreadyExists(workflowName),
      onFail: () => displayErrorGenericToast(),
    });
    if (!error.value) {
      workflows.value = response.value;
      return SUCCESS_STATUS;
    }
    return FAIL_STATUS;
  }

  async function duplicateWorkflow(workflow) {
    const { response, error } = await makeRequest({
      endpoint: () =>
        RoutesApiRequests.duplicateRouteConfiguration(workflow.id),
      onSuccess: () => {
        displayDuplicateSuccessToast(workflow.name);
      },
      onFail: () => displayWorkflowDuplicateFailToast(workflow.name),
    });
    if (!error.value) {
      workflows.value = response.value;
    }
  }

  async function activateWorkflow(workflow) {
    const { response, error } = await makeRequest({
      endpoint: () => RoutesApiRequests.activateRouteConfiguration(workflow.id),
      onSuccess: () => {
        displayWorkflowActivateSuccessToast(workflow.name);
      },
      onFail: () => displayWorkflowActivateFailToast(workflow.name),
    });
    if (!error.value) {
      workflows.value = response.value;
    }
  }

  async function deactivateWorkflow(workflow) {
    const { response, error } = await makeRequest({
      endpoint: () => RoutesApiRequests.deactivateRouteConfiguration(),
      onSuccess: () => {
        displayWorkflowDeactivateSuccessToast(workflow.name);
      },
      onFail: () => displayWorkflowDeactivateFailToast(workflow.name),
    });
    if (!error.value) {
      workflows.value = response.value;
    }
  }

  async function getWorkflows() {
    const { response, error } = await makeRequest({
      endpoint: async () => await RoutesApiRequests.getRouteConfigurations(),
    });
    if (!error.value) {
      workflows.value = response.value;
      return SUCCESS_STATUS;
    }
    return FAIL_STATUS;
  }

  async function deleteWorkflow(workflow) {
    const { response, error } = await makeRequest({
      endpoint: () => RoutesApiRequests.deleteConfiguration(workflow.id),
      onSuccess: () => {
        displayWorkflowDeletionSuccessToast(workflow.name);
      },
      onFail: () => displayWorkflowDeletionFailToast(workflow.name),
    });
    if (!error.value) {
      workflows.value = response.value;
    }
  }

  function isWorkflowActiveForId(workflowId) {
    const _workflow = workflows.value.find((w) => w.id === workflowId);
    return _workflow ? _workflow.isActive : false;
  }

  const { makeRequest } = useConfigurationRequests();
  const {
    displayDuplicateSuccessToast,
    displayWorkflowDuplicateFailToast,
    displayCreateSuccessToast,
    displayWorkflowCreateNameAlreadyExists,
    displayEditSuccessToast,
    displayWorkflowEditNameAlreadyExists,
    displayWorkflowActivateSuccessToast,
    displayWorkflowActivateFailToast,
    displayWorkflowDeactivateSuccessToast,
    displayWorkflowDeactivateFailToast,
    displayWorkflowDeletionSuccessToast,
    displayWorkflowDeletionFailToast,
    displayRouteSuccessToast,
    displayWorkflowRoutesReorderedToast,
  } = useWorkflowToastService();

  const { displayErrorGenericToast } = useToastService();
  const { confirmSetToInactiveLastRoute } = useWorkflowsConfirmDialog();

  return {
    id,
    name,
    deleted,
    workflowRoutes,
    immediatelySchedule,
    statusOptions,
    notificationOptions,
    filterOptions,
    workflows,
    isLatestVersion,
    version,
    hasChanged,
    isRouteActive,
    getWorkflow,
    createWorkflow,
    updateWorkflow,
    setName,
    getAvailableOptions,
    addWorkflowRoute,
    updateWorkflowRoute,
    deleteWorkflowRoute,
    resetWorkflow,
    duplicateWorkflow,
    getWorkflows,
    activateWorkflow,
    deactivateWorkflow,
    deleteWorkflow,
    setWorkflowRoutesOrder,
    setWorkflowRoutesActiveState,
  };
});
