<template>
  <div class="dialog-content" data-cy="csv-preview-modal">
    <div class="dialog-header">
      <h3>
        Preview CSV
        <span class="dialog-header__filename">({{ filename }})</span>
      </h3>
      <span>
        You can preview the CSV file below, including first few rows and header.
      </span>
    </div>
    <DataTable
      :value="fileContent.rows"
      :class="{ 'hide-me': !isFirstRowHeader }"
    >
      <Column
        v-for="(_, index) in numberOfColumns"
        :key="index"
        style="min-width: 161px; height: 45px"
      >
        <template #header>
          {{ isFirstRowHeader ? fileContent.headers[index] : "" }}
        </template>
        <template #body="{ data }">
          {{ data[index] }}
        </template>
      </Column>
    </DataTable>
    <div>
      <Button
        class="p-button p-button-outlined button-pos"
        data-cy="close-preview-button"
        @click="closeModal"
      >
        <i class="pi pi-eye-slash" /> Close Preview
      </Button>
    </div>
  </div>
</template>

<script setup>
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { useCsvImportStore } from "@/components/csv-import/stores/csvImport";
import { storeToRefs } from "pinia";

const csvImportStore = useCsvImportStore();
const { fileContent, numberOfColumns, isFirstRowHeader, filename } =
  storeToRefs(csvImportStore);

const emit = defineEmits(["onPreviewOpen"]);

const closeModal = () => {
  emit("onPreviewOpen", false);
};
</script>

<style scoped>
.button-pos {
  float: right;
}

.dialog-content {
  gap: 52px;
  display: flex;
  flex-direction: column;
}

.dialog-header {
  display: flex;
  gap: 24px;
  flex-direction: column;
}

.dialog-header__filename {
  color: #6c757d;
}

.hide-me :is(.p-datatable-thead) {
  display: none;
}
</style>
