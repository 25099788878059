<template>
  <StepContainer
    title="Step 3: Notification Triggers"
    subtitle="Specify which notification(s) to send when a delivery status is triggered."
    next-button-label="Next Step"
    next-button-icon="pi pi-angle-right"
    display-previous-button
    :is-next-button-disabled="disableNextButton"
    @next-button-click="onNextClick"
    @prev-button-click="onPrevClick"
  >
    <template #content>
      <form data-cy="route-options-form" class="route-options">
        <RouteOptionConfig
          v-for="(routeOption, index) in routeOptionFields"
          :key="routeOption.key"
          :route-option-index="index"
          :route-option-field="routeOption.value"
          :total-count="routeOptions.length"
          :status-options="statusOptions"
          :notification-options="notificationOptions"
          @delete-route-option="deleteRouteOption(index)"
        />
        <Button
          class="p-button p-button-outlined"
          data-cy="add-route-option"
          @click="onAddRouteOption"
          ><i class="pi pi-plus" /> Add Trigger</Button
        >
      </form>
    </template>
  </StepContainer>
</template>

<script setup>
import Button from "primevue/button";
import RouteOptionConfig from "@/components/route-configurations/form/RouteOptionConfig";
import StepContainer from "@/components/stepper/StepContainer";
import { computed, watch } from "vue";
import { useWorkflowStore } from "@/components/route-configurations/stores/workflow";
import { storeToRefs } from "pinia";
import { useRouteStore } from "@/components/route-configurations/stores/route";
import { toTypedSchema } from "@vee-validate/zod";
import { useRouteOptionSchema } from "@/services/validation-form-schemas/routeOptionSchema";
import { useFieldArray, useForm } from "vee-validate";

const emit = defineEmits(["next-page", "prev-page"]);
const store = useWorkflowStore();
const { statusOptions, notificationOptions } = storeToRefs(store);
const routeStore = useRouteStore();
const { routeOptions } = storeToRefs(routeStore);
const { setRouteOptions } = routeStore;
const validationSchema = toTypedSchema(useRouteOptionSchema());

const disableNextButton = computed(
  () =>
    !routeOptions.value[0].scheduledActions[0].actionId ||
    !routeOptions.value[0].triggeredStatuses.length,
);

const { handleSubmit } = useForm({
  validationSchema,
  initialValues: { routeOptions: routeOptions.value },
});

const {
  remove: deleteRouteOption,
  push: addRouteOption,
  fields: routeOptionFields,
} = useFieldArray("routeOptions");

const fieldsLength = computed(() => {
  return routeOptionFields.value.length;
});

watch(fieldsLength, async (newVal, oldVal) => {
  // if route options have been added/deleted
  if (newVal !== oldVal) {
    await setRouteOptions(
      routeOptionFields.value.map((newVal) => newVal.value),
    );
  }
});

const onAddRouteOption = () => {
  const routeOptionInitial = {
    triggeredStatuses: [],
    scheduledActions: [{ actionId: "", scheduleId: "" }],
  };
  addRouteOption(routeOptionInitial);
};

const onSubmit = handleSubmit(async () => {
  emit("next-page");
});

const onNextClick = async () => {
  await onSubmit();
};

const onPrevClick = () => {
  emit("prev-page");
};
</script>
