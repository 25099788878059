import { useToastService } from "@/services/toast/utils";

export const useShipmentsToastService = () => {
  const { displaySuccessToast, displayErrorToast, displayWarnToast } =
    useToastService();

  const displayReevaluationStartedToast = () => {
    return displaySuccessToast({
      message: `We are currently rechecking your shipments in an attempt to assign them a campaign. 
      Sit tight while we finish the process.`,
      summary: "Rechecking Shipments",
    });
  };

  const displaySingleReevaluationStartedToast = () => {
    return displaySuccessToast({
      message: `We are currently rechecking this shipment in an attempt to assign it a campaign. 
      Sit tight while we finish the process.`,
      summary: "Rechecking Shipment",
    });
  };

  const displaySingleReevaluationFailedToast = () => {
    return displayErrorToast({
      message: `We encountered an error while trying to recheck your shipment. Please try again later.`,
      summary: "Shipment Recheck Failed.",
    });
  };

  const displayDeleteImportErrorStartedToast = () => {
    return displaySuccessToast({
      message: `We are currently deleting all import failed shipments.
      Sit tight while we finish the process.`,
      summary: "Deleting Shipments",
    });
  };

  const displayPreviewPortalMissingShipmentIdFail = () => {
    return displayErrorToast({
      message: `Preview of Tracking Portal has failed as shipment does not contain all necessary data.`,
      summary: "Tracking Page Failed",
    });
  };

  const displayManyReroutesAlreadyInProgress = () => {
    return displayWarnToast({
      message: `A recheck of your shipments is already in progress. Sit tight while we finish the process.`,
      summary: "Recheck In Progress",
    });
  };

  const displaySingleReroutesAlreadyInProgress = () => {
    return displayWarnToast({
      message: `The recheck of shipment is already in progress. Please wait for the current process to complete.`,
      summary: "Recheck In Progress",
    });
  };

  return {
    displayReevaluationStartedToast,
    displayDeleteImportErrorStartedToast,
    displayPreviewPortalMissingShipmentIdFail,
    displayManyReroutesAlreadyInProgress,
    displaySingleReroutesAlreadyInProgress,
    displaySingleReevaluationStartedToast,
    displaySingleReevaluationFailedToast,
  };
};
