import { useConfirmDialog } from "@/services/confirm-modals/base";
import { getDiscardChangesConfig } from "@/services/utils/utils";

const confirmTrackingPageCancelEditingConfig = (action) => {
  return getDiscardChangesConfig("tracking page", action);
};

const confirmTrackingPageImageCancelEditingConfig = (action) => {
  return getDiscardChangesConfig("image", action);
};

export const useTrackingPagesConfirmDialog = () => {
  const { confirmAction } = useConfirmDialog();

  const confirmCancelTrackingPageForm = ({ callback, action }) => {
    return confirmAction({
      callback,
      ...confirmTrackingPageCancelEditingConfig(action),
    });
  };

  const confirmCancelTrackingPageImageForm = ({ callback, action }) => {
    return confirmAction({
      callback,
      ...confirmTrackingPageImageCancelEditingConfig(action),
    });
  };

  return {
    confirmCancelTrackingPageForm,
    confirmCancelTrackingPageImageForm,
  };
};
