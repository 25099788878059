<template>
  <ImportProfileStepName
    :profile-name="profileName"
    page-title="Step 1: Profile Name"
    page-subtitle="Add the profile name below:"
    @next-page="emitNextPage"
    @update:profile-name="setProfileName"
  />
</template>

<script setup>
import { useCsvProfileCreateStore } from "@/components/csv-import/stores/csvProfile";
import { storeToRefs } from "pinia";
import ImportProfileStepName from "@/components/csv-import/ImportProfileStepName.vue";

const emit = defineEmits(["next-page", "prev-page"]);

const csvCreateProfileStore = useCsvProfileCreateStore();
const { profileName } = storeToRefs(csvCreateProfileStore);
const { setProfileName } = csvCreateProfileStore;

const emitNextPage = () => {
  emit("next-page");
};
</script>
