<template>
  <MainPage>
    <template #body>
      <StepperContainer :steps="steps" />
    </template>
  </MainPage>
</template>

<script setup>
import StepperContainer from "@/components/stepper/StepperContainer.vue";
import { ref } from "vue";
import _ from "lodash";
import MainPage from "@/components/MainPage.vue";
import { onBeforeRouteLeave } from "vue-router";
import { useRouteStore } from "@/components/route-configurations/stores/route";
import { useWorkflowStore } from "@/components/route-configurations/stores/workflow";
import { storeToRefs } from "pinia";
import { useWorkflowsConfirmDialog } from "@/components/route-configurations/helpers/confirmModals";
import { CREATE, EDIT } from "@/constants";

const routeStore = useRouteStore();
const workflowStore = useWorkflowStore();
const { currentRoute, isEdit, name, shipmentFilters, routeOptions } =
  storeToRefs(routeStore);
const { hasChanged } = storeToRefs(workflowStore);
const { confirmCancelWorkflowForm } = useWorkflowsConfirmDialog();

const leaveOnItem = ref("");
const steps = ref([
  {
    label: "Name",
    to: "campaign-name",
    step: 0,
  },
  {
    label: "Segmentation",
    to: "segmentation",
    step: 1,
  },
  {
    label: "Triggers",
    to: "triggers",
    step: 2,
  },
  {
    label: "Review",
    to: "campaign-review",
    step: 3,
  },
]);

const navigatingToWorkflowForm = (pathName) => {
  return ["CreateNotificationWorkflow", "EditNotificationWorkflow"].includes(
    pathName,
  );
};

const createRouteDataPopulated = () => {
  return (
    name.value ||
    shipmentFilters.value.length ||
    routeOptions.value.some(
      (routeOption) => routeOption.triggeredStatuses.length > 0,
    )
  );
};

const hasRouteChanged = () => {
  return !_.isEqual(currentRoute.value, {
    name: name.value,
    shipmentFilters: shipmentFilters.value,
    routeOptions: routeOptions.value,
  });
};

onBeforeRouteLeave((to, from, next) => {
  if (!to.path.includes("configure-route")) {
    // check if the user is leaving workflow page completely with unsaved changes
    if (!navigatingToWorkflowForm(to.name) && hasChanged.value)
      leaveOnItem.value = "automation";
    else if (
      // check if the user is redirecting back to base page without saving route data
      (isEdit.value && hasRouteChanged()) ||
      (!isEdit.value && createRouteDataPopulated())
    ) {
      leaveOnItem.value = "campaign";
    }
  }
  if (leaveOnItem.value) {
    confirmCancelWorkflowForm({
      callback: () => next(),
      item: leaveOnItem.value,
      action: isEdit.value ? EDIT : CREATE,
    });
  } else {
    next();
  }
});
</script>
