<template>
  <div class="email-row">
    <div class="email-row__text">
      <Checkbox :model-value="isSelected" :binary="true" @input="onSelect" />
      <span class="email-row__name">{{ dataRow.name }}</span>
    </div>
    <div class="email-row__buttons">
      <Button
        class="p-button p-button-tertiary-outlined"
        data-cy="edit-row"
        @click="onEditClick"
      >
        Edit
      </Button>
      <BasicEllipsisMenu
        @on-delete="onMenuDelete"
        @on-duplicate="onMenuDuplicate"
      />
    </div>
  </div>
</template>

<script setup>
import Checkbox from "primevue/checkbox";
import Button from "primevue/button";
import BasicEllipsisMenu from "@/components/BasicEllipsisMenu.vue";

const props = defineProps({
  dataRow: {
    type: Object,
    default: () => {},
  },
  isSelected: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  "deleteRow",
  "duplicateRow",
  "editRow",
  "update:isSelected",
]);

const onMenuDelete = () => {
  emit("deleteRow", props.dataRow);
};

const onMenuDuplicate = () => {
  emit("duplicateRow", props.dataRow);
};

const onSelect = (isSelected) => {
  emit("update:isSelected", isSelected);
};
const onEditClick = () => {
  emit("editRow", props.dataRow);
};
</script>

<style scoped>
:deep(.p-checkbox .p-checkbox-box) {
  border-radius: 6px;
}

.email-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  height: 56px;
}
.email-row:not(:last-child) {
  border-bottom: 1px solid #edf0f2;
}
.email-row__buttons {
  margin-left: auto;
  display: flex;
  gap: 8px;
}
.email-row__text {
  display: flex;
  gap: 12px;
}
.email-row__name {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #495057;
}
</style>
