<template>
  <Button
    :class="'p-button ' + className"
    :data-cy="view + '-button'"
    :disabled="isCurrentView"
    @click="setView"
  >
    <i :class="icon"></i>{{ label }}
  </Button>
</template>
<script setup>
import Button from "primevue/button";
import { computed, toRefs } from "vue";

const props = defineProps({
  view: {
    type: String,
    default: "",
  },
  icon: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  currentView: {
    type: String,
    default: "",
  },
});

const { view, icon, label, currentView } = toRefs(props);

const isCurrentView = computed(() => {
  return currentView.value === view.value;
});

const className = computed(() => {
  return isCurrentView.value
    ? "p-button-tertiary-solid"
    : "p-button-tertiary-outlined";
});

const emit = defineEmits(["setView"]);

const setView = () => {
  emit("setView", view.value);
};
</script>
<style scoped lang="scss">
.p-button-tertiary-solid {
  padding: 7.5px 12px;
  height: 32px;
  font-weight: 600;

  &:disabled {
    opacity: 1;
  }
}
</style>
