<template>
  <div class="search-bar" data-cy="search-bar">
    <div @keydown.enter="onSubmit">
      <span class="p-input-icon-right">
        <InputText
          v-model="searchTerm"
          type="search"
          :placeholder="placeholder"
          data-cy="search-input"
        />
        <i class="pi pi-search" />
      </span>
    </div>
  </div>
</template>

<script>
import InputText from "primevue/inputtext";

export default {
  name: "SearchBar",
  components: {
    InputText,
  },
  props: {
    placeholder: {
      default: "Search",
      type: String,
    },
    initialSearchTerm: {
      default: "",
      type: String,
    },
  },
  emits: ["onSubmit"],
  data() {
    return {
      searchTerm: "",
      lastSearchedTerm: "",
    };
  },
  created() {
    this.searchTerm = this.initialSearchTerm;
  },
  methods: {
    onSubmit() {
      if (this.searchTerm !== this.lastSearchedTerm) {
        this.$emit("onSubmit", this.searchTerm);
        this.lastSearchedTerm = JSON.parse(JSON.stringify(this.searchTerm));
      }
    },
  },
};
</script>
