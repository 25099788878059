import { ref } from "vue";
import { defineStore } from "pinia";
import { useConfigurationRequests } from "@/services/utils/configurationRequests";
import { useTemplateMappingsToastService } from "@/components/template-data-mapping/helpers/toastConfigs.js";
import { DIALOG_RESULTS } from "@/constants";
import { templateMappingErrorMapper } from "@/components/template-data-mapping/helpers/errors";
import {
  CREATE_ACTION,
  EDIT_ACTION,
} from "@/components/template-data-mapping/helpers/constants";
import TemplateMappingsApiRequests from "@/components/template-data-mapping/helpers/apiRequests";

export const useTemplateMappingsStore = defineStore("templateMappings", () => {
  // State
  const templateMappings = ref([]);
  const lastAddedMapping = ref("");

  // Actions
  async function getTemplateMappings() {
    const { response } = await makeRequest({
      endpoint: async () => await TemplateMappingsApiRequests.getDataMappings(),
    });
    templateMappings.value = response.value || [];
  }

  async function createTemplateMapping(templateMappingName, handlebarMappings) {
    const { response, error } = await makeRequest({
      endpoint: () =>
        TemplateMappingsApiRequests.createTemplateDataMapping({
          name: templateMappingName,
          handlebarMappings: handlebarMappings,
        }),
      onSuccess: () => displayTemplateMappingCreated(templateMappingName),
      errorMapper: (errors) =>
        templateMappingErrorMapper(errors, {
          templateMappingName,
          action: CREATE_ACTION,
        }),
    });
    if (!error.value) {
      lastAddedMapping.value = response.value
        .filter(
          ({ id: id1 }) =>
            !templateMappings.value.some(({ id: id2 }) => id2 === id1),
        )
        .find((elem) => elem.name === templateMappingName);
      templateMappings.value = response.value;
      return DIALOG_RESULTS.CREATED;
    }
    return DIALOG_RESULTS.FAILED;
  }

  async function updateTemplateMapping(
    dataMappingId,
    templateMappingName,
    handlebarMappings,
  ) {
    const { response, error } = await makeRequest({
      endpoint: () =>
        TemplateMappingsApiRequests.updateTemplateDataMapping({
          id: dataMappingId,
          name: templateMappingName,
          handlebarMappings: handlebarMappings,
        }),
      onSuccess: () => displayTemplateMappingUpdated(templateMappingName),
      errorMapper: (errors) =>
        templateMappingErrorMapper(errors, {
          templateMappingName,
          action: EDIT_ACTION,
        }),
    });
    if (!error.value) {
      templateMappings.value = response.value;
    }
  }

  async function deleteTemplateMapping(template) {
    const { response, error } = await makeRequest({
      endpoint: () =>
        TemplateMappingsApiRequests.deleteTemplateDataMapping(template.id),
      onSuccess: () => displayDeleteSuccessToast(template.name),
      errorMapper: (errors) =>
        templateMappingErrorMapper(errors, {
          templateMappingName: template.name,
        }),
    });
    if (!error.value) {
      templateMappings.value = response.value.data;
    }
  }

  async function bulkDeleteTemplateMappings(templates) {
    await makeBulkRequest({
      endpoint: TemplateMappingsApiRequests.deleteTemplateDataMapping.bind(
        TemplateMappingsApiRequests,
      ),
      endpointArgs: templates.map((t) => t.id),
      onFail: () => displaySelectAllDeleteFailToast(),
      onPartialSuccess: () => displaySelectAllDeletePartialToast(),
      onSuccess: () => displaySelectAllDeleteSuccessToast(),
      onEachSuccessRequest: (_response) => replaceTemplateMapping(_response),
    });
  }
  function replaceTemplateMapping(templates) {
    templateMappings.value = templates;
  }

  async function duplicateTemplateMapping(template) {
    const { response, error } = await makeRequest({
      endpoint: () =>
        TemplateMappingsApiRequests.duplicateDataMapping(template.id),
      onSuccess: () => {
        displayDuplicateSuccessToast(template.name);
      },
    });
    if (!error.value) {
      templateMappings.value = response.value;
    }
  }

  const { makeRequest, makeBulkRequest } = useConfigurationRequests();
  const {
    displaySelectAllDeleteSuccessToast,
    displaySelectAllDeletePartialToast,
    displaySelectAllDeleteFailToast,
    displayDeleteSuccessToast,
    displayTemplateMappingCreated,
    displayTemplateMappingUpdated,
    displayDuplicateSuccessToast,
  } = useTemplateMappingsToastService();

  return {
    templateMappings,
    lastAddedMapping,
    getTemplateMappings,
    createTemplateMapping,
    updateTemplateMapping,
    deleteTemplateMapping,
    bulkDeleteTemplateMappings,
    duplicateTemplateMapping,
  };
});
