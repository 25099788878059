// shipment statuses
export const INITIALIZED_STATUS = "initialized";
export const IMPORTED_STATUS = "imported";
export const ERROR_STATUS = "error";
export const ROUTING_FAILED_STATUS = "routing_failed";
export const ROUTED_STATUS = "routed";

export const STATUS_DISPLAY_MAP = {
  [ROUTED_STATUS]: "Campaign Assigned",
  [ROUTING_FAILED_STATUS]: "No Campaign Assigned",
  [ERROR_STATUS]: "Import Failed",
};

// event statuses
const UNKNOWN = "UNKNOWN";
const IMPORTED = "IMPORTED";
const MANIFESTED = "MANIFESTED";
const INFORMATION = "INFORMATION";
const IN_TRANSIT = "IN_TRANSIT";
const OUT_FOR_DELIVERY = "OUT_FOR_DELIVERY";
const DELIVERED = "DELIVERED";
const EXCEPTION = "EXCEPTION";
const ATTEMPTED_DELIVERY = "ATTEMPTED_DELIVERY";
const DESPATCHED = "DESPATCHED";
const FIRST_SCAN = "FIRST_SCAN";
export const UNAVAILABLE = "UNAVAILABLE";

export const PENDING_NOTIFICATION_STATUS = "Pending";
export const TRIGGERED_NOTIFICATION_STATUS = "Triggered";
export const FAILED_NOTIFICATION_STATUS = "Failed";

export const NO_DATA = "N/A";

export const EVENT_STATUS_DISPLAY_MAP = {
  [IMPORTED]: "Imported",
  [MANIFESTED]: "Manifested",
  [FIRST_SCAN]: "First Scan",
  [DESPATCHED]: "Dispatched",
  [IN_TRANSIT]: "In Transit",
  [OUT_FOR_DELIVERY]: "Out For Delivery",
  [DELIVERED]: "Delivered",
  [ATTEMPTED_DELIVERY]: "Attempted Delivery",
  [EXCEPTION]: "Exception",
  [INFORMATION]: "Information",
  [UNKNOWN]: "Unknown",
  [UNAVAILABLE]: "Unavailable",
};

const ALLOWED_DUMMY_EVENTS = [
  IMPORTED,
  DESPATCHED,
  IN_TRANSIT,
  EXCEPTION,
  OUT_FOR_DELIVERY,
  ATTEMPTED_DELIVERY,
  DELIVERED,
];

const DELETED_EVENTS = [DESPATCHED];

export const DUMMY_EVENT_CHOICES = ALLOWED_DUMMY_EVENTS.map((allowedEvent) => {
  return {
    label: EVENT_STATUS_DISPLAY_MAP[allowedEvent],
    value: allowedEvent,
  };
}).filter((choice) => !DELETED_EVENTS.includes(choice.value));

// filter choices
export const TRACKING_EVENT_FILTER_CHOICES = [
  IMPORTED,
  IN_TRANSIT,
  OUT_FOR_DELIVERY,
  DELIVERED,
  ATTEMPTED_DELIVERY,
  EXCEPTION,
]
  .filter((status) => !DELETED_EVENTS.includes(status))
  .map((status) => EVENT_STATUS_DISPLAY_MAP[status]);
export const ROUTE_STATUS_FILTER_CHOICES = Object.values(STATUS_DISPLAY_MAP);

// action statuses
export const SUCCESS_STATUS = "success";
export const FAIL_STATUS = "fail";
export const SCHEDULED_STATUS = "scheduled";

// notification configurations
export const LATEST_VERSION = "latest";

export const IMPORT_FEEDBACK_OK = "OK";
export const IMPORT_FEEDBACK_ERROR = "ERROR";

export const DIALOG_RESULTS = {
  CANCELLED: "CANCELLED",
  CREATED: "CREATED",
  UPDATED: "UPDATED",
  FAILED: "FAILED",
};

// form actions
export const CREATE = "create";
export const EDIT = "edit";

// lifecycle statuses
export const ACTIVE = "active";
export const COMPLETED = "completed";
export const EXPIRED = "expired";

// tracking page
export const GENERIC_TEMPLATE = "generic";
export const ORDER_DETAILS_TEMPLATE = "order_details";

export const LEFT_IMAGE = "left";
export const RIGHT_IMAGE = "right";
export const BOTTOM_IMAGE = "bottom";

const _sides_desktop = {
  width: 424,
  height: 576,
};

const _mobile = {
  width: 720,
  height: 224,
};

export const IMAGE_DIMENSIONS_MAP = {
  [LEFT_IMAGE]: {
    desktop: _sides_desktop,
    mobile: _mobile,
  },
  [RIGHT_IMAGE]: {
    desktop: _sides_desktop,
    mobile: _mobile,
  },
  [BOTTOM_IMAGE]: {
    desktop: {
      width: 1320,
      height: 360,
    },
    mobile: _mobile,
  },
};

export const SUPPORT_LINK =
  "https://support.scurri.co.uk/hc/en-us/requests/new";
