import { useConfirmDialog } from "@/services/confirm-modals/base";
import { getDiscardChangesConfig } from "@/services/utils/utils";

const confirmWorkflowDuplicationConfig = () => {
  return {
    header: "Duplicate Automation?",
    message: "Are you sure you want to duplicate this automation?",
    acceptLabel: "Duplicate",
  };
};

const confirmWorkflowActivationConfig = (hasActiveWorkflow) => {
  const message = hasActiveWorkflow
    ? `There can only be one active automation at a time. By activating this automation, it will deactivate
    the existing automation. Are you sure you want to proceed?`
    : "Are you sure you want to activate this automation?";
  return {
    header: "Activate Automation?",
    message,
    acceptLabel: "Activate",
  };
};

const confirmCancelWorkflowFormConfig = (item, action) => {
  return getDiscardChangesConfig(item, action);
};

const confirmDeleteWorkflowRouteConfig = () => {
  return {
    header: "Delete Campaign?",
    message:
      "You're about to delete this campaign. This action cannot be undone. Are you sure you want to proceed?",
    acceptLabel: "Delete",
    acceptIcon: "pi pi-trash",
    acceptClass: "p-button p-button-danger",
    rejectLabel: "Cancel",
    rejectClass: "p-button p-button-text",
  };
};

const confirmSetToInactiveLastRouteConfig = () => {
  return {
    header: "Deactivate Campaign?",
    message: `Deactivating this campaign will result in no active campaigns.
        Continuing will prevent shipments from being assigned a campaign. Are you sure you want to proceed?`,
    acceptLabel: "Deactivate",
    acceptClass: "p-button p-button-danger",
    rejectLabel: "Cancel",
    rejectClass: "p-button p-button-text",
  };
};

export const useWorkflowsConfirmDialog = () => {
  const { confirmAction } = useConfirmDialog();

  const confirmWorkflowDuplication = ({ callback }) => {
    return confirmAction({
      callback,
      ...confirmWorkflowDuplicationConfig(),
    });
  };

  const confirmWorkflowActivation = ({ callback, hasActiveWorkflow }) => {
    return confirmAction({
      callback,
      ...confirmWorkflowActivationConfig(hasActiveWorkflow),
    });
  };

  const confirmCancelWorkflowForm = ({ callback, item, action }) => {
    return confirmAction({
      callback,
      ...confirmCancelWorkflowFormConfig(item, action),
    });
  };

  const confirmDeleteWorkflowRoute = ({ callback }) => {
    return confirmAction({
      callback,
      ...confirmDeleteWorkflowRouteConfig(),
    });
  };
  const confirmSetToInactiveLastRoute = ({ callback }) => {
    return confirmAction({
      callback,
      ...confirmSetToInactiveLastRouteConfig(),
    });
  };

  return {
    confirmWorkflowDuplication,
    confirmWorkflowActivation,
    confirmCancelWorkflowForm,
    confirmDeleteWorkflowRoute,
    confirmSetToInactiveLastRoute,
  };
};
