<template>
  <div
    class="no-route-assigned-details mb-4"
    data-cy="routing-failed-shipments-details"
  >
    <ShipmentsActionBar
      :message="description"
      severity="warn"
      icon="pi pi-exclamation-triangle"
      :btn-config="btnConfig"
      @button-primary-action="confirmReEvaluate"
      @button-secondary-action="openListFiltersDialog"
    />
    <div class="tab-actions-bar d-flex flex-wrap justify-content-end"></div>
  </div>
</template>

<script setup>
import { useDynamicSidebar } from "@/store/dynamicSidebar";
import ShipmentsActionBar from "@/components/shipments/ShipmentsActionBar.vue";
import ListFilters from "@/components/filters/ListFilters";

defineProps({
  description: {
    type: String,
    required: true,
  },
});

const btnConfig = {
  btnPrimary: {
    icon: "pi pi-sync",
    label: "Recheck",
  },
  btnSecondary: {
    icon: "pi pi-chart-pie",
    label: "Segments",
  },
};

const emit = defineEmits(["confirm-re-evaluate"]);

const confirmReEvaluate = () => {
  emit("confirm-re-evaluate");
};

const sidebar = useDynamicSidebar();
const openListFiltersDialog = () => {
  sidebar.open(ListFilters);
};
</script>
