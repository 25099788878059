<template>
  <div v-if="notificationsData.no_actions_info" class="notification-row">
    <div class="notification-status waiting">
      <span
        v-tooltip.top="{
          value: notificationsData.no_actions_info.tooltip,
          id: 'ListShipmentsTableNotificationsTooltip',
        }"
        >{{ notificationsData.no_actions_info.msg }}</span
      >
    </div>
  </div>
  <div v-else class="notification-row">
    <div v-for="(value, key) in notificationsData" :key="key">
      <span
        v-if="displayNotificationBadge(key)"
        v-tooltip.top="{
          value: value.tooltip,
          id: 'ListShipmentsTableNotificationsTooltip',
        }"
        :class="'notification-status ' + key"
      >
        <i :class="'pi ' + value.icon"></i><span>{{ value.count }}</span>
      </span>
    </div>
  </div>
</template>
<script setup>
import { toRefs } from "vue";
import { PENDING_NOTIFICATION_STATUS } from "@/constants";

const props = defineProps({
  notificationsData: {
    type: Object,
    default: () => {},
  },
  hidePending: {
    type: Boolean,
    default: false,
  },
});

const { hidePending } = toRefs(props);

const displayNotificationBadge = (notificationStatus) => {
  return (
    !hidePending.value ||
    notificationStatus.toLowerCase() !==
      PENDING_NOTIFICATION_STATUS.toLowerCase()
  );
};
</script>
